import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfigService } from "./appconfig.service";
import { ApiURLConstants } from "../helpers/apiURLConstants";
import {
  AccountRequestPayload,
  AccountRequestResponse,
  SetDefaultAccountRequestPayload,
  RequestResponse,
  GetCustomerBVNRequest,
  SMEUserCorporateLimitResponse,
} from "../models/account";
import { _mockAccountList } from "../models/mocks/_accounts.mock";
import { AuthService } from "./auth.service";
import { GenericApiResponse } from "../models/payloads/generic-response";
// import { Observable } from '../../../../node_modules/rxjs';
import { Observable, of } from "rxjs";

@Injectable()
export class AccountService {
  private _apiBaseUrl: string;
  useMock: boolean;
  constructor(
    private _httpClient: HttpClient,
    private _settingsService: AppConfigService
  ) {
    this._apiBaseUrl = this._settingsService.APIBaseUrl;
    this.useMock = _settingsService.UseMockData;
  }

  getAccountsByCIFID(
    payload?: any,useMock: boolean = false): Observable<AccountRequestResponse> {
    payload = payload && payload["CifId"]  ? payload : this.buildAccountsRequestPayload();
        

            // if (useMock) {
            //     return of({
            //         ResponseCode: '00', ResponseFriendlyMessage: 'success', defaultAccount: _mockAccountList[0].accountNumber,
            //         Accounts: _mockAccountList
            //     });
            // }

    return this._httpClient.post<AccountRequestResponse>(`${this._apiBaseUrl}/${ApiURLConstants.CustomerAccounts}`,payload);
  }

  getAccountsByUserID(userId: string): Observable<AccountRequestResponse> {
   
        return this._httpClient.post<AccountRequestResponse>(`${this._apiBaseUrl}/${ApiURLConstants.SMECustomerAccounts}`,
            { UserId: userId }
        );

    }



    setDefaultAccount(accountId: string): Promise<RequestResponse> {
        const requestPayload = this.buildSetDefaultAccountRequestpayload(accountId);
        return this._httpClient.post<RequestResponse>(`${this._apiBaseUrl}/AccountManagement/SetDashBoardDefaultAccount`, requestPayload)
            .toPromise();
    }

    private buildSetDefaultAccountRequestpayload(accountId: string): SetDefaultAccountRequestPayload {
        const userAuthData = AuthService.FullyAuthenticatedUser;
        return {
            UserId: userAuthData && userAuthData['UserId'] ? userAuthData.UserId : '',
            SessionId: userAuthData && userAuthData['SessionId'] ? userAuthData.SessionId : '',
            AccountNo: accountId
        };
    }
    private buildAccountsRequestPayload(): AccountRequestPayload {
        const userAuthData = AuthService.FullyAuthenticatedUser;
        return {
            CifId: userAuthData && userAuthData.CifId ? userAuthData.CifId : '',
            userId: userAuthData && userAuthData.UserId ? userAuthData.UserId : '',
            SessionId: userAuthData && userAuthData.SessionId ? userAuthData.SessionId : ''
        };
    }

    getCustomerBVN(payload: GetCustomerBVNRequest): Observable<GenericApiResponse> {
        return this._httpClient.post<GenericApiResponse>(`${this._apiBaseUrl}/${ApiURLConstants.CustomerBVN}`,
            payload
        );
    }


    getSMEUserCorporateLimit(userId: string): Observable<SMEUserCorporateLimitResponse> {
        return this._httpClient.post<SMEUserCorporateLimitResponse>(`${this._apiBaseUrl}/${ApiURLConstants.SMEUserCorporateLimit}`,
            { UserId: userId }
        );
    }

    private getMockAccountListResponse(): Observable<AccountRequestResponse> {
        const res = {
            "ResponseCode": "00",
            "ResponseDescription": "Accounts fetched successfully",
            "ResponseFriendlyMessage": "Accounts fetched successfully",
            "Accounts": [{
                "accountNumber": "0006897146",
                "accountName": "AYODELE  OLATUNDE  AYETIGBO",
                "debitAllowed": true,
                "creditAllowed": true,
                "accountType": "SAVINGS",
                "accountCategory": "SB001",
                "effectiveBalance": 15823.26,
                "availableBalance": 15323.26,
                "currency": "NGN"
            }, {
                "accountNumber": "0013621657",
                "accountName": "AYODELE  OLATUNDE  AYETIGBO",
                "debitAllowed": true,
                "creditAllowed": true,
                "accountType": "CURRENT",
                "accountCategory": "OD003",
                "effectiveBalance": 1010819.21,
                "availableBalance": 1010819.21,
                "currency": "NGN"
            }, {
                "accountNumber": "0015934988",
                "accountName": "AYODELE OLATUNDE AYETIGBO",
                "debitAllowed": false,
                "creditAllowed": true,
                "accountType": "VISA CREDIT",
                "accountCategory": "ODVIS",
                "effectiveBalance": 284.35,
                "availableBalance": 284.35,
                "currency": "NGN"
            }, {
                "accountNumber": "0022390409",
                "accountName": "AYODELE O AYETIGBO",
                "debitAllowed": false,
                "creditAllowed": false,
                "accountType": "UNSECURED PERSONAL LOAN",
                "accountCategory": "USPLN",
                "effectiveBalance": -2000000.0,
                "availableBalance": 0.0,
                "currency": "NGN"
            }],
            "DefaultAccount": "0006897146"
        } as {} as AccountRequestResponse;

        return of(res);
    }




}
