import { BaseApprovalComponent } from '../approvals/base.approval.component';
import { AuthService } from 'src/app/core/services/auth.service';
import { MatDialog, MatDialogRef, PageEvent } from '@angular/material';
import { ApprovalHistoryComponent } from 'src/app/shared/approval-history/approval-history.component';

export class BasePendingRequestComponent extends BaseApprovalComponent {

  ownLoaderIsActive: boolean;
  ownloaderMsg: string;
    constructor(authService: AuthService, public _dialog: MatDialog) {
        super(authService);
    }

    protected buildRequestApprovalHistoryPayload(data: any, operationType: string, pageSize: number = 100, start: number = 0 ) {
        return  {
          OperationType: operationType,
          OperationId: data.Id,
          UserId: '',
          PageSize: pageSize,
          LastIdFetched: start
        };
      }

    protected launchRequestHistoryModal(payload: any, operationName: string, title: string, extra: any = {}):
      MatDialogRef<ApprovalHistoryComponent, any> {
        return this._dialog.open<ApprovalHistoryComponent>(
          ApprovalHistoryComponent,
          {
            hasBackdrop: false,
            closeOnNavigation: true,
            data: {
              operationName: operationName,
              requestPayload: payload,
              title: title,
              extra: extra
            }
          });
      }

    public  onTransactionsPageChanged(pageChangedEvent: PageEvent, iterableCollection: Array<any>, metaCollection: Array<any>) {
        const start = pageChangedEvent.pageIndex * pageChangedEvent.pageSize;
        const end = start + pageChangedEvent.pageSize;
        iterableCollection = metaCollection.slice(start, end);
      }
}
