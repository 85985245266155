import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiURLConstants } from '../helpers/apiURLConstants';
import { AuthService } from './auth.service';
import { CardFundingRequest, CardFundingRequestResponse } from '../models/card.models';
import { AppConfigService } from './appconfig.service';

@Injectable()
export class CardService {

    private _apiBaseUrl: string;
    constructor(private _httpClient: HttpClient, private _authService: AuthService, private _settings: AppConfigService) {
        this._apiBaseUrl = _settings.APIBaseUrl;
    }

    getPrepaidCardDetails(customerId: string, cardType: string): Observable<CardFundingRequestResponse> {
        const requestPayload = this.updateRequestPayload({customerId: customerId, cardType: cardType});
        console.log(requestPayload);
        return this._httpClient.post<any>(`${this._apiBaseUrl}/${ApiURLConstants.GET_PREPAID_CARD_DETAILS}`, requestPayload);
    }

    fundPrepaidCard(paylod: CardFundingRequest): Observable<any> {
        paylod = this.updateCardFundingRequestPayload(paylod);
        return this._httpClient.post<any>(`${this._apiBaseUrl}/${ApiURLConstants.FUND_PREPAID_CARD}`, paylod);
    }



    private updateRequestPayload(payload) {
        const loggedInUser = this._authService.getLoggedInUser();
        payload['userId'] = loggedInUser && loggedInUser.UserId ? loggedInUser.UserId : '';
        return payload;
    }

    private updateCardFundingRequestPayload(payload: CardFundingRequest) {
        const loggedInUser = this._authService.getLoggedInUser();
        payload['userId'] = loggedInUser && loggedInUser.UserId ? loggedInUser.UserId : '';
        payload.sessionId = loggedInUser['SessionId'] ? loggedInUser['SessionId'] : ' ';
        payload.cif = loggedInUser['CifId'] ? loggedInUser['CifId'] : '';
        return payload;
    }
}
