import { RouterModule, Route } from "@angular/router";
import { NgModule } from "@angular/core";
// import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DefaultLayoutComponent } from "./pages/containers/default-layout/default-layout.component";
// import { LoginComponent } from './pages/login/login.component';
// import { AuthComponent } from './pages/auth/auth.component';
import { ProfileComponent } from "./pages/profile/profile.component";
import { TransactionStatusComponent } from "./pages/transaction-status/transaction-status.component";
import { FxTransactionRequest } from "./pages/fx-transactions/fx-transaction-request";

// import { RouterConstants } from './core/helpers/routerConstants';
// import { TransactionStatusComponent } from './pages/transaction-status/transaction-status.component';

// let loginUrl = RouterConstants.LoginURL;

export const routes: Route[] = [
  {
    path: "",
    redirectTo: "auth",
    pathMatch: "full",
  },
  {
    path: "auth",
    loadChildren:
      "src/app/pages/authentication/authentication.module#AuthenticationPagesModule",
  },

  // {
  //     path: 'authaction/:action',
  //     component: AuthActionComponent
  // },
  {
    path: "",
    component: DefaultLayoutComponent,
    children: [
      {
        path: "accounts",
        loadChildren: "src/app/pages/account/accounts.module#AccountModule",
      },

      {
        path: "transfers",
        loadChildren: "src/app/pages/transfer/transfer.module#TransferModule",
      },
      {
        path: "fx",
        loadChildren: "src/app/pages/fx-transactions/fx-transactions.module#FxRequestModule"

      },
      {
        path: "international-payments",
        loadChildren:
          "src/app/pages/internationalpayments/internationalpayments.module#InternationalpaymentsModule",
      },
      {
        path: "beneficiaries",
        loadChildren:
          "src/app/pages/beneficiaries/beneficiaries.module#BeneficiaryModule",
      },
      {
        path: "pay-bills",
        loadChildren:
          "src/app/pages/bill-payment/billpayments.module#BillPaymentModule",
      },
      {
        path: "billers",
        loadChildren:
          "src/app/pages/billers/billers.module#BillerBeneficiaryModule",
      },
      {
        path: "3sbanking",
        loadChildren:
          "src/app/pages/three-s-banking/three-s-banking.module#ThreeSBankingModule",
      },
      {
        path: "bento",
        loadChildren:
          "src/app/pages/bento/bento.module#BentoModule",
      },
      // {
      //     path: '3sbanking',
      //     loadChildren: 'src/app/pages/three-s-banking/three-s-banking.module#ThreeSBankingModule'
      // },
      {
        path: "receipts",
        loadChildren: "src/app/pages/receipts/receipts.module#ReceiptModule",
      },
      {
        path: "schedule-payments",
        loadChildren:
          "src/app/pages/scheduled-payments/schedule-payments.module#SchedulePaymentModule",
      },
      {
        path: "buy-airtime",
        loadChildren:
          "src/app/pages/buy-airtime/airtime-topup.module#AirtimeTopUpModule",
      },
      {
        path: "service-request",
        loadChildren:
          "src/app/pages/service-request/service-request.module#ServiceRequestModule",
      },

      {
        path: "approvals",
        loadChildren:
          "src/app/pages/approvals/approvals.module#ApprovalsModule",
      },
      {
        path: 'flights',
        loadChildren: 'src/app/pages/wakanow-integration/wakanow-integration.module#WakanowIntegrationModule'
      },
      // {
      //     path: 'profile',
      //     component: ProfileComponent
      // },
      // {
      //     path: 'service-request',
      //     component: ServiceRequestComponent
      // },
      {
        path: "profile",
        loadChildren: "src/app/pages/profile/profile.module#ProfileModule",
      },
      /* {
            {
                path: 'transfers',
                loadChildren: "src/app/pages/transfer/transfer.module#TransferModule"
            },
            {
                path: 'international-payments',
                loadChildren: "src/app/pages/internationalpayments/internationalpayments.module#InternationalpaymentsModule"
            },
            {
                path: 'beneficiaries',
                loadChildren: "src/app/pages/beneficiaries/beneficiaries.module#BeneficiaryModule"
            },
            {
                path: 'pay-bills',
                loadChildren: "src/app/pages/bill-payment/billpayments.module#BillPaymentModule"
            },
            {
                path: 'billers',
                loadChildren: "src/app/pages/billers/billers.module#BillerBeneficiaryModule"
            },
            // {
            //     path: '3sbanking',
            //     loadChildren: 'src/app/pages/three-s-banking/three-s-banking.module#ThreeSBankingModule'
            // },
            {
                path: 'receipts',
                loadChildren: 'src/app/pages/receipts/receipts.module#ReceiptModule'
            },
            {
                path: 'schedule-payments',
                loadChildren: 'src/app/pages/scheduled-payments/schedule-payments.module#SchedulePaymentModule'
            },
            {
                path: 'buy-airtime',
                loadChildren: 'src/app/pages/buy-airtime/airtime-topup.module#AirtimeTopUpModule'
            },
            {
                path: 'service-request',
                loadChildren: 'src/app/pages/service-request/service-request.module#ServiceRequestModule'
            },

            {
                path: 'approvals',
                loadChildren: 'src/app/pages/approvals/approvals.module#ApprovalsModule'
            },
            {
                path: 'credit-cards',
                loadChildren: 'src/app/pages/credit-card-management/credit-card-management.module#CreditCardManagementModule'
            },
            // {
            //     path: 'flights',
            //     loadChildren: 'src/app/pages/wakanow-integration/wakanow-integration.module#WakanowIntegrationModule'
            // },
            // {
            //     path: 'profile',
            //     component: ProfileComponent
            // },
            // {
            //     path: 'service-request',
            //     component: ServiceRequestComponent
            // },
            {
                path: 'profile',
                loadChildren: 'src/app/pages/profile/profile.module#ProfileModule'
            },
            /* {
                path: 'transactions-status',
                loadChildren: 'src/app/pages/transaction-status/transactionstatus.module#TransactionsStatusModule'
            }, */
      {
        path: "transactions-status",
        component: TransactionStatusComponent,
      },
      {
        path: "payment/transactions",
        loadChildren:
          "src/app/pages/payment-transactions/payment-transactions.module#PaymentTransactionsModule",
      },
      {
        path: "upl-loan",
        loadChildren: "src/app/pages/loan/loan.module#LoanFeatureModule",
      },
      {
        path: 'hardware-token',
        loadChildren: 'src/app/pages/hardware-token/hardware-token.module#HardwareTokenModule'
      },
      {
        path: "international-payments",
        loadChildren:
          "src/app/pages/internationalpayments/internationalpayments.module#InternationalpaymentsModule",
      },
      {
        path: "fraud-management",
        loadChildren:
          "src/app/pages/intellinx-management/intellinx-management.module#IntellinxManagementModule",
      },
      {
        path: "corporate-billers",
        loadChildren:
          "src/app/pages/corporate-billers/corporate-billers.module#CorporateBillersModule",
      },
      {
        path:'fx-transactions',
        loadChildren:'src/app/pages/manage-fx-transaction/manage-fx-transaction.module#ManageFxTransactionModule'
      },
      {
        path:'e-naira',
        loadChildren: 'src/app/pages/enaira-management/enaira-management.module#ENairaModule'
      },
      {
        path: 'virtual-card',
        loadChildren: 'src/app/pages/virtual-card/virtual-card.module#VirtualCardModule'
      },
      {
        path: 'credit-cards',
        loadChildren: 'src/app/pages/credit-card-management/credit-card-management.module#CreditCardManagementModule'
    },
    {
      path: 'debitcardautorenewal',
      loadChildren: 'src/app/pages/DebitCardAutoRenewal/debitcard-autorenewal/debitcard-autorenewal.module#DebitcardAutorenewalModule'
  },
    ],
  },
];
@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
