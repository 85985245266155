import { Component, OnInit, ViewChild, TemplateRef, Input, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { MatSnackBar, MatDialogRef, MatDialog, MatStepper, PageEvent } from '@angular/material';
import { NotificationsService } from 'angular2-notifications';
import { StagedBeneficiaryRequestObject, StagedBeneficiaryRequestApprovalPayload, StagedBeneficiaryRequestReponse } from 'src/app/core/models/beneficiary';
import { ApprovalRequestStatus, OtpReasonCodes, ResponseCodes } from 'src/app/core/models/Enumerations';
import { NgForm } from '@angular/forms';
import { BeneficiaryStagingService } from 'src/app/core/services/staging/beneficiary.staging.service';
import { MiscService } from 'src/app/core/services/misc.service';
import { BeneficiaryApprovalService } from 'src/app/core/services/workflow/beneficiary.approval.service';
import { ApprovalStage } from 'src/app/core/models/payloads/transactions';
import { RejectRequestComponent } from 'src/app/shared/dialogs/reject-request/reject-request.component';
import { BaseApprovalComponent } from '../base.approval.component';
import { AuthService } from 'src/app/core/services/auth.service';
import { UtilService } from 'src/app/core/services/helpers.service';
import { RequestApprovalHistoryPayload } from 'src/app/core/models/misc-requests-model';
import { ApprovalHistoryComponent } from 'src/app/shared/approval-history/approval-history.component';

@Component({
  selector: 'app-beneficiariry-approvals',
  templateUrl: './beneficiariry-approvals.component.html',
  styleUrls: ['./beneficiariry-approvals.component.css']
})
export class BeneficiariryApprovalsComponent extends BaseApprovalComponent implements OnInit, OnChanges, OnDestroy {

  ownLoaderIsActive: boolean;
  ownloaderMsg: string;
  _activeRequestCount = 0;

  @Input() _activeTab: string;
  requestList: StagedBeneficiaryRequestObject[] = [];
  requestListBackup: StagedBeneficiaryRequestObject[] = [];
  _selectedRequestCount = 0;
  requestDetailModalData = {
    currentRequestAction: '',
    currentApprovalAction: '',
    data: null,
    requestMeta: {} as any
  };
  filterIsActive: boolean;
  approvalStatusEnum = ApprovalRequestStatus;
  searchFilter = this.initSearchFilterParams();
  _otpReferenceId: string;
  oneTimePassCode: string;
  _otpInstruction: string;

  _paymentApprovalCompleted: boolean;
  _availableApprovalStages: Array<ApprovalStage> = [];
  @ViewChild('beneficiaryDetailModal') _beneficiarydetailModalTemplate: TemplateRef<any>;
  public requestDetailModal: MatDialogRef<any>;
  @ViewChild('beneficiaryEditModal') _beneficiaryEditModalTemplate: TemplateRef<any>;
  public editApprovalModal: MatDialogRef<any>;

  private approvalHistoryModal: MatDialogRef<any>;
  private rejectionDialog: MatDialogRef<any>;

  constructor(private _snackbar: MatSnackBar, private _toast: NotificationsService,
    private _stagedRequestsService: BeneficiaryStagingService, private _miscService: MiscService, authService: AuthService,
    private _dialogService: MatDialog, private _approvalService: BeneficiaryApprovalService) {
    super(authService);
    // try {
    //   window['casa']('other'); 
    //  } catch (error) {}


    this.pageTitle = this._loggedInUser && authService.loggedInUserHasSMEProfile() && this._loggedInUserIsApprover ?
      'Authorize Beneficiary Management Requests' : '';
    UtilService.onPageLoadCallback();


  
  }

  ngOnInit() {
    // this.fetchBeneficiaryApprovalRequests();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['_activeTab']) {
      this._activeTab = changes['_activeTab'].currentValue;
      if (this._activeTab === 'beneficiaries') {
        this.fetchBeneficiaryApprovalRequestsAlt();
      }
      if (this._activeTab !== 'beneficiaries') {
        this.dismissOpenModalWindows();
      }
    }
  }

  ngOnDestroy(): void {
    this.dismissOpenModalWindows();
  }

  fetchBeneficiaryApprovalRequestsAlt(loaderMsg = 'loading requests .. please wait', lastFetchedId: number = 0, pageSize: number = 100) {
    this.ownLoaderIsActive = true;
    this.ownloaderMsg = loaderMsg;
    this.fetchPendingBeneficiaryRequestAggregate(pageSize, lastFetchedId).then(
      (responseArray) => {
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        this.requestListBackup = [];
        responseArray.forEach((response, index) => {
          if (response && response.ResponseCode && response.ResponseCode === '00' && response.PaginatedItems) {
            if (index === 0) {
              response.PaginatedItems.forEach(obj => obj['operationType'] = 'ADD_BENEFICIARY');
            }
            this.requestListBackup = this.requestListBackup.concat(response.PaginatedItems);
          }
        });
        this.requestListBackup.forEach(req => {
          req['requestType'] = req['operationType'] === 'ADD_BENEFICIARY' ? 'Addition' :
            req['BeneficiaryDetails'].operationType === 'EDIT_BENEFICIARY' ? 'Update' :
              req['BeneficiaryDetails'].operationType === 'REMOVE_BENEFICIARY' ? 'Removal' : '';
        });


        this.requestList = this.requestListBackup.slice(0, 5);

      }
      ,
      (error) => {
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        this._snackbar.open('load pending approval requests failed with error => ', error);
      });
  }

  onRequestCheckboxChanged(evtData, postion: number) {
    this.requestList[postion]['checked'] = evtData.checked;
    const indexOnBackup = this.requestListBackup.indexOf(this.requestList[postion]);
    this.requestListBackup[indexOnBackup]['checked'] = evtData.checked;
    this._selectedRequestCount = this.requestListBackup.filter(request => request['checked']).length;
  }

  onRequestModalAction(action: string, stepper: MatStepper, form?: NgForm) {
    console.log(action);
    switch (action) {
      case 'approve': {
        this.initiateRequestApprovalOTP(stepper);
        break;
      }
      case 'decline': {
        const rejectionDialog = this._dialogService.open(RejectRequestComponent,
          {
            data: {
              title: 'Reject Beneficiary Approval Request',
              payload: {
                OperationType: 'ADD_BENEFICIARY',
                OperationId: this.requestDetailModalData.data.Id,
              }
            },
            hasBackdrop: false,
            closeOnNavigation: true,
            disableClose: true
          });
        rejectionDialog.afterClosed()
          .subscribe((result) => {
            if (result) {
              this.requestDetailModalData.currentRequestAction = 'requestComplete';
              this.fetchBeneficiaryApprovalRequestsAlt('refreshing .. please wait');
            }
          });
        this.requestDetailModal.close();
        break;
      }
    }
  }

  onAllCheckboxChanged(evtData) {
    if (evtData.checked) {
      this.requestListBackup.forEach(row => row['checked'] = true);
      this._selectedRequestCount = this.requestListBackup.length;
    } else {
      this.requestListBackup.forEach(b => b['checked'] = false);
      this._selectedRequestCount = 0;
    }
    this.requestList = this.requestListBackup.slice(0, 5);
  }

  onApprovalRequestAction(action: string, data: StagedBeneficiaryRequestObject) {
    this.requestDetailModalData.requestMeta['BatchId'] = data.BatchId;
    this.requestDetailModalData.requestMeta['Initiator'] = data.Initiator;
    this.requestDetailModalData.requestMeta['ApprovalStageId'] = data.ApprovalStageId;
    this.requestDetailModalData.requestMeta['ApproverId'] = data.ApproverId;

    // this.requestDetailModalData.data = data.NewBeneficiaryDetails;


    switch (action) {
      case 'view': {
        this.onViewRequestDetail(data);
        break;
      }
      case 'approve-edit': {
        this._launchEditApprovalModal(data);
        break;
      }
      case 'reject': {
        this.rejectionDialog = this._dialogService.open(RejectRequestComponent,
          {
            data: {
              title: 'Reject Beneficiary Approval Request',
              payload: {
                OperationType: data.NewBeneficiaryDetails['OperationType'],
                OperationId: data.NewBeneficiaryDetails.Id
              }
            },
            hasBackdrop: false,
            closeOnNavigation: true,
            disableClose: true
          });
        this.rejectionDialog.afterClosed()
          .subscribe((result) => {
            if (result) {
              this.fetchBeneficiaryApprovalRequestsAlt('refreshing .. please wait');
            }
          });
        break;
      }
      case 'view-history': {
        const requestPayload: RequestApprovalHistoryPayload = {
          OperationType: data['operationType'],
          OperationId: Number(data.NewBeneficiaryDetails.Id),
          UserId: '',
          PageSize: 100,
          LastIdFetched: 0
        };
        this.approvalHistoryModal = this._dialogService.open<ApprovalHistoryComponent>(
          ApprovalHistoryComponent,
          {
            hasBackdrop: false,
            data: {
              operationName: data['requestType'] && data['requestType'] === 'Addition' ? 'beneficiary-management-add' :
                data['requestType'] && data['requestType'] === 'Update' ? 'beneficiary-management-update' : '',
              requestPayload: requestPayload,
              title: 'Request Approval History',
              extra: {}
            }
          });
        this.approvalHistoryModal.afterClosed().subscribe(
          (result) => {
            //
          }
        );
        break;
      }
    }
  }

  onVerifyApprovalConfirmationOtp(form: NgForm, stepper: MatStepper) {
    if (!form.valid) {
      this._snackbar.open(`Invalid One Time Pin provided. Please provide a valid OTP to continue`, 'Ok', { duration: 10000 });
      return;
    }
    this.sendBeneficiaryApprovalRequest(this.requestDetailModalData.data as StagedBeneficiaryRequestObject, stepper);
  }

  oncloseRequestApprovalModal() {
    if (this.requestDetailModal) {
      this.requestDetailModal.close();
    }
    if (this.editApprovalModal) {
      this.editApprovalModal.close();
    }
    this.ownLoaderIsActive = false;
    this.ownloaderMsg = '';
    this.oneTimePassCode = '';
    this._otpReferenceId = '';
    this.requestDetailModalData.currentApprovalAction = '';
    this.requestDetailModalData.currentRequestAction = '';
  }

  onSelectedNextApprovalStageSubmitted(approvalStage: ApprovalStage, stepper: MatStepper) {
    this.ownLoaderIsActive = true;
    this.ownloaderMsg = 'setting next approval stage .. please wait';
    const requestPayload = this.buildBeneficiaryApprovalRequestPayload('ADD_BENEFICIARY'); // todo: Fix request type
    requestPayload.NextStages = [approvalStage];
    this.sendBeneficiaryApprovalRequestWithStageSelection(this.requestDetailModalData.data, stepper);
  }
  private sendBeneficiaryApprovalRequest(request: StagedBeneficiaryRequestObject, stepper: MatStepper) {
    this.ownLoaderIsActive = true;
    this.ownloaderMsg = `Approving .. please wait`;
    const operationName = this.getRequestOperationName(request['requestType']);
    const approvalRequestPayload = this.buildBeneficiaryApprovalRequestPayload(operationName);


    if (request['requestType'] != 'Addition') {

      this._approvalService.approvePendingBeneficiaryUpdateRequest(approvalRequestPayload).then(
        (response) => {
          console.log(response);
          this.ownLoaderIsActive = false;
          this.ownloaderMsg = '';
          if (!response || response.ResponseCode !== ResponseCodes.SUCCESS) {
            this.handleNonSuccessApprovalResponse(request, response, stepper);
            return;
          }
          this.requestDetailModalData.currentRequestAction = 'requestComplete';
          setTimeout(() => {
            stepper.next();
          }, 15);
          this.fetchBeneficiaryApprovalRequestsAlt('refreshing pending beneficiary approvals');
          this._snackbar.open(`${response.ResponseFriendlyMessage}`, 'Ok', { duration: 150000 });
        },
        (error: any) => {
          this.ownLoaderIsActive = false;
          this.ownloaderMsg = '';
          this._snackbar.open(`We encountered an error while approving request .. Please retry`, 'Ok', { duration: 250000 });
        }
      );
      return;
    }

    this._approvalService.approvePendingBeneficiaryAddRequest(approvalRequestPayload).then(
      (response) => {
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        if (!response || response.ResponseCode !== ResponseCodes.SUCCESS) {
          this.handleNonSuccessApprovalResponse(request, response, stepper);
          return;
        }
        this.requestDetailModalData.currentRequestAction = 'requestComplete';
        setTimeout(() => {
          stepper.next();
        }, 15);
        this.fetchBeneficiaryApprovalRequestsAlt('refreshing pending beneficiary approvals');
        this._snackbar.open(`${response.ResponseFriendlyMessage}`, 'Ok', { duration: 150000 });
      },
      (error: any) => {
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        this._snackbar.open(`We encountered an error while approving request .. Please retry`, 'Ok', { duration: 250000 });
      }
    );
  }

  private sendBeneficiaryApprovalRequestWithStageSelection(request: StagedBeneficiaryRequestObject, stepper: MatStepper) {
    this.ownLoaderIsActive = true;
    this.ownloaderMsg = `Approving .. please wait`;
    const operationName = this.getRequestOperationName(request['requestType']);
    const approvalRequestPayload = this.buildBeneficiaryApprovalRequestPayload(operationName);
    approvalRequestPayload['CurrentApprovalStageId'] = request.ApprovalStageId;
    this._approvalService.approvePendingBeneficiaryAddRequestWithSelectedStage(approvalRequestPayload).then(
      (response) => {
        console.log(response);
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        if (!response || response.ResponseCode !== ResponseCodes.SUCCESS) {
          this.handleNonSuccessApprovalResponse(request, response, stepper);
          return;
        }
        this.requestDetailModalData.currentRequestAction = 'requestComplete';
        setTimeout(() => {
          stepper.next();
        }, 15);
        this.fetchBeneficiaryApprovalRequestsAlt('refreshing pending beneficiary approvals');
        this._snackbar.open(`${response.ResponseFriendlyMessage}`, 'Ok', { duration: 50000 });
      },
      (error: any) => {
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        this._snackbar.open(`We encountered an error while approving request .. Please retry`, 'Ok', { duration: 25000 });
      }
    );
  }

  private getRequestOperationName(requestType: string) {
    switch (requestType) {
      case 'Addition': return 'ADD_BENEFICIARY';
      case 'Removal': return 'REMOVE_BENEFICIARY';
      case 'Update': return 'EDIT_BENEFICIARY';
    }
  }

  private handleNonSuccessApprovalResponse(request: StagedBeneficiaryRequestObject, response: any, stepper: MatStepper) {
    switch (response.ResponseCode) {
      case '70': {
        this.requestDetailModalData.currentApprovalAction = 'select-next-approval-stage';
        this._availableApprovalStages = response.Stages;
        setTimeout(() => {
          stepper.next();
        }, 50);
        break;
      }
      default: {
        this._snackbar.open(`${response.ResponseFriendlyMessage}`, 'Retry', { duration: 25000 }).onAction().subscribe(
          () => this.sendBeneficiaryApprovalRequest(request, stepper)
        );
        break;
      }
    }
  }
  private initiateRequestApprovalOTP(stepper: MatStepper, isResendOTP = false) {
    this.ownLoaderIsActive = true;
    this.ownloaderMsg = 'initiating .. please wait';
    this._miscService.initiateOtpRequest(OtpReasonCodes.GENERIC, 'APPROVE BENEFICIARY OPERATION').then(
      (response) => {
        this.ownloaderMsg = '';
        this.ownLoaderIsActive = false;
        if (!response || response.ResponseCode !== ResponseCodes.SUCCESS) {
          this._snackbar.open(`Failed to initiate approval process. Server responded with -> ${response.ResponseFriendlyMessage}`,
            'Try again', { duration: 25000 }).onAction().subscribe(() => this.initiateRequestApprovalOTP(stepper));
          return;
        }
        this._otpInstruction = `Please enter the One Time Pin sent to ${response.NotificationAddress} to continue`;
        this.requestDetailModalData.currentApprovalAction = 'verify-otp';
        this._otpReferenceId = response.ResponseDescription;
        if (!isResendOTP) {
          setTimeout(() => stepper.next(), 25);
        }
      },
      (error) => {
        console.log(error);
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        this._snackbar.open(`We encountered an error while processing your request. please retry`, 'Ok', { duration: 25000 });
      }
    );
  }



  private onViewRequestDetail(beneficiary: StagedBeneficiaryRequestObject) {
    this.requestDetailModalData.currentApprovalAction = '';
    this.requestDetailModalData.currentRequestAction = '';
    this.requestDetailModalData.data = beneficiary;

    this.requestDetailModal = this._dialogService.open(this._beneficiarydetailModalTemplate,
      { hasBackdrop: false, panelClass: 'beneficiary-details-card' });
  }

  _launchEditApprovalModal(data) {
    this.requestDetailModalData.data = data;
    // this.editApprovalModal = this._dialogService.open(this._beneficiaryEditModalTemplate,
    //   { hasBackdrop: false, panelClass: 'beneficiary-details-card' });


    this.editApprovalModal = UtilService.showBasicModal(this.editApprovalModal, this._beneficiaryEditModalTemplate, this._dialogService);



  }

  private buildBeneficiaryApprovalRequestPayload(operationName):
    StagedBeneficiaryRequestApprovalPayload {
    return {
      BatchId: Number(this.requestDetailModalData.requestMeta.BatchId),
      ApproverId: Number(this.requestDetailModalData.requestMeta.ApproverId),
      ApproverStageId: Number(this.requestDetailModalData.requestMeta.ApprovalStageId),
      OTP: this.oneTimePassCode,
      SourceReferenceId: this._otpReferenceId,
      OperationType: operationName, // 'ADD_BENEFICIARY',
      UserId: ''
    };
  }


  onBeneficiariesApprovalsPaginationChanged(onBeneficiariesApprovalsPaginationChanged: PageEvent) {

    const start = onBeneficiariesApprovalsPaginationChanged.pageIndex * onBeneficiariesApprovalsPaginationChanged.pageSize;
    const end = start + onBeneficiariesApprovalsPaginationChanged.pageSize;

    // console.log(this.requestListBackup);
    this.requestList = this.requestListBackup.slice(start, end);
    // console.log(start);
    // console.log(end);
    // console.log(this.requestList);
  }

  initSearchFilterParams() {
    return {
      beneficiaryName: '',
      beneficiaryId: '',
      partyType: '',
      status: 0
    };
  }

  private fetchPendingBeneficiaryRequestAggregate(pageSize = 100, lastFetchedId = 0, ): Promise<StagedBeneficiaryRequestReponse[]> {
    const pendingBeneficiaryAddRequests = this._stagedRequestsService.getStagedBeneficiaryRequests(
      { PageSize: pageSize, LastIdFetched: lastFetchedId, OperationType: 'ADD_BENEFICIARY' })
      .catch(error => { console.log(error); return null; });
    const pendingBeneficiaryUpdateRequests = this._stagedRequestsService.getStagedBeneficiaryUpdateRequests(
      { PageSize: pageSize, LastIdFetched: lastFetchedId }).catch(error => { console.log(error); return null; });

    return Promise.all([pendingBeneficiaryAddRequests, pendingBeneficiaryUpdateRequests]);
  }


  private dismissOpenModalWindows() {
    if (this.requestDetailModal) {
      this.requestDetailModal.close();
    }
    if (this.editApprovalModal) {
      this.editApprovalModal.close();
    }
    if (this.approvalHistoryModal) {
      this.approvalHistoryModal.close();
    }

    if (this.rejectionDialog) {
      this.rejectionDialog.close();
    }
  }
}
