import { Injectable } from '@angular/core';
import { AccountService } from './account.service';
import { TransactionService } from './transaction.service';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './appconfig.service';
import { Observable } from 'rxjs';
// import data from '../../../assets'
@Injectable({
  providedIn: 'root'
})
export class MockService {

    constructor(private _accountService: AccountService, private _transactionService: TransactionService,
      private _httpClient: HttpClient,private _settings: AppConfigService) {


    }

    loadTransactionsForAccount( ): Observable<any> {
      return this._httpClient.get<any>('../../assets/json/data.json' );
    }

    loadPensionRemittanceBatchInfo( ): Observable<any> {
      return this._httpClient.get<any>('../../assets/json/batchData.json' );
    }
    getBulkPensionPayments( ): Observable<any> {
      return this._httpClient.get<any>('../../../assets/bulk-pension-mock.json' );
    }
    getTransactionHistory():Observable<any>{
      return this._httpClient.get<any>('../../../assets/json/tranHistory.json');
    }
  
}
