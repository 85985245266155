import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-tenure-modal',
  templateUrl: './tenure-modal.component.html',
  styleUrls: ['./tenure-modal.component.css']
})
export class TenureModalComponent {

  constructor(
    public dialogRef: MatDialogRef<TenureModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }


}

export interface DialogData{
  name:string,
  amount:string
}
