import { Injectable } from '@angular/core';
import { CardFundingRequestResponse } from '../models/card.models';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { AppConfigService } from './appconfig.service';
import { Observable } from 'rxjs';
import { ApiURLConstants } from '../helpers/apiURLConstants';
import { PensionFileRequestResponse, PensionFileRecordsRequestResponse, StagedPendingPensionPaymentResponse } from '../models/pension-remittance';

@Injectable({
  providedIn: 'root'
})
export class PensionService {


  private _apiBaseUrl: string;
  constructor(private _httpClient: HttpClient, private _authService: AuthService, private _settings: AppConfigService) {
    this._apiBaseUrl = _settings.pensionAPIBaseUrl;
  }

  getPensionFiles(pageSize: number, lastRecordIdFetched: number): Observable<PensionFileRequestResponse> {
    const requestPayload = this.updateRequestPayload({ PageSize: pageSize, LastRecordIdFetched: lastRecordIdFetched });
    console.log(requestPayload);
    return this._httpClient.post<PensionFileRequestResponse>(`${this._apiBaseUrl}/${ApiURLConstants.GetPensionFileInfo}`, requestPayload);
  }
  private updateRequestPayload(payload, hasUserIdProperty = false) {
    const loggedInUser = this._authService.getLoggedInUser();
    if (hasUserIdProperty) {
      payload['UserId'] = loggedInUser && loggedInUser.UserId.trim() ? loggedInUser.UserId.trim() : '';
      return payload;
    }
    payload['InitiatorUserId'] = loggedInUser && loggedInUser.UserId ? loggedInUser.UserId : '';
    return payload;
  }
  getPensionRecords(payload): Observable<PensionFileRecordsRequestResponse> {
    // const requestPayload = this.updateRequestPayload({ PageSize: pageSize, LastRecordIdFetched: lastRecordIdFetched, fileId: fileId }, true);
    // console.log(requestPayload);
    return this._httpClient.post<PensionFileRecordsRequestResponse>(`${this._apiBaseUrl}/${ApiURLConstants.GetPensionRecords}`, payload);
  }
  stageBulkPensionRequest(requestPayload): Observable<any> {
    const newRequestPayload = this.updateRequestPayload(requestPayload, true);
    console.log(newRequestPayload);
    // return this._httpClient.post<any>(`${this._apiBaseUrl}/${ApiURLConstants.StagePensionFile}`, newRequestPayload);
    return this._httpClient.post<any>(`${this._apiBaseUrl}/${ApiURLConstants.StagePensionFile}`, newRequestPayload);

  }
  getStagedPaymentTransactions(pageSize: number, lastRecordIdFetched: number): Observable<StagedPendingPensionPaymentResponse> {
    const loggedInUser = this._authService.getLoggedInUser();

    const requestPayload = this.updateRequestPayload({pageSize: pageSize, LastRecordIdFetched: lastRecordIdFetched }, true);

    return this._httpClient.post<any>(`${this._apiBaseUrl}/${ApiURLConstants.GetPendingItemsForApprovals}`, requestPayload);

  }
  approvePendingPaymentRequest(payload){
    const requestPayload = this.updateRequestPayload(payload,true)
    return this._httpClient.post<any>(`${this._apiBaseUrl}/${ApiURLConstants.ApprovePensionFile}`, requestPayload);

  }

  getApprovedPensionFiles(pageSize: number, lastRecordIdFetched: number): Observable<any> {
    const requestPayload = this.updateRequestPayload({ PageSize: pageSize, LastRecordIdFetched: lastRecordIdFetched });
    console.log(requestPayload);
    return this._httpClient.post(`${this._apiBaseUrl}/${ApiURLConstants.GetApprovedPensionFiles}`, requestPayload);
  }

  rejectPendingPaymentRequest(payload): Observable<any> {
    const requestPayload = this.updateRequestPayload(payload,true);
    return this._httpClient.post(`${this._apiBaseUrl}/${ApiURLConstants.RejectPensionFile}`, requestPayload);

  }
 StagePensionFileFromConflict(payload): Observable<any>{
  const requestPayload = this.updateRequestPayload(payload,true);

  return this._httpClient.post(`${this._apiBaseUrl}/${ApiURLConstants.StagePensionFileFromConflict}`, requestPayload);

 }
}
