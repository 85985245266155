import { NgModule } from '@angular/core';
import {MatButtonModule, MatToolbarModule, MatTableModule, MatTabsModule,
    MatIconModule, MatProgressBarModule, MatProgressSpinnerModule, MatFormFieldModule, MatSelectModule,
    MatDialogModule, MatInputModule, MatDatepickerModule, MatNativeDateModule, MatSnackBarModule, MatTooltipModule,
    MatCardModule, MatPaginatorModule, MatSidenavModule, MatListModule, MatMenuModule,
    MatDividerModule, MatRadioModule, MatStepperModule, MatAutocompleteModule, MatCheckboxModule,
    MatExpansionModule,
    MAT_SNACK_BAR_DEFAULT_OPTIONS,
    MatBadgeModule,
    MAT_DIALOG_DEFAULT_OPTIONS} from '@angular/material';

@NgModule({
    imports: [
                MatNativeDateModule,
                MatButtonModule,
                MatToolbarModule,
                MatTableModule,
                MatTabsModule,
                MatIconModule,
                MatProgressBarModule,
                MatProgressSpinnerModule,
                MatInputModule,
                MatFormFieldModule,
                MatDialogModule,
                MatDatepickerModule,
                MatSnackBarModule,
                MatTooltipModule,
                MatCardModule,
                MatPaginatorModule,
                MatListModule,
                MatSidenavModule,
                MatMenuModule,
                MatDividerModule,
                MatSelectModule,
                MatRadioModule,
                MatExpansionModule,
                MatBadgeModule
             ],
    exports: [
                MatButtonModule,
                MatToolbarModule,
                MatTableModule,
                MatTabsModule,
                MatIconModule,
                MatProgressBarModule,
                MatProgressSpinnerModule,
                MatInputModule,
                MatFormFieldModule,
                MatDialogModule,
                MatDatepickerModule,
                MatSnackBarModule,
                MatTooltipModule,
                MatCardModule,
                MatPaginatorModule,
                MatListModule,
                MatSidenavModule,
                MatMenuModule,
                MatDividerModule,
                MatSelectModule,
                MatRadioModule,
                MatStepperModule,
                MatAutocompleteModule,
                MatCheckboxModule,
                MatExpansionModule,
                MatBadgeModule
            ],
            providers: [
                {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {
                    verticalPosition: 'top', horizontalPosition: 'center', duration: 25000}
                },
                {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {closeOnNavigation: true}}
            ]
})
export class AppMaterialModule {}
