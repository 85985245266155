import { Injectable } from '@angular/core';
import { Observable, of,BehaviorSubject } from 'rxjs';
import { ActiveCreditCardResponse } from '../models/credit-cards';
import { ApiURLConstants } from '../helpers/apiURLConstants';
import { AppConfigService } from './appconfig.service';
import { HttpClient } from '@angular/common/http';
import { GenericApiResponse } from '../models/payloads/generic-response';
import { CreditCardEligibilityResp } from '../models/credit-card-resp';
import { CreditCardRequest } from '../models/credtit-card-request';
import { CardLimitEligibility } from '../models/card-limit-increase-eligibility';
import { CardIncreaseEligibilityRequest } from '../models/card-increase-eligibility-request';

@Injectable()
export class CreditCardRequestService {

    cardDetails = new BehaviorSubject<any>({
        card: {}
    });

    private _apiBaseUrl: string;
    useMock: true;
    private _apiBaseUrl2: string;

    constructor(private _httpClient: HttpClient, private _settingsService: AppConfigService) {
        this._apiBaseUrl = this._settingsService.CreditCardBaseUrl;
        this._apiBaseUrl2 = this._settingsService.CreditCardRequestBaseUrl;

    }
    // getCreditCardEligibility(CifId: string): Observable<CreditCardEligibilityResp> {
    //     console.log(this._apiBaseUrl, this._apiBaseUrl2)

    //     return this._httpClient.post<CreditCardEligibilityResp>(`${this._apiBaseUrl}/credit-card-transactions/card-eligibility`,
    //         { cif: CifId }
    //     );
    // }

    requestId = new BehaviorSubject<any>({
        id: ""
    });


    GetCardEligibilityByCif(CifId: string): Observable<any> {
        // console.log(this._apiBaseUrl, this._apiBaseUrl2)
        return this._httpClient.post<any>(`${this._apiBaseUrl}/credit-card-transactions/GetCardEligibilityByCif?cifId=${CifId}`,
            { cifId: CifId }
        );
    }

    // requestCreditCard(payload:CreditCardRequest): Observable<GenericApiResponse> {
    //     return this._httpClient.post<GenericApiResponse>(`${this._apiBaseUrl}/credit-card-transactions/request-card`,
    //         payload
    //     );
    // }
    getActiveCreditCardDetails(CifId: string): Observable<ActiveCreditCardResponse> {
    if(this.useMock){

        return of({
            ResponseCode: '000',
            ResponseDescription: 'Success',
            Data:[
                {
                    Amount:200.0,
                    CardName:'Chukuwam Anthony',
                    CifId:'10129203911',
                    DebitAccount:'00229930011',
                    ExpiryDate:'04/11',
                    Foracid:'20393002833',
                    OdvisAccount:'20393002833',
                    PanMasked:'2039*********0833',
                    currency:'NGN'
                }
            ]
        });
       }
         return this._httpClient.post<ActiveCreditCardResponse>(`${this._apiBaseUrl}/credit-card-transactions/GetCreditCardBalance`,
            { CIF: CifId }
        );
    }

    loadCreditCard(payload): Observable<GenericApiResponse> {
        return this._httpClient.post<GenericApiResponse>(`${this._apiBaseUrl}/${ApiURLConstants.LoadCreditCard}`,
            payload
        );
    }

    debitCreditCard(payload): Observable<GenericApiResponse> {
        return this._httpClient.post<GenericApiResponse>(`${this._apiBaseUrl}/${ApiURLConstants.DebitCreditCard}`,
            payload
        );
    }
    logCardTransaction(payload): Observable<GenericApiResponse> {
        return this._httpClient.post<GenericApiResponse>(`${this._apiBaseUrl}/${ApiURLConstants.LogCardTransaction}`, payload);
    }
    logCreditCard(payload): Observable<GenericApiResponse> {
        return this._httpClient.post<GenericApiResponse>(`${this._apiBaseUrl}/${ApiURLConstants.LogCardTransaction}`,
            payload
        );
    }
    creditCardIncreaseEligibility(payload: CardIncreaseEligibilityRequest): Observable< CardLimitEligibility>{
        return this._httpClient.post<CardLimitEligibility>(`${this._apiBaseUrl}/credit-card-transactions/limit-increase-eligibility`,
        payload
        );
    }
}
