import { Component, OnInit, Input, SimpleChanges } from "@angular/core";
import { VariablesService } from "src/app/core/services/variables.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { FxPaymentApprovalsComponent } from "../../approvals/fx-payment-approvals/fx-payment-approvals.component";
import { FXPaymentService } from "src/app/core/services/fx-payment.service";
import { DatePipe, DecimalPipe } from "@angular/common";
import { UtilService } from "src/app/core/services/helpers.service";
import { MatSnackBar } from "@angular/material";
import { AuthService } from "src/app/core/services/auth.service";
import { RouterConstants } from "src/app/core/helpers/routerConstants";
import { Router } from "@angular/router";
import { ResponseCodes } from "src/app/core/models/Enumerations";
import {
  BranchListResponse,
  NIBBSBranch,
} from "src/app/core/models/service-request/branch";

var $: any;
class BaseString {
  base64String: string;
  id: number;
}
@Component({
  selector: "app-initate-fx-transaction",
  templateUrl: "./initate-fx-transaction.component.html",
  styleUrls: ["./initate-fx-transaction.component.css"],
})
export class InitateFxTransactionComponent implements OnInit {
  @Input() tab: string = "";
  tabTitle: string;
  _trnsDetailReqType: string;
  customerBvn: string;
  purchaseTypes = [];
  transferTypes = [];
  selectedTransferType: any;
  selectedPurchaseType: any;
  initiateForm: FormGroup;
  documents = [];
  noOfCompleteRows = [];
  remainder = 0;
  balance = [];
  _selectedFileToUpload: File;
  _fileError: string;
  _uploadedFileData: any;
  branches: any;
  beneficiaryBVN: number;
  initiateRequestForm: FormBuilder;
  selectedBranchCode: any;
  amount: any;
  _selectedFileName = "";
  file = "093993";
  passportNo: any;
  purpose: any;
  transactionTypes: any;
  taxCertificationNumber: any;
  dob: any;
  selectedTransactionType: any;
  purchseTypeObj: {
    id: null;
  };
  selectedTransactionTypeObj: {
    id: null;
  };
  selectedBranchObj: {
    branchCode: null;
  };
  base64StrArr: any[] = [];
  loaderIsActive: boolean;
  constructor(
    private formBuilder: FormBuilder,
    private fxService: FXPaymentService,
    private decimalPipe: DecimalPipe,
    private _snackbar: MatSnackBar,
    private router: Router,
    private datePicker: DatePipe
  ) {
    // try {
    //   window['casa']('transaction');
    //  } catch (error) {}
  }

  ngOnInit() {
    if (!VariablesService.CustomerBVN) {
      this.router.navigate([RouterConstants.FullDashboardURL]);
      return;
    }

    this.customerBvn = VariablesService.CustomerBVN;
    this.getCustomerBalance(this.customerBvn);
    this.getFxTypes();
    this.getBranches();
    this.getPurchaseTypes();
  }
  onSelectedBranchChanged(evt) {
    this.selectedBranchCode = evt.value.name;
    this.selectedBranchObj = evt.value;
  }

  onFormControlClicked(event: any, i) {
    console.log(i, "index");
    const element = event.target as HTMLInputElement;
    element.value = "";
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.setTabTitle(this.tab);
  }

  getCustomerBalance(customerBvn: string) {
    this.loaderBranchIsActive = true;

    this.fxService.getFxBalance(customerBvn).subscribe(
      (res) => {
        this.loaderBranchIsActive = false;

        if (res.responseCode === "00") {
          this.balance = res.data;
        }
      },
      (err) => {
        //  this.balance = [0.00]
        this.loaderBranchIsActive = false;
      }
    );
  }

  getFxTypes() {
    this.loaderIsActive = true;

    this.fxService.getFxTypes().subscribe(
      (res) => {
        this.loaderIsActive = false;

        if (res.responseCode === "000") {
          this.transactionTypes = res.data;
        }
      },
      (err) => {
        this.loaderIsActive = false;
      }
    );
  }

  getPurchaseTypes() {
    this.loaderIsActive = true;

    this.fxService.getPurchaseType().subscribe(
      (res) => {
        this.loaderIsActive = false;

        if (res.responseCode === "000") {
          this.purchaseTypes = res.data;
        }
      },
      (err) => {
        this.loaderIsActive = false;
      }
    );
  }

  getBranches() {
    this.loaderIsActive = true;
    this.fxService.getFxBranches().subscribe(
      (res) => {
          this.loaderIsActive = false;
        if (res.responseCode === "000") {
          let unsortedBranches = res.data;
          // unsortedBranches;
          this.branches = this.sortBranches(unsortedBranches);
          // console.log(x, "sorted");
        }
      },
      (err) => {
        this.loaderIsActive = false;
      }
    );
  }

  sortBranches(branchArr): NIBBSBranch[] {
    return branchArr.sort((a: NIBBSBranch, b: NIBBSBranch) => {
      // var textA = a.branchName.toUpperCase();
      // var textB = b.branchName.toUpperCase();
      // textA < textB ? -1 : textA > textB ? 1 : 0;
      var nameA = a.branchName.toUpperCase(); // ignore upper and lowercase
      var nameB = b.branchName.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
    
      // names must be equal
      return 0;
    });
  }
  successful = false;
  GotoDashboard() {
    this.router.navigate([RouterConstants.FullDashboardURL]);
  }

  createnewRequest() {
    this.successful = false;
    this.documents = [];
    this.base64StrArr = [];
    this.filetoBase64 = "";
    this.amount = 0;
    this.passportNo = "";
    this.beneficiaryBVN = null;
    this.selectedBranchCode = "";
    this.purpose = "";
    this.taxCertificationNumber = "";
    this.dob = null;
    this.selectedPurchaseType = null;
    this.selectedTransactionType = null;
  }
  SubmitRequest() {
    this.loaderIsActive = true;
    const payload = this.buildPayload();
    if (!payload) {
      this._snackbar.open("Please Fill all the required fields");
      this.loaderIsActive = false;
    }
    this.fxService.SubmitFxRequest(payload).subscribe(
      (res) => {
        if (res.responseCode === "000") {
          this.loaderIsActive = false;
          this.successful = true;
          this._snackbar.open(
            "Your request has been submitted successfully",
            "Success",
            {
              duration: 5000,
            }
          );
        } else {
          this.loaderIsActive = false;

          this._snackbar.open(res.responseDescription, "Failed", {
            duration: 5000,
          });
        }
      },
      (err) => {
        this.loaderIsActive = false;
        if (err.status === 400) {
          this._snackbar.open("Please validate all input fields", "Error", {
            duration: 5000,
          });
          return;
        }
      }
    );
  }

  amountInputKeyup() {
    // Only allow digits on amount alone.
    let amountEntered = this.amount;
    amountEntered = UtilService.getSanitizedAmount(amountEntered);

    this.amount = amountEntered;
  }

  amountInputFocus() {
    let amountEntered = this.amount;

    if (amountEntered) {
      //remove .00 if exist
      if ((amountEntered as string).indexOf(".00") != -1) {
        amountEntered = (amountEntered as string).replace(".00", "");
      }

      amountEntered = amountEntered.replace(/,/g, "");
    }

    this.amount = amountEntered;
  }

  amountInputBlur() {
    let amount = this.amount as number;

    if (amount > 0) {
      this.amount = this.decimalPipe.transform(amount, "1.2-2");
    }
  }
  uploadDoc(evt, item, index) {
    console.log(item, index, evt);
    this._uploadedFileData = null;
    this._selectedFileToUpload = null;
    this._snackbar.dismiss();
    this._fileError = "";
    if (!evt.target.files || evt.target.files.length < 1) {
      this._fileError =
        "No file selected. Please select a valid excel of csv file to upload";
      this._snackbar.open(this._fileError, "Ok", { duration: 15000 });
      return;
    }
    if (evt.target.files.length > 1) {
      this._fileError =
        "Multiple file upload is not supported. please select a single file and retry";
      this._snackbar.open(this._fileError, "Ok", { duration: 15000 });
      return;
    }
    const _fileToUpload: File = evt.target.files[0];
    if (_fileToUpload.size / (1000 * 1024) > 20) {
      this._fileError =
        "File size is more than 20 MB. Cannot upload a bulk-payment-file larger in size that 20MB";
      this._snackbar.open(this._fileError, "Ok", { duration: 15000 });
      return;
    }
    this._selectedFileToUpload = _fileToUpload;
    this._selectedFileName = _fileToUpload.name;
    // }
    const fileReader = new FileReader();
    fileReader.readAsBinaryString(this._selectedFileToUpload);
    fileReader.onload = (e: any) => {
      this.ToBase64(this._selectedFileToUpload).then((res) => {
        this.filetoBase64 = res;
        if (this.filetoBase64) {
          const strObj = { base64String: this.filetoBase64 };
          if (this.base64StrArr.length === 0) {
            this.base64StrArr.push(strObj);
            return;
          }
          if (this.base64StrArr.length > 0) {
            const findObj = this.base64StrArr.findIndex(
              (el) => el.id === index
            );
            console.log(findObj);
            if (findObj === -1) {
              this.base64StrArr.push(strObj);
            }
            if (findObj != -1) {
              this.base64StrArr.splice(findObj, 1);
              this.base64StrArr.push(strObj);
            }
          }
        }
      });
      this.filetoBase64 = "";

      var filename = this._selectedFileName;
      console.log(document.getElementById("file-name-" + index));
      document.getElementById("file-name-" + index).innerText = filename;
    };
  }

  filetoBase64: string;
  validateFileContents(data: string) {
    this.ConvertToBase64(this._selectedFileToUpload).then(
      (result) => (this.filetoBase64 = this.transformBase64String(result))
    );
  }

  ToBase64(file: File): any {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  ConvertToBase64(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  transformBase64String(base64String: string) {
    if (base64String && base64String.indexOf("base64") > -1) {
      const hay = base64String.indexOf("base64");
      return base64String.substr(hay + 7);
    }
    return base64String;
  }

  private setTabTitle(_title) {
    switch (_title) {
      case "initiate-fx-transaction": {
        this.tabTitle = "FX Purchase Request";
        break;
      }
      default: {
        break;
      }
    }
  }

  showNB = false;
  loaderBranchIsActive = false;

  onSelectedPurchaseTypeChanged(option) {
    console.log(option);
    this.selectedPurchaseType = option.value.name;
    this.purchseTypeObj = option.value;
  }
  onSelectedTransactionTypeChanged(option) {
    console.log(option);
    this.documents = option.value.requiredFxDocuments;
    this.selectedTransactionType = option.value.name;
    this.selectedTransactionTypeObj = option.value;
    this.showNB = true;
  }

  //refactor
  buildPayload() {
    return {
      passportNumber: this.passportNo,
      accountNumber: AuthService.FullyAuthenticatedUser.UserId,
      bvn: this.customerBvn ? this.customerBvn : null,
      purpose: this.purpose ? this.purpose : null,
      amount: this.amount ? this.amount : 0.0,
      branchCode: this.selectedBranchObj
        ? this.selectedBranchObj.branchCode
        : null,
      beneficiaryBVN: this.beneficiaryBVN
        ? this.beneficiaryBVN
        : this.customerBvn,
      transactionType: this.selectedTransactionTypeObj
        ? this.selectedTransactionTypeObj.id
        : null,
      purchaseType: this.purchseTypeObj ? this.purchseTypeObj.id : null,
      taxCertificationNumber: this.taxCertificationNumber
        ? this.taxCertificationNumber
        : null,
      documents: this.getBaseString(this.base64StrArr),
      dateOfBirth: this.dob
        ? this.datePicker.transform(this.dob, "yyyy-MM-dd")
        : null,
    };
  }

  getBaseString(base64StrArr: BaseString[]) {
    let newArray = [];
    base64StrArr.forEach((item) => {
      newArray.push({ base64String: item.base64String });
    });
    return newArray;
  }
}
