import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { AirtimeBeneficiary, AccountBeneficiary } from 'src/app/core/models/beneficiary';

@Component({
  selector: 'app-beneficiary',
  templateUrl: './beneficiary.component.html',
  styleUrls: ['./beneficiary.component.css']
})
export class BeneficiaryDialogComponent implements OnInit {
  public transferBeneficiary = {
    accountNumber: '',
    accountName: ''
  };
  public airtimeBeneficiary = {
    name: '',
    phoneNumber: '',
    providerId: ''
  };


  public loaderIsActive: boolean;
  loaderMsg: string;

  networkProviders: string[] = ['MTN NG', 'Glo NG', '9Mobile', 'Airtel NG'];
  selectedNetworkProvider: string ;

  bankList: string [] = this.loadMockBankList();
  selectedBank: string;

  constructor(private dialogInstance: MatDialogRef<BeneficiaryDialogComponent>,
    private snackBarInstance: MatSnackBar, @Inject(MAT_DIALOG_DATA) public data: BeneficiaryDialogData
    ) {
      this.selectedNetworkProvider = this.networkProviders[0];
      
  }

  ngOnInit() {
    if (this.data &&  this.data.dialogAction === 'view-beneficiary') {
      if (this.data.dialogType === 'airtime') {
      const beneficiary = this.data.extras as AirtimeBeneficiary;
      this.airtimeBeneficiary = {name: beneficiary.beneficiaryName,
                                 phoneNumber: beneficiary.mobileNumber, providerId: beneficiary.mobileNetwork};
      }
      if (this.data.dialogType === 'transfer') {
        const beneficiary = this.data.extras as AccountBeneficiary;
        this.airtimeBeneficiary = {name: beneficiary.beneficiaryName,
                                   phoneNumber: beneficiary.accountNumber, providerId: beneficiary.bankName};
        }
    }
  }

  close() {
    this.dialogInstance.close();
  }

  onSelectedBankChanged(eventData) {
    this.selectedBank = eventData['value'] as string;
  }

  onSelectedNetworkProviderChanged(eventData) {
    console.log('selected -> ', eventData);
    this.selectedNetworkProvider = eventData['value'] as string;
  }

  onViewBeneficiaryAction(action: string): void {
    switch (action) {
      case 'send-airtime': {
        this.dialogInstance.close(this.data.extras);
        break;
      }
      case 'send-money': {
        this.dialogInstance.close(this.data.extras);
      }
    }
  }

  loadMockBankList(): string[] {
    return [
      'Zenth Bank PLC',
      'Access Bank',
      'Stanbic IBTC Bank',
      'United Bank For Africa',
      'First Bank',
      'Diamond Bank',
      'GT Bank'
    ];
  }

}

export interface BeneficiaryDialogData {
  dialogType: string;
  dialogAction: string;
  extras: object;
}
