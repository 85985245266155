import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, Inject } from "@angular/core";
import { VerifyAccountUserComponent } from '../verify-account-user/verify-account-user.component';

@Component({
    selector:'app-show-bvn-number',
    templateUrl:'./show-bvn-number.component.html',
    styleUrls:['./show-bvn-number.component.css']
})
export class ShowBvnNumberComponent {
    customerBvn: string;
    mode:any
    constructor(private _dialogRef: MatDialogRef<ShowBvnNumberComponent>,
        @Inject(MAT_DIALOG_DATA) public data:any) 
        {
        
            //console.log("BVN" + data)
            this.customerBvn =  data.data;
            this.mode = data.mode
        }


        closeDialog() {
            this._dialogRef.close();
        }

        copyToClipboard(item): void {
            let listener = (e: ClipboardEvent) => {
                console.log(e)
                e.clipboardData.setData('text/plain', (item));
                e.preventDefault();
            };
        
            document.addEventListener('copy', listener);
            document.execCommand('copy');
            document.removeEventListener('copy', listener);
        }
}