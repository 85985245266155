import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EditRequest, FxBranch, FxRequestHistoryResponse, GetFxTypesResponse } from "../models/GetFxTypesResponse";
import { AppConfigService } from "./appconfig.service";

@Injectable()
export class FxRequestHistoryService {

    constructor(
        private _htp: HttpClient,
        private _config: AppConfigService
    ){
         this.baseUrl = _config.FxPaymentApiBaseUrl
    }
    baseUrl: string = ""
    headers = {
        "Q-wxTYDV-vxq":"hhdkiuvxxqeTRfHyGVccDDALoieuhsWQArtdlirXX"
    }

    LoadFxTypes(): Promise<GetFxTypesResponse> {
        const fullUrl = `${this.baseUrl}/GetFxTypes`
        return this._htp.post<GetFxTypesResponse>(fullUrl,{},{
            headers:this.headers}).toPromise()
    }

    CancelFxRequesr(Id) {
        const fullUrl = `${this.baseUrl}/CancelRequest`
        return this._htp.post(fullUrl, {"requestId":Id}, {
            headers:this.headers
        }).toPromise();
    }

    LoadFxBranches(): Promise<FxBranch> {
        const fullUrl = `${this.baseUrl}/GetFxBranches`
        return this._htp.post<FxBranch>(fullUrl,{}, {
            headers:this.headers
        }).toPromise()
    }

    LoadFxRequestHistory(body: any): Promise<FxRequestHistoryResponse> {
        const fullUrl = `${this.baseUrl}/GetRequestHistory`
        return this._htp.post<FxRequestHistoryResponse>(fullUrl, body, {
            headers:this.headers
        }).toPromise();
    }

    EditRequest(body: EditRequest) {
        const fullUrl = `${this.baseUrl}/EditRequest`

        return this._htp.post(fullUrl, body, {
            headers:this.headers
        }).toPromise();
    }
}