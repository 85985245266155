import { Injectable } from '@angular/core';
import * as jsPdf from 'jspdf';
import { TransferHistory } from '../models/transactions/transaction';
import { StanbicImageConstants } from '../stanbic.base64Image.constants';

import 'jspdf-autotable';
import { PendingSingleTransferObject } from '../models/pending-request.models';
import { PensionGetRecordsResponse, PensionRecordInfo } from '../models/payloads/pensionremittance';
import { Booking } from '../models/payloads/wakanow';
import { DatePipe } from '@angular/common';
import { BookingHistory } from '../models/payloads/booking-history';
import { CurrencyPipe } from '@angular/common';
// import { BookingHistory } from '../models/payloads/booking-history';


interface TransactionHistory{
    ReceiverAcctNum: string;
    ReceiverBank: string;
    TransactionId: string;
    FromAccount:string;
    ToWho:string;
    AmountSentFX: number;
    AmountSentNaira: number;
    Date: string;
    Status: string;
    ExchangeRate: number;
    AmountPaidFX:number;
    AmountPaidNaira:number;
    FXCurrencySent:string;
    FXCurrencyPaid:string;
    VAT:number;
    VATAmount: number;
    OverseasCharges: number;
    ReceiverName: string;
    ReceiverPhoneNumber: string;
    ReceiverEmail: string;
    ReceiverAddress: string;
    ReceiverCountry: string;
    Purpose: string;
  }



@Injectable()
export class DocumentHelperService {

    constructor(
      private currencyPipe: CurrencyPipe
    ) {
    }



    GenerateTransferTransactionReceipt(transaction: TransferHistory|any, receiptType, recipientName, recipientBank,
        otherReceiptObjectValues: any, selfTransferDestinationAccountName = '', selfTransferDestinationAccountNo = ''): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const doc = new jsPdf('p', 'pt');

            const isSuccessfulTransaction = transaction.transactionStatus.toLowerCase().includes('suc');
            const isPendingTranx = transaction.transactionStatus.toLowerCase().includes('pending');
            const isFailedTranx = transaction.transactionStatus.toLowerCase().includes('failed');
            const isUnknownTranx = transaction.transactionStatus.toLowerCase().includes('unknown');


            // Add Stanbic Logo
            doc.setFillColor(240, 243, 248);
            doc.rect(0, 0, 600, 120, 'F');
            doc.addImage(StanbicImageConstants.STANBIC_PDF_HEADER_LOGO, 'PNG', 40, 60, 183, 34);

            // Draw Receipt Header
            doc.setFontSize(18);
            doc.setTextColor(0, 0, 0);
            doc.text('Receipt', 280, 150, 'center');

            //  Draw success/failure icon


            const _transStatusLogo = isSuccessfulTransaction || isPendingTranx ? StanbicImageConstants.RECEIPT_SUCCESS_LOGO :
            isUnknownTranx ? StanbicImageConstants.RECEIPT_UNKNOWN_LOGO :   StanbicImageConstants.RECEIPT_FAILURE_LOGO;

            doc.addImage(_transStatusLogo, 'PNG', 281, 200, 44, 44);

            // Draw transaction status message
            let _transStatusMsg = isSuccessfulTransaction ? 'Transaction successful' :
                isPendingTranx ? "Pending Transaction" : isFailedTranx ? 'Transaction failed' : "Unknown Status";
            let startY = 0;
            if (_transStatusMsg && _transStatusMsg.length > 0) {
                const headerMessageArray = this.breakdownReceiptHeaderMessage(_transStatusMsg);

                for (let cursor = 0; cursor < headerMessageArray.length; cursor++) {
                    const message = headerMessageArray[cursor];
                    const _startY = 270 + 15 * cursor;
                    startY = _startY + 15;
                    doc.setFontSize(12);
                    doc.setTextColor(51, 51, 51);
                    doc.text(message, 300, _startY, 'center');
                }
            }

            // Draw amount and currency
            const x = transaction.currency + ' ' + transaction.amount;
            startY = startY + 10;
            doc.setFontSize(16);
            doc.setTextColor(51, 51, 51);
            doc.text(x, 300, startY, 'center');

            startY = startY + 10;


            // Draw beneficiary/biller/remita name
            if (receiptType === 'beneficiary' || receiptType === 'biller' || receiptType === 'remita') {
                startY = startY + 20;
                doc.setFontSize(12);
                doc.setTextColor(51, 51, 51);
                doc.text(recipientName, 300, startY, 'center');
            }

            // Print Beneficiary account no and bank
            if (receiptType === 'beneficiary') {
                startY = startY + 20;
                doc.text(recipientBank + ' - ' + transaction.destinationAccountNo, 300, startY, 'center');
            }



            // Print To account name and number if self transfer
            if (receiptType === 'self-transfer') {
                if (selfTransferDestinationAccountName) {
                    startY = startY + 20;
                    doc.setFontSize(12);
                    doc.setTextColor(51, 51, 51);
                    doc.text(selfTransferDestinationAccountName, 300, startY, 'center');
                    startY += 15;
                } else {
                    startY += 20;
                }

                doc.text(selfTransferDestinationAccountNo, 300, startY, 'center');
            }

            // Draw Dotted Lines
            doc.setDrawColor(142, 142, 142);
            let _d;
            startY += 15;
            // tslint:disable-next-line:no-var-keyword
            for (var e = 1; 50 > e; e++) {
                _d = 175 + 5 * e;
                doc.rect(100, 20, 10, 10, 'F');
            }

            // Draw other key values e.g tran Id, my reference, their reference
            // startY = this.printObjectOnPdf(doc, this.getOtherReceiptDetails(transaction, recipientRef), startY);
            startY = this.printObjectOnPdf(doc, otherReceiptObjectValues, startY);

            // Wrap content with a round rectangle
            const r = doc.internal.pageSize.height;
            const E = r - 200;
            const l = startY - 160; // c + 40; // or c - 160

             doc.setDrawColor(142, 142, 142);
            // doc.roundedRect(175, 180, 250, l, 3, 3, 'D');

            // Draw disclamer Image
            if (startY > E) {
                doc.addPage();
                doc.addImage(StanbicImageConstants.STANBIC_PDF_DISCLAIMER, 'PNG', 50, 100, 493, 120);
            } else {
                doc.addImage(StanbicImageConstants.STANBIC_PDF_DISCLAIMER, 'PNG', 50, 655, 493, 120);
            }
            resolve(doc);
        });
    }


    generateTransferTransactionReceipt(transaction: TransferHistory, receiptType, recipientName, recipientBank, recipientRef,
        selfTrnsDestName = '', selfTrnsAccNo = ''): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const doc = new jsPdf('p', 'pt');

            const isSuccessfulTransaction = transaction.transactionStatus.toLowerCase().includes('suc');
            const isPendingTranx = transaction.transactionStatus.toLowerCase().includes('pending');
            const isFailedTranx = transaction.transactionStatus.toLowerCase().includes('failed');
            const isUnknownTranx = transaction.transactionStatus.toLowerCase().includes('unknown');

         // Add Stanbic Logo
            doc.setFillColor(240, 243, 248);
            doc.rect(0, 0, 600, 120, 'F');
            doc.addImage(StanbicImageConstants.STANBIC_PDF_HEADER_LOGO, 'PNG', 40, 60, 183, 34);

            // Draw Receipt Header
            doc.setFontSize(18);
            doc.setTextColor(0, 0, 0);
            doc.text('Receipt', 280, 150, 'center');

            //  Draw success/failure icon

            const _transStatusLogo = isSuccessfulTransaction || isPendingTranx ? StanbicImageConstants.RECEIPT_SUCCESS_LOGO :
                isUnknownTranx ? StanbicImageConstants.RECEIPT_UNKNOWN_LOGO :   StanbicImageConstants.RECEIPT_FAILURE_LOGO;


            doc.addImage(_transStatusLogo, 'PNG', 281, 200, 44, 44);

            // Draw transaction status message
            let _transStatusMsg = isSuccessfulTransaction ? 'Transaction successful' :
                isPendingTranx ? "Pending Transaction" : isFailedTranx ? 'Transaction failed' : "Unknown Status";
            let startY = 0;
            if (_transStatusMsg && _transStatusMsg.length > 0) {
                const headerMessageArray = this.breakdownReceiptHeaderMessage(_transStatusMsg);

                for (let cursor = 0; cursor < headerMessageArray.length; cursor++) {
                    const message = headerMessageArray[cursor];
                    const _startY = 270 + 15 * cursor;
                    startY = _startY + 15;
                    doc.setFontSize(12);
                    doc.setTextColor(51, 51, 51);
                    doc.text(message, 300, _startY, 'center');
                }
            }

            // Draw amount and currency
            const x = transaction.currency + ' ' + transaction.amount;
            startY = startY + 10;
            // doc.setFontSize(16);
            // doc.setTextColor(51, 51, 51);
            // doc.text(x, 300, startY, 'center');

            startY = startY + 10;


            // Draw beneficiary/biller/remita name
            if (receiptType === 'beneficiary' || receiptType === 'biller' || receiptType === 'remita') {
                startY = startY + 20;
                // doc.setFontSize(12);
                // doc.setTextColor(51, 51, 51);
                // doc.text(recipientName, 300, startY, 'center');
            }

            // Print Beneficiary account no and bank
            if (receiptType === 'beneficiary') {
                startY = startY + 20;
                doc.text(recipientBank + ' - ' + transaction.destinationAccountNo, 300, startY, 'center');
            }



            // Print To account name and number if self transfer
            if (receiptType === 'self-transfer') {
                if (selfTrnsDestName) {
                    startY = startY + 20;
                    // doc.setFontSize(12);
                    // doc.setTextColor(51, 51, 51);
                    // doc.text(selfTrnsDestName, 300, startY, 'center');
                    startY += 15;
                } else {
                    startY += 20;
                }

                doc.text(selfTrnsAccNo, 300, startY, 'center');
            }

            // Draw Dotted Lines
            doc.setDrawColor(142, 142, 142);
            let _d;
            startY += 15;
            // tslint:disable-next-line:no-var-keyword
            for (var e = 1; 50 > e; e++) {
                _d = 175 + 5 * e;
                // doc.rect(_d, startY, 2, 1, 'D');
            }

            // Draw other key values e.g tran Id, my reference, their reference
            startY = this.printObjectOnPdf(doc, this.getOtherReceiptDetails(transaction, recipientRef), startY);

            // Wrap content with a round rectangle
            const r = doc.internal.pageSize.height;
            const E = r - 200;
            const l = startY - 160; // c + 40; // or c - 160

             doc.setDrawColor(142, 142, 142);
            // doc.roundedRect(175, 180, 250, l, 3, 3, 'D');

            // Draw disclamer Image
            if (startY > E) {
                doc.addPage();
                doc.addImage(StanbicImageConstants.STANBIC_PDF_DISCLAIMER, 'PNG', 50, 100, 493, 120);
            } else {
                doc.addImage(StanbicImageConstants.STANBIC_PDF_DISCLAIMER, 'PNG', 50, 655, 493, 120);
            }
            resolve(doc);
        });
    }



    generateSingleTransferTransactionReceipt(transaction: PendingSingleTransferObject | any, receiptType, recipientName, recipientBank,
        otherReceiptObjectValues: any, selfTransferDestinationAccountName = '', selfTransferDestinationAccountNo = ''): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const doc = new jsPdf('p', 'pt');

            // const isSuccessfulTransaction = transaction['transactionStatus'] && transaction['transactionStatus'].toLowerCase().includes('suc');
            // const isPendingTranx = transaction['transactionStatus'] && transaction['transactionStatus'].toLowerCase().includes('pending');
            // const isFailedTranx = transaction['transactionStatus'] && transaction['transactionStatus'].toLowerCase().includes('failed');

            const isSuccessfulTransaction = transaction['TransferStatus'] && transaction['TransferStatus'].toLowerCase().includes('suc');
            const isPendingTranx = transaction['TransferStatus'] && transaction['TransferStatus'].toLowerCase().includes('pending');
            const isFailedTranx = transaction['TransferStatus'] && transaction['TransferStatus'].toLowerCase().includes('failed');
            const isUnknownTranx = transaction['TransferStatus'] && transaction['TransferStatus'].toLowerCase().includes('unknown');


            // Add Stanbic Logo
            doc.setFillColor(240, 243, 248);
            doc.rect(0, 0, 600, 120, 'F');
            doc.addImage(StanbicImageConstants.STANBIC_PDF_HEADER_LOGO, 'PNG', 40, 60, 183, 34);

            // Draw Receipt Header
            doc.setFontSize(18);
            doc.setTextColor(0, 0, 0);
            doc.text('Receipt', 285, 150, 'center');


           

            //  Draw success/failure icon
            const _transStatusLogo = isSuccessfulTransaction || isPendingTranx ? StanbicImageConstants.RECEIPT_SUCCESS_LOGO :
            isUnknownTranx ? StanbicImageConstants.RECEIPT_UNKNOWN_LOGO :   StanbicImageConstants.RECEIPT_FAILURE_LOGO;
            doc.addImage(_transStatusLogo, 'PNG', 280, 200, 44, 44);
           

            // Draw transaction status message
            let _transStatusMsg = isSuccessfulTransaction ? 'Transaction successful' :
                isPendingTranx ? "Pending Transaction" : isFailedTranx ? 'Transaction failed' : "Unknown Status";
            let startY = 0;
            if (_transStatusMsg && _transStatusMsg.length > 0) {
                const headerMessageArray = this.breakdownReceiptHeaderMessage(_transStatusMsg);

                for (let cursor = 0; cursor < headerMessageArray.length; cursor++) {
                    const message = headerMessageArray[cursor];
                    const _startY = 270 + 15 * cursor;
                    startY = _startY + 15;
                    // doc.setFontSize(12);
                    // doc.setTextColor(51, 51, 51);
                    // doc.text(message, 300, _startY, 'center');
                }
            }

            // Draw amount and currency
            const x = (transaction['sourceCurrency'] || transaction['SourceAccountCurrency'] || '') + '' + transaction.amount; //source currency
            startY = startY + 10;
            doc.setFontSize(16);
            doc.setTextColor(51, 51, 51);
            doc.text(x, 300, startY, 'center');


            startY = startY + 10;


            // Draw beneficiary/biller/remita name
            if (receiptType == 'single-transfer' || receiptType === 'beneficiary' || receiptType === 'biller' || receiptType === 'remita') {
                startY = startY + 20;
                doc.setFontSize(12);
                doc.setTextColor(51, 51, 51);
                doc.text(recipientName, 300, startY, 'center');
                if (recipientName && recipientName.length > 0) {
                    const recipientNameArray = this.breakdownRecipientMessage(recipientName);

                    for (let cursor = 0; cursor < recipientNameArray.length; cursor++) {
                        const message = recipientNameArray[cursor];
                        // startY = startY + 15;
                        // doc.setFontSize(12);
                        // doc.setTextColor(51, 51, 51);
                        // doc.text(message, 300, startY, 'center');
                    }
                }
            }

            // Print Beneficiary account no and bank
            if (receiptType === 'beneficiary' || receiptType === 'single-transfer') {
                startY = startY + 20;
                doc.text(recipientBank + ' - ' + transaction.destinationAccountNo, 300, startY, 'center');
            }



            // Print To account name and number if self transfer
            if (receiptType === 'self-transfer') {
                selfTransferDestinationAccountName = recipientName;
                if (selfTransferDestinationAccountName) {
                    startY = startY + 20;
                    doc.setFontSize(12);
                    doc.setTextColor(51, 51, 51);
                    doc.text(selfTransferDestinationAccountName, 300, startY, 'center');
                    startY += 15;
                } else {
                    startY += 20;
                }

                doc.text(selfTransferDestinationAccountNo, 300, startY, 'center');
            }

            // Draw Dotted Lines
            // doc.setDrawColor(142, 142, 142);
            let _d;
            startY += 15;
            // tslint:disable-next-line:no-var-keyword
            for (var e = 1; 50 > e; e++) {
                _d = 175 + 5 * e;
                // doc.rect(_d, startY, 2, 1, 'D');
            }

            // Draw other key values e.g tran Id, my reference, their reference
            startY = this.printObjectOnPdf(doc, otherReceiptObjectValues, startY);

            // Wrap content with a round rectangle
            const r = doc.internal.pageSize.height;
            const E = r - 200;
            const l = startY - 160; // c + 40; // or c - 160

            doc.setDrawColor(142, 142, 142);
            //  doc.roundedRect(175, 180, 250, l, 3, 3, 'D');

            // Draw disclamer Image
            if (startY > E) {
                doc.addPage();
                doc.addImage(StanbicImageConstants.STANBIC_PDF_DISCLAIMER, 'PNG', 50, 100, 493, 120);
            } else {
                doc.addImage(StanbicImageConstants.STANBIC_PDF_DISCLAIMER, 'PNG', 50, 655, 493, 120);
            }
            resolve(doc);
        });
    }

    generatePensionRemittanceReceipt(transaction: PensionRecordInfo, receiptType, recipientName): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const doc = new jsPdf('p', 'pt');

            // const isSuccessfulTransaction = transaction['RemittanceStatus'] && transaction['RemittanceStatus'].toLowerCase().includes('suc');
            // const isPendingTranx = transaction['RemittanceStatus'] && transaction['RemittanceStatus'].toLowerCase().includes('pending');
            // const isFailedTranx = transaction['RemittanceStatus'] && transaction['RemittanceStatus'].toLowerCase().includes('failed');

            // Add Stanbic Logo
            doc.setFillColor(240, 243, 248);
            doc.rect(0, 0, 600, 120, 'F');
            doc.addImage(StanbicImageConstants.STANBIC_PDF_HEADER_LOGO, 'PNG', 190, 60, 183, 34);

            // Draw Receipt Header
            doc.setFontSize(18);
            doc.setTextColor(0, 0, 0);
            doc.text('Remittance Receipt', 280, 150, 'center');

            //  Draw success/failure icon
            const _transStatusLogo =  StanbicImageConstants.RECEIPT_SUCCESS_LOGO
            doc.addImage(_transStatusLogo, 'PNG', 281, 200, 44, 44);

            // Draw transaction status message
            let _transStatusMsg =  'Pension Remittance was successful';
            let startY = 0;
            if (_transStatusMsg && _transStatusMsg.length > 0) {
                const headerMessageArray = this.breakdownReceiptHeaderMessage(_transStatusMsg);

                for (let cursor = 0; cursor < headerMessageArray.length; cursor++) {
                    const message = headerMessageArray[cursor];
                    const _startY = 270 + 15 * cursor;
                    startY = _startY + 15;
                    doc.setFontSize(12);
                    doc.setTextColor(51, 51, 51);
                    doc.text(message, 300, _startY, 'center');
                }

            }

            // Draw amount and currency
            const x =  this.currencyPipe.transform(transaction.Amount, '₦') ;
            startY = startY + 10;
            doc.setFontSize(16);
            doc.setTextColor(51, 51, 51);
            doc.text(x, 300, startY, 'center');

            startY = startY + 10;

            // Draw beneficiary/biller/remita name
            if (receiptType == 'single-transfer' || receiptType === 'beneficiary' || receiptType === 'biller' || receiptType === 'remita') {
                startY = startY + 20;
                doc.setFontSize(12);
                doc.setTextColor(51, 51, 51);
                doc.text(transaction.CustomerName, 300, startY, 'center');
            }

            // Print Beneficiary account no and bank
            if (receiptType === 'beneficiary' || receiptType === 'single-transfer') {
                startY = startY + 20;
                doc.text(transaction.PFA + ' - ' + transaction.RSA, 300, startY, 'center');
            }
            // Draw Dotted Lines
            doc.setDrawColor(142, 142, 142);
            let _d;
            startY += 15;
            // tslint:disable-next-line:no-var-keyword
            for (var e = 1; 50 > e; e++) {
                _d = 175 + 5 * e;
                doc.rect(_d, startY, 2, 1, 'D');
            }

            // Draw other key values e.g tran Id, my reference, their reference
            //startY = this.printObjectOnPdf(doc, otherReceiptObjectValues, startY);

            // Wrap content with a round rectangle
            const r = doc.internal.pageSize.height;
            const E = r - 200;
            const l = startY - 160; // c + 40; // or c - 160

            doc.setDrawColor(142, 142, 142);
            // doc.roundedRect(175, 180, 250, l, 3, 3, 'D');

            // Draw disclamer Image
            if (startY > E) {
                doc.addPage();
                doc.addImage(StanbicImageConstants.STANBIC_PDF_DISCLAIMER, 'PNG', 50, 100, 493, 120);
            } else {
                doc.addImage(StanbicImageConstants.STANBIC_PDF_DISCLAIMER, 'PNG', 50, 655, 493, 120);
            }
            resolve(doc);
        });
    }

    generateMastercardTransactionReceipt(transaction: TransactionHistory, receiptType, recipientName): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const doc = new jsPdf('p', 'pt');
            // Add Stanbic Logo
            doc.setFillColor(240, 243, 248);
            doc.rect(0, 0, 600, 120, 'F');
            doc.addImage(StanbicImageConstants.STANBIC_PDF_HEADER_LOGO, 'PNG', 190, 60, 183, 34);

            // Draw Receipt Header
            doc.setFontSize(18);
            doc.setTextColor(0, 0, 0);
            doc.text('International Payments Receipt', 280, 150, 'center');

            //  Draw success/failure icon
            const _transStatusLogo =  StanbicImageConstants.RECEIPT_SUCCESS_LOGO
            doc.addImage(_transStatusLogo, 'PNG', 281, 200, 44, 44);

            // Draw transaction status message
            let _transStatusMsg =  'Transfer was successful';
            let startY = 0;
            if (_transStatusMsg && _transStatusMsg.length > 0) {
                const headerMessageArray = this.breakdownReceiptHeaderMessage(_transStatusMsg);

                for (let cursor = 0; cursor < headerMessageArray.length; cursor++) {
                    const message = headerMessageArray[cursor];
                    const _startY = 270 + 15 * cursor;
                    startY = _startY + 15;
                    doc.setFontSize(12);
                    doc.setTextColor(51, 51, 51);
                    doc.text(message, 300, _startY, 'center');
                }

            }

            // Draw amount and currency
            const x =  transaction.AmountSentFX ;
            startY = startY + 10;
            doc.setFontSize(16);
            doc.setTextColor(51, 51, 51);
            doc.text(transaction.FXCurrencySent+' '+Number(x).toLocaleString(), 300, startY, 'center');

            startY = startY + 10;

            // Draw beneficiary/biller/remita name
            if (receiptType == 'single-transfer' || receiptType === 'beneficiary' || receiptType === 'biller' || receiptType === 'remita') {
                //startY = startY + 20;
                //doc.setFontSize(12);
                //doc.setTextColor(51, 51, 51);
               // doc.text( "Sent to: "+transaction.ReceiverName, 300, startY, 'center');
              if (recipientName && recipientName.length > 0) {
                    const recipientNameArray = this.breakdownRecipientMessage(recipientName);

                    for (let cursor = 0; cursor < recipientNameArray.length; cursor++) {
                        const message = recipientNameArray[cursor];
                        startY = startY + 15;
                        doc.setFontSize(12);
                        doc.setTextColor(51, 51, 51);
                        doc.text(message, 300, startY, 'center');
                    }
                }
            }

            // Print Beneficiary account no and bank
            if (receiptType === 'beneficiary' || receiptType === 'single-transfer') {
                startY = startY + 20;

                doc.text(transaction.ReceiverBank + ' - ' + transaction.ReceiverAcctNum, 300, startY, 'center');
            }

            // Draw Dotted Lines
            doc.setDrawColor(142, 142, 142);
            let _d;
            startY += 15;
            // tslint:disable-next-line:no-var-keyword
            for (var e = 1; 50 > e; e++) {
                _d = 175 + 5 * e;
                doc.rect(_d, startY, 2, 1, 'D');
            }
            startY = startY + 20;
            doc.setFontSize(10);
            doc.setTextColor(102, 102, 102);
            doc.text("Transaction Type", 300, startY, 'center');

            startY = startY + 15;
            doc.setFontSize(12);
            doc.setTextColor(51, 51, 51);
            doc.text("International Payments", 300, startY, 'center');

            startY = startY + 20;
            doc.setFontSize(10);
            doc.setTextColor(102, 102, 102);
            doc.text("Date", 300, startY, 'center');

            startY = startY + 15;
            doc.setFontSize(12);
            doc.setTextColor(51, 51, 51);
            doc.text(transaction.Date +" | 15:32 WAT", 300, startY, 'center');

            startY = startY + 20;
            doc.setFontSize(10);
            doc.setTextColor(102, 102, 102);
            doc.text("From", 300, startY, 'center');

            startY = startY + 15;
            doc.setFontSize(12);
            doc.setTextColor(51, 51, 51);
            doc.text("Idris UgoChukwu", 300, startY, 'center');





            // Wrap content with a round rectangle
            const r = doc.internal.pageSize.height;
            const E = r - 200;
            const l = startY - 160; // c + 40; // or c - 160

            doc.setDrawColor(142, 142, 142);
            // doc.roundedRect(175, 180, 250, l, 3, 3, 'D');

            // Draw disclamer Image
            if (startY > E) {
                doc.addPage();
                doc.addImage(StanbicImageConstants.STANBIC_PDF_DISCLAIMER, 'PNG', 50, 100, 493, 120);
            } else {
                doc.addImage(StanbicImageConstants.STANBIC_PDF_DISCLAIMER, 'PNG', 50, 655, 493, 120);
            }
            resolve(doc);
        });
    }

    generateFlightReceipt(booking : BookingHistory, customerName, bookingDate, arrivalDate, departureDate, noOfPassengers): Promise<any>
    {
        return new Promise<any>((resolve, reject) => {
            const doc = new jsPdf('p', 'pt');
            // Add Stanbic Logo
            doc.setFillColor(240, 243, 248);
            doc.rect(0, 0, 600, 120, 'F');
            doc.addImage(StanbicImageConstants.STANBIC_PDF_HEADER_LOGO, 'PNG', 190, 60, 183, 34);

            // Draw Receipt Header
            doc.setFontSize(18);
            doc.setTextColor(0, 0, 0);
            doc.text('Wakanow ' + booking.type+' Booking Receipt', 280, 150, 'center');

            //  Draw success/failure icon
            const _transStatusLogo =  StanbicImageConstants.RECEIPT_SUCCESS_LOGO
            doc.addImage(_transStatusLogo, 'PNG', 281, 200, 44, 44);

            // Draw transaction status message
            let _transStatusMsg =  'Booking was successful';
            let startY = 0;
            if (_transStatusMsg && _transStatusMsg.length > 0) {
                const headerMessageArray = this.breakdownReceiptHeaderMessage(_transStatusMsg);

                for (let cursor = 0; cursor < headerMessageArray.length; cursor++) {
                    const message = headerMessageArray[cursor];
                    const _startY = 270 + 15 * cursor;
                    startY = _startY + 15;
                    doc.setFontSize(12);
                    doc.setTextColor(51, 51, 51);
                    doc.text(message, 300, _startY, 'center');
                }

            }

            // Draw amount and currency
          const x = this.currencyPipe.transform(booking.totalCost, `${booking.currency}`);
            startY = startY + 10;
            doc.setFontSize(16);
            doc.setTextColor(51, 51, 51);
            doc.text(x, 300, startY, 'center');
            startY = startY + 10;

            // Draw Dotted Lines
            doc.setDrawColor(142, 142, 142);
            let _d;
            startY += 15;
            // tslint:disable-next-line:no-var-keyword
            for (var e = 1; 50 > e; e++) {
                _d = 175 + 5 * e;
                doc.rect(_d, startY, 2, 1, 'S');
            }

            startY = startY + 20;
            doc.setFontSize(10);
            doc.setTextColor(102, 102, 102);
            doc.text("Transaction Type", 300, startY, 'center');

            startY = startY + 15;
            doc.setFontSize(12);
            doc.setTextColor(51, 51, 51);
            doc.text("Wakanow Payment for "+ booking.flightDetails[0].name, 300, startY, 'center');

            startY = startY + 20;
            doc.setFontSize(10);
            doc.setTextColor(102, 102, 102);
            doc.text("Payment Made By", 300, startY, 'center');

            if (customerName && customerName.length > 0) {
                const customerNameArray = this.breakdownRecipientMessage(customerName);

                for (let cursor = 0; cursor < customerNameArray.length; cursor++) {
                    const message = customerNameArray[cursor];
                    startY = startY + 15;
                    doc.setFontSize(12);
                    doc.setTextColor(51, 51, 51);
                    doc.text(message, 300, startY, 'center');
                }
            }

            startY = startY + 20;
            doc.setFontSize(10);
            doc.setTextColor(102, 102, 102);
            doc.text("Booking Date", 300, startY, 'center');

            startY = startY + 15;
            doc.setFontSize(12);
            doc.setTextColor(51, 51, 51);
            doc.text(bookingDate , 300, startY, 'center');

            startY = startY + 20;
            doc.setFontSize(10);
            doc.setTextColor(102, 102, 102);
            doc.text("Booking Id", 300, startY, 'center');

            startY = startY + 15;
            doc.setFontSize(12);
            doc.setTextColor(51, 51, 51);
            doc.text(booking.id, 300, startY, 'center');



            startY = startY + 20;
            doc.setFontSize(10);
            doc.setTextColor(102, 102, 102);
            doc.text("Departure Details", 300, startY, 'center');

            const departureDetails = booking.flightDetails[0].leavingFrom +' - '+
            booking.flightDetails[0].departingAirport+' - '+departureDate;
            if (departureDetails && departureDetails.length > 0) {
                const departureDetailsArray = this.breakdownRecipientMessage(departureDetails);

                for (let cursor = 0; cursor < departureDetailsArray.length; cursor++) {
                    const message = departureDetailsArray[cursor];
                    startY = startY + 15;
                    doc.setFontSize(12);
                    doc.setTextColor(51, 51, 51);
                    doc.text(message, 300, startY, 'center');
                }
            }


            startY = startY + 20;
            doc.setFontSize(10);
            doc.setTextColor(102, 102, 102);
            doc.text("Arrival Details", 300, startY, 'center');

            const arrivalDetails = booking.flightDetails[0].destination +' - '+booking.flightDetails[0].arrivingAirport
            +' - '+arrivalDate;
            if (arrivalDetails && arrivalDetails.length > 0) {
                const arrivalDetailsArray = this.breakdownRecipientMessage(arrivalDetails);

                for (let cursor = 0; cursor < arrivalDetailsArray.length; cursor++) {
                    const message = arrivalDetailsArray[cursor];
                    startY = startY + 15;
                    doc.setFontSize(12);
                    doc.setTextColor(51, 51, 51);
                    doc.text(message, 300, startY, 'center');
                }
            }
            if(booking.flightDetails[1]){
                startY = startY + 20;
                doc.setFontSize(10);
                doc.setTextColor(102, 102, 102);
                doc.text("Return Details", 300, startY, 'center');

                // doc.text("Departure Details", 300, startY, 'center');

                const departureDetails = booking.flightDetails[1].leavingFrom +' - '+
                booking.flightDetails[1].departingAirport+' - '+ booking.flightDetails[1].departureTime;
                if (departureDetails && departureDetails.length > 0) {
                    const departureDetailsArray = this.breakdownRecipientMessage(departureDetails);

                    for (let cursor = 0; cursor < departureDetailsArray.length; cursor++) {
                        const message = departureDetailsArray[cursor];
                        startY = startY + 15;
                        doc.setFontSize(12);
                        doc.setTextColor(51, 51, 51);
                        doc.text(message, 300, startY, 'center');
                    }
                }

                startY = startY + 20;
                doc.setFontSize(10);
                doc.setTextColor(102, 102, 102);

                const arrivalDetails = booking.flightDetails[1].destination +' - '+booking.flightDetails[1].arrivingAirport
                +' - '+ booking.flightDetails[1].arrivalTime;
                if (arrivalDetails && arrivalDetails.length > 0) {
                    const arrivalDetailsArray = this.breakdownRecipientMessage(arrivalDetails);

                    for (let cursor = 0; cursor < arrivalDetailsArray.length; cursor++) {
                        const message = arrivalDetailsArray[cursor];
                        startY = startY + 15;
                        doc.setFontSize(12);
                        doc.setTextColor(51, 51, 51);
                        doc.text(message, 300, startY, 'center');
                    }
                }

            }
            startY = startY + 20;
            doc.setFontSize(10);
            doc.setTextColor(102, 102, 102);
            doc.text("Total Number of Passengers", 300, startY, 'center');

            startY = startY + 15;
            doc.setFontSize(12);
            doc.setTextColor(51, 51, 51);
            doc.text(noOfPassengers, 300, startY, 'center');



            // Wrap content with a round rectangle
            const r = doc.internal.pageSize.height;
            const E = r - 200;
            const l = startY - 160; // c + 40; // or c - 160

            doc.setDrawColor(142, 142, 142);
            doc.roundedRect(175, 180, 250, l, 3, 3, 'S');

            // Draw disclamer Image
            if (startY > E) {
                doc.addPage();
                doc.addImage(StanbicImageConstants.STANBIC_PDF_DISCLAIMER, 'PNG', 50, 100, 493, 120);
            } else {
                doc.addImage(StanbicImageConstants.STANBIC_PDF_DISCLAIMER, 'PNG', 50, 655, 493, 120);
            }
            resolve(doc);
        });
    }

    generateHotelReceipt(booking : BookingHistory, customerName, bookingDate, arrivalDate, totalGuests): Promise<any>
    {
        return new Promise<any>((resolve, reject) => {
            const doc = new jsPdf('p', 'pt');
            // Add Stanbic Logo
            doc.setFillColor(240, 243, 248);
            doc.rect(0, 0, 600, 120, 'F');
            doc.addImage(StanbicImageConstants.STANBIC_PDF_HEADER_LOGO, 'PNG', 190, 60, 183, 34);

            // Draw Receipt Header
            doc.setFontSize(18);
            doc.setTextColor(0, 0, 0);
            doc.text('Wakanow ' +booking.type+' Booking Receipt', 280, 150, 'center');

            //  Draw success/failure icon
            const _transStatusLogo =  StanbicImageConstants.RECEIPT_SUCCESS_LOGO
            doc.addImage(_transStatusLogo, 'PNG', 281, 200, 44, 44);

            // Draw transaction status message
            let _transStatusMsg =  'Booking was successful';
            let startY = 0;
            if (_transStatusMsg && _transStatusMsg.length > 0) {
                const headerMessageArray = this.breakdownReceiptHeaderMessage(_transStatusMsg);

                for (let cursor = 0; cursor < headerMessageArray.length; cursor++) {
                    const message = headerMessageArray[cursor];
                    const _startY = 270 + 15 * cursor;
                    startY = _startY + 15;
                    doc.setFontSize(12);
                    doc.setTextColor(51, 51, 51);
                    doc.text(message, 300, _startY, 'center');
                }

            }

            // Draw amount and currency
            const x = this.currencyPipe.transform(booking.totalCost, `${booking.currency}`);
            startY = startY + 10;
            doc.setFontSize(16);
            doc.setTextColor(51, 51, 51);
            doc.text(x, 300, startY, 'center');

            startY = startY + 10;


            // Draw Dotted Lines
            doc.setDrawColor(142, 142, 142);
            let _d;
            startY += 15;
            // tslint:disable-next-line:no-var-keyword
            for (var e = 1; 50 > e; e++) {
                _d = 175 + 5 * e;
                doc.rect(_d, startY, 2, 1, 'S');
            }
            startY = startY + 20;
            doc.setFontSize(10);
            doc.setTextColor(102, 102, 102);
            doc.text("Transaction Type", 300, startY, 'center');

            startY = startY + 15;
            doc.setFontSize(12);
            doc.setTextColor(51, 51, 51);
            doc.text("Wakanow Payment for "+ booking.name, 300, startY, 'center');

            startY = startY + 20;
            doc.setFontSize(10);
            doc.setTextColor(102, 102, 102);
            doc.text("Payment Made By", 300, startY, 'center');

            if (customerName && customerName.length > 0) {
                const customerNameArray = this.breakdownRecipientMessage(customerName);

                for (let cursor = 0; cursor < customerNameArray.length; cursor++) {
                    const message = customerNameArray[cursor];
                    startY = startY + 15;
                    doc.setFontSize(12);
                    doc.setTextColor(51, 51, 51);
                    doc.text(message, 300, startY, 'center');
                }
            }

            startY = startY + 20;
            doc.setFontSize(10);
            doc.setTextColor(102, 102, 102);
            doc.text("Booking Date", 300, startY, 'center');


            startY = startY + 15;
            doc.setFontSize(12);
            doc.setTextColor(51, 51, 51);
            doc.text(bookingDate , 300, startY, 'center');

            startY = startY + 20;
            doc.setFontSize(10);
            doc.setTextColor(102, 102, 102);
            doc.text("Booking Id", 300, startY, 'center');

            startY = startY + 15;
            doc.setFontSize(12);
            doc.setTextColor(51, 51, 51);
            doc.text(booking.id, 300, startY, 'center');



            startY = startY + 20;
            doc.setFontSize(10);
            doc.setTextColor(102, 102, 102);
            doc.text("Hotel Details", 300, startY, 'center');

            const hotelDetails = booking.name;
            if (hotelDetails && hotelDetails.length > 0) {
                const hotelDetailsArray = this.breakdownRecipientMessage(hotelDetails);

                for (let cursor = 0; cursor < hotelDetailsArray.length; cursor++) {
                    const message = hotelDetailsArray[cursor];
                    startY = startY + 15;
                    doc.setFontSize(12);
                    doc.setTextColor(51, 51, 51);
                    doc.text(message, 300, startY, 'center');
                }
            }




            startY = startY + 20;
            doc.setFontSize(10);
            doc.setTextColor(102, 102, 102);
            doc.text("Total Number of Guests", 300, startY, 'center');

            startY = startY + 15;
            doc.setFontSize(12);
            doc.setTextColor(51, 51, 51);
            doc.text(totalGuests, 300, startY, 'center');

            startY = startY + 20;
            doc.setFontSize(10);
            doc.setTextColor(102, 102, 102);
            doc.text("Check-In Date", 300, startY, 'center');

            startY = startY + 15;
            doc.setFontSize(12);
            doc.setTextColor(51, 51, 51);
            doc.text(arrivalDate, 300, startY, 'center');


            // Wrap content with a round rectangle
            const r = doc.internal.pageSize.height;
            const E = r - 200;
            const l = startY - 160; // c + 40; // or c - 160

            doc.setDrawColor(142, 142, 142);
            doc.roundedRect(175, 180, 250, l, 3, 3, 'S');

            // Draw disclamer Image
            if (startY > E) {
                doc.addPage();
                doc.addImage(StanbicImageConstants.STANBIC_PDF_DISCLAIMER, 'PNG', 50, 100, 493, 120);
            } else {
                doc.addImage(StanbicImageConstants.STANBIC_PDF_DISCLAIMER, 'PNG', 50, 655, 493, 120);
            }
            resolve(doc);
        });
    }

    generateStatement(accountNo: string, accountName: string, fromDate: string, toDate: string,
        openingBalance: string, closingBalance: string, statementCurrency: string,
        headerColumns: Array<string>, rowValues: any): any {

        const headerLogo = StanbicImageConstants.STANBIC_PDF_HEADER_LOGO;
        const watermarkImage = StanbicImageConstants.STANBIC_PDF_WATERMARK;
        const disclaimerImage = StanbicImageConstants.STANBIC_PDF_DISCLAIMER;
        const generationDate = (new Date()).toLocaleDateString();

        const doc = new jsPdf('p', 'pt');
        doc.setFillColor(240, 243, 248);
        doc.rect(0, 0, 600, 210, 'F');
        // Set Provisional Statement and Account Number Header
        doc.setFontSize(12);
        doc.setTextColor(0, 0, 0);
        doc.setFontStyle('bold');

        doc.text('Statement', 40, 110);
        doc.text('Account Number: ' + accountNo, 555, 110, 'right');

        // Set details as at
        doc.setFontStyle('normal');
        doc.setFontSize(10);
        doc.setTextColor(102, 102, 102);
        doc.text('Details as on: ', 40, 125);
        doc.setTextColor(51, 51, 51);
        // doc.text('2:46:46 PM, 20/03/2018', 102, 125);
        doc.text(generationDate, 102, 125);

        // Set account name
        doc.setFontSize(10);
        doc.setTextColor(51, 51, 51);
        doc.text(accountName, 555, 125, 'right');


        doc.setFontSize(10);
        doc.setTextColor(102, 102, 102);

        // Set From Date Headers
        doc.text('From date', 40, 160);

        // Set To Date Headers
        doc.text('To date', 170, 160);

        // Set Opening Balance Headers

        doc.text('Opening balance', 415, 160, 'right');

        // Set Closing Balance Headers
        doc.text('Closing balance', 555, 160, 'right');



        doc.setFontSize(12);
        doc.setTextColor(51, 51, 51);
        doc.setFontStyle('bold');

        // Set From Date Value
        // doc.text("27/09/2017", 40, 175);
        doc.text(fromDate, 40, 175);

        // Set To Date Value
        doc.text(toDate, 170, 175);

        // Set Opening Balance Value
        doc.text(statementCurrency.toUpperCase() + ' ' + closingBalance, 415, 175, 'right');



        // Set Closing Balance Value
        doc.text(statementCurrency.toUpperCase() + ' ' + openingBalance, 555, 175, 'right');

        // Generate Table
        doc.autoTable(headerColumns, rowValues, {
            startY: 210,
            styles: {
                fontSize: 8, lineHeight: 30, valign: 'middle', halign: 'left',
                overflow: 'linebreak', overflowColumns: true, columnWidth: 'wrap'
                // tableWidth: 'auto',
                // columnWidth: 'auto'
            },
            headerStyles: {
                fillColor: [34, 68, 132],
                fontStyle: 'bold',
                textColor: [255, 255, 255]
            },
            drawHeaderRow: function (row, data) {
                row.height = 35;
                row.width = 600;
            },
            drawRow: function (row, data) {
                // row.height = 30;
                // row.height = 'auto';
                row.width = 600;
            },
            margin: {
                right: 0,
                left: 0,
                top: 100,
                bottom: 40
            },
            columnStyles: {
                0: { columnWidth: 50 },
                1: { columnWidth: 50 },
                2: { columnWidth: 75 },
                3: { columnWidth: 300 },
                4: { columnWidth: 50 },
                5: { columnWidth: 75 }
            }
        });


        //   ['S/N 50', 'Date 50', 'Amount75', 'Description 300', 'Type 50', 'Balance 75'],
        // 600


        // Place Disclaimer Image. Height of Disclaimer: 120
        const lastPageHeightLessFooter = doc.internal.pageSize.height - 30;

        // doc.autoTable.previous.finalY -- gives the y-coordinate of the bottom of the table on the current page.
        // Add 150 to the value to prevent disclaimer from falling on the footer
        if (doc.autoTable.previous.finalY + 150 < lastPageHeightLessFooter) {
            doc.addImage(disclaimerImage, 'PNG', 50, doc.autoTable.previous.finalY + 20, 493, 120);
        } else {
            doc.addPage();
            doc.addImage(disclaimerImage, 'PNG', 50, 100, 493, 120);
        }

        // Add footer, watermark, and stanbic logo to each page
        const numberOfPages = doc.internal.getNumberOfPages();

        doc.setFontStyle('normal');
        doc.setFontSize(13);
        doc.setTextColor(0, 0, 0);
        for (let O = 1; O <= numberOfPages; O++) {
            const P = 'Page ' + O + ' of ' + numberOfPages;
            const Q = doc.internal.pageSize.height - 50;
            doc.setPage(O);
            doc.addImage(watermarkImage, 'PNG', 220, 330, 160, 190);
            doc.addImage(headerLogo, 'PNG', 40, 40, 183, 34);
            doc.text(P, 50, Q);
        }


        return doc;
    }

    private breakdownReceiptHeaderMessage(headerMessage) {
        const b = []
            , c = [];
        if (headerMessage.length <= 40) {
            b.push(headerMessage);
        } else {
            // tslint:disable-next-line:no-var-keyword
            for (var d, e = headerMessage.split(' '), d = e[0], f = e.length, g = 0; f > g; g++) {
                c[0] = d,
                    d = d + ' ' + e[g + 1],
                    c[1] = d,
                    // tslint:disable-next-line:no-unused-expression
                    g !== e.length - 1 ? c[1].length > 40 && (b.push(c[0]),
                        d = e[g + 1]) : g === e.length - 1 && b.push(c[0]);
            }
        }
        return b;

    }
    private breakdownRecipientMessage(recipientName) {
        const b = []
            , c = [];
        if (recipientName.length <= 30) {
            b.push(recipientName);
        } else {
            // tslint:disable-next-line:no-var-keyword
            for (var d, e = recipientName.split(' '), d = e[0], f = e.length, g = 0; f > g; g++) {
                c[0] = d,
                    d = d + ' ' + e[g + 1],
                    c[1] = d,
                    // tslint:disable-next-line:no-unused-expression
                    g !== e.length - 1 ? c[1].length > 40 && (b.push(c[0]),
                        d = e[g + 1]) : g === e.length - 1 && b.push(c[0]);
            }
        }
        return b;

    }
    private printObjectOnPdf(doc, _object, startY) {
        const objectKeyArray = Object.keys(_object);
        for (let cursor = 0; cursor < objectKeyArray.length; cursor++) {
            const header = objectKeyArray[cursor];
            const value = _object[header];

            const headerY = 20 + startY;
            const valueY = headerY + 15;
            startY = valueY;

            doc.setFontSize(10);
            doc.setTextColor(102, 102, 102);
            doc.text(header, 300, headerY, 'center');
            doc.setFontSize(12);
            doc.setTextColor(51, 51, 51);
            doc.text(value, 300, valueY, 'center');
        }
        return startY;
    }

    private getOtherReceiptDetails(transactionObj: TransferHistory, theirReference) {
        return {
            'Transaction type': transactionObj.transactionType,
            'Reference ID': transactionObj.referenceId,
            'Date': transactionObj.date,
            'From': transactionObj['sourceAccountName'] ? transactionObj['sourceAccountName'].toUpperCase() : '',
            'Their reference': theirReference ? theirReference.toUpperCase() : ''
        };
    }
}
