import { Component, OnInit, Input, SimpleChanges, OnChanges, TemplateRef, ViewChild, OnDestroy } from '@angular/core';
import { ApprovalStage } from 'src/app/core/models/payloads/transactions';
import { Role, ResponseCodes, OtpReasonCodes } from 'src/app/core/models/Enumerations';
import { MatSnackBar, MatDialogRef, MatDialog, PageEvent, MatStepper } from '@angular/material';
import { MiscService } from 'src/app/core/services/misc.service';
import { RequestStagingService } from 'src/app/core/services/staging/service-request.staging.service';
import { StagedServiceRequest } from 'src/app/core/models/service-request/misc-models';
import { StagedBeneficiaryRequestApprovalPayload } from 'src/app/core/models/beneficiary';
import { NgForm } from '@angular/forms';
import { ServiceRequestOperationApprovalService } from 'src/app/core/services/workflow/service-request.approval.service';
import { Constants } from 'src/app/core/helpers/appConstants';
import { BaseApprovalComponent } from '../base.approval.component';
import { AuthService } from 'src/app/core/services/auth.service';
import { RejectRequestComponent } from 'src/app/shared/dialogs/reject-request/reject-request.component';
import { RequestApprovalHistoryPayload } from 'src/app/core/models/misc-requests-model';
import { ApprovalHistoryComponent } from 'src/app/shared/approval-history/approval-history.component';
import { UtilService } from 'src/app/core/services/helpers.service';

@Component({
  selector: 'app-service-request-approvals',
  templateUrl: './service-request-approvals.component.html',
  styleUrls: ['./service-request-approvals.component.css']
})
export class ServiceRequestApprovalsComponent extends BaseApprovalComponent implements OnInit, OnChanges, OnDestroy {

  ownLoaderIsActive: boolean;
  ownloaderMsg: string;
  @Input() _activeTab: string;

  _activeRequestCount = 0;
  requestList: StagedServiceRequest[] = [];
  requestListBackup: StagedServiceRequest[] = [];
  _activeRequestObject: StagedServiceRequest;
  _paginationSize = 5;
  _selectedRequestCount = 0;
  requestDetailModalData = {
    currentRequestAction: '',
    currentApprovalAction: '',
    data: null,
    requestMeta: {} as any
  };
  oneTimePassCode: string;
  _otpReferenceId: string;
  filterIsActive: boolean;
  userRoleEnum = Role;
  allCheckboxesSelected: boolean;
  _availableApprovalStages: Array<ApprovalStage> = [];

  @ViewChild('requestDetailModalTemplate') _requestDetailModalTemplate: TemplateRef<any>;
  public requestDetailModal: MatDialogRef<any>;
  private approvalHistoryModal: MatDialogRef<any>;
  private rejectionDialog: MatDialogRef<any>;

  constructor(private _requestStagingService: RequestStagingService,
    private _dialog: MatDialog, private _snackbar: MatSnackBar,
    private _miscService: MiscService,
    private _approvalService: ServiceRequestOperationApprovalService, authService: AuthService) {
      
    super(authService);
    // try {
    //   window['casa']('other'); 
    //  } catch (error) {}
    this.pageTitle = this._loggedInUser && this._authService.loggedInUserHasSMEProfile() &&
      this._loggedInUserIsApprover ? 'Authorize Service-Request Approval Requests' : '';

    UtilService.onPageLoadCallback();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['_activeTab']) {
      this._activeTab = changes['_activeTab'].currentValue;
      if (this._activeTab === 'service-requests') {
        this.loadPendingServiceRequests();
      }
      if (this._activeTab !== 'service-requests') {
        this.dismissOpenModalWindows();
      }
    }
  }

  ngOnDestroy(): void {
    this.dismissOpenModalWindows();
  }

  loadPendingServiceRequests(loaderMsg = 'retrieving pending service requests .. please wait', lastFetchId = 0, pageSize = 100) {
    this.ownLoaderIsActive = true;
    this.ownloaderMsg = loaderMsg;
    this._requestStagingService.getStagedServiceRequestApprovalRequest({ PageSize: pageSize, LastFetchId: lastFetchId }).then(
      (response) => {
        console.log(response);
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        if (!response || !response.ResponseCode || response.ResponseCode !== ResponseCodes.SUCCESS) {
          const statusMsg = response && response.ResponseFriendlyMessage ? response.ResponseFriendlyMessage :
            'Failed to load pending service requestal awaiting your approvals';
          this._snackbar.open(`${statusMsg}`, 'Ok', { duration: 25000 }); return;
        }
        this.requestListBackup = response.PaginatedItems;
        this.requestList = this.requestListBackup.slice(0, this._paginationSize);

      },
      (error) => {
        console.log(error);
      }
    );

  }

  onRequestCheckboxChanged(evtData, postion: number) {
    this.requestList[postion]['checked'] = evtData.checked;
    const indexOnBackup = this.requestListBackup.indexOf(this.requestList[postion]);
    this.requestListBackup[indexOnBackup]['checked'] = evtData.checked;
    this._selectedRequestCount = this.requestListBackup.filter(request => request['checked']).length;
  }

  onAllCheckboxChanged(evtData) {
    if (evtData.checked) {
      this.requestListBackup.forEach(row => row['checked'] = true);
      this._selectedRequestCount = this.requestListBackup.length;
    } else {
      this.requestListBackup.forEach(b => b['checked'] = false);
      this._selectedRequestCount = 0;
    }
    this.requestList = this.requestListBackup.slice(0, 5);
  }


  onServiceRequestListPageChanged(pageChangedEvent: PageEvent) {
    const start = pageChangedEvent.pageIndex * pageChangedEvent.pageSize;
    const end = start + pageChangedEvent.pageSize;
    this.requestList = this.requestListBackup.slice(start, end);

  }

  onApprovalRequestAction(action: string, data: StagedServiceRequest) {
    this.requestDetailModalData['modalTitle'] = this.getApprovalModalTitle(data.Details.OperationType);
    this.requestDetailModalData.requestMeta['BatchId'] = data.BatchId;
    this.requestDetailModalData.requestMeta['Initiator'] = data.Initiator;
    this.requestDetailModalData.requestMeta['ApprovalStageId'] = data.ApprovalStageId;
    this.requestDetailModalData.requestMeta['ApproverId'] = data.ApproverId;
    this.requestDetailModalData.data = data.Details;
    switch (action) {
      case 'approve': {
        this.getRequestDetailObj(data.Details.SerializedDetails);
        this.initRequestApprovalModal(data);
        break;
      }
      case 'reject': {
        this.rejectionDialog = this._dialog.open(RejectRequestComponent,
          {
            data: {
              title: 'Reject Service-Request Approval Request',
              payload: {
                OperationType: 'SERVICE_REQUEST',
                OperationId: data.Details.Id
              }
            },
            hasBackdrop: false,
            closeOnNavigation: true,
            disableClose: true
          });
        this.rejectionDialog.afterClosed()
          .subscribe((result) => {
            if (result) {
              this.loadPendingServiceRequests('refreshing .. please wait');
            }
          });

        break;
      }
      case 'view-history': {
        const requestPayload: RequestApprovalHistoryPayload = {
          OperationType: 'SERVICE_REQUEST',
          OperationId: Number(data.Details.Id),
          UserId: '',
          PageSize: 100,
          LastIdFetched: 0
        };
        this.approvalHistoryModal = this._dialog.open<ApprovalHistoryComponent>(
          ApprovalHistoryComponent,
          {
            hasBackdrop: false,
            data: {
              operationName: 'service-request',
              requestPayload: requestPayload,
              title: 'Request Approval History',
              extra: {}
            }
          });
        this.approvalHistoryModal.afterClosed().subscribe(
          (result) => {
            //
          }
        );
        break;
      }
    }
  }

  onRequestModalAction(action: string, stepper: MatStepper, form?: NgForm) {
    console.log(action);
    switch (action) {
      case 'approve': {
        this.initiateRequestApprovalOTP(stepper);
        break;
      }
      case 'reject': {
        if (form && !form.valid) {
          this._snackbar.open('Please provode a reason for rejecting request .. ', 'Ok',
            { duration: 10000 });
          return;
        }
        break;
      }
    }
  }

  onSelectedNextApprovalStageSubmitted(approvalStage: ApprovalStage, stepper: MatStepper) {
    // this.ownLoaderIsActive = true;
    // this.ownloaderMsg = 'setting next approval stage .. please wait';
    const requestPayload = this.buildOperationApprovalRequestPayload(this.requestDetailModalData.data.Details.OperationType);
    requestPayload.NextStages = [approvalStage];
    this.sendServiceOperationApprovalRequest(requestPayload, stepper);
  }

  onVerifyApprovalConfirmationOtp(form: NgForm, stepper: MatStepper) {
    if (!form.valid) {
      this._snackbar.open(`Invalid One Time Pin provided. Please provide a valid OTP to continue`, 'Ok',
        { duration: 10000 });
      return;
    }
    const requestPayload = this.buildOperationApprovalRequestPayload(this.requestDetailModalData.data.Details.OperationType);
    this.sendServiceOperationApprovalRequest(requestPayload, stepper);
  }

  private getApprovalModalTitle(operationType) {
    switch (operationType) {
      case 'CHEQUEBOOK_REQUEST':
        return 'Approve Cheque book service request';
      case 'STOP_CHEQUE':
        return 'Approve Stop Cheque Request';
      case 'MAKE_ENQUIRY':
        return 'Approve Enquiry Request';
      case 'FREEZE_ACCOUNT':
        return 'Approve Account Freeze Request';
      case 'MODIFY_ACCOUNT':
        return 'Approve Name Change request';
      case 'CARD_REQUEST':
        return '';
    }
  }

  private buildOperationApprovalRequestPayload(operationType: string):
    StagedBeneficiaryRequestApprovalPayload {
    return {
      BatchId: Number(this.requestDetailModalData.requestMeta.BatchId),
      ApproverId: Number(this.requestDetailModalData.requestMeta.ApproverId),
      ApproverStageId: Number(this.requestDetailModalData.requestMeta.ApprovalStageId),
      OTP: this.oneTimePassCode,
      SourceReferenceId: this._otpReferenceId,
      OperationType: operationType
    };
  }

  private initiateRequestApprovalOTP(stepper: MatStepper) {
    this.ownLoaderIsActive = true;
    this.ownloaderMsg = 'initiating .. please wait';
    this._miscService.initiateOtpRequest(OtpReasonCodes.GENERIC, 'APPROVE SERVICE REQUEST').then(
      (response) => {
        this.ownloaderMsg = '';
        this.ownLoaderIsActive = false;
        if (!response || response.ResponseCode !== ResponseCodes.SUCCESS) {
          this._snackbar.open(`Failed to initiate approval process. Server responded with -> ${response.ResponseFriendlyMessage}`,
            'Ok', { duration: 25000 });
          return;
        }
        this._snackbar.open(`${response.ResponseFriendlyMessage}`, 'Ok', { duration: 25000 });
        this.requestDetailModalData.currentApprovalAction = 'verify-otp';
        this._otpReferenceId = response.ResponseDescription;
        setTimeout(() => stepper.next(), 25);
      },
      (error) => {
        console.log(error);
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        this._snackbar.open(`We encountered an error while processing your request. please retry`, 'Ok', { duration: 25000 });
      }
    );
  }
  private sendServiceOperationApprovalRequest(requestPayload, stepper: MatStepper) {
    this.ownLoaderIsActive = true;
    this.ownloaderMsg = 'Approving request .. please wait';
    this._approvalService.approvePendingServiceOperationRequest(requestPayload).then(
      (response) => {
        console.log(response);
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        if (!response || !response.ResponseCode) {
          this._snackbar.open(response && response.ResponseFriendlyMessage ? response.ResponseFriendlyMessage :
            `Failed to approve service request operation. Error occured`, 'Ok', { duration: 25000 });
          return;
        }
        if (response.ResponseCode === ResponseCodes.SUCCESS) {
          this._snackbar.open(response.ResponseFriendlyMessage, 'Ok', { duration: 25000 });
          this.requestDetailModalData.currentRequestAction = 'requestComplete';
          setTimeout(() => stepper.next(), 25);
          this.loadPendingServiceRequests();
          return;
        }
        this.handleNonSuccessApprovalResponse(requestPayload, response, stepper);
      },
      (error) => {
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        if (error && error.status != Constants.UnauthorizedStatusCode) {
          this._snackbar.open(Constants.APITechnicalErrorMessage,
            'Ok', { verticalPosition: 'bottom', horizontalPosition: 'right', duration: 1500 });
        }
        // this._snackbar.open(`${Constants.APITechnicalErrorMessage}`, 'Ok', { duration: 25000 });
      }
    );

  }

  private handleNonSuccessApprovalResponse(request: StagedServiceRequest, response: any, stepper: MatStepper) {
    switch (response.ResponseCode) {
      case '70': {
        this.requestDetailModalData.currentApprovalAction = 'select-next-approval-stage';
        this._availableApprovalStages = response.Stages;
        setTimeout(() => stepper.next(), 50);
        break;
      }
      default: {
        this._snackbar.open(`${response.ResponseFriendlyMessage}`, 'Retry', { duration: 25000 }).onAction()
          .subscribe(() => this.sendServiceOperationApprovalRequest(request, stepper));
        break;
      }
    }
  }

  private initRequestApprovalModal(request: StagedServiceRequest) {
    this.requestDetailModalData.data = request;
    this.requestDetailModal = this._dialog.open(this._requestDetailModalTemplate,
      { hasBackdrop: false, panelClass: 'beneficiary-details-card' });
  }

  oncloseRequestApprovalModal() {
    this.requestDetailModal.close();
    this.ownLoaderIsActive = false;
    this.ownloaderMsg = '';
    this.oneTimePassCode = '';
    this._otpReferenceId = '';
    this.requestDetailModalData.currentApprovalAction = '';
    this.requestDetailModalData.currentRequestAction = '';
  }

  getRequestDetailObj(text: string): object {
    text = text.replace('\\', '');
    const textData = text.substr(0, text.length).replace(/'/g, '"');
    return JSON.parse(textData);
  }


  private dismissOpenModalWindows() {
    if (this.requestDetailModal) {
      this.requestDetailModal.close();
    }
    if (this.approvalHistoryModal) {
      this.approvalHistoryModal.close();
    }

    if (this.rejectionDialog) {
      this.rejectionDialog.close();
    }
  }
}
