import { Injectable } from '@angular/core';
import { AppConfigService } from './appconfig.service';
import { HttpClient } from '@angular/common/http';
import { StagedTransfer } from '../models/transfer';
import { _mocktransferData, _mockPaymentApprovalHistory } from '../models/mocks/_transfers.mock';
import { FileUploadApprovalRequest } from '../models/approval-requests';
import { _mockFileUploadsData } from '../models/mocks/_fileuploads.mock';
import { BeneficiaryMgtApprovalRequest } from '../models/beneficiary';
import { _mockBeneficiaryData } from '../models/mocks/_beneficiaries.mock';
import { BillerMgtApprovalRequest } from '../models/biller';
import { _mockBillersManagementData } from '../models/mocks/_billers.mock';
import { PaymentApprovalDetail, Approval, RequestApprovalHistoryResponse } from '../models/approvals/payment.approval';

@Injectable()
export class StagedRequestService {

    _requestStagingAPIBase: string;
    constructor(private _settings: AppConfigService, private _httpClient: HttpClient) {
        this._requestStagingAPIBase = _settings.APIBaseUrl;
    }

    getStagedPaymentRequest(): Promise<StagedTransfer[]> {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(this.getMockPaymentRequests());
            }, 500);
        });
    }

    getStagedBeneficiaryApprovalsRequests(): Promise<BeneficiaryMgtApprovalRequest[]> {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(this.getMockBeneficiaryApprovalRequests());
            }, 500);
        });
    }

    getStagedBillerApprovalsRequests(): Promise<BillerMgtApprovalRequest[]> {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(this.getMockBillerApprovalRequests());
            }, 500);
        });
    }

    getStagedBillerPaymentRequest(): Promise<any[]> {
        return new Promise<any[]>((resolve, reject) => {
            setTimeout(() => {
                resolve([]);
            }, 500);
        });
    }

    getStagedServiceRequests(): Promise<any[]> {
        return new Promise<any[]>((resolve, reject) => {

        });
    }

    makePaymentApprovalRequest(payload: StagedTransfer): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            setTimeout(() => {
                resolve(payload);
            }, 500);
        });
    }

    makePaymentApprovalRecallRequest(payload: StagedTransfer): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            setTimeout(() => {
                resolve({});
            }, 500);
        });
    }

    getStagedPaymentRequestDetail(referenceId: string, request: StagedTransfer = {} as StagedTransfer): Promise<PaymentApprovalDetail> {
        return new Promise<PaymentApprovalDetail>((resolve, reject) => {
            resolve({
                referenceId: request.referenceId,
                transactionType: request.transactionType,
                transactionCurrency: 'NGN',
                transactionDate: request.transactionDate,
                transactionStatus: request.status,
                receipientReference: 'Sbassey consult',
                sourceAccount: 'Account Name',
                frequencyType: request.frequency,
                network: 'Within-Bank',
                beneficiaryType: 'Oneoff-ThirdParty',
                beneficiaryDescription: 'N/A',
                amount: request.totalAmount.toString(),
                senderReference: 'My_Ref',
                nextAuthorizer: 'NextApproverId',
                markedForStop: false
            });
        });
    }

    private getMockPaymentRequests(): Array<StagedTransfer> {
        return _mocktransferData['oneoff'] as Array<StagedTransfer>;
    }
    private getMockFileUploadRequests(): Array<FileUploadApprovalRequest> {
        return _mockFileUploadsData as Array<FileUploadApprovalRequest>;
    }

    private getMockBeneficiaryApprovalRequests(): Array<BeneficiaryMgtApprovalRequest> {
        return _mockBeneficiaryData as Array<BeneficiaryMgtApprovalRequest>;
    }
    private getMockBillerApprovalRequests(): Array<BillerMgtApprovalRequest> {
        return _mockBillersManagementData as Array<BillerMgtApprovalRequest>;
    }

}
