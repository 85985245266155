import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from '../../../../environments/environment';

@Injectable()
export class BentoService {
  baseURL = environment.bentoBaseURL;
  constructor(
    private _httpClient: HttpClient
  ) {}

  addBentoRecord(data: any): Observable<any> {
    const payload = {
      'customerCIF': data.customerCIF,
      'userId': data.userId,
      'serializedUserData': data.serializedUserData
    };

    return this._httpClient.post<any>(`${this.baseURL}AddBentoRecord`, payload);
  }

  authenticateBentoAPI(): Observable<any> {
    return this._httpClient.get<any>(`${this.baseURL}GetBentoAuthorization`);
  }

  // async authenticateBentoAPI() {
  //   const headers = new Headers();
  //   headers.append('provider_id', this.bentoCredentials.provider_id);
  //   headers.append('provider_secret', this.bentoCredentials.provider_secret);
  //
  //   // return this._httpClient.get<any>(`https://devapi.bento.africa/provider/authoriztion/generate`, {headers});
  //   let response;
  //   await fetch('https://devapi.bento.africa/provider/authoriztion/generate', {headers: headers})
  //     .then(r => {
  //       console.log(r);
  //       return r;
  //     }).then(res => {
  //       console.log(res);
  //       response = res;
  //   });
  //   return response;
  // }
}
