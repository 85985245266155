import { Component, OnInit, ViewChild, TemplateRef, Input, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { ApprovalRequestStatus, ResponseCodes, OtpReasonCodes } from 'src/app/core/models/Enumerations';
import { BillerMgtApprovalRequest } from 'src/app/core/models/biller';
import { MatSnackBar, MatDialog, PageEvent, MatDialogRef, MatStepper } from '@angular/material';
import { NotificationsService } from 'angular2-notifications';
import { _mocktransferData } from 'src/app/core/models/mocks/_transfers.mock';
import { BillerStagingService } from 'src/app/core/services/staging/biller.staging.service';
import {
  StagedBillerOperationRequestObject, StagedBeneficiaryRequestApprovalPayload,
  StagedBillerOperationRequestReponse
} from 'src/app/core/models/beneficiary';
import { ApprovalStage } from 'src/app/core/models/payloads/transactions';
import { NgForm } from '@angular/forms';
import { MiscService } from 'src/app/core/services/misc.service';
import { BillerApprovalService } from 'src/app/core/services/workflow/biller.approval.service';
import { BaseApprovalComponent } from '../base.approval.component';
import { AuthService } from 'src/app/core/services/auth.service';
import { RejectRequestComponent } from 'src/app/shared/dialogs/reject-request/reject-request.component';
import { RequestApprovalHistoryPayload } from 'src/app/core/models/misc-requests-model';
import { ApprovalHistoryComponent } from 'src/app/shared/approval-history/approval-history.component';
import { UtilService } from 'src/app/core/services/helpers.service';

@Component({
  selector: 'app-biller-approvals',
  templateUrl: './biller-approvals.component.html',
  styleUrls: ['./biller-approvals.component.css']
})
export class BillerApprovalsComponent extends BaseApprovalComponent implements OnInit, OnChanges, OnDestroy {

  ownLoaderIsActive: boolean;
  ownloaderMsg: string;
  @Input() _activeTab: string;
  _activeRequestCount = 0;

  requestList: StagedBillerOperationRequestObject[] = [];
  requestListBackup: StagedBillerOperationRequestObject[] = [];
  _pageSizeLimit = 5;
  _selectedRequestCount = 0;
  requestDetailModalData = {
    currentRequestAction: '',
    currentApprovalAction: '',
    data: null,
    requestMeta: {} as any
  };
  filterIsActive: boolean;
  approvalStatusEnum = ApprovalRequestStatus;
  searchFilter = this.initSearchFilterParams();

  @ViewChild('beneficiaryDetailModal') _beneficiarydetailModalTemplate: TemplateRef<any>;
  public requestDetailModal: MatDialogRef<any>;
  public rejectionDialog: MatDialogRef<RejectRequestComponent, any>;
  public approvalHistoryModal: MatDialogRef<ApprovalHistoryComponent, any>;

  _otpReferenceId: string;
  oneTimePassCode: string;

  _paymentApprovalCompleted: boolean;
  _availableApprovalStages: Array<ApprovalStage> = [];
  otpValidationFailed: boolean;
  constructor(private _snackbar: MatSnackBar, private _toast: NotificationsService, private _dialogService: MatDialog,
    private _billerStagingService: BillerStagingService, private _miscService: MiscService, private _approvalService: BillerApprovalService,
    authService: AuthService) {
    super(authService);
    // try {
    //   window['casa']('other'); 
    //  } catch (error) {}
    this.pageTitle = this._loggedInUser && this._authService.loggedInUserHasSMEProfile() && this._loggedInUserIsApprover ?
      'Authorize Biller Management' : '';

    UtilService.onPageLoadCallback();
  }

  ngOnInit() {

    this.requestDetailModalData.currentApprovalAction = '';
    this.requestDetailModalData.currentRequestAction = '';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['_activeTab']) {
      this._activeTab = changes['_activeTab'].currentValue;
      if (this._activeTab === 'billers') {
        this.loadPendingBillerRequests();
      }
      if (this._activeTab !== 'billers') {
        this.dismissOpenModalWindows();
      }
    }
  }

  ngOnDestroy(): void {
    this.dismissOpenModalWindows();
  }

  onRequestCheckboxChanged(evtData, postion: number) {
    this.requestList[postion]['checked'] = evtData.checked;
    const indexOnBackup = this.requestListBackup.indexOf(this.requestList[postion]);
    this.requestListBackup[indexOnBackup]['checked'] = evtData.checked;
    this._selectedRequestCount = this.requestListBackup.filter(request => request['checked']).length;
  }

  fetchBillerApprovalRequests(loaderMsg = 'loading requests .. please wait', lastFetchedId: number = 0, pageSize: number = 100): void {
    this.ownLoaderIsActive = true;
    this.ownloaderMsg = loaderMsg;
    this._billerStagingService.getStagedBillerOperationRequests(
      { PageSize: pageSize, LastIdFetched: lastFetchedId, OperationType: 'ADD_BILLER_BENEFICIARY' }).then(
        (response) => {
          this.ownLoaderIsActive = false;
          this.ownloaderMsg = '';
          if (!response || !response.ResponseCode) {
            this._snackbar.open(`Failed to load pending biller approval requests`, 'Ok', { duration: 20000 });
            return;
          }
          if (response.ResponseCode !== ResponseCodes.SUCCESS) {
            this._snackbar.open(response.ResponseFriendlyMessage, 'Ok', { duration: 20000 });
            return;
          }
          response.PaginatedItems.forEach(r => {
            r['checked'] = false;
            r.BeneficiaryDetails['operationTypeText'] = this.getRequestOperationType(r.BeneficiaryDetails.operationType);
          });
          this.requestListBackup = response.PaginatedItems;
          this.requestList = this.requestListBackup.slice(0, this._pageSizeLimit);
          console.log(this.requestListBackup);
        },
        (error) => {
          console.log(error);
          this.ownLoaderIsActive = false;
          this.ownloaderMsg = '';
        }
      );
  }

  loadPendingBillerRequests(loaderMsg = 'loading requests .. please wait', lastFetchedId: number = 0, pageSize: number = 100) {
    this.ownLoaderIsActive = true;
    this.ownloaderMsg = loaderMsg;
    this.fetchAggregatePendingBillerRequest(pageSize, lastFetchedId).then(
      (responseArray) => {
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        this.requestListBackup = [];
        responseArray.forEach((response, index) => {
          if (response && response.ResponseCode && response.ResponseCode === '00' && response.PaginatedItems) {
            this.requestListBackup = this.requestListBackup.concat(response.PaginatedItems);
          }
        });
        this.requestListBackup.forEach(req => {
          req['requestType'] = req.BeneficiaryDetails.operationType === 'ADD_BILLER_BENEFICIARY' ? ' Addition' :
            req.BeneficiaryDetails.operationType === 'EDIT_BILLER_BENEFICIARY' ? 'Update' :
              req.BeneficiaryDetails.operationType === 'REMOVE_BILLER_BENEFICIARY' ? 'Removal' : '';
        });
        this.requestList = this.requestListBackup.slice(0, 5);
        console.log(this.requestListBackup);
      },
      (error) => {
        console.log(error);
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
      }
    );
  }

  private fetchAggregatePendingBillerRequest(pageSize = 100, lastFetchedId = 0): Promise<StagedBillerOperationRequestReponse[]> {
    const pendingAddReq = this._billerStagingService.getStagedBillerOperationRequests(
      this.buildPendingRequestApprovalPayload('ADD_BILLER_BENEFICIARY')).catch(error => { console.log(error); return null; });
    const pendingUpdateReq = this._billerStagingService.getStagedBillerOperationRequests(
      this.buildPendingRequestApprovalPayload('EDIT_BILLER_BENEFICIARY')).catch(error => { console.log(error); return null; });
    const pendingRemovalReq = this._billerStagingService.getStagedBillerOperationRequests(
      this.buildBeneficiaryeCreateApprovalRequestPayload('REMOVE_BILLER_BENEFICIARY')).catch(error => { console.log(error); return null; });

    return Promise.all([pendingAddReq, pendingUpdateReq, pendingRemovalReq]);
  }

  onAllCheckboxChanged(evtData) {
    if (evtData.checked) {
      this.requestListBackup.forEach(row => row['checked'] = true);
      this._selectedRequestCount = this.requestListBackup.length;
    } else {
      this.requestListBackup.forEach(b => b['checked'] = false);
      this._selectedRequestCount = 0;
    }
    this.requestList = this.requestListBackup.slice(0, 5);
  }

  onBillerRequestApprovalsPaginationChanged(pageChangedEvent: PageEvent) {
    const start = pageChangedEvent.pageIndex * pageChangedEvent.pageSize;
    const end = start + pageChangedEvent.pageSize;
    this.requestList = this.requestListBackup.slice(start, end);
  }

  onApprovalRequestAction(action: string, data: StagedBillerOperationRequestObject) {
    this.requestDetailModalData.requestMeta['BatchId'] = data.BatchId;
    this.requestDetailModalData.requestMeta['Initiator'] = data.Initiator;
    this.requestDetailModalData.requestMeta['ApprovalStageId'] = data.ApprovalStageId;
    this.requestDetailModalData.requestMeta['ApproverId'] = data.ApproverId;
    this.requestDetailModalData.data = data.BeneficiaryDetails;
    switch (action) {
      case 'approve': {
        this.initRequestApprovalModal(data);
        break;
      }
      case 'reject': {
        this.rejectionDialog = this._dialogService.open(RejectRequestComponent,
          {
            data: {
              title: 'Reject Biller Approval Request',
              payload: {
                OperationType: data.BeneficiaryDetails.operationType,
                OperationId: data.BeneficiaryDetails.Id
              }
            },
            hasBackdrop: false,
            closeOnNavigation: true,
            disableClose: true
          });
        this.rejectionDialog.afterClosed()
          .subscribe((result) => {
            if (result) {
              this.requestDetailModalData.currentRequestAction = 'requestComplete';
              this.loadPendingBillerRequests('refreshing .. please wait');
            }
          });

        break;
      }
      case 'view-history': {
        const requestPayload: RequestApprovalHistoryPayload = {
          OperationType: data.BeneficiaryDetails['operationType'],
          OperationId: Number(data.BeneficiaryDetails.Id),
          UserId: '',
          PageSize: 100,
          LastIdFetched: 0
        };
        this.approvalHistoryModal = this._dialogService.open<ApprovalHistoryComponent>(
          ApprovalHistoryComponent,
          {
            hasBackdrop: false,
            data: {
              operationName: 'biller-management',
              requestPayload: requestPayload,
              title: 'Request Approval History',
              extra: {}
            }
          });
        this.approvalHistoryModal.afterClosed().subscribe(
          (result) => {
            //
          }
        );
        break;
      }
    }
  }

  onRequestModalAction(action: string, stepper: MatStepper, form?: NgForm) {
    console.log(action);
    switch (action) {
      case 'approve': {
        this.initiateRequestApprovalOTP(stepper);
        break;
      }
      case 'reject': {
        if (form && !form.valid) {
          this._snackbar.open('Please provode a reason for rejecting request .. ', 'Ok',
            {duration: 10000 });
          return;
        }
        break;
      }

    }
  }

  onVerifyApprovalConfirmationOtp(form: NgForm, stepper: MatStepper) {
    if (!form.valid) {
      this._snackbar.open(`Invalid One Time Pin provided. Please provide a valid OTP to continue`, 'Ok',
        {duration: 10000 });
      return;
    }
    this.sendBillerBeneficiaryApprovalRequest(this.requestDetailModalData.data as StagedBillerOperationRequestObject, stepper);
  }

  private sendBillerBeneficiaryApprovalRequest(request: StagedBillerOperationRequestObject, stepper: MatStepper) {
    this.ownLoaderIsActive = true;
    this.ownloaderMsg = `Approving request .. please wait`;
    const approvalRequestPayload = this.buildBeneficiaryeCreateApprovalRequestPayload(request.BeneficiaryDetails.operationType);
    this._approvalService.approvePendingBillerOperationRequest(approvalRequestPayload).then(
      (response) => {
        console.log(response);
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        if (!response || response.ResponseCode !== ResponseCodes.SUCCESS) {
          this.handleNonSuccessApprovalResponse(request, response, stepper);
          return;
        }
        this.requestDetailModalData.currentRequestAction = 'requestComplete';
        setTimeout(() => stepper.next(), 15);
        this._snackbar.open(`${response.ResponseFriendlyMessage}`, 'Ok', { duration: 50000 });
        this.loadPendingBillerRequests();
      },
      (error: any) => {
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        this._snackbar.open(`We encountered an error while approving request .. Please retry`, 'Ok', { duration: 25000 });
      }
    );
  }
  private buildBeneficiaryeCreateApprovalRequestPayload(operationType: string):
    StagedBeneficiaryRequestApprovalPayload {
    return {
      BatchId: Number(this.requestDetailModalData.requestMeta.BatchId),
      ApproverId: Number(this.requestDetailModalData.requestMeta.ApproverId),
      ApproverStageId: Number(this.requestDetailModalData.requestMeta.ApprovalStageId),
      OTP: this.oneTimePassCode,
      SourceReferenceId: this._otpReferenceId,
      OperationType: operationType
    };
  }

  private initiateRequestApprovalOTP(stepper: MatStepper) {
    this.ownLoaderIsActive = true;
    this.ownloaderMsg = 'initiating .. please wait';
    this._miscService.initiateOtpRequest(OtpReasonCodes.GENERIC, 'APPROVE BILLER OPERATION').then(
      (response) => {
        console.log(response);
        this.ownloaderMsg = '';
        this.ownLoaderIsActive = false;
        if (!response || response.ResponseCode !== ResponseCodes.SUCCESS) {
          this._snackbar.open(`Failed to initiate approval process. Server responded with -> ${response.ResponseFriendlyMessage}`,
            'Try again', { duration: 25000 }).onAction().subscribe(() => this.initiateRequestApprovalOTP(stepper));
          return;
        }
        this._snackbar.open(`${response.ResponseFriendlyMessage}`, 'Ok', { duration: 25000 });
        this.requestDetailModalData.currentApprovalAction = 'verify-otp';
        this._otpReferenceId = response.ResponseDescription;
        setTimeout(() => stepper.next(), 25);
      },
      (error) => {
        console.log(error);
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        this._snackbar.open(`We encountered an error while processing your request. please retry`, 'Ok', { duration: 25000 });
      }
    );
  }

  private handleNonSuccessApprovalResponse(request: StagedBillerOperationRequestObject, response: any, stepper: MatStepper) {
    switch (response.ResponseCode) {
      case '70': {
        this.requestDetailModalData.currentApprovalAction = 'select-next-approval-stage';
        this._availableApprovalStages = response.Stages;
        setTimeout(() => {
          stepper.next();
        }, 50);
        break;
      }
      default: {
        this._snackbar.open(`${response.ResponseFriendlyMessage}`, 'Retry', { duration: 25000 }).onAction().subscribe(
          () => this.sendBillerBeneficiaryApprovalRequest(request, stepper)
        );
        break;
      }
    }
  }

  private initRequestApprovalModal(beneficiary: StagedBillerOperationRequestObject) {
    this.requestDetailModalData.currentApprovalAction = '';
    this.requestDetailModalData.currentRequestAction = '';
    this.requestDetailModalData.data = beneficiary;
    this.requestDetailModal = this._dialogService.open(this._beneficiarydetailModalTemplate,
      { hasBackdrop: false, panelClass: 'beneficiary-details-card', closeOnNavigation: true });
  }

  oncloseRequestApprovalModal() {
    this.requestDetailModal.close();
    // this.requestDetailModalData.data = null;
    this.oneTimePassCode = '';
    this._otpReferenceId = '';
  }

  private initSearchFilterParams() {
    return {
      beneficiaryName: '',
      beneficiaryId: '',
      partyType: '',
      status: 0
    };
  }

  private getRequestOperationType(statusString: string) {
    switch (statusString) {
      case 'ADD_BILLER_BENEFICIARY':
        return 'ADD BILLER';
      case 'EDIT_BILLER_BENEFICIARY':
        return 'UPDATE BILLER';
      case 'REMOVE_BILLER_BENEFICIARY':
      default: return 'N/A';

    }
  }

  buildPendingRequestApprovalPayload(operationName: string, pageSize = 100, lastFetchedId = 0) {
    return {
      PageSize: pageSize,
      LastIdFetched: lastFetchedId,
      OperationType: operationName
    };
  }

  private dismissOpenModalWindows() {
    if (this.requestDetailModal) {
      this.requestDetailModal.close();
    }
    if (this.rejectionDialog) {
      this.rejectionDialog.close();
    }
    if (this.approvalHistoryModal) {
      this.approvalHistoryModal.close();
    }
  }
}
