import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { PendingWesternUnionRedemptionRequestObject } from 'src/app/core/models/pending-request.models';
import { BasePendingRequestComponent } from '../base.pending-request.component';
import { AuthService } from 'src/app/core/services/auth.service';
import { MiscService } from 'src/app/core/services/misc.service';
import { MatSnackBar, MatDialog } from '@angular/material';
import { BasePagedRequestResponse, RequestApprovalHistoryPayload } from 'src/app/core/models/misc-requests-model';
import { ResponseCodes } from 'src/app/core/models/Enumerations';

@Component({
  selector: 'app-western-union-payment-requests',
  templateUrl: './western-union-payment-requests.component.html',
  styleUrls: ['./western-union-payment-requests.component.css']
})
export class WesternUnionPaymentRequestsComponent extends BasePendingRequestComponent implements OnInit, OnChanges {

  @Input() _activeTab: string;
  requestList: Array<PendingWesternUnionRedemptionRequestObject> = [];
  requestListBackup: Array<PendingWesternUnionRedemptionRequestObject> = [];
  _pageSize = 5;

  constructor(authService: AuthService, private _miscService: MiscService, private _snackbar: MatSnackBar, _dialog: MatDialog) { 
    super(authService, _dialog);
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['_activeTab']) {
      this._activeTab = changes['_activeTab'].currentValue;
      if (this._activeTab === 'wester_union_redemption') {
        this.loadStagedWesternUnionRedemptionRequests();
      }
    }
  }

  loadStagedWesternUnionRedemptionRequests(loaderMsg = 'fetching pending western-union requests. please wait', lastFetchId = 0, pageSize = 100) {
    this.ownLoaderIsActive = true;
    this.ownloaderMsg = loaderMsg;
    const payload = {PageSize: pageSize, LastFetchId: lastFetchId, OperationType: 'WESTERN_UNION' };
    this._miscService.getPendingRequest<BasePagedRequestResponse<PendingWesternUnionRedemptionRequestObject>>(payload).then(
      (response) => {
        console.log(response);
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        if (!response || response.ResponseCode !== ResponseCodes.SUCCESS) {
          this._snackbar.open(`${response.ResponseFriendlyMessage}`, 'Ok', {duration: 25000});
          return;
        }
        this.requestListBackup = response.PaginatedItems;
        this.requestList = this.requestListBackup.slice(0, 5);
      },
      (error) => {
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
      }
    );
  }

  onRequestAction(action: string, data: PendingWesternUnionRedemptionRequestObject) {
    switch (action) {
      case 'view-history': {
        const requestPayload: RequestApprovalHistoryPayload = this.buildRequestApprovalHistoryPayload(data, 'WESTERN_UNION');
        const approvalHistoryModal = this.launchRequestHistoryModal(requestPayload,
                                                    'western-union', 'Western-Union Redemption Approval History');
        approvalHistoryModal.afterClosed().subscribe((result) => {});
        break;
      }
    }
  }

}
