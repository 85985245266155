export const _workflowRules = [
      {
        ConflictedApprovalStages: [
          {
            CacheXFIG: '',
            OperationType: 'BULK_UPLOAD',
            NumOfRequiredApprovers: 5,
            ApprovalLevel: 1,
            ApprovalUserIds: ['ADEBAYOOLUSOLA', 'NAYANAKSHASHERRY']
          },
          {
            CacheXFIG: '',
            OperationType: 'BULK_UPLOAD',
            NumOfRequiredApprovers: 2,
            ApprovalLevel: 1,
            ApprovalUserIds: ['ADEBAYOOLUSOLA', 'NAYANAKSHASHERRY']
          }
        ],
        OtherApprovalStages: [
          {
            CacheXFIG: '',
            OperationType: 'BULK_UPLOAD',
            NumOfRequiredApprovers: 5,
            ApprovalLevel: 2,
            ApprovalUserIds: ['ADEBAYOOLUSOLA', 'NAYANAKSHASHERRY']
          }
        ]
      },
      {
        ConflictedApprovalStages: [],
        OtherApprovalStages: [
          {
            CacheXFIG: '',
            OperationType: 'BULK_UPLOAD',
            NumOfRequiredApprovers: 5,
            ApprovalLevel: 2,
            ApprovalUserIds: ['ADEBAYOOLUSOLA', 'NAYANAKSHASHERRY']
          },
          {
            CacheXFIG: '',
            OperationType: 'BULK_UPLOAD',
            NumOfRequiredApprovers: 5,
            ApprovalLevel: 2,
            ApprovalUserIds: ['ADEBAYOOLUSOLA', 'NAYANAKSHASHERRY']
          }
        ]
      }
];
