import { Injectable } from '@angular/core';
import { AppConfigService } from './appconfig.service';
import { HttpClient } from '@angular/common/http';
import { RequestResponse } from '../models/account';
import { ApiURLConstants } from '../helpers/apiURLConstants';
import { AuthService } from './auth.service';

import {
    BeneficiaryListRequestResponse,
    AddBeneficiaryRequestPayload,
    EditBeneficiaryRequestPayload, RemoveBeneficiaryRequestPayload,
    BillerProductsRequestPayload, BillerProductsResponse,
    BillerListRequestResponse, BillerMerchantRequestResponse,
    AddBillerBeneficiaryRequestPayload, EditBillerBeneficiaryRequestPayload,
    InitBeneficiaryCreateResponse
} from '../models/payloads/beneficiaries';
import { AppUser } from '../models/auth';
import { BaseRequestStagingResponse } from '../models/misc-requests-model';
import { Observable, of } from '../../../../node_modules/rxjs';
import { environment } from 'src/environments/environment';


@Injectable()
export class BeneficiaryService {
    deleteBeneficiaryBaseURL = environment.deleteBeneficiaryBaseURL

    _apiBaseUrl: string;
    private _beneficiaryAPIBase: string;
    private _loggedInUser: AppUser;
    constructor(private _httpClient: HttpClient, private _settings: AppConfigService, private _authService: AuthService) {
        this._apiBaseUrl = _settings.APIBaseUrl;
        this._beneficiaryAPIBase = `${this._apiBaseUrl}/BeneficiaryManagement`;
        this._loggedInUser = _authService.getLoggedInUser();
    }



    getBeneficiaries(): Observable<BeneficiaryListRequestResponse> {
        // return of({
        //     ResponseCode: '00',
        //     Beneficiaries: [{
        //         beneficiaryAlias: 'string',
        //         beneficiaryName: 'string',
        //         beneficiaryAccountNumber: 'string',
        //         beneficiaryBank: 'string',
        //         beneficiaryBankCode: 'string',
        //         beneficiaryReference: 'string',
        //         customerReference: 'string',
        //         beneficiaryId: '0',
        //         groupId: '0',
        //         Id: 0
        //     }],
        //     ResponseDescription: 'test',
        //     ResponseFriendlyMessage: '',
        // });
        //commnt
        return this._httpClient.post<BeneficiaryListRequestResponse>(`${this._apiBaseUrl}/${ApiURLConstants.BeneficiaryList}`,
            { UserId: AuthService.FullyAuthenticatedUser.UserId });
    }


    addBeneficiary(payload: AddBeneficiaryRequestPayload): Observable<RequestResponse> {
        return this._httpClient.post<RequestResponse>(`${this._apiBaseUrl}/${ApiURLConstants.AddBeneficiary}`, payload)
            ;
    }

    initiatBeneficiaryCreate(payload: AddBeneficiaryRequestPayload): Observable<InitBeneficiaryCreateResponse> {
        return this._httpClient.post<InitBeneficiaryCreateResponse>(`${this._apiBaseUrl}/${ApiURLConstants.INITIATE_BENEFICIARY_CREATE}`,
            payload);
    }

    initiateBeneficiaryUpdate(payload: EditBeneficiaryRequestPayload): Observable<BaseRequestStagingResponse> {
        return this._httpClient.post<BaseRequestStagingResponse>(`${this.deleteBeneficiaryBaseURL}/${ApiURLConstants.INITIATE_BENEFICIARY_UPDATE}`, payload);
    }

    updateBeneficiary(payload: EditBeneficiaryRequestPayload): Observable<RequestResponse> {

        return this._httpClient.post<RequestResponse>(`${this._apiBaseUrl}/${ApiURLConstants.EditBenficiary}`, payload)
            ;
    }

    removeBeneficiary(payload: RemoveBeneficiaryRequestPayload): Observable<RequestResponse> {
        return this._httpClient.post<RequestResponse>(`${this._apiBaseUrl}/${ApiURLConstants.DeleteBenficiary}`, payload)
            ;
    }


    getBillerBeneficiaries(): Promise<BillerListRequestResponse> {
        return this._httpClient.post<BillerListRequestResponse>(`${this._apiBaseUrl}/${ApiURLConstants.BillerBeneficiaryList}`,
            { UserId: AuthService.FullyAuthenticatedUser.UserId })
            .toPromise();
    }


    getBillerMerchants(): Promise<BillerMerchantRequestResponse> {
        return this._httpClient.post<BillerMerchantRequestResponse>(`${this._apiBaseUrl}/${ApiURLConstants.BillerMerchants}`,
            { UserId: AuthService.FullyAuthenticatedUser.UserId })
            .toPromise();
    }



    addBillerBeneficiary(payload: AddBillerBeneficiaryRequestPayload): Promise<RequestResponse> {
        return this._httpClient.post<RequestResponse>(`${this._apiBaseUrl}/${ApiURLConstants.AddBillerBeneficiary}`, payload)
            .toPromise();
    }

    initiateBillerBeneficiaryOperationRequest(payload: any): Promise<InitBeneficiaryCreateResponse> {
        return this._httpClient.post<InitBeneficiaryCreateResponse>(`${this._apiBaseUrl}/${ApiURLConstants.INITIATE_BILLER_OPERATION}`,
            payload).toPromise();
    }

    updateBillerBeneficiary(payload: EditBillerBeneficiaryRequestPayload): Promise<RequestResponse> {

        return this._httpClient.post<RequestResponse>(`${this._apiBaseUrl}/${ApiURLConstants.EditBillerBenficiary}`, payload)
            .toPromise();
    }

    removeBillerBeneficiary(payload: RemoveBeneficiaryRequestPayload): Promise<RequestResponse> {
        return this._httpClient.post<RequestResponse>(`${this._apiBaseUrl}/${ApiURLConstants.DeleteBillerBenficiary}`, payload)
            .toPromise();
    }

    getBillerProducts(payload: BillerProductsRequestPayload): Promise<BillerProductsResponse> {
        return this._httpClient.post<BillerProductsResponse>(`${this._apiBaseUrl}/${ApiURLConstants.GetBillerBeneficiaryProduct}`,
            payload)
            .toPromise();
    }


}
