import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatStepper, MatSnackBar, MatCard } from "@angular/material";
import { AuthService } from "src/app/core/services/auth.service";
import {
  VariableSingletonService,
  VariablesService,
} from "src/app/core/services/variables.service";
import { VirtualCardService } from "src/app/core/services/virtual-card.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-virtual-card-request",
  templateUrl: "./virtual-card-request.component.html",
  styleUrls: ["./virtual-card-request.component.css"],
})
export class VirtualCardRequestComponent implements OnInit {
  @ViewChild("stepper")
  myStepper: MatStepper;

  isLinear = false;

  virtualCardType: FormGroup;
  currencyType: FormGroup;
  cardName: FormGroup;
  accountDetails: FormGroup;
  otherInfo: FormGroup;
  otpFormGroup: FormGroup;
  isEditable = false;
  setTransLimit: number[] = [100000, 300000, 500000];
  activeSelection: number;
  otpReference: string;
  cardProgramNames: any;
  cardPin: any;

  loaderIsActive: boolean;
  noAccountFound: boolean;
  _accountsFetchError: string;
  loaderMsg: string;
  getAccountsByCIFSub: any;
  private _variableService: VariableSingletonService;

  accountOptions: any;
  // accountFilters: any[];

  accountFilters: Array<any> = [];

  accountDollarFilters: Array<any> = [];

  isDomiciliary: boolean = false;

  isDollarSelect: boolean = false;

  constructor(
    private _formBuilder: FormBuilder,
    private virtualCardService: VirtualCardService,
    private _snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    // this.accountOptions = JSON.parse(localStorage.getItem("userAccount"));

    // console.log("QQQQQQQQQQQQQQQQQQQQQQQQQQQ",this.accountOptions)

    this.accountFilter();

    this.virtualCardType = this._formBuilder.group({
      cardProgram: ["", Validators.required],
    });
    this.currencyType = this._formBuilder.group({
      currencyCode: ["", Validators.required],
    });
    this.cardName = this._formBuilder.group({
      nameOnCard: ["", Validators.required],
      accountId: ["", Validators.required],
      AccountNumberToDebitForUSD: [""],
    });
    this.accountDetails = this._formBuilder.group({
      cardBrand: ["", Validators.required],
      transactionLimit: ["", Validators.required],
    });

    this.otherInfo = this._formBuilder.group({
      lastName: [
        AuthService.FullyAuthenticatedUser.Lastname,
        Validators.required,
      ],
      city: [null, Validators.required],
      accountType: [null, Validators.required],
      postalCode: [null, Validators.required],
      streetAddressLine2: [null, Validators.required],
      userId: [AuthService.FullyAuthenticatedUser.UserId, Validators.required],
      mobileNr: [null, Validators.required],
      firstName: [
        AuthService.FullyAuthenticatedUser.Firstname,
        Validators.required,
      ],
      emailAddress: [null, Validators.required],
      streetAddress: [null, Validators.required],
      countryCode: [null, Validators.required],
      issuerNr: [null, Validators.required],
      state: [null, Validators.required],
      alias: ["", Validators.required],
      clientReference: ["", Validators.required],
    });

    this.otpFormGroup = this._formBuilder.group({
      otp: ["", Validators.required],
    });
  }

  setDollar() {
    this.isDollarSelect = true;
  }

  setNaira() {
    this.isDollarSelect = false;
  }

  accountFilter() {
    // this.accountOptions = JSON.parse(localStorage.getItem("userAccount"));
    this.accountOptions = VariablesService.CustomerAccounts;
    console.log(VariablesService.CustomerAccounts);

    this.accountOptions.forEach((item) => {
      if (item.currency === "USD") {
        this.isDomiciliary = true;
        this.accountDollarFilters.push({
          accountCategory: item.accountCategory,
          accountName: item.accountName,
          accountNumber: item.accountNumber,
          accountType: item.accountType,
          availableBalance: item.availableBalance,
          creditAllowed: item.creditAllowed,
          currency: item.currency,
          debitAllowed: item.debitAllowed,
          effectiveBalance: item.effectiveBalance,
        });
      }

      if (
        item.accountType != "Loan" &&
        item.accountType != "UNSECURED PERSONAL LOAN" &&
        item.accountType != "HOME LOAN" &&
        item.accountCategory != "ODVIS" &&
        item.accountCategory != "CAVIS" &&
        item.currency === "NGN"
      ) {
        console.log(item.currency);
        this.accountFilters.push({
          accountCategory: item.accountCategory,
          accountName: item.accountName,
          accountNumber: item.accountNumber,
          accountType: item.accountType,
          availableBalance: item.availableBalance,
          creditAllowed: item.creditAllowed,
          currency: item.currency,
          debitAllowed: item.debitAllowed,
          effectiveBalance: item.effectiveBalance,
        });
      }
    });
  }

  seFormLimit(limit: number, index: number) {
    this.accountDetails.get("transactionLimit").setValue(limit);
    this.activeSelection = index;
  }

  onBack(stepper) {
    stepper.previous();
  }

  nextClick(): void {
    this.myStepper.selectedIndex = 2;
    setTimeout(() => {
      this.myStepper.linear = true;
    });
  }

  initiateOpt(stepper) {
    this.loaderIsActive = true;

    this.virtualCardService
      .cardtypeCheckCheck({
        // AccountNumber: "0026214860",
        AccountNumber: this.cardName.value.accountId.accountNumber,
      })
      .subscribe((response) => {
        this.loaderIsActive = false;

        if (response.responseCode === "000") {
          this.cardProgramNames = response.data;

          if (
            this.cardProgramNames.some((item: any) =>
              item.cardProgramName.startsWith("MASTER")
            ) ||
            (this.cardProgramNames.some((item: any) =>
              item.cardProgramName.startsWith("CB_MC_")
            ) &&
              this.accountDetails.value.cardBrand === "MASTERCARD_VIRTUAL" &&
              this.currencyType.value.currencyCode != "840")
          ) {
            this._snackBar.open(
              "You already have a master card pls select another card type.",
              "Error",
              {
                verticalPosition: "bottom",
                horizontalPosition: "right",
                duration: 2500,
              }
            );
          } else {
            // const accountDetail = VariablesService.CustomerAccounts;
            // console.log("accountDetail", accountDetail);
            // const [accountNumber] = accountDetail;
            // .sendOpt(accountNumber.toString())
            this.loaderIsActive = true;
            this.virtualCardService
              .sendOpt(AuthService.FullyAuthenticatedUser.UserId)
              .subscribe(
                (response) => {
                  if (response.responseCode === "000") {
                    this.loaderIsActive = false;
                    this.otpReference = response.data;
                    stepper.next();
                  } else {
                    this.loaderIsActive = false;
                    this._snackBar.open(response.responseDescription, "Error", {
                      verticalPosition: "bottom",
                      horizontalPosition: "right",
                      duration: 1500,
                    });
                  }
                },
                (error) => {
                  this.loaderIsActive = false;
                  this._snackBar.open(error, "Error", {
                    verticalPosition: "bottom",
                    horizontalPosition: "right",
                    duration: 1500,
                  });
                }
              );
          }
        } else {
          this._snackBar.open(response.responseDescription, "Error", {
            verticalPosition: "bottom",
            horizontalPosition: "right",
            duration: 1500,
          });
        }
      });
  }

  onValidateOTP(stepper) {
    this.loaderIsActive = true;
    let formObj = {} as any;
    formObj = {
      otpReference: this.otpReference,
      accountNumber: AuthService.FullyAuthenticatedUser.UserId,
      ...this.otpFormGroup.value,
    };
    this.virtualCardService.validateOpt(formObj).subscribe(
      (response) => {
        if (response.responseCode === "000") {
          this.loaderIsActive = false;
          this.onSubmit(stepper);
        } else {
          this.loaderIsActive = false;
          this._snackBar.open(response.responseDescription, "Error", {
            verticalPosition: "bottom",
            horizontalPosition: "right",
            duration: 1500,
          });
        }
      },
      (error) => {
        this.loaderIsActive = false;
        this._snackBar.open(error, "Error", {
          verticalPosition: "bottom",
          horizontalPosition: "right",
          duration: 1500,
        });
      }
    );
  }

  // onValidateOTP(stepper) {
  //   console.log(this.myStepper.next())
  //   this.myStepper.next();
  // }

  done() {
    // this.router.navigate(['card-management'], {relativeTo: this.route})

    this.router.navigate(["/virtual-card/card-management"]).then();
  }

  ResendOtp(stepper) {
    this.loaderIsActive = true;
    this.virtualCardService
      .sendOpt(AuthService.FullyAuthenticatedUser.UserId)
      .subscribe(
        (response) => {
          if (response.responseCode === "000") {
            this.loaderIsActive = false;
            this.otpReference = response.data;
          } else {
            this.loaderIsActive = false;
            this._snackBar.open(response.responseDescription, "Error", {
              verticalPosition: "bottom",
              horizontalPosition: "right",
              duration: 1500,
            });
          }
        },
        (error) => {
          this.loaderIsActive = false;
          this._snackBar.open(error, "Error", {
            verticalPosition: "bottom",
            horizontalPosition: "right",
            duration: 1500,
          });
        }
      );
  }

  private virtualCardPayload() {
    return {
      CardDetails: {
        lastName: AuthService.FullyAuthenticatedUser.Lastname,
        city: null,
        accountType:
          this.cardName.value.accountType === "CURRENT" ? "20" : "10",
        postalCode: null,
        streetAddressLine2: null,
        userId: "Virtual Card",
        mobileNr: "",
        cardProgram:
          this.currencyType.value.currencyCode === "840"
            ? "MC_USD_Virtual"
            : this.accountDetails.value.cardBrand,
        firstName: AuthService.FullyAuthenticatedUser.Firstname,
        accountId: this.cardName.value.AccountNumberToDebitForUSD
          ? this.cardName.value.AccountNumberToDebitForUSD.accountNumber
          : this.cardName.value.accountId.accountNumber,
        emailAddress: null,
        nameOnCard: this.cardName.value.nameOnCard,
        streetAddress: null,
        countryCode: "NG",
        issuerNr: this.currencyType.value.currencyCode === "566" ? "1" : "3",
        state: null,
        currencyCode: this.currencyType.value.currencyCode,
        alias: "1231",
        clientReference: AuthService.FullyAuthenticatedUser.CifId,
      },
      Limit: this.accountDetails.value.transactionLimit.toString(),
      CIFID: AuthService.FullyAuthenticatedUser.CifId,
      cardVariant: this.virtualCardType.value.cardProgram,
      AccountNumberToDebitForUSD: this.cardName.value.accountId.accountNumber,
    };
  }

  accountBalance(stepper) {
    console.log("accountType", this.cardName.value.accountId.accountType);

    this.loaderIsActive = true;
    this.virtualCardService
      .accountBalanceCheck({
        AccountNumber: this.cardName.value.accountId.accountNumber,
      })
      .subscribe(
        (response) => {
          this.loaderIsActive = false;

          if (response.responseCode === "999") {
            this._snackBar.open(response.responseDescription, "Error", {
              duration: 30000,
            });
          }

          if (response.responseCode === "000") {
            if (response.data.availableBalance > 537.5) {
              this._snackBar.open(response.responseDescription, "Ok", {
                duration: 30000,
              });
              this.myStepper.next();
            } else {
              this._snackBar.open(
                "You cannot proceed because of Insufficient funds",
                "Ok",
                { duration: 30000 }
              );
            }
          }
        },
        (error) => {
          this.loaderIsActive = false;

          this._snackBar.open("Something went wrong", "Error", {
            duration: 30000,
          });
        }
      );
  }

  onSubmit(stepper) {
    this.loaderIsActive = true;
    const payload = this.virtualCardPayload();
    this.virtualCardService.requestVirtualCard(payload).subscribe(
      (response) => {
        if (response.responseCode === "000") {
          console.log("ssssss", response.data.card.pinInfo);
          this.cardPin = response.data.card.pinInfo;
          this.loaderIsActive = false;
          this._snackBar.open(response.responseDescription, "Ok", {
            verticalPosition: "bottom",
            horizontalPosition: "right",
            duration: 15000,
          });
          this.myStepper.next();
        } else {
          this.loaderIsActive = false;
          this._snackBar.open(response.responseDescription, "Error", {
            verticalPosition: "bottom",
            horizontalPosition: "right",
            duration: 15000,
          });
        }
      },
      (error) => {
        this.loaderIsActive = false;
        this._snackBar.open(error.error.responseDescription, "Error", {
          verticalPosition: "bottom",
          horizontalPosition: "right",
          duration: 15000,
        });
      }
    );
  }
}
