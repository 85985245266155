import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { BasePendingRequestComponent } from '../base.pending-request.component';
import { AuthService } from 'src/app/core/services/auth.service';
import { MiscService } from 'src/app/core/services/misc.service';
import { MatSnackBar, MatDialog, PageEvent } from '@angular/material';
import { PendingBillPaymentRequestObject, PendingBulkPaymentRequestObject } from 'src/app/core/models/pending-request.models';
import { BasePagedRequestResponse, RequestApprovalHistoryPayload } from 'src/app/core/models/misc-requests-model';
import { ResponseCodes } from 'src/app/core/models/Enumerations';

@Component({
  selector: 'app-bulk-payment-requests',
  templateUrl: './bulk-payment-requests.component.html',
  styleUrls: ['./bulk-payment-requests.component.css']
})
export class PendingBulkPaymentRequestsComponent extends BasePendingRequestComponent implements OnInit, OnChanges {


  @Input() _activeTab: string;
  requestList: Array<PendingBulkPaymentRequestObject> = [];
  requestListBackup: Array<PendingBulkPaymentRequestObject> = [];
  _pageSize = 5;
  _requestErrorMessage: string;

  constructor(authService: AuthService, private _miscService: MiscService, private _snackbar: MatSnackBar, _dialog: MatDialog) {
    super(authService, _dialog);
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['_activeTab']) {
      this._activeTab = changes['_activeTab'].currentValue;
      if (this._activeTab === 'fileuploads') {
        this.loadStagedBulkPaymentRequests();
      }
    }
  }

  onTransactionsPageChanged(pageChangedEvent: PageEvent) {
    const start = pageChangedEvent.pageIndex * pageChangedEvent.pageSize;
    const end = start + pageChangedEvent.pageSize;
    this.requestList = this.requestListBackup.slice(start, end);
  }
  loadStagedBulkPaymentRequests(loaderMsg = 'fetching pending bulk-payment requests. please wait', lastFetchId = 0, pageSize = 100) {
    this.ownLoaderIsActive = true;
    this.ownloaderMsg = loaderMsg;
    this._requestErrorMessage = '';
    const payload = {PageSize: pageSize, LastFetchId: lastFetchId, OperationType: 'BULK_UPLOAD' };
    this._miscService.getPendingRequest<BasePagedRequestResponse<PendingBulkPaymentRequestObject>>(payload).then(
      (response) => {
        console.log(response);
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        if (!response || response.ResponseCode !== ResponseCodes.SUCCESS) {
          this._snackbar.open(`${response.ResponseFriendlyMessage}`, 'Ok', {duration: 25000});
          return;
        }
        this.requestListBackup = response.PaginatedItems;
        this.requestList = this.requestListBackup.slice(0, 5);
      },
      (error) => {
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        this._requestErrorMessage = 'We encountered an unknown error while loading your pending bulk-payment. Please retry';
        this._snackbar.open(`We encountered an error while loading pending bulk-payment request`, 'Ok', {duration: 25000});
      }
    );
  }

  onRequestAction(action: string, data: PendingBillPaymentRequestObject) {
    switch (action) {
      case 'view-history': {
        const requestPayload: RequestApprovalHistoryPayload = this.buildRequestApprovalHistoryPayload(data, 'BULK_UPLOAD');
        const approvalHistoryModal = this.launchRequestHistoryModal(requestPayload, 'bulk-transfer', 'Bulk Payments Approval History');
        approvalHistoryModal.afterClosed().subscribe((result) => {});
        break;
      }
    }
  }

}
