import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppMaterialModule } from 'src/app/app.material.module';
import { ApprovalsComponent } from './approvals.component';
import { PaymentApprovalsComponent } from './payment-approvals/payment-approvals.component';
import { BeneficiariryApprovalsComponent } from './beneficiariry-approvals/beneficiariry-approvals.component';
import { BillerApprovalsComponent } from './biller-approvals/biller-approvals.component';
import { ServiceRequestApprovalsComponent } from './service-request-approvals/service-request-approvals.component';
import { StagedRequestService } from 'src/app/core/services/request.staged.service';
import { FileUploadApprovalComponent } from './file-upload-approval/file-upload-approval.component';
import { VerticalTimelineModule } from 'angular-vertical-timeline';
import { FxPaymentApprovalsComponent } from './fx-payment-approvals/fx-payment-approvals.component';
import { BeneficiaryApprovalService } from 'src/app/core/services/workflow/beneficiary.approval.service';
import { BillPaymentApprovalsComponent } from './bill-payment-approvals/bill-payment-approvals.component';
import { WesternUnionApprovalsComponent } from './western-union-approvals/western-union-approvals.component';
import { BulkPensionPaymentsComponent } from './bulk-pension-payments/bulk-pension-payments.component';

const approvalsRouter = RouterModule.forChild([
    {path: '', component: ApprovalsComponent}
]);
@NgModule({
    declarations: [
        ApprovalsComponent,
        PaymentApprovalsComponent,
        BeneficiariryApprovalsComponent,
        BillerApprovalsComponent,
        ServiceRequestApprovalsComponent,
        FileUploadApprovalComponent,
        FxPaymentApprovalsComponent,
        BillPaymentApprovalsComponent,
        WesternUnionApprovalsComponent,
        BulkPensionPaymentsComponent,
    ],
    imports: [ CommonModule, approvalsRouter, FormsModule, RouterModule, ReactiveFormsModule, AppMaterialModule, VerticalTimelineModule],
    exports: [
        ApprovalsComponent,
        PaymentApprovalsComponent,
        BeneficiariryApprovalsComponent,
        BillerApprovalsComponent,
        ServiceRequestApprovalsComponent,
        FileUploadApprovalComponent,
        FxPaymentApprovalsComponent,
        BillPaymentApprovalsComponent,
        WesternUnionApprovalsComponent
    ],
    providers: [
        StagedRequestService,
        BeneficiaryApprovalService
    ],
})
export class ApprovalsModule {}
