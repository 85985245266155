import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../appconfig.service';
import { ApiURLConstants } from '../../helpers/apiURLConstants';
import { AuthService } from '../auth.service';
import {
    SMETransferResponsePayload, WorkflowSelectionRequestPayload,
    BillPaymentRequestPayload, StagedBillPaymentRequestResponse
} from '../../models/payloads/transactions';
import { GenericApiResponse } from '../../models/payloads/generic-response';
import { Role } from '../../models/Enumerations';

@Injectable()
export class PaymentStagingService {

    private _apiBaseUrl: string;
    constructor(private _httpClient: HttpClient, private _settings: AppConfigService, private _authService: AuthService) {
        this._apiBaseUrl = _settings.APIBaseUrl;
    }

    initiateBillPayment(payload: BillPaymentRequestPayload): Promise<SMETransferResponsePayload> {
        payload = this.updateRequestPayloadWithUserId(payload);
        return this._httpClient.post<SMETransferResponsePayload>(
            `${this._apiBaseUrl}/${ApiURLConstants.INITIATE_BILLPAYMENT}`, payload)
            .toPromise();
    }

    setBillPaymentRequestApprovalWorkflow(payload: WorkflowSelectionRequestPayload): Promise<GenericApiResponse> {
        payload = this.updateRequestPayloadWithUserId(payload);
        if (this._settings.UseMockData) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve({
                        ResponseCode: '00',
                        ResponseDescription: 'success',
                        ResponseFriendlyMessage: 'selected workflow has been successfully saved. Approval request sent'
                    });
                }, 1000);
            });
        }
        return this._httpClient.post<GenericApiResponse>(`${this._apiBaseUrl}/${ApiURLConstants.SET_BILLPAYMENT_APPROVAL_WORKFLOW}`,
            payload).toPromise();
    }

    getStagedBillPaymentsRequest(payload): Promise<StagedBillPaymentRequestResponse> {
        payload = this.updateRequestPayloadWithUserId(payload);
        const loggedInUser = this._authService.getLoggedInUser();
        if (loggedInUser && loggedInUser.Role && loggedInUser.Role.roles.includes(Role.Initiator)) {
            payload['ForInitiator'] = true;
        }
        return this._httpClient.post<StagedBillPaymentRequestResponse>(
            `${this._apiBaseUrl}/${ApiURLConstants.GET_PENDING_BILLPAYMENT_APPROVAL_REQUESTS}`, payload)
            .toPromise();
    }

    private updateRequestPayloadWithUserId(payload: any): any {
        const loggedInUser = this._authService.getLoggedInUser();
        payload['UserId'] = loggedInUser && loggedInUser.UserId ? loggedInUser.UserId : '';
        return payload;
    }

}
