export const _mockFileUploadsData = [
        {
            referenceId: '000000000',
            title: '13th Month Staff Salary Payments',
            status: 'Pending',
            transactionType: 'Salary Payment',
            uploadedBy: 'Requestor with Initiator Role',
            filePath: 'C:\\Users\\Sbassey\\Downloads\\paymentsfile.exls',
            sequenceNo: '1',
            requestDate: '2018-12-18T12:34:01+01:00'
        },
        {
            referenceId: '11100011100',
            title: '2nd Installment Payment for TeampApt',
            status: 'Pending',
            transactionType: 'External Payment',
            uploadedBy: 'Requestor with Initiator Role',
            filePath: 'C:\\Users\\Sbassey\\Downloads\\paymentsfile.exls',
            sequenceNo: '1',
            requestDate: '2018-12-18T12:34:01+01:00'
        },
];
