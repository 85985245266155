import { Component, OnInit, Input, ViewChild, TemplateRef, SimpleChanges, ɵConsole, OnChanges } from '@angular/core';
import { CustomerAccount } from 'src/app/core/models/account';
import { StagedPaymentRequest, ApprovalStage, StagedPaymentRequestResponse } from 'src/app/core/models/payloads/transactions';
import { Role, ResponseCodes, OtpReasonCodes } from 'src/app/core/models/Enumerations';
import { Approval } from 'src/app/core/models/approvals/payment.approval';
import { AppUser } from 'src/app/core/models/auth';
import { MatDialogRef, MatDialog, MatSnackBar, MatStepper, PageEvent } from '@angular/material';
import { StagedRequestService } from 'src/app/core/services/request.staged.service';
import { TransferStagingService } from 'src/app/core/services/staging/transfer.staging.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { MiscService } from 'src/app/core/services/misc.service';
import { TransferApprovalService } from 'src/app/core/services/workflow/transfer.approval.service';
import { UtilService } from 'src/app/core/services/helpers.service';
import { RejectRequestComponent } from 'src/app/shared/dialogs/reject-request/reject-request.component';
import { NgForm } from '@angular/forms';
import { StagedTransfer } from 'src/app/core/models/transfer';
import { VariablesService } from 'src/app/core/services/variables.service';
import { _banksList } from 'src/app/core/models/_banks_list_backup';
import { BulkPaymentBatchApprovalResponse, SinglePaymentApprovalRequestPayload } from 'src/app/core/models/approvals/file-upload.approval';
import { PensionService } from 'src/app/core/services/pension.service';
import { PendingRequestsRecords, StagedPendingPensionPaymentResponse, PensionFileRecords } from 'src/app/core/models/pension-remittance';
@Component({
  selector: 'app-bulk-pension-payments',
  templateUrl: './bulk-pension-payments.component.html',
  styleUrls: ['./bulk-pension-payments.component.css']
})
export class BulkPensionPaymentsComponent implements OnInit, OnChanges {
  @Input() _activeTab = '';
  ownLoaderIsActive: boolean;
  ownloaderMsg: string;
  _activeTabIndex = 0;
  _activeRequestCount = 0;
  accountList: CustomerAccount[];
  requestList: PendingRequestsRecords[] = [];
  requestListBackup: PendingRequestsRecords[] = [];
  _selectedRequestCount = 0;
  _approvalModalData = {
    title: '',
    message: '',
    currentRequestAction: '',
    currentApprovalAction: '',
    data: null,
    requestMeta: { batchId: '', approverStageId: '', approverId: '', initiator: '' }
  };
  oneTimePassCode: string;
  _otpReferenceId: string;
  otpInstruction: string;
  searchFilter = this.initSearchFilter();
  filterIsActive: boolean;
  userRoleEnum = Role;
  allCheckboxesSelected: boolean;
  progressColor = '';
  _requestApprovalHistory: Array<Approval> = [];
  _availableApprovalStages: Array<ApprovalStage> = [];

  _paymentApprovalCompleted: boolean;
  public _loggedInUser: AppUser;
  public _loggedInUserIsApprover: boolean;
  public _loggedInUserIsInitiator: boolean;
  pageTitle: string;
  @ViewChild('approveBulkPensionModal') _approvalModalTemplate: TemplateRef<any>;
  public approvalRequestModal: MatDialogRef<any>;

  @ViewChild('approvalRequestDetailModalTemplate') _requestDetailModalTemplate: TemplateRef<any>;
  public approvalRequestDetailModal: MatDialogRef<any>;

  @ViewChild('approvalRequestHistoryModalTemplate') _approvalHistoryModalTemplate: TemplateRef<any>;
  public approvalHistoryModal: MatDialogRef<any>;
  public requestRejectionModal: MatDialogRef<any>;
  cityList: any;
  pageSize = 20;
  lastRecordIdFetched = 0;
  transactionBatch: PensionFileRecords[] = [];
  transactionBatchBackup: PensionFileRecords[] = [];
  showViewMore: boolean;
  approvedPensionFiles: any;
  batchTransactionsTablePageLimit: number = 5;
  accountNoSet: Set<string>;
  constructor(private _stageRequestService: StagedRequestService, private _stagedTransferService: TransferStagingService,
    private _dialog: MatDialog, private _snackBar: MatSnackBar, private _authService: AuthService,
    private _miscService: MiscService, private _approvalService: TransferApprovalService, private pensionPaymentService: PensionService) {
      // try {
      //   window['casa']('other'); 
      //  } catch (error) {}
    this._loggedInUser = _authService.getLoggedInUser();
    this._loggedInUserIsApprover = this._authService.loggedInUserHasSMEProfile() && this._loggedInUser.Role.roles.includes(Role.Approver);
    this._loggedInUserIsInitiator = this._authService.loggedInUserHasSMEProfile() && this._loggedInUser.Role.roles.includes(Role.Initiator);

    UtilService.onPageLoadCallback();
  }

  ngOnInit() {
    this.accountNoSet = new Set<string>();
    VariablesService.CustomerAccounts.forEach(element => {
    this.accountNoSet.add(element.accountNumber)});
    this.initComponent();
    this._activeTabIndex = 0;
    this.getBulkPendingPaymentTransactions(this.pageSize, this.lastRecordIdFetched);
    this.loadApprovedPaymentTransactions();

  }
ngOnChanges(){

}

  loadApprovedPaymentTransactions(loaderMsg = 'fetching pension files', isViewMore = false) {
    this.ownLoaderIsActive = true;
    this.ownloaderMsg = loaderMsg;
    this.pensionPaymentService.getApprovedPensionFiles(this.pageSize, this.lastRecordIdFetched).subscribe((response) => {
      console.log(response);
      if (!response || response.ResponseCode !== ResponseCodes.SUCCESS) {
        this._snackBar.open(`Fetched records successfully`, 'Ok',
          { verticalPosition: 'top', horizontalPosition: 'right', duration: 10000 });
        return;
      }

      // if (response.Items && response.Items.length < 1) {
      //   this.showViewMore = false;
      //   return;
      // }

      this.showViewMore = true;
      // this.lastRecordIdFetched = response.Items[response.Items.length - 1].Id;
      this.approvedPensionFiles = response.Items;
      this.ownLoaderIsActive = false;
      this.ownloaderMsg = '';
      // if (isViewMore && response.Items && response.Items.length > 0) {
      //   this.pensionnFiles = this.pensionnFiles.concat(response.Items);
      // } else if (response.Items && response.Items.length > 0) {
      //   this.pensionnFiles = response.Items;
      // }
    },
      (error) => {
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
      });
  }



  getBulkPendingPaymentTransactions(pageSize: number, lastRecordIdFetched: number) {
    this.ownLoaderIsActive = true;
    this.ownloaderMsg = 'fetching pending payment requests. please wait';

    this.pensionPaymentService.getStagedPaymentTransactions(pageSize, lastRecordIdFetched).subscribe(
      (response) => {
        this.ownLoaderIsActive = this._activeRequestCount > 0;
        this.ownloaderMsg = '';
        this.ownLoaderIsActive = false;
        if (!response || response.ResponseCode !== ResponseCodes.SUCCESS) {
          this._snackBar.open(`${response.ResponseDescription}`, 'Ok', { duration: 25000 });
          return;
        }
        // response.PaginatedItems.forEach(request => {
        //   request['color'] = this.getRequestColor();
        //   request['checked'] = false;
        // });
        this.requestList = response.Items;
        console.log('request List', this.requestList);
        this.requestListBackup = this.requestList;
        this.requestListBackup = this.requestListBackup.filter(item=> this.accountNoSet.has(item.SourceAccount));
        this.requestList = this.requestListBackup.slice(0, 5);
      },
      (error) => {
        console.log(error);
        this._snackBar.open(`We encountered an error while loading pending payment request`, 'Ok', { duration: 25000 });
      }
    );
  }

  ngOnDestroy(): void {
    this.dismissOpenModalWindows();
  }


  loadPendingPaymentRequests(loaderMsg = 'fetching pending payment requests. please wait', lastFetchId = 0, pageSize = 100) {
    this.ownLoaderIsActive = true;
    this.ownloaderMsg = loaderMsg;
    const payload = { PageSize: pageSize, LastFetchId: lastFetchId };

    const _request = this._loggedInUserIsApprover ? this._stagedTransferService.getStagedPaymentRequest(payload) :
      this._miscService.getPendingRequest<StagedPaymentRequestResponse>(payload);
    _request.then(
      (response) => {
        this.ownLoaderIsActive = this._activeRequestCount > 0;
        this.ownloaderMsg = '';
        if (!response || response.ResponseCode !== ResponseCodes.SUCCESS || !response.PaginatedItems) {
          this._snackBar.open(`${response.ResponseDescription}`, 'Ok', { duration: 25000 });
          return;
        }
        response.PaginatedItems.forEach(request => {
          request['color'] = this.getRequestColor();
          request['checked'] = false;
        });
        //  this.requestListBackup = response.PaginatedItems;
        this.requestList = this.requestListBackup.slice(0, 5);
      },
      (error) => {
        console.log(error);
        this._snackBar.open(`We encountered an error while loading pending payment request`, 'Ok', { duration: 25000 });
      }
    );
  }

  onSearchFilterStateChanged() {
    this.filterIsActive = !this.filterIsActive;
    if (this.filterIsActive) {
      this.loadAccounts();
    }
  }
  onApprovalRequestAction(action: string, data: PendingRequestsRecords) {
    this._approvalModalData.currentRequestAction = 'approve';
    this.fetchPensionRecord(data.FileId, this.lastRecordIdFetched, 'fetching pending batch payments',this.pageSize);
    this._approvalModalData.data = data;
    this._approvalModalData.requestMeta.batchId = data.FileId.toString();
    this._approvalModalData.requestMeta.approverStageId = data.ApprovalStageId.toString();
    this._approvalModalData.requestMeta.approverId = data.ApproverId.toString();
    this._approvalModalData.requestMeta.initiator = data.InitiatorUserId;
    if (!this._loggedInUserIsApprover && action !== ('view-history')) {
      return;
    }
    switch (action) {
      case 'approve': {
        this._approvalModalData.currentRequestAction = 'approve';
        this._approvalModalData.message = '';
        this._approvalModalData.currentApprovalAction = '';
        this.approvalRequestModal = this._dialog.open(this._approvalModalTemplate);
        break;
      }
      case 'reject': {
        this.requestRejectionModal = this._dialog.open(RejectRequestComponent,
          {
            data: {
              title: 'Reject Payment Approval Request',
              payload: {
                OperationType: 'PENSION_REMITTANCE',
                OperationId: data.FileId
              }
            },
            hasBackdrop: false,
            closeOnNavigation: true,
            disableClose: true
          });
        this.requestRejectionModal.afterClosed()
          .subscribe((result) => this.getBulkPendingPaymentTransactions(this.pageSize, this.lastRecordIdFetched));

        break;
      }

      //     break;
      //   }
      case 'view-detail': {
        this._approvalModalData.currentRequestAction = 'view-detail';
        this._approvalModalData.title = '';
        this.approvalRequestDetailModal = this._dialog.open(this._requestDetailModalTemplate, {
          hasBackdrop: true,
          disableClose: true,
          minWidth: 480,
          panelClass: ['custom-add-confirmationModal'],
          closeOnNavigation: true
        });
        break;
      }
      case 'view-history': {
        //     this.approvalHistoryModal = this._dialog.open<ApprovalHistoryComponent>(
        //       ApprovalHistoryComponent,
        //       {
        //         hasBackdrop: false,
        //         data: {
        //           operationName: 'single-transfer',
        //           requestPayload: requestPayload,
        //           title: 'Request Approval History',
        //           extra: {}
        //         },
        //         closeOnNavigation: true
        //       });
        //     this.approvalHistoryModal.afterClosed().subscribe(
        //       (result) => {
        //         //
        //       }
        //     );
        //     break;
        //   }
        //   case 'approve-multiple': {
        //     this._approvalModalData.currentRequestAction = 'approve-multiple';
        //     const _selectedRequests = this.requestListBackup.filter(request => request['checked']);
        //     this._approvalModalData.data['totalAmount'] = _selectedRequests.map(request => request.SingleTransfer.amount)
        //       .reduce((prev, current) => current + prev);
        //     this._approvalModalData.title = `Approve ${this._selectedRequestCount} Payment ` +
        //       (this._selectedRequestCount > 1 ? 'Requests' : 'Request');
        //     this.approvalRequestModal = this._dialog.open((this._approvalModalTemplate));
        //     break;
        //   }
        //   case 'reject-multiple': {
        //     this._approvalModalData.currentRequestAction = 'reject-multiple';
        //     this._approvalModalData.title = `Reject ${this._selectedRequestCount} Payment ` +
        //       (this._selectedRequestCount > 1 ? 'Requests' : 'Request');
        //     this.approvalRequestModal = this._dialog.open((this._approvalModalTemplate));
        //     break;
      }
    }
  }

  gotoApprovedRequestsPage() {
    // this.router.navigate(['/approved-bulkpensiom-requests'])
  }

  onSelectedTabIndexChange(eventData) {
    if (eventData === 0) {
      // this.loadApprovedPaymentTransactions();
    }

  }
  onRequestModalAction(action: string, stepper: MatStepper, form?: NgForm) {

    switch (action) {
      case 'approve-multiple':
      case 'approve': {
        this.initiatePaymentRequestApprovalOTP(stepper);
        break;
      }
      case 'reject': {
        if (form && !form.valid) {
          this._snackBar.open('Please provode a reason for rejecting request .. ', 'Ok',
            { duration: 10000 });
          return;
        }
        this.sendPaymentApprovalRecallRequest({} as StagedTransfer);
        break;
      }
    }
  }

  initiatePaymentRequestApprovalOTP(stepper: MatStepper, isResendOTP = false) {
    this.ownLoaderIsActive = true;
    this.ownloaderMsg = 'initiating .. please wait';
    this._miscService.initiateOtpRequest(OtpReasonCodes.GENERIC, 'APPROVE TRANSFER').then(
      (response) => {
        console.log(response);
        this.ownloaderMsg = '';
        this.ownLoaderIsActive = false;
        if (!response || response.ResponseCode !== ResponseCodes.SUCCESS) {
          this._snackBar.open(`Failed to initiate approval process. Server responded with -> ${response.ResponseDescription}`,
            'Try again', { duration: 25000 }).onAction().subscribe(() => this.initiatePaymentRequestApprovalOTP(stepper));
          return;
        }
        this._otpReferenceId = response.ResponseDescription;
        this._approvalModalData.currentApprovalAction = 'verify-otp';
        this.otpInstruction = `Please enter the One Time Pin sent to ${response.NotificationAddress} to approve request`;
        if (!isResendOTP) {
          stepper.next();
        }
      },
      (error) => {
        console.log(error);
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        this._snackBar.open(`We encountered an error while processing your request. please retry`, 'Ok', { duration: 25000 });
      }
    );
  }
  onVerifyApprovalConfirmationOtp(form: NgForm, stepper: MatStepper) {
    if (!form.valid) {
      this._snackBar.open(`Invalid One Time Pin provided. Please provide a valid OTP to continue`, 'Ok',
        { duration: 10000 });
      return;
    }
    this.sendPaymentApprovalRequest(this._approvalModalData.data as StagedPaymentRequest, stepper);
  }
  sendPaymentApprovalRequest(request: StagedPaymentRequest, stepper: MatStepper) {
    this.ownLoaderIsActive = true;
    this.ownloaderMsg = `Approving payment .. please wait`;
    const approvalRequestPayload = this.buildSinglePaymentApprovalRequestPayload();
    this.pensionPaymentService.approvePendingPaymentRequest(approvalRequestPayload).subscribe(
      (response) => {
        console.log(response);
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        if (!response || response.ResponseCode !== ResponseCodes.SUCCESS) {
          this.handleNonSuccessApprovalResponse(request, response, stepper);
          return;
        }
        this._approvalModalData.currentRequestAction = 'requestComplete';
        setTimeout(() => {
          stepper.next();
        }, 50);
        this.getBulkPendingPaymentTransactions(this.pageSize, this.lastRecordIdFetched);
        this._snackBar.open(`${response.ResponseDescription}`, 'Ok', { duration: 50000 });
        this.oneTimePassCode = '';
      },
      () => {
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        this._snackBar.open(`We encountered an error while approving request .. Please retry`, 'Ok', { duration: 25000 });
      }
    );
  }
  sendPaymentApprovalRequestWithStageSelection(request: StagedPaymentRequest, stepper: MatStepper) {
    this.ownLoaderIsActive = true;
    this.ownloaderMsg = `Approving payment .. please wait`;
    const approvalRequestPayload = this.buildSinglePaymentApprovalRequestPayload();
    // this._approvalService.approvePendingPaymentRequestWithSelectedStage({approvalRequestPayload}).then(
    //   (response) => {
    //     console.log(response);
    //     this.ownLoaderIsActive = false;
    //     this.ownloaderMsg = '';
    //     if (!response || response.ResponseCode !== ResponseCodes.SUCCESS) {
    //       this.handleNonSuccessApprovalResponse(request, response, stepper);
    //       return;
    //     }
    //     this._approvalModalData.currentRequestAction = 'requestComplete';
    //     setTimeout(() => {
    //       stepper.next();
    //     }, 50);
    //     this.loadPendingPaymentRequests('refreshing pending batch list');
    //     this._snackBar.open(`${response.ResponseDescription}`, 'Ok', { duration: 50000 });
    //   },
    //   (error: any) => {
    //     console.log(error);
    //   }
    // );
  }
  onSelectedNextApprovalStageSubmitted(approvalStage: ApprovalStage, stepper: MatStepper) {
    this.ownLoaderIsActive = true;
    this.ownloaderMsg = 'setting next approval stage .. please wait';
    const requestPayload = this.buildSinglePaymentApprovalRequestPayload();
    // requestPayload.NextStages = [approvalStage];
    this.sendPaymentApprovalRequestWithStageSelection(this._approvalModalData.data, stepper);
  }

  sendPaymentApprovalRecallRequest(request: StagedTransfer) {
    this.ownLoaderIsActive = true;
    this.ownloaderMsg = `Approving payment .. please wait`;
    this._stageRequestService.makePaymentApprovalRecallRequest(request).then(
      (response: any) => {
        console.log(response);
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        this.approvalRequestModal.close();
        this._snackBar.open(`Payment request recalled. Notification email has been sent to initiator`, 'Ok',
          { duration: 5000 });
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  getRequestColor(): string {
    const color = ['lightcoral', '#2196F3', '#009688', '#b71c1c'];
    const pos = Math.floor(Math.random() * color.length);
    return color[pos];
  }

  onTransactionsPageChanged(pageChangedEvent: PageEvent) {
    const start = pageChangedEvent.pageIndex * pageChangedEvent.pageSize;
    const end = start + pageChangedEvent.pageSize;
    this.requestList = this.requestListBackup.slice(start, end);
  }

  loadAccounts() {
    this.accountList = VariablesService.CustomerAccounts;
  }

  getRoleName(role: Role): string {
    switch (role) {
      case Role.Initiator:
        return 'Initiator';
      case Role.Approver:
        return 'Approver';
      case Role.Viewer:
        return 'Viewer';
      default:
        return 'N\\A';
    }
  }
  getBankName(bankCode: string) {
    const bank = _banksList.find(b => b['bankCode'] === bankCode);
    return bank && bank['name'] ? bank['name'] : 'N/A';
  }

  onRequestCheckboxChanged(evtData, position: number) {
    this.requestList[position]['checked'] = evtData.checked;
    this._selectedRequestCount = this.requestList.filter(request => request['checked']).length;
  }

  onAllCheckboxChanged(evtData) {
    if (evtData.checked) {
      this.requestList.forEach(b => b['checked'] = true);
      this._selectedRequestCount = this.requestList.filter(b => b['checked']).length;
      this.allCheckboxesSelected = true;
      console.log(this.requestList);
    } else {
      this.requestList.forEach(b => b['checked'] = false);
      this._selectedRequestCount = 0;
      this.allCheckboxesSelected = false;
    }
  }

  initSearchFilter() {
    return {
      startDate: '',
      endDate: '',
      transactionType: '',
      selectedAccount: {} as CustomerAccount,
      transactionStatus: '',
      role: 0 as Role
    };
  }
  onBatchTransactionsPageChanged(pageEvent:PageEvent){

    const start = pageEvent.pageIndex * pageEvent.pageSize;
    const end = start + pageEvent.pageSize;
    this.transactionBatch = this.transactionBatchBackup.slice(start, end);
  }
  private handleNonSuccessApprovalResponse(request: StagedPaymentRequest, response: BulkPaymentBatchApprovalResponse, stepper: MatStepper) {
    switch (response.ResponseCode) {
      case '70': {
        this._approvalModalData.currentApprovalAction = 'select-next-approval-stage';
        this._availableApprovalStages = response.Stages;
        setTimeout(() => {
          stepper.next();
        }, 50);
        break;
      }
      default: {
        this._snackBar.open(`${response.ResponseDescription}`, 'Retry', { duration: 25000 }).onAction().subscribe(
          () => this.sendPaymentApprovalRequest(request, stepper)
        );
        break;
      }
    }
  }
  private buildBatchTransactionsRequestPayload(batchId, lastPageId = 0) {
    return {
      PageSize: 2000,
      lastRecordIdFetched: lastPageId,
      fileId: batchId
    };
  }
  fetchPensionRecord(batchId, lastRecordIdFetched, loaderMsg = '', pageSize) {
    this.ownLoaderIsActive = true;
    this.ownloaderMsg = loaderMsg || 'querying  transactions ..';
    const requestPayload = this.buildBatchTransactionsRequestPayload(batchId, lastRecordIdFetched);
    this.pensionPaymentService.getPensionRecords(requestPayload).subscribe(response => {
      if (!response || response.ResponseCode !== ResponseCodes.SUCCESS) {
        this._snackBar.open(`Fetched records successfully`, 'Ok',
          { verticalPosition: 'top', horizontalPosition: 'right', duration: 10000 });
        return;
      }
      this.ownLoaderIsActive = false;
      this.ownloaderMsg = '';
      this.transactionBatchBackup = response.Items.filter(item => item.NameEnquiryStatus === 'SUCCESS' );
      this.transactionBatch = this.transactionBatchBackup.slice(0, this.batchTransactionsTablePageLimit);

    },
      (err) => {
        this._snackBar.open(`Error encountered, Please try again`, 'Failed',
          { verticalPosition: 'top', horizontalPosition: 'right', duration: 10000 });
        return;

      });
  }
  private buildSinglePaymentApprovalRequestPayload() {
    return {
      // 'userId': 'string',
      'fileId': Number(this._approvalModalData.requestMeta.batchId),
      'approverStageId': Number(this._approvalModalData.requestMeta.approverStageId),
      'approverId': Number(this._approvalModalData.requestMeta.approverId),
      'otp': Number(this.oneTimePassCode),
      'otpReference': this._otpReferenceId
    };
  }

  private initComponent() {
    if (this._authService.loggedInUserHasSMEProfile() && this._loggedInUserIsApprover) {
      this.pageTitle = 'Authorize Pending Payment Approval Requests';
    }
  }

  private dismissOpenModalWindows() {
    if (this.approvalRequestDetailModal) {
      this.approvalRequestDetailModal.close();
    }
    if (this.approvalHistoryModal) {
      this.approvalHistoryModal.close();
    }
    if (this.approvalRequestModal) {
      this.approvalRequestModal.close();
    }

    if (this.requestRejectionModal) {
      this.requestRejectionModal.close();
    }
  }


}
