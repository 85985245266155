import { AuthService } from './../../../core/services/auth.service';
import { GetAuthorizedBvn } from './../../../core/models/getAuthorizedBvn';
import { MiscService } from './../../../core/services/misc.service';
import { ShowBvnNumberComponent } from './../show-bvn-number/show-bvn-number.component';
import { Validators } from '@angular/forms';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { Constants } from 'src/app/core/helpers/appConstants';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, interval } from 'rxjs';

@Component({
    selector:'app-verify-account-user',
    templateUrl: './verify-account-user.component.html',
    styleUrls: ['./verify-account-user.component.css']
})
export class VerifyAccountUserComponent implements OnInit, OnDestroy {
    txtOtp:string= "";
    otpForm: FormGroup;
    _showBvnDialogRef;
    sending:boolean = false;
    formValid:boolean = false;
    requestOngoing = false;
    _minutes;
    _seconds;
    sendingOtp:boolean = true;
    _mode:any;
    
    constructor(private dialogRef: MatDialogRef<VerifyAccountUserComponent>,
        private misc: MiscService,
        private _snackBar: MatSnackBar,
        private _dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data:any) 
        {
            this.otpForm = new FormGroup({
                otp: new FormControl('', [Validators.required]),
                otpReference: new FormControl('')
            });
            this._mode = this.data == 'NIN' ? 'NIN': 'BVN';
        }
    ngOnDestroy(): void {
        if(this.dialogRef) {
            this.dialogRef.close();
        }
    }

    startTimer() {
        var timer = 120;
        var minutes;
        var seconds;

       const sub = interval(1000).subscribe(x => {
            minutes = Math.floor(timer / 60);
            seconds = Math.floor(timer % 60);

            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;
            this._minutes = minutes;
            this._seconds = seconds;
            //display.textContent = minutes + ":" + seconds;

            --timer;
            if (--timer < 0) {
                // console.log('timeup');
                this.sending = false;
                sub.unsubscribe()
            }
        })
    }

    resendOtp() {
        const reasonCode = "20";
        const reasonDescription = "Show BVN";
        const self = this;
        this.sendingOtp = true;
        this.sendOtp(reasonCode, reasonDescription);
            
    }

    sendOtp(reasonCode:string, reasonDescription:string) {
       // this.canResend = !this.canResend;
        this.misc.initiateOtpRequest(reasonCode, reasonDescription)
            .then((response)=>{
                this.sending = true;
               //console.log("otp", response)
               this.sendingOtp = false;
                if(this.sending == true) {
                    if(response["ResponseCode"] == "00") {
                        this.get('otpReference').setValue(response["ResponseDescription"]);
                        this.startTimer();
                    }
                    else {
                        this.dialogRef.close('otp sending failed')
                        this.sending = false;
                        this.sendingOtp = false;
                        this._snackBar.open(response["ResponseDescription"], "Ok", {
                            verticalPosition: "bottom",
                            horizontalPosition: "right",
                            duration: 3500,
                        });
                        
                    }
                    
                }
                // ResponseDescription
               
            }).catch((er)=>{
                this.dialogRef.close('otp sending failed')
                this.sendingOtp = false;
                this._snackBar.open(Constants.APITechnicalErrorMessage, "Ok", {
                        verticalPosition: "bottom",
                        horizontalPosition: "right",
                        duration: 3500,
                    });
                    
            })
    }
    ngOnInit(): void {
        //throw new Error('Method not implemented.');
        const reasonCode = "20";
        const reasonDescription = "Show BVN";
        const self = this;
        this.sendOtp(reasonCode, reasonDescription);
            
    }

    submitOtp() {
        this.formValid = this.isFormFieldValid('otp');
     //   console.log(this.otpForm)
        if(this.formValid) {
            this.requestOngoing = true;
            const sessionId = Math.ceil(Math.random()*10000000).toString();
            const payload: GetAuthorizedBvn = {
                sessionId,
                userId:AuthService.FullyAuthenticatedUser.UserId,
                otp: this.get('otp').value,
                otpReference:this.get('otpReference').value,
                cifid:AuthService.FullyAuthenticatedUser.CifId
            }
           // console.log(payload)

            this.misc.getAuthorizedBVN(payload).then((response)=>{
                //console.log("validate otp", response);
                this.requestOngoing = false;
                this.dialogRef.close();
                this.showBvnNumber(response, this._mode);
            }).catch((er: HttpErrorResponse)=>{
                console.log(er)
                this.requestOngoing = false;
                const errormessge = er.status == 400 ? er["error"]["Message"] : Constants.APITechnicalErrorMessage
                this._snackBar.open(errormessge, "Ok", {
                    verticalPosition: "bottom",
                    horizontalPosition: "right",
                    duration: 1500,
                });
            })
           
        }

        return;
    }

    cancelDialog() {
        this.dialogRef.close('cancelled');
    }

    isFormFieldValid(formFieldname) {
        const formControl = this.get(formFieldname);
        return formControl.touched && formControl.valid
    }

    get(keyname: string) {
        return this.otpForm.get(keyname) as FormControl;
    }
    showBvnNumber(data, mode) {
        
        this._showBvnDialogRef = this._dialog.open(ShowBvnNumberComponent, {
            hasBackdrop:true,
            disableClose:true,
            width:'434px',
            height:'302px',
            data:{data, mode}
            
        })
    }

}