import { MatDialogModule } from '@angular/material';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TenureModalComponent } from './tenure-modal.component';
import { RouterModule } from '@angular/router';
import { AppMaterialModule } from 'src/app/app.material.module';
import {MatButtonModule} from '@angular/material/button';

const tenureModuleRoute = RouterModule.forChild([
  {
      path: '',
      component: TenureModalComponent,
  },
]);
@NgModule({
  imports: [
    CommonModule,MatDialogModule,RouterModule,AppMaterialModule,MatButtonModule,
    tenureModuleRoute
  ],
  declarations: [TenureModalComponent]
})
export class TenureModalModule { }
