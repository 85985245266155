export const countries = [
    {
        code: '7',
        isoCode: 'GEA',
        countryName: 'Abkhazia'
    },
    {
        code: '93',
        isoCode: 'AF',
        countryName: 'Afghanistan'
    },
    {
        code: '355',
        isoCode: 'AL',
        countryName: 'Albania'
    },
    {
        code: '213',
        isoCode: 'DZ',
        countryName: 'Algeria'
    },
    {
        code: '684',
        isoCode: 'AS',
        countryName: 'American Samoa'
    },
    {
        code: '376',
        isoCode: 'AD',
        countryName: 'Andorra'
    },
    {
        code: '244',
        isoCode: 'AO',
        countryName: 'Angola'
    },
    {
        code: '1264',
        isoCode: 'AI',
        countryName: 'Anguilla'
    },
    {
        code: '1268',
        isoCode: 'AG',
        countryName: 'Antigua and Barbuda'
    },
    {
        code: '54',
        isoCode: 'AR',
        countryName: 'Argentina Republic'
    },
    {
        code: '374',
        isoCode: 'AM',
        countryName: 'Armenia'
    },
    {
        code: '297',
        isoCode: 'AW',
        countryName: 'Aruba'
    },
    {
        code: '61',
        isoCode: 'AU',
        countryName: 'Australia'
    },
    {
        code: '43',
        isoCode: 'AT',
        countryName: 'Austria'
    },
    {
        code: '994',
        isoCode: 'AZ',
        countryName: 'Azerbaijan'
    },
    {
        code: '1242',
        isoCode: 'BS',
        countryName: 'Bahamas'
    },
    {
        code: '973',
        isoCode: 'BH',
        countryName: 'Bahrain'
    },
    {
        code: '880',
        isoCode: 'BD',
        countryName: 'Bangladesh'
    },
    {
        code: '1246',
        isoCode: 'BB',
        countryName: 'Barbados'
    },
    {
        code: '375',
        isoCode: 'BY',
        countryName: 'Belarus'
    },
    {
        code: '32',
        isoCode: 'BE',
        countryName: 'Belgium'
    },
    {
        code: '501',
        isoCode: 'BZ',
        countryName: 'Belize'
    },
    {
        code: '229',
        isoCode: 'BJ',
        countryName: 'Benin'
    },
    {
        code: '1441',
        isoCode: 'BM',
        countryName: 'Bermuda'
    },
    {
        code: '975',
        isoCode: 'BT',
        countryName: 'Bhutan'
    },
    {
        code: '591',
        isoCode: 'BO',
        countryName: 'Bolivia'
    },
    {
        code: '387',
        isoCode: 'BA',
        countryName: 'Bosnia and Herzegovina '
    },
    {
        code: '267',
        isoCode: 'BW',
        countryName: 'Botswana'
    },
    {
        code: '55',
        isoCode: 'BR',
        countryName: 'Brazil'
    },
    {
        code: '284',
        isoCode: 'VG',
        countryName: 'British Virgin Islands'
    },
    {
        code: '673',
        isoCode: 'BN',
        countryName: 'Brunei'
    },
    {
        code: '359',
        isoCode: 'BG',
        countryName: 'Bulgaria'
    },
    {
        code: '226',
        isoCode: 'BF',
        countryName: 'Burkina Faso'
    },
    {
        code: '95',
        isoCode: 'MM',
        countryName: 'Burma'
    },
    {
        code: '257',
        isoCode: 'BI',
        countryName: 'Burundi'
    },
    {
        code: '855',
        isoCode: 'KH',
        countryName: 'Cambodia'
    },
    {
        code: '237',
        isoCode: 'CM',
        countryName: 'Cameroon'
    },
    {
        code: '1',
        isoCode: 'CA',
        countryName: 'Canada'
    },
    {
        code: '238',
        isoCode: 'CV',
        countryName: 'Cape Verde'
    },
    {
        code: '1345',
        isoCode: 'KY',
        countryName: 'Cayman Islands'
    },
    {
        code: '236',
        isoCode: 'CF',
        countryName: 'Central African Republic'
    },
    {
        code: '235',
        isoCode: 'TD',
        countryName: 'Chad'
    },
    {
        code: '56',
        isoCode: 'CL',
        countryName: 'Chile'
    },
    {
        code: '86',
        isoCode: 'CN',
        countryName: 'China'
    },
    {
        code: '57',
        isoCode: 'CO',
        countryName: 'Colombia'
    },
    {
        code: '269',
        isoCode: 'KM',
        countryName: 'Comoros'
    },
    {
        code: '242',
        isoCode: 'CG',
        countryName: 'Congo Republic'
    },
    {
        code: '682',
        isoCode: 'CK',
        countryName: 'Cook Islands'
    },
    {
        code: '506',
        isoCode: 'CR',
        countryName: 'Costa Rica'
    },
    {
        code: '385',
        isoCode: 'HR',
        countryName: 'Croatia'
    },
    {
        code: '53',
        isoCode: 'CU',
        countryName: 'Cuba'
    },
    {
        code: '599',
        isoCode: 'CW',
        countryName: 'Curacao'
    },
    {
        code: '357',
        isoCode: 'CY',
        countryName: 'Cyprus'
    },
    {
        code: '420',
        isoCode: 'CZ',
        countryName: 'Czech Republic'
    },
    {
        code: '243',
        isoCode: 'CD',
        countryName: 'Democratic Republic of the Congo'
    },
    {
        code: '45',
        isoCode: 'DK',
        countryName: 'Denmark'
    },
    {
        code: '253',
        isoCode: 'DJ',
        countryName: 'Djibouti'
    },
    {
        code: '1767',
        isoCode: 'DM',
        countryName: 'Dominica'
    },
    {
        code: '1809',
        isoCode: 'DO',
        countryName: 'Dominican Republic'
    },
    {
        code: '593',
        isoCode: 'EC',
        countryName: 'Ecuador'
    },
    {
        code: '20',
        isoCode: 'EG',
        countryName: 'Egypt'
    },
    {
        code: '503',
        isoCode: 'SV',
        countryName: 'El Salvador'
    },
    {
        code: '240',
        isoCode: 'GQ',
        countryName: 'Equatorial Guinea'
    },
    {
        code: '291',
        isoCode: 'ER',
        countryName: 'Eritrea'
    },
    {
        code: '372',
        isoCode: 'EE',
        countryName: 'Estonia'
    },
    {
        code: '251',
        isoCode: 'ET',
        countryName: 'Ethiopia'
    },
    {
        code: '500',
        isoCode: 'FK',
        countryName: 'Falkland Islands'
    },
    {
        code: '298',
        isoCode: 'FO',
        countryName: 'Faroe Islands'
    },
    {
        code: '679',
        isoCode: 'FJ',
        countryName: 'Fiji'
    },
    {
        code: '358',
        isoCode: 'FI',
        countryName: 'Finland'
    },
    {
        code: '33',
        isoCode: 'FR',
        countryName: 'France'
    },
    {
        code: '594',
        isoCode: 'FG',
        countryName: 'French Guiana'
    },
    {
        code: '689',
        isoCode: 'PF',
        countryName: 'French Polynesia'
    },
    {
        code: '241',
        isoCode: 'GA',
        countryName: 'Gabon'
    },
    {
        code: '220',
        isoCode: 'GM',
        countryName: 'Gambia'
    },
    {
        code: '995',
        isoCode: 'GE',
        countryName: 'Georgia'
    },
    {
        code: '49',
        isoCode: 'DE',
        countryName: 'Germany'
    },
    {
        code: '233',
        isoCode: 'GH',
        countryName: 'Ghana'
    },
    {
        code: '350',
        isoCode: 'GI',
        countryName: 'Gibraltar'
    },
    {
        code: '30',
        isoCode: 'GR',
        countryName: 'Greece'
    },
    {
        code: '299',
        isoCode: 'GL',
        countryName: 'Greenland'
    },
    {
        code: '1473',
        isoCode: 'GD',
        countryName: 'Grenada'
    },
    {
        code: '590',
        isoCode: 'GP',
        countryName: 'Guadeloupe'
    },
    {
        code: '1671',
        isoCode: 'GU',
        countryName: 'Guam'
    },
    {
        code: '502',
        isoCode: 'GT',
        countryName: 'Guatemala'
    },
    {
        code: '224',
        isoCode: 'GN',
        countryName: 'Guinea'
    },
    {
        code: '245',
        isoCode: 'GW',
        countryName: 'Guinea-Bissau'
    },
    {
        code: '592',
        isoCode: 'GY',
        countryName: 'Guyana'
    },
    {
        code: '509',
        isoCode: 'HT',
        countryName: 'Haiti'
    },
    {
        code: '504',
        isoCode: 'HN',
        countryName: 'Honduras'
    },
    {
        code: '852',
        isoCode: 'HK',
        countryName: 'Hongkong China'
    },
    {
        code: '36',
        isoCode: 'HU',
        countryName: 'Hungary'
    },
    {
        code: '354',
        isoCode: 'IS',
        countryName: 'Iceland'
    },
    {
        code: '91',
        isoCode: 'IN',
        countryName: 'India'
    },
    {
        code: '62',
        isoCode: 'ID',
        countryName: 'Indonesia'
    },
    {
        code: '98',
        isoCode: 'IR',
        countryName: 'Iran'
    },
    {
        code: '964',
        isoCode: 'IQ',
        countryName: 'Iraq'
    },
    {
        code: '353',
        isoCode: 'IE',
        countryName: 'Ireland'
    },
    {
        code: '972',
        isoCode: 'IL',
        countryName: 'Israel'
    },
    {
        code: '39',
        isoCode: 'IT',
        countryName: 'Italy'
    },
    {
        code: '225',
        isoCode: 'CI',
        countryName: 'Ivory Coast'
    },
    {
        code: '1876',
        isoCode: 'JM',
        countryName: 'Jamaica'
    },
    {
        code: '81',
        isoCode: 'JP',
        countryName: 'Japan'
    },
    {
        code: '962',
        isoCode: 'JO',
        countryName: 'Jordan'
    },
    {
        code: '7',
        isoCode: 'KZ',
        countryName: 'Kazakhstan'
    },
    {
        code: '254',
        isoCode: 'KE',
        countryName: 'Kenya'
    },
    {
        code: '686',
        isoCode: 'KI',
        countryName: 'Kiribati'
    },
    {
        code: '965',
        isoCode: 'KW',
        countryName: 'Kuwait'
    },
    {
        code: '996',
        isoCode: 'KG',
        countryName: 'Kyrgyzstan'
    },
    {
        code: '856',
        isoCode: 'LA',
        countryName: 'Laos'
    },
    {
        code: '371',
        isoCode: 'LV',
        countryName: 'Latvia'
    },
    {
        code: '961',
        isoCode: 'LB',
        countryName: 'Lebanon'
    },
    {
        code: '266',
        isoCode: 'LS',
        countryName: 'Lesotho'
    },
    {
        code: '231',
        isoCode: 'LR',
        countryName: 'Liberia'
    },
    {
        code: '218',
        isoCode: 'LY',
        countryName: 'Libya'
    },
    {
        code: '423',
        isoCode: 'LI',
        countryName: 'Liechtenstein'
    },
    {
        code: '370',
        isoCode: 'LT',
        countryName: 'Lithuania'
    },
    {
        code: '352',
        isoCode: 'LU',
        countryName: 'Luxembourg'
    },
    {
        code: '853',
        isoCode: 'MO',
        countryName: 'Macao China'
    },
    {
        code: '389',
        isoCode: 'MK',
        countryName: 'Macedonia'
    },
    {
        code: '261',
        isoCode: 'MG',
        countryName: 'Madagascar'
    },
    {
        code: '265',
        isoCode: 'MW',
        countryName: 'Malawi'
    },
    {
        code: '60',
        isoCode: 'MY',
        countryName: 'Malaysia'
    },
    {
        code: '960',
        isoCode: 'MV',
        countryName: 'Maldives'
    },
    {
        code: '223',
        isoCode: 'ML',
        countryName: 'Mali'
    },
    {
        code: '356',
        isoCode: 'MT',
        countryName: 'Malta'
    },
    {
        code: '596',
        isoCode: 'MQ',
        countryName: 'Martinique'
    },
    {
        code: '222',
        isoCode: 'MR',
        countryName: 'Mauritania'
    },
    {
        code: '230',
        isoCode: 'MU',
        countryName: 'Mauritius'
    },
    {
        code: '52',
        isoCode: 'MX',
        countryName: 'Mexico'
    },
    {
        code: '691',
        isoCode: 'FM',
        countryName: 'Micronesia'
    },
    {
        code: '373',
        isoCode: 'MD',
        countryName: 'Moldova'
    },
    {
        code: '377',
        isoCode: 'MC',
        countryName: 'Monaco'
    },
    {
        code: '976',
        isoCode: 'MN',
        countryName: 'Mongolia'
    },
    {
        code: '382',
        isoCode: 'ME',
        countryName: 'Montenegro'
    },
    {
        code: '1664',
        isoCode: 'MS',
        countryName: 'Montserrat'
    },
    {
        code: '212',
        isoCode: 'MA',
        countryName: 'Morocco'
    },
    {
        code: '258',
        isoCode: 'MZ',
        countryName: 'Mozambique'
    },
    {
        code: '264',
        isoCode: 'NA',
        countryName: 'Namibia'
    },
    {
        code: '977',
        isoCode: 'NP',
        countryName: 'Nepal'
    },
    {
        code: '31',
        isoCode: 'NL',
        countryName: 'Netherlands'
    },
    {
        code: '599',
        isoCode: 'AN',
        countryName: 'Netherlands Antilles'
    },
    {
        code: '687',
        isoCode: 'NC',
        countryName: 'New Caledonia'
    },
    {
        code: '64',
        isoCode: 'NZ',
        countryName: 'New Zealand'
    },
    {
        code: '505',
        isoCode: 'NI',
        countryName: 'Nicaragua'
    },
    {
        code: '227',
        isoCode: 'NE',
        countryName: 'Niger'
    },
    {
        code: '234',
        isoCode: 'NG',
        countryName: 'Nigeria'
    },
    {
        code: '683',
        isoCode: 'NU',
        countryName: 'Niue'
    },
    {
        code: '850',
        isoCode: 'KP',
        countryName: 'North Korea'
    },
    {
        code: '47',
        isoCode: 'NO',
        countryName: 'Norway'
    },
    {
        code: '968',
        isoCode: 'OM',
        countryName: 'Oman'
    },
    {
        code: '92',
        isoCode: 'PK',
        countryName: 'Pakistan'
    },
    {
        code: '970',
        isoCode: 'PS',
        countryName: 'Palestinian Territory'
    },
    {
        code: '507',
        isoCode: 'PA',
        countryName: 'Panama'
    },
    {
        code: '675',
        isoCode: 'PG',
        countryName: 'Papua New Guinea'
    },
    {
        code: '595',
        isoCode: 'PY',
        countryName: 'Paraguay'
    },
    {
        code: '51',
        isoCode: 'PE',
        countryName: 'Peru'
    },
    {
        code: '63',
        isoCode: 'PH',
        countryName: 'Philippines'
    },
    {
        code: '48',
        isoCode: 'PL',
        countryName: 'Poland'
    },
    {
        code: '351',
        isoCode: 'PT',
        countryName: 'Portugal'
    },
    {
        code: '1787',
        isoCode: 'PR',
        countryName: 'Puerto Rico'
    },
    {
        code: '974',
        isoCode: 'QA',
        countryName: 'Qatar'
    },
    {
        code: '680',
        isoCode: 'PW',
        countryName: 'Republic of Palau'
    },
    {
        code: '211',
        isoCode: 'SS',
        countryName: 'Republic of South Sudan'
    },
    {
        code: '262',
        isoCode: 'RE',
        countryName: 'Reunion'
    },
    {
        code: '40',
        isoCode: 'RO',
        countryName: 'Romania'
    },
    {
        code: '79',
        isoCode: 'RU',
        countryName: 'Russian Federation'
    },
    {
        code: '250',
        isoCode: 'RW',
        countryName: 'Rwanda'
    },
    {
        code: '1869',
        isoCode: 'KN',
        countryName: 'Saint Kitts and Nevis'
    },
    {
        code: '1758',
        isoCode: 'LC',
        countryName: 'Saint Lucia'
    },
    {
        code: '508',
        isoCode: 'PM',
        countryName: 'Saint Pierre and Miquelon'
    },
    {
        code: '1784',
        isoCode: 'VC',
        countryName: 'Saint Vincent and the Grenadines'
    },
    {
        code: '685',
        isoCode: 'WS',
        countryName: 'Samoa'
    },
    {
        code: '378',
        isoCode: 'SM',
        countryName: 'San Marino'
    },
    {
        code: '239',
        isoCode: 'ST',
        countryName: 'Sao Tome and Principe'
    },
    {
        code: '966',
        isoCode: 'SA',
        countryName: 'Saudi Arabia'
    },
    {
        code: '221',
        isoCode: 'SN',
        countryName: 'Senegal'
    },
    {
        code: '381',
        isoCode: 'RS',
        countryName: 'Serbia'
    },
    {
        code: '248',
        isoCode: 'SC',
        countryName: 'Seychelles'
    },
    {
        code: '232',
        isoCode: 'SL',
        countryName: 'Sierra Leone'
    },
    {
        code: '65',
        isoCode: 'SG',
        countryName: 'Singapore'
    },
    {
        code: '421',
        isoCode: 'SK',
        countryName: 'Slovakia'
    },
    {
        code: '386',
        isoCode: 'SI',
        countryName: 'Slovenia'
    },
    {
        code: '677',
        isoCode: 'SB',
        countryName: 'Solomon Islands'
    },
    {
        code: '252',
        isoCode: 'SO',
        countryName: 'Somalia'
    },
    {
        code: '27',
        isoCode: 'ZA',
        countryName: 'South Africa'
    },
    {
        code: '82',
        isoCode: 'KR',
        countryName: 'South Korea'
    },
    {
        code: '34',
        isoCode: 'ES',
        countryName: 'Spain'
    },
    {
        code: '94',
        isoCode: 'LK',
        countryName: 'Sri Lanka'
    },
    {
        code: '249',
        isoCode: 'SD',
        countryName: 'Sudan'
    },
    {
        code: '597',
        isoCode: 'SR',
        countryName: 'Suriname'
    },
    {
        code: '268',
        isoCode: 'SZ',
        countryName: 'Swaziland'
    },
    {
        code: '46',
        isoCode: 'SE',
        countryName: 'Sweden'
    },
    {
        code: '41',
        isoCode: 'CH',
        countryName: 'Switzerland'
    },
    {
        code: '963',
        isoCode: 'SY',
        countryName: 'Syrian Arab Republic'
    },
    {
        code: '992',
        isoCode: 'TK',
        countryName: 'Tajikistan'
    },
    {
        code: '255',
        isoCode: 'TZ',
        countryName: 'Tanzania'
    },
    {
        code: '66',
        isoCode: 'TH',
        countryName: 'Thailand'
    },
    {
        code: '670',
        isoCode: 'TP',
        countryName: 'Timor-Leste'
    },
    {
        code: '228',
        isoCode: 'TG',
        countryName: 'Togo'
    },
    {
        code: '676',
        isoCode: 'TO',
        countryName: 'Tonga'
    },
    {
        code: '1868',
        isoCode: 'TT',
        countryName: 'Trinidad and Tobago'
    },
    {
        code: '216',
        isoCode: 'TN',
        countryName: 'Tunisia'
    },
    {
        code: '90',
        isoCode: 'TR',
        countryName: 'Turkey'
    },
    {
        code: '993',
        isoCode: 'TM',
        countryName: 'Turkmenistan'
    },
    {
        code: '1649',
        isoCode: 'TC',
        countryName: 'Turks and Caicos Islands'
    },
    {
        code: '688',
        isoCode: 'TV',
        countryName: 'Tuvalu'
    },
    {
        code: '256',
        isoCode: 'UG',
        countryName: 'Uganda'
    },
    {
        code: '380',
        isoCode: 'UA',
        countryName: 'Ukraine'
    },
    {
        code: '971',
        isoCode: 'AE',
        countryName: 'United Arab Emirates'
    },
    {
        code: '44',
        isoCode: 'GB',
        countryName: 'United Kingdom'
    },
    {
        code: '1',
        isoCode: 'US',
        countryName: 'United States of America'
    },
    {
        code: '598',
        isoCode: 'UY',
        countryName: 'Uruguay'
    },
    {
        code: '998',
        isoCode: 'UZ',
        countryName: 'Uzbekistan'
    },
    {
        code: '678',
        isoCode: 'VU',
        countryName: 'Vanuatu'
    },
    {
        code: '58',
        isoCode: 'VE',
        countryName: 'Venezuela'
    },
    {
        code: '84',
        isoCode: 'VN',
        countryName: 'Vietnam'
    },
    {
        code: '1340',
        isoCode: 'VI',
        countryName: 'Virgin Islands of the United States'
    },
    {
        code: '967',
        isoCode: 'YE',
        countryName: 'Yemen'
    },
    {
        code: '260',
        isoCode: 'ZM',
        countryName: 'Zambia'
    },
    {
        code: '263',
        isoCode: 'ZW',
        countryName: 'Zimbabwe'
    }
];


export const countryList = [
    {
        code: '7',
        isoCode: 'GEA',
        countryName: 'Abkhazia'
    },
    {
        code: '93',
        isoCode: 'AF',
        countryName: 'Afghanistan'
    },
    {
        code: '355',
        isoCode: 'AL',
        countryName: 'Albania'
    },
    {
        code: '213',
        isoCode: 'DZ',
        countryName: 'Algeria'
    },
    {
        code: '684',
        isoCode: 'AS',
        countryName: 'American Samoa'
    },
    {
        code: '376',
        isoCode: 'AD',
        countryName: 'Andorra'
    },
    {
        code: '244',
        isoCode: 'AO',
        countryName: 'Angola'
    },
    {
        code: '1264',
        isoCode: 'AI',
        countryName: 'Anguilla'
    },
    {
        code: '1268',
        isoCode: 'AG',
        countryName: 'Antigua and Barbuda'
    },
    {
        code: '54',
        isoCode: 'AR',
        countryName: 'Argentina Republic'
    },
    {
        code: '374',
        isoCode: 'AM',
        countryName: 'Armenia'
    },
    {
        code: '297',
        isoCode: 'AW',
        countryName: 'Aruba'
    },
    {
        code: '61',
        isoCode: 'AU',
        countryName: 'Australia'
    },
    {
        code: '43',
        isoCode: 'AT',
        countryName: 'Austria'
    },
    {
        code: '994',
        isoCode: 'AZ',
        countryName: 'Azerbaijan'
    },
    {
        code: '1242',
        isoCode: 'BS',
        countryName: 'Bahamas'
    },
    {
        code: '973',
        isoCode: 'BH',
        countryName: 'Bahrain'
    },
    {
        code: '880',
        isoCode: 'BD',
        countryName: 'Bangladesh'
    },
    {
        code: '1246',
        isoCode: 'BB',
        countryName: 'Barbados'
    },
    {
        code: '375',
        isoCode: 'BY',
        countryName: 'Belarus'
    },
    {
        code: '32',
        isoCode: 'BE',
        countryName: 'Belgium'
    },
    {
        code: '501',
        isoCode: 'BZ',
        countryName: 'Belize'
    },
    {
        code: '229',
        isoCode: 'BJ',
        countryName: 'Benin'
    },
    {
        code: '1441',
        isoCode: 'BM',
        countryName: 'Bermuda'
    },
    {
        code: '975',
        isoCode: 'BT',
        countryName: 'Bhutan'
    },
    {
        code: '591',
        isoCode: 'BO',
        countryName: 'Bolivia'
    },
    {
        code: '387',
        isoCode: 'BA',
        countryName: 'Bosnia and Herzegovina '
    },
    {
        code: '267',
        isoCode: 'BW',
        countryName: 'Botswana'
    },
    {
        code: '55',
        isoCode: 'BR',
        countryName: 'Brazil'
    },
    {
        code: '284',
        isoCode: 'VG',
        countryName: 'British Virgin Islands'
    },
    {
        code: '673',
        isoCode: 'BN',
        countryName: 'Brunei'
    },
    {
        code: '359',
        isoCode: 'BG',
        countryName: 'Bulgaria'
    },
    {
        code: '226',
        isoCode: 'BF',
        countryName: 'Burkina Faso'
    },
    {
        code: '95',
        isoCode: 'MM',
        countryName: 'Burma'
    },
    {
        code: '257',
        isoCode: 'BI',
        countryName: 'Burundi'
    },
    {
        code: '855',
        isoCode: 'KH',
        countryName: 'Cambodia'
    },
    {
        code: '237',
        isoCode: 'CM',
        countryName: 'Cameroon'
    },
    {
        code: '1',
        isoCode: 'CA',
        countryName: 'Canada'
    },
    {
        code: '238',
        isoCode: 'CV',
        countryName: 'Cape Verde'
    },
    {
        code: '1345',
        isoCode: 'KY',
        countryName: 'Cayman Islands'
    },
    {
        code: '236',
        isoCode: 'CF',
        countryName: 'Central African Republic'
    },
    {
        code: '235',
        isoCode: 'TD',
        countryName: 'Chad'
    },
    {
        code: '56',
        isoCode: 'CL',
        countryName: 'Chile'
    },
    {
        code: '86',
        isoCode: 'CN',
        countryName: 'China'
    },
    {
        code: '57',
        isoCode: 'CO',
        countryName: 'Colombia'
    },
    {
        code: '269',
        isoCode: 'KM',
        countryName: 'Comoros'
    },
    {
        code: '242',
        isoCode: 'CG',
        countryName: 'Congo Republic'
    },
    {
        code: '682',
        isoCode: 'CK',
        countryName: 'Cook Islands'
    },
    {
        code: '506',
        isoCode: 'CR',
        countryName: 'Costa Rica'
    },
    {
        code: '385',
        isoCode: 'HR',
        countryName: 'Croatia'
    },
    {
        code: '53',
        isoCode: 'CU',
        countryName: 'Cuba'
    },
    {
        code: '599',
        isoCode: 'CW',
        countryName: 'Curacao'
    },
    {
        code: '357',
        isoCode: 'CY',
        countryName: 'Cyprus'
    },
    {
        code: '420',
        isoCode: 'CZ',
        countryName: 'Czech Republic'
    },
    {
        code: '243',
        isoCode: 'CD',
        countryName: 'Democratic Republic of the Congo'
    },
    {
        code: '45',
        isoCode: 'DK',
        countryName: 'Denmark'
    },
    {
        code: '253',
        isoCode: 'DJ',
        countryName: 'Djibouti'
    },
    {
        code: '1767',
        isoCode: 'DM',
        countryName: 'Dominica'
    },
    {
        code: '1809',
        isoCode: 'DO',
        countryName: 'Dominican Republic'
    },
    {
        code: '593',
        isoCode: 'EC',
        countryName: 'Ecuador'
    },
    {
        code: '20',
        isoCode: 'EG',
        countryName: 'Egypt'
    },
    {
        code: '503',
        isoCode: 'SV',
        countryName: 'El Salvador'
    },
    {
        code: '240',
        isoCode: 'GQ',
        countryName: 'Equatorial Guinea'
    },
    {
        code: '291',
        isoCode: 'ER',
        countryName: 'Eritrea'
    },
    {
        code: '372',
        isoCode: 'EE',
        countryName: 'Estonia'
    },
    {
        code: '251',
        isoCode: 'ET',
        countryName: 'Ethiopia'
    },
    {
        code: '500',
        isoCode: 'FK',
        countryName: 'Falkland Islands'
    },
    {
        code: '298',
        isoCode: 'FO',
        countryName: 'Faroe Islands'
    },
    {
        code: '679',
        isoCode: 'FJ',
        countryName: 'Fiji'
    },
    {
        code: '358',
        isoCode: 'FI',
        countryName: 'Finland'
    },
    {
        code: '33',
        isoCode: 'FR',
        countryName: 'France'
    },
    {
        code: '594',
        isoCode: 'FG',
        countryName: 'French Guiana'
    },
    {
        code: '689',
        isoCode: 'PF',
        countryName: 'French Polynesia'
    },
    {
        code: '241',
        isoCode: 'GA',
        countryName: 'Gabon'
    },
    {
        code: '220',
        isoCode: 'GM',
        countryName: 'Gambia'
    },
    {
        code: '995',
        isoCode: 'GE',
        countryName: 'Georgia'
    },
    {
        code: '49',
        isoCode: 'DE',
        countryName: 'Germany'
    },
    {
        code: '233',
        isoCode: 'GH',
        countryName: 'Ghana'
    },
    {
        code: '350',
        isoCode: 'GI',
        countryName: 'Gibraltar'
    },
    {
        code: '30',
        isoCode: 'GR',
        countryName: 'Greece'
    },
    {
        code: '299',
        isoCode: 'GL',
        countryName: 'Greenland'
    },
    {
        code: '1473',
        isoCode: 'GD',
        countryName: 'Grenada'
    },
    {
        code: '590',
        isoCode: 'GP',
        countryName: 'Guadeloupe'
    },
    {
        code: '1671',
        isoCode: 'GU',
        countryName: 'Guam'
    },
    {
        code: '502',
        isoCode: 'GT',
        countryName: 'Guatemala'
    },
    {
        code: '224',
        isoCode: 'GN',
        countryName: 'Guinea'
    },
    {
        code: '245',
        isoCode: 'GW',
        countryName: 'Guinea-Bissau'
    },
    {
        code: '592',
        isoCode: 'GY',
        countryName: 'Guyana'
    },
    {
        code: '509',
        isoCode: 'HT',
        countryName: 'Haiti'
    },
    {
        code: '504',
        isoCode: 'HN',
        countryName: 'Honduras'
    },
    {
        code: '852',
        isoCode: 'HK',
        countryName: 'Hongkong China'
    },
    {
        code: '36',
        isoCode: 'HU',
        countryName: 'Hungary'
    },
    {
        code: '354',
        isoCode: 'IS',
        countryName: 'Iceland'
    },
    {
        code: '91',
        isoCode: 'IN',
        countryName: 'India'
    },
    {
        code: '62',
        isoCode: 'ID',
        countryName: 'Indonesia'
    },
    {
        code: '98',
        isoCode: 'IR',
        countryName: 'Iran'
    },
    {
        code: '964',
        isoCode: 'IQ',
        countryName: 'Iraq'
    },
    {
        code: '353',
        isoCode: 'IE',
        countryName: 'Ireland'
    },
    {
        code: '972',
        isoCode: 'IL',
        countryName: 'Israel'
    },
    {
        code: '39',
        isoCode: 'IT',
        countryName: 'Italy'
    },
    {
        code: '225',
        isoCode: 'CI',
        countryName: 'Ivory Coast'
    },
    {
        code: '1876',
        isoCode: 'JM',
        countryName: 'Jamaica'
    },
    {
        code: '81',
        isoCode: 'JP',
        countryName: 'Japan'
    },
    {
        code: '962',
        isoCode: 'JO',
        countryName: 'Jordan'
    },
    {
        code: '7',
        isoCode: 'KZ',
        countryName: 'Kazakhstan'
    },
    {
        code: '254',
        isoCode: 'KE',
        countryName: 'Kenya'
    },
    {
        code: '686',
        isoCode: 'KI',
        countryName: 'Kiribati'
    },
    {
        code: '965',
        isoCode: 'KW',
        countryName: 'Kuwait'
    },
    {
        code: '996',
        isoCode: 'KG',
        countryName: 'Kyrgyzstan'
    },
    {
        code: '856',
        isoCode: 'LA',
        countryName: 'Laos'
    },
    {
        code: '371',
        isoCode: 'LV',
        countryName: 'Latvia'
    },
    {
        code: '961',
        isoCode: 'LB',
        countryName: 'Lebanon'
    },
    {
        code: '266',
        isoCode: 'LS',
        countryName: 'Lesotho'
    },
    {
        code: '231',
        isoCode: 'LR',
        countryName: 'Liberia'
    },
    {
        code: '218',
        isoCode: 'LY',
        countryName: 'Libya'
    },
    {
        code: '423',
        isoCode: 'LI',
        countryName: 'Liechtenstein'
    },
    {
        code: '370',
        isoCode: 'LT',
        countryName: 'Lithuania'
    },
    {
        code: '352',
        isoCode: 'LU',
        countryName: 'Luxembourg'
    },
    {
        code: '853',
        isoCode: 'MO',
        countryName: 'Macao China'
    },
    {
        code: '389',
        isoCode: 'MK',
        countryName: 'Macedonia'
    },
    {
        code: '261',
        isoCode: 'MG',
        countryName: 'Madagascar'
    },
    {
        code: '265',
        isoCode: 'MW',
        countryName: 'Malawi'
    },
    {
        code: '60',
        isoCode: 'MY',
        countryName: 'Malaysia'
    },
    {
        code: '960',
        isoCode: 'MV',
        countryName: 'Maldives'
    },
    {
        code: '223',
        isoCode: 'ML',
        countryName: 'Mali'
    },
    {
        code: '356',
        isoCode: 'MT',
        countryName: 'Malta'
    },
    {
        code: '596',
        isoCode: 'MQ',
        countryName: 'Martinique'
    },
    {
        code: '222',
        isoCode: 'MR',
        countryName: 'Mauritania'
    },
    {
        code: '230',
        isoCode: 'MU',
        countryName: 'Mauritius'
    },
    {
        code: '52',
        isoCode: 'MX',
        countryName: 'Mexico'
    },
    {
        code: '691',
        isoCode: 'FM',
        countryName: 'Micronesia'
    },
    {
        code: '373',
        isoCode: 'MD',
        countryName: 'Moldova'
    },
    {
        code: '377',
        isoCode: 'MC',
        countryName: 'Monaco'
    },
    {
        code: '976',
        isoCode: 'MN',
        countryName: 'Mongolia'
    },
    {
        code: '382',
        isoCode: 'ME',
        countryName: 'Montenegro'
    },
    {
        code: '1664',
        isoCode: 'MS',
        countryName: 'Montserrat'
    },
    {
        code: '212',
        isoCode: 'MA',
        countryName: 'Morocco'
    },
    {
        code: '258',
        isoCode: 'MZ',
        countryName: 'Mozambique'
    },
    {
        code: '264',
        isoCode: 'NA',
        countryName: 'Namibia'
    },
    {
        code: '977',
        isoCode: 'NP',
        countryName: 'Nepal'
    },
    {
        code: '31',
        isoCode: 'NL',
        countryName: 'Netherlands'
    },
    {
        code: '599',
        isoCode: 'AN',
        countryName: 'Netherlands Antilles'
    },
    {
        code: '687',
        isoCode: 'NC',
        countryName: 'New Caledonia'
    },
    {
        code: '64',
        isoCode: 'NZ',
        countryName: 'New Zealand'
    },
    {
        code: '505',
        isoCode: 'NI',
        countryName: 'Nicaragua'
    },
    {
        code: '227',
        isoCode: 'NE',
        countryName: 'Niger'
    },
    {
        code: '234',
        isoCode: 'NG',
        countryName: 'Nigeria'
    },
    {
        code: '683',
        isoCode: 'NU',
        countryName: 'Niue'
    },
    {
        code: '850',
        isoCode: 'KP',
        countryName: 'North Korea'
    },
    {
        code: '47',
        isoCode: 'NO',
        countryName: 'Norway'
    },
    {
        code: '968',
        isoCode: 'OM',
        countryName: 'Oman'
    },
    {
        code: '92',
        isoCode: 'PK',
        countryName: 'Pakistan'
    },
    {
        code: '970',
        isoCode: 'PS',
        countryName: 'Palestinian Territory'
    },
    {
        code: '507',
        isoCode: 'PA',
        countryName: 'Panama'
    },
    {
        code: '675',
        isoCode: 'PG',
        countryName: 'Papua New Guinea'
    },
    {
        code: '595',
        isoCode: 'PY',
        countryName: 'Paraguay'
    },
    {
        code: '51',
        isoCode: 'PE',
        countryName: 'Peru'
    },
    {
        code: '63',
        isoCode: 'PH',
        countryName: 'Philippines'
    },
    {
        code: '48',
        isoCode: 'PL',
        countryName: 'Poland'
    },
    {
        code: '351',
        isoCode: 'PT',
        countryName: 'Portugal'
    },
    {
        code: '1787',
        isoCode: 'PR',
        countryName: 'Puerto Rico'
    },
    {
        code: '974',
        isoCode: 'QA',
        countryName: 'Qatar'
    },
    {
        code: '680',
        isoCode: 'PW',
        countryName: 'Republic of Palau'
    },
    {
        code: '211',
        isoCode: 'SS',
        countryName: 'Republic of South Sudan'
    },
    {
        code: '262',
        isoCode: 'RE',
        countryName: 'Reunion'
    },
    {
        code: '40',
        isoCode: 'RO',
        countryName: 'Romania'
    },
    {
        code: '79',
        isoCode: 'RU',
        countryName: 'Russian Federation'
    },
    {
        code: '250',
        isoCode: 'RW',
        countryName: 'Rwanda'
    },
    {
        code: '1869',
        isoCode: 'KN',
        countryName: 'Saint Kitts and Nevis'
    },
    {
        code: '1758',
        isoCode: 'LC',
        countryName: 'Saint Lucia'
    },
    {
        code: '508',
        isoCode: 'PM',
        countryName: 'Saint Pierre and Miquelon'
    },
    {
        code: '1784',
        isoCode: 'VC',
        countryName: 'Saint Vincent and the Grenadines'
    },
    {
        code: '685',
        isoCode: 'WS',
        countryName: 'Samoa'
    },
    {
        code: '378',
        isoCode: 'SM',
        countryName: 'San Marino'
    },
    {
        code: '239',
        isoCode: 'ST',
        countryName: 'Sao Tome and Principe'
    },
    {
        code: '966',
        isoCode: 'SA',
        countryName: 'Saudi Arabia'
    },
    {
        code: '221',
        isoCode: 'SN',
        countryName: 'Senegal'
    },
    {
        code: '381',
        isoCode: 'RS',
        countryName: 'Serbia'
    },
    {
        code: '248',
        isoCode: 'SC',
        countryName: 'Seychelles'
    },
    {
        code: '232',
        isoCode: 'SL',
        countryName: 'Sierra Leone'
    },
    {
        code: '65',
        isoCode: 'SG',
        countryName: 'Singapore'
    },
    {
        code: '421',
        isoCode: 'SK',
        countryName: 'Slovakia'
    },
    {
        code: '386',
        isoCode: 'SI',
        countryName: 'Slovenia'
    },
    {
        code: '677',
        isoCode: 'SB',
        countryName: 'Solomon Islands'
    },
    {
        code: '252',
        isoCode: 'SO',
        countryName: 'Somalia'
    },
    {
        code: '27',
        isoCode: 'ZA',
        countryName: 'South Africa'
    },
    {
        code: '82',
        isoCode: 'KR',
        countryName: 'South Korea'
    },
    {
        code: '34',
        isoCode: 'ES',
        countryName: 'Spain'
    },
    {
        code: '94',
        isoCode: 'LK',
        countryName: 'Sri Lanka'
    },
    {
        code: '249',
        isoCode: 'SD',
        countryName: 'Sudan'
    },
    {
        code: '597',
        isoCode: 'SR',
        countryName: 'Suriname'
    },
    {
        code: '268',
        isoCode: 'SZ',
        countryName: 'Swaziland'
    },
    {
        code: '46',
        isoCode: 'SE',
        countryName: 'Sweden'
    },
    {
        code: '41',
        isoCode: 'CH',
        countryName: 'Switzerland'
    },
    {
        code: '963',
        isoCode: 'SY',
        countryName: 'Syrian Arab Republic'
    },
    {
        code: '992',
        isoCode: 'TK',
        countryName: 'Tajikistan'
    },
    {
        code: '255',
        isoCode: 'TZ',
        countryName: 'Tanzania'
    },
    {
        code: '66',
        isoCode: 'TH',
        countryName: 'Thailand'
    },
    {
        code: '670',
        isoCode: 'TP',
        countryName: 'Timor-Leste'
    },
    {
        code: '228',
        isoCode: 'TG',
        countryName: 'Togo'
    },
    {
        code: '676',
        isoCode: 'TO',
        countryName: 'Tonga'
    },
    {
        code: '1868',
        isoCode: 'TT',
        countryName: 'Trinidad and Tobago'
    },
    {
        code: '216',
        isoCode: 'TN',
        countryName: 'Tunisia'
    },
    {
        code: '90',
        isoCode: 'TR',
        countryName: 'Turkey'
    },
    {
        code: '993',
        isoCode: 'TM',
        countryName: 'Turkmenistan'
    },
    {
        code: '1649',
        isoCode: 'TC',
        countryName: 'Turks and Caicos Islands'
    },
    {
        code: '688',
        isoCode: 'TV',
        countryName: 'Tuvalu'
    },
    {
        code: '256',
        isoCode: 'UG',
        countryName: 'Uganda'
    },
    {
        code: '380',
        isoCode: 'UA',
        countryName: 'Ukraine'
    },
    {
        code: '971',
        isoCode: 'AE',
        countryName: 'United Arab Emirates'
    },
    {
        code: '44',
        isoCode: 'GB',
        countryName: 'United Kingdom'
    },
    {
        code: '1',
        isoCode: 'US',
        countryName: 'United States of America'
    },
    {
        code: '598',
        isoCode: 'UY',
        countryName: 'Uruguay'
    },
    {
        code: '998',
        isoCode: 'UZ',
        countryName: 'Uzbekistan'
    },
    {
        code: '678',
        isoCode: 'VU',
        countryName: 'Vanuatu'
    },
    {
        code: '58',
        isoCode: 'VE',
        countryName: 'Venezuela'
    },
    {
        code: '84',
        isoCode: 'VN',
        countryName: 'Vietnam'
    },
    {
        code: '1340',
        isoCode: 'VI',
        countryName: 'Virgin Islands of the United States'
    },
    {
        code: '967',
        isoCode: 'YE',
        countryName: 'Yemen'
    },
    {
        code: '260',
        isoCode: 'ZM',
        countryName: 'Zambia'
    },
    {
        code: '263',
        isoCode: 'ZW',
        countryName: 'Zimbabwe'
    }
];
