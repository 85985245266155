import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { AppMaterialModule } from 'src/app/app.material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReceiptService } from 'src/app/core/services/receipt.service';
import { DocumentHelperService } from 'src/app/core/services/document.helper.service';
import { ManageFxTransactionComponent } from './manage-fx-transaction.component';
import { routes } from './manage-fx-transaction.routes';
import { InitateFxTransactionComponent } from './initate-fx-transaction/initate-fx-transaction.component';
import { FxTransactionHistoryComponent } from './fx-transaction-history/fx-transaction-history.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
    declarations: [
        ManageFxTransactionComponent,
       // InitateFxTransactionComponent,
        FxTransactionHistoryComponent
    ],
    imports: [CommonModule, AppMaterialModule, FormsModule, ReactiveFormsModule,SharedModule, routes],
    exports: [
        ManageFxTransactionComponent,
        //InitateFxTransactionComponent,
        FxTransactionHistoryComponent      
    ],
    providers: [
        DocumentHelperService,
        DecimalPipe,
        DatePipe
    ]
})
export class ManageFxTransactionModule { }
