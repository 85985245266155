export const _mocktransferData = {
    'oneoff': [
        {
            referenceId: '00000000000',
            transactionType: 'OneOff',
            status: 'Pending',
            requestedBy: 'Requestor with Initiator Role',
            frequency: 'Once',
            transactionDate: '2018-12-18T12:34:01+01:00',
            totalAmount: 50000
        },
        {
            referenceId: '00000000001',
            transactionType: 'Self',
            status: 'Pending',
            requestedBy: 'Requestor',
            frequency: 'Once',
            transactionDate: '2018-12-18T12:34:01+01:00',
            totalAmount: 1050000
        }
    ]
};

export const _mockPaymentApprovalHistory = [
        {
            user: 'AYODEJIOLAOYE',
            action: 'Initiated',
            date : '2019-01-23T17:34:01+01:00'
        },
        {
            user: 'KOLAWOLE',
            action: 'Approved',
            date : '2019-01-24T08:45:01+01:00'
        },
        {
            user: 'BASSEY',
            action: 'Approved',
            date : '2019-01-24T11:30:01+01:00'
        }
];
