
export enum ResponseCodes {
    SUCCESS = '00',
    LoginSuccessWithLimitedAccess = '02',
    LoginSuccessWithLimitedAccessUnderReview = "11",
    QuestionAndPasswordRegistrationRequired = '04',
    PasswordChangeRequired = '05',
    TechnicalError = "99",
    TransferDynamicRouteFailure = "70",
    FailedSessionOnValidateOTP = '70',
    ResetSessionOnLogin = '800',
    IntellinxChallengeCode = '801',
    SuccessCreditCardAction = '000',
    FailedCreditCardAction = '999',
    TimeoutCreditCard = '202'

}

export enum OtpReasonCodes {
    CHANGE_PASSWORD = '04',
    LOGIN = '02',
    RESET_PASSWORD = '04',
    ADD_BENEFICIARY = '06',
    ADD_BILLER = '06',
    REMOVE_BENEFICIARY = '08',
    EDIT_BENEFICIARY = '07',
    PAY_BILLER = '09',
    PURCHASE_AIRTIME = '10',
    TRANSFER_FUND = '05',
    BUY_AIRTIME = '10',
    GENERIC = '20'

}

export enum ApprovalRequestStatus {
    PendingForMyApproval = 2,
    WaitingForCoporateApproval = 4,
    PendingForBankApproval = 8
}



export enum Role {
    Initiator = 2,
    Viewer = 4,
    Approver = 8,
    Retail = 0
}




export enum SecurityQuestionCreationRequestAuthor {
    RetailLogin = 1,
    RetailUserCreation = 2
}

export enum OTPType {
    NONE = 0,
    OTP = 1,
    HWT = 2
}
