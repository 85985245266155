import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { VariablesService } from 'src/app/core/services/variables.service';
import { CustomerAccount } from 'src/app/core/models/account';

@Component({
  selector: 'app-AcceptCondition-Modal',
  templateUrl: './AcceptCondition-Modal.component.html',
  styleUrls: ['./AcceptCondition-Modal.component.css']
})
export class AcceptConditionModalComponent implements OnInit  {
  accountForm:FormGroup;
  accountNumber: string;
  accountNumbers: CustomerAccount[];
  buttondisabler: boolean;

  constructor(
    public dialogRef: MatDialogRef<AcceptConditionModalComponent>,private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {

    }
  ngOnInit() {

  }

  onNoClick(): void {
    this.data.proceed = this.data.proceed;
    this.dialogRef.close();
  }


  datasetter(){
    this.data.proceed = !this.data.proceed;
  }


}

export interface DialogData{

  proceed:boolean
  account:string
}
