import { Component, OnInit, AfterViewInit } from '@angular/core';
import { EventHandlerService } from 'src/app/core/services/EventHandlerService';

@Component({
  selector: 'app-approvals',
  templateUrl: './approvals.component.html',
  styleUrls: ['./approvals.component.css']
})
export class ApprovalsComponent implements OnInit, AfterViewInit {

  public _activeTab: string;
  loaderIsActive: boolean;
  constructor() { 
    try {
      window['casa']('add_payee'); 
     } catch (error) {}
  }

  ngOnInit() {
    EventHandlerService.emitOnAppViewChangedEvent();
    if (!this._activeTab) {
      this._activeTab = 'payments';
    }
    this.openTab(null, this._activeTab);
  }

  ngAfterViewInit(): void {
  }

  openTab(evt, tabName) {
    let i, tabcontent;
    tabcontent = document.getElementsByClassName('tabcontent');
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = 'none';
    }
    this._activeTab = tabName;
    document.getElementById(tabName).style.display = 'block';
  }

  onNavigationOptionChange(evtData) {
    this._activeTab = evtData.value as string;
    this.openTab('', this._activeTab);
  }
}
