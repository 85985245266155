import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { StorageService } from "./storage.service";
import { Constants } from "../helpers/appConstants";
import { ApiURLConstants } from "../helpers/apiURLConstants";

import {
  TransactionListRequestPayload,
  TransactionListRequestResponse,
  TransactionDetailsRequestResponse,
  CorporateTransactionRequest,
} from "../models/payloads/transactions";

import { TransactionModel } from "../models/transactions/transaction";

import { AppConfigService } from "./appconfig.service";
import { Observable, of } from "../../../../node_modules/rxjs";
import { GenericApiResponse } from "../models/payloads/generic-response";
import {
  LoanLimitRequestResponse,
  LoanLimitCheckRequest,
} from "../models/loan.request.model";
import { BranchListResponse } from "../models/service-request/branch";

@Injectable()
export class TransactionService {
  private _apiBaseUrl: string;
  private _transactionsAPIBaseUrl: string;
  _apiBaseUrl2: string;
  _transactionsAPIBaseUrl2: string;

  constructor(
    private _settings: AppConfigService,
    private _httpClient: HttpClient,
    private _storageService: StorageService
  ) {
    this._apiBaseUrl = _settings.APIBaseUrl;
    this._apiBaseUrl2 = _settings.APIBaseUrl2;
    this._transactionsAPIBaseUrl = `${this._apiBaseUrl}/TransactionManagement`;
    this._transactionsAPIBaseUrl2 = `${this._apiBaseUrl2}/TransactionManagement`;
  }

  loadTransactionsForAccount(
    payload: TransactionListRequestPayload
  ): Observable<TransactionListRequestResponse> {
    const requestPayload = this.buildTransactionsFetchPayload(payload.AccountNumber, payload.TransactionCount);

    return this._httpClient.post<TransactionListRequestResponse>(
      `${this._apiBaseUrl}/${ApiURLConstants.CustomerAccountTransactionsByCount}`,
      payload
    );
    // return of({
    //   ResponseFriendlyMessage: 'sucess',
    //   ResponseDescription: 'success',
    //   ResponseCode: '00',
    //   Transactions: [],
    //   TransferHistoryLists:[]
    // })
  }

  loadAccountTransactionsByDate(
    payload: TransactionListRequestPayload
  ): Observable<TransactionDetailsRequestResponse> {
    // const requestPayload = this.buildTransactionsSearchPayload(accountNumber, startDate, endDate);
    return this._httpClient.post<TransactionDetailsRequestResponse>(
      `${this._apiBaseUrl}/${ApiURLConstants.CustomerAccountTransactionsByDate}`,
      payload
    );
  }

  loadCorporateTransactions(
    payload: CorporateTransactionRequest
  ): Observable<TransactionDetailsRequestResponse> {
    // const requestPayload = this.buildTransactionsSearchPayload(accountNumber, startDate, endDate);
    return this._httpClient.post<TransactionDetailsRequestResponse>(
      `${this._apiBaseUrl}/${ApiURLConstants.CorporateStaement}`,
      payload
    );
  }

  requestCorporateTransaction(
    payload: CorporateTransactionRequest
  ): Observable<GenericApiResponse> {
    // const requestPayload = this.buildTransactionsSearchPayload(accountNumber, startDate, endDate);
    return this._httpClient.post<GenericApiResponse>(
      `${this._apiBaseUrl}/${ApiURLConstants.RequestCorporateStaement}`,
      payload
    );
  }

  loadSalaryAdvanceLimit(
    payload: LoanLimitCheckRequest
  ): Observable<LoanLimitRequestResponse> {
    return this._httpClient.post<LoanLimitRequestResponse>(
      `${this._apiBaseUrl}/${ApiURLConstants.INSTANT_CASH_ADVANCE_LIMIT}`,
      payload
    );
  }

  loadUplLoanLimit(
    payload: LoanLimitCheckRequest
  ): Observable<LoanLimitRequestResponse> {
    return this._httpClient.post<LoanLimitRequestResponse>(
      `${this._apiBaseUrl}/${ApiURLConstants.INSTANT_UPL_LOAN_LIMIT}`,
      payload
    );
  }

  saveTransaction(transaction: TransactionModel): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const result = this._storageService.add<TransactionModel>(
        transaction,
        Constants.Store_trans
      );
      if (result) {
        resolve(result);
      } else {
        reject("failed to save transaction");
      }
    });
  }

  getTransactions(): Promise<TransactionModel[]> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(
          this._storageService.getAll<TransactionModel>(Constants.Store_trans)
        );
      }, 500);
    });
  }

  removeTransaction(transactionId: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      resolve(
        this._storageService.removeItem<TransactionModel>(
          Constants.Store_trans,
          "transactionId",
          transactionId
        )
      );
    });
  }

  private buildTransactionsFetchPayload(
    accountNumber: string,
    transactionCount
  ) {
    const userId = !this._storageService.find(Constants.User_Id)
      ? ""
      : this._storageService.find(Constants.User_Id);
    return {
      AccountNumber: accountNumber,
      TransactionCount: transactionCount,
      TransactionOwner: 0,
      UserId: userId,
    };
  }

  private buildTransactionsSearchPayload(accountNumber, startDate, endDate) {
    let userAuthData = this._storageService.find(Constants.Auth_Data);
    userAuthData = userAuthData ? JSON.parse(userAuthData) : userAuthData;
    const userId = !this._storageService.find(Constants.User_Id)
      ? ""
      : this._storageService.find(Constants.User_Id);
    return {
      AccountNumber: accountNumber,
      EndDate: endDate,
      StartDate: startDate,
      SessionId:
        userAuthData && userAuthData["SessionId"]
          ? userAuthData["SessionId"]
          : "",
      UserId: userId,
    };
  }

  loadBranchList(userId: string): Observable<BranchListResponse> {
    // return of({
    //   ResponseCode: '00',
    //   ResponseDescription: 'Success',
    //   ResponseFriendlyMessage: 'Successful response',
    //   solIds: [{
    //     branchState: 'string',
    //     branchRegion: 'string',
    //     branchId: 'string',
    //     branchName: 'string'
    //   }]
    // });
    return this._httpClient.post<BranchListResponse>(
      `${this._apiBaseUrl}/AccountManagement/GetBranchList`,
      // https://devtest.stanbicibtc.com:7443/internet-banking/api/AccountManagement/GetBranchList
      { UserId: userId }
    );
  }


}
