import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../appconfig.service';
import { AuthService } from '../auth.service';
import { GenericApiResponse } from '../../models/payloads/generic-response';
import { BeneficiaryWorkflowSelectionRequestPayload } from '../../models/payloads/beneficiaries';
import { ApiURLConstants } from '../../helpers/apiURLConstants';
import { StagedBeneficiaryRequestReponse } from '../../models/beneficiary';
import { Role } from '../../models/user/AppUser';

@Injectable()
export class BeneficiaryStagingService {

    private _apiBaseUrl: string;
    constructor(private _httpClient: HttpClient, private _settings: AppConfigService, private _authService: AuthService) {
        this._apiBaseUrl = _settings.APIBaseUrl;
    }

    setBeneficiaryCreateApproval(payload: BeneficiaryWorkflowSelectionRequestPayload): Promise<GenericApiResponse> {
        payload = this.updateApprovalWorkflowRequestPayload(payload);
        return this._httpClient.post<GenericApiResponse>(
            `${this._apiBaseUrl}/${ApiURLConstants.SET_BENEFICIARY_CREATE_APPROVAL_WORKFLOW}`, payload)
            .toPromise();
    }

    getStagedBeneficiaryRequests(payload): Promise<StagedBeneficiaryRequestReponse> {
        const loggedInUser = this._authService.getLoggedInUser();
        payload['UserId'] = loggedInUser && loggedInUser.UserId ? loggedInUser.UserId : '';
        if (loggedInUser && loggedInUser.Role && loggedInUser.Role.roles && loggedInUser.Role.roles.includes(Role.Initiator)) {
            payload['ForInitiator'] = true;
        }
        return this._httpClient.post<StagedBeneficiaryRequestReponse>
            (`${this._apiBaseUrl}/${ApiURLConstants.GET_STAGED_BENEFICIARY_REQUEST}`, payload).toPromise();
    }
    getStagedBeneficiaryUpdateRequests(payload): Promise<StagedBeneficiaryRequestReponse> {
        const loggedInUser = this._authService.getLoggedInUser();
        payload['UserId'] = loggedInUser && loggedInUser.UserId ? loggedInUser.UserId : '';
        if (loggedInUser && loggedInUser.Role && loggedInUser.Role.roles && loggedInUser.Role.roles.includes(Role.Initiator)) {
            payload['ForInitiator'] = true;
        }
        return this._httpClient.post<StagedBeneficiaryRequestReponse>
            (`${this._apiBaseUrl}/${ApiURLConstants.GET_STAGED_BENEFICIARY_UPDATE_REQUEST}`, payload).toPromise();
    }
    setBeneficiaryMgtOperationApprovalWorkflow(payload): Promise<StagedBeneficiaryRequestReponse> {
        payload['UserId'] = this._authService.getLoggedInUser() ? this._authService.getLoggedInUser().UserId : '';
        return this._httpClient.post<StagedBeneficiaryRequestReponse>
            (`${this._apiBaseUrl}/${ApiURLConstants.SET_BENEFICIARY_UPDATE_APPROVAL_WORKFLOW}`, payload).toPromise();
    }

    private updateApprovalWorkflowRequestPayload(payload: any): any {
        payload.UserId = this._authService.getLoggedInUser() ? this._authService.getLoggedInUser().UserId : '';
        return payload;
    }

}
