import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {VirtualCardComponent} from './virtual-card.component';
import {VirtualCardManagementComponent} from './virtual-card-management/virtual-card-management.component';
import {VirtualCardRequestComponent} from './virtual-card-request/virtual-card-request.component';
import {RetrievePinComponent} from './virtual-card-management/retrieve-pin/retrieve-pin.component';
import { HotlistCardComponent } from './virtual-card-management/hotlist-card/hotlist-card.component';

export const VirtualCardRoutingModule = RouterModule.forChild([
  {
    path: '',
    component: VirtualCardComponent,
    children: [
      {
        path: 'card-management',
        component: VirtualCardManagementComponent,
      },
      {
        path: 'card-management/retrieve-pin',
        component: RetrievePinComponent,
      },
      {
        path: 'card-management/hotlist-card',
        component: HotlistCardComponent,
      },
      {
        path: 'request',
        component: VirtualCardRequestComponent,
      }
    ]
  }
]);
//
// const routes: Routes = [
//   {
//     path: '',
//     component: BentoComponent,
//   },
// ];
//
// @NgModule({
//   imports: [RouterModule.forChild(routes)],
//   exports: [RouterModule]
// })
// export class BentoRoutingModule { }
