import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { BasePendingRequestComponent } from '../base.pending-request.component';
import { AuthService } from 'src/app/core/services/auth.service';
import { MiscService } from 'src/app/core/services/misc.service';
import { MatSnackBar, MatDialog } from '@angular/material';
import { PendingBillPaymentRequestObject, PendingBillerOperationRequestObject } from 'src/app/core/models/pending-request.models';
import { BasePagedRequestResponse, RequestApprovalHistoryPayload } from 'src/app/core/models/misc-requests-model';
import { ResponseCodes } from 'src/app/core/models/Enumerations';

@Component({
  selector: 'app-pending-biller-mgt-request',
  templateUrl: './pending-biller-mgt-request.component.html',
  styleUrls: ['./pending-biller-mgt-request.component.css']
})
export class PendingBillerMgtRequestComponent extends BasePendingRequestComponent implements OnInit, OnChanges {


  @Input() _activeTab: string;
  requestList: Array<PendingBillerOperationRequestObject> = [];
  requestListBackup: Array<PendingBillerOperationRequestObject> = [];
  _pageSize = 5;
  _requestErrorMessage: string;

  constructor(authService: AuthService, private _miscService: MiscService, private _snackbar: MatSnackBar, _dialog: MatDialog) {
    super(authService, _dialog);
   }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['_activeTab']) {
      this._activeTab = changes['_activeTab'].currentValue;
      if (this._activeTab === 'billers') {
        this.loadPendingBillerOperationRequests();
      }
    }
  }

  loadPendingBillerOperationRequests(loaderMsg = 'fetching pending biller operation requests. please wait ..',
    lastFetchId = 0, pageSize = 100) {
    this.ownLoaderIsActive = true;
    this.ownloaderMsg = loaderMsg;
    this._requestErrorMessage = !this._requestErrorMessage ? this._requestErrorMessage : '';
    const payload = { PageSize: pageSize, LastFetchId: lastFetchId, OperationType: 'BILLER_BENEFICIARY_OPERATION' };
    this._miscService.getPendingRequest<BasePagedRequestResponse<PendingBillerOperationRequestObject>>(payload).then(
      (response) => {
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        if (!response || response.ResponseCode !== ResponseCodes.SUCCESS || !response.PaginatedItems) {
          this._snackbar.open(`${response.ResponseFriendlyMessage}`, 'Ok', { duration: 25000 });
          this._requestErrorMessage = response && response.ResponseFriendlyMessage ? response.ResponseFriendlyMessage :
            'We encountered an unknown error while loading loading pending biller requests';
          return;
        }
        this.requestListBackup = response.PaginatedItems;
        this.requestListBackup.forEach( r => r['operationTypeText'] = this.getOperationType(r.operationType));
        this.requestList = this.requestListBackup.slice(0, pageSize);
      },
      (error) => {
        console.log(error);
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        this._requestErrorMessage = 'We encountered an unknown error while loading your pending biller requests. Please retry';
        this._snackbar.open(`We encountered an error while loading pending biller request`, 'Ok', { duration: 25000 });
      }

    );
  }

  onRequestAction(action: string, data: PendingBillerOperationRequestObject) {
    switch (action) {
      case 'view-history': {
        const requestPayload: RequestApprovalHistoryPayload = this.buildRequestApprovalHistoryPayload(data, data.operationType);
        const approvalHistoryModal = this.launchRequestHistoryModal(requestPayload,
          'biller-management', 'Biller Operation Approval History');
        approvalHistoryModal.afterClosed().subscribe((result) => {});
        break;
      }
    }
  }

  private getOperationType(typeText: string) {
    switch (typeText) {
      case 'EDIT_BILLER_BENEFICIARY':
        return 'EDIT-BILLER';
      case 'ADD_BILLER_BENEFICIARY':
        return 'ADD-BILLER';
      case 'REMOVE_BILLER_BENEFICIARY':
        return 'REMOVE-BILLER';
      default:
        return 'N/A';
    }
  }

}
