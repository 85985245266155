import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { BasePendingRequestComponent } from '../base.pending-request.component';
import { PendingBillPaymentRequestObject, PendingBeneficiaryOperationRequestObject } from 'src/app/core/models/pending-request.models';
import { AuthService } from 'src/app/core/services/auth.service';
import { MiscService } from 'src/app/core/services/misc.service';
import { MatSnackBar, MatDialog } from '@angular/material';
import { BasePagedRequestResponse, RequestApprovalHistoryPayload } from 'src/app/core/models/misc-requests-model';
import { ResponseCodes } from 'src/app/core/models/Enumerations';

@Component({
  selector: 'app-pending-beneficiary-mgt-request',
  templateUrl: './pending-beneficiary-mgt-request.component.html',
  styleUrls: ['./pending-beneficiary-mgt-request.component.css']
})
export class PendingBeneficiaryMgtRequestComponent extends BasePendingRequestComponent implements OnInit, OnChanges {

  @Input() _activeTab: string;
  requestList: Array<PendingBeneficiaryOperationRequestObject> = [];
  requestListBackup: Array<PendingBeneficiaryOperationRequestObject> = [];
  _pageSize = 5;
  _requestErrorMessage: string;

  constructor(authService: AuthService, private _miscService: MiscService, private _snackbar: MatSnackBar, _dialog: MatDialog) {
    super(authService, _dialog);
   }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['_activeTab']) {
      this._activeTab = changes['_activeTab'].currentValue;
      if (this._activeTab === 'beneficiaries') {
        this.loadPendingBeneficiaryMgtRequests();
      }
    }
  }

  loadPendingBeneficiaryMgtRequests(
    loaderMsg = 'fetching pending beneficiary requests. please wait ..' , lastFetchId = 0, pageSize = 100) {
    this.ownLoaderIsActive = true;
    this.ownloaderMsg = loaderMsg;
    this._requestErrorMessage = '';
    const payload = {PageSize: pageSize, LastFetchId: lastFetchId};
    this.fetchAggregatePendingBeneficiaryRequests(payload).then(
      (responseArray) => {
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        this.handlePendingBeneficiaryFetchResponse(responseArray);
      },
      (error) => {
        console.log(error);
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        this._requestErrorMessage = 'We encountered an unknown error while loading your pending beneficiary requests. Please retry';
        this._snackbar.open(`We encountered an error while loading pending beneficiary request`, 'Ok', {duration: 25000});
       }
    );
  }

  onRequestAction(action: string, data: PendingBillPaymentRequestObject) {
    switch (action) {
      case 'view-history': {
        const requestPayload: RequestApprovalHistoryPayload = this.buildRequestApprovalHistoryPayload(data, data.operationType);
        const approvalHistoryModal = this.launchRequestHistoryModal(requestPayload, 'biller-payments', 'Bill Payments Approval History');
        approvalHistoryModal.afterClosed().subscribe((result) => {});
        break;
      }
    }
  }

  fetchAggregatePendingBeneficiaryRequests(payload) {
      const pendinAddReqs = this._miscService.getPendingRequest<BasePagedRequestResponse<PendingBeneficiaryOperationRequestObject>>(
        this.buildPendingRequestPayload('ADD_BENEFICIARY', payload)).catch(error => {console.log(error); return null; });

      const pendingUpdateReqs = this._miscService.getPendingRequest<BasePagedRequestResponse<PendingBeneficiaryOperationRequestObject>>(
        this.buildPendingRequestPayload('EDIT_BENEFICIARY', payload)).catch(error => {console.log(error); return null; });

      const pendingRemovalReqs = this._miscService.getPendingRequest<BasePagedRequestResponse<PendingBillPaymentRequestObject>>(
        this.buildPendingRequestPayload('REMOVE_BENEFICIARY', payload)).catch(error => {console.log(error); return null; });

      return Promise.all([pendinAddReqs, pendingUpdateReqs, pendingRemovalReqs]);
  }

  private handlePendingBeneficiaryFetchResponse(responseArray: Array<any>) {
    this.requestListBackup = [];
    responseArray.forEach((response, index) => {
      if (response && response.ResponseCode && response.ResponseCode === '00' && response.PaginatedItems) {
        if (index === 0) { response.PaginatedItems.forEach(obj => obj['operationType'] = 'ADD_BENEFICIARY');
        this.requestListBackup = this.requestListBackup.concat(response.PaginatedItems);
      }}
    });
    this.requestListBackup.forEach(req => req['requestType'] = this.getOperationName(req['operationType']));
    this.requestList = this.requestListBackup.slice(0, this._pageSize);
    console.log(this.requestListBackup);
  }
  private getOperationName(operationType) {
    switch (operationType) {
      case 'ADD_BENEFICIARY':
        return ' Addition';
      case 'EDIT_BENEFICIARY':
        return 'Update';
      case 'REMOVE_BENEFICIARY':
        return 'Removal';
    }
  }

  private buildPendingRequestPayload(operationType, payload) {
    payload['OperationType'] = operationType;
    return payload;
  }

}
