import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { BaseApprovalComponent } from '../../approvals/base.approval.component';
import { AuthService } from 'src/app/core/services/auth.service';
import { MiscService } from 'src/app/core/services/misc.service';
import { BasePagedRequestResponse, RequestApprovalHistoryPayload } from 'src/app/core/models/misc-requests-model';
import { PendingSingleTransferObject } from 'src/app/core/models/pending-request.models';
import { ResponseCodes } from 'src/app/core/models/Enumerations';
import { MatSnackBar, MatDialog, MatDialogRef, PageEvent } from '@angular/material';
import { ApprovalHistoryComponent } from 'src/app/shared/approval-history/approval-history.component';

@Component({
  selector: 'app-payment-requests',
  templateUrl: './payment-requests.component.html',
  styleUrls: ['./payment-requests.component.css']
})
export class PaymentRequestsComponent extends BaseApprovalComponent implements OnInit, OnChanges {

  @Input() _activeTab: string;
  ownLoaderIsActive: boolean;
  ownloaderMsg: string;

  requestList: Array<PendingSingleTransferObject> = [];
  requestListBackup: Array<PendingSingleTransferObject> = [];
  _requestErrorMessage: string;

  constructor(authService: AuthService, private _miscService: MiscService, private _snackbar: MatSnackBar, private _dialog: MatDialog) {
    super(authService);
   }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['_activeTab']) {
      this._activeTab = changes['_activeTab'].currentValue;
      if (this._activeTab === 'payments') {
        this.loadPendingPaymentRequests();
      }
    }
  }

  loadPendingPaymentRequests(loaderMsg = 'fetching pending payment requests .. please wait', start: number = 0, pageSize: number = 100) {
    this.ownLoaderIsActive = true;
    this.ownloaderMsg = loaderMsg;
    this._requestErrorMessage = '';
    const payload = {PageSize: pageSize, LastFetchId: start, OperationType: 'SINGLE_TRANSFER' };
    this._miscService.getPendingRequest<BasePagedRequestResponse<PendingSingleTransferObject>>(payload).then(
      (response) => {
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        if (!response || response.ResponseCode !== ResponseCodes.SUCCESS || !response.PaginatedItems) {
          this._snackbar.open(`${response.ResponseFriendlyMessage}`, 'Ok', { duration: 25000 });
          return;
        }
        this.requestListBackup = response.PaginatedItems;
        this.requestList = this.requestListBackup.slice(0, 5);
      },
      (error) => {
        console.log(error);
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        this._requestErrorMessage = 'We encountered an unknown error while loading your pending payment requests. Please retry';
        this._snackbar.open(`We encountered an error while loading pending payment request`, 'Ok', { duration: 25000 });
      }
    );
  }

  onRequestAction(action: string, data: PendingSingleTransferObject) {
    switch (action) {
      case 'view-history': {
        const requestPayload: RequestApprovalHistoryPayload = this.buildRequestApprovalHistoryPayload(data);
        const approvalHistoryModal = this.launchRequestHistoryModal(requestPayload);
        approvalHistoryModal.afterClosed().subscribe(
          (result) => {}
        );
        break;
      }
    }
  }

  onTransactionsPageChanged(pageChangedEvent: PageEvent) {
    const start = pageChangedEvent.pageIndex * pageChangedEvent.pageSize;
    const end = start + pageChangedEvent.pageSize;
    this.requestList = this.requestListBackup.slice(start, end);
  }
  private buildRequestApprovalHistoryPayload(data: PendingSingleTransferObject, pageSize: number = 100, start: number = 0 ) {
    return  {
      OperationType: data.OperationType,
      OperationId: data.Id,
      UserId: '',
      PageSize: pageSize,
      LastIdFetched: start
    };
  }

  private launchRequestHistoryModal(payload: RequestApprovalHistoryPayload): MatDialogRef<ApprovalHistoryComponent, any> {
    return this._dialog.open<ApprovalHistoryComponent>(
      ApprovalHistoryComponent,
      {
        hasBackdrop: false,
        closeOnNavigation: true,
        data: {
          operationName: 'single-transfer',
          requestPayload: payload,
          title: 'Request Approval History',
          extra: {}
        }
      });
  }

}
