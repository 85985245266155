import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from './appconfig.service';
import { UnscheduledRepaymentGetBalance } from '../models/unschedulepayment/uscheduledRepaymentgetBalance';
import { GetOutStandingTenure } from '../models/unschedulepayment/unscheduledGetOuStandingTenure';
import { MakeUscheduledRepaymentRequest } from '../models/unschedulepayment/makeUnscheduleRepaymentRequest';
import { UnscheduleRepaymentResponse } from '../models/unschedulepayment/makeUscheduledRepaymentResponse';
import { InitiateOtpResponseUnscheduledRepayment } from '../models/unschedulepayment/InitiateOtpResponseUnscheduledRepayment';
import { InitiateOtpRequestUnscheduledRepayment } from '../models/unschedulepayment/InitiateOtpRequestUnscheduledRepayment';
import { ShippingBillers, ShippingBillersData } from '../models/shipping-billers';

@Injectable()
export class ShippingPaymentService {

  private _apiBaseUrl: string;

  constructor(private _httpClient: HttpClient, private _settingsService: AppConfigService) {
    this._apiBaseUrl = this._settingsService.ShippingBillersBaseUrl;
   //this._apiBaseUrl = 'https://mobile.stanbicibtc.com/BillPayments/api/MB/GetAlternateCorporateBillers'
  }
   getShippingBillers(): Observable<ShippingBillersData>{
   //z return this._httpClient.get<ShippingBillersData>(`${this._apiBaseUrl}`);
   return this._httpClient.get<ShippingBillersData>(`${this._apiBaseUrl}`);
  }

}



