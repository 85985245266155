export const _banksList = [
    
        {
          "bankId": 1,
          "bankCode": "044",
          "name": "Access Bank"
        },
        {
          "bankId": 2,
          "bankCode": "023",
          "name": "Citibank Nigeria"
        },
        {
          "bankId": 3,
          "bankCode": "063",
          "name": "Diamond Bank"
        },
        {
          "bankId": 4,
          "bankCode": "050",
          "name": "Ecobank Nigeria"
        },
        {
          "bankId": 5,
          "bankCode": "084",
          "name": "Enterprise Bank"
        },
        {
          "bankId": 6,
          "bankCode": "011",
          "name": "First Bank of Nigeria"
        },
        {
          "bankId": 7,
          "bankCode": "214",
          "name": "First City Monument Bank"
        },
        {
          "bankId": 8,
          "bankCode": "058",
          "name": "Guaranty Trust Bank"
        },
        {
          "bankId": 9,
          "bankCode": "030",
          "name": "Heritage Bank"
        },
        {
          "bankId": 10,
          "bankCode": "082",
          "name": "Keystone Bank"
        },
        {
          "bankId": 11,
          "bankCode": "014",
          "name": "MainStreet Bank"
        },
        {
          "bankId": 12,
          "bankCode": "076",
          "name": "Skye Bank"
        },
        {
          "bankId": 13,
          "bankCode": "221",
          "name": "Stanbic IBTC Bank"
        },
        {
          "bankId": 14,
          "bankCode": "068",
          "name": "Standard Chartered Bank"
        },
        {
          "bankId": 15,
          "bankCode": "232",
          "name": "Sterling Bank"
        },
        {
          "bankId": 16,
          "bankCode": "032",
          "name": "Union Bank of Nigeria"
        },
        {
          "bankId": 17,
          "bankCode": "033",
          "name": "United Bank For Africa"
        },
        {
          "bankId": 18,
          "bankCode": "215",
          "name": "Unity Bank"
        },
        {
          "bankId": 19,
          "bankCode": "035",
          "name": "Wema Bank"
        },
        {
          "bankId": 20,
          "bankCode": "057",
          "name": "Zenith Bank"
        }
];