import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as XLSX from 'xlsx';

@Injectable()
export class FileHelperService {

    constructor(private _httpClient: HttpClient) { }
    downloadFileAsBlob(url = '../../assets/', fileName = 'Bulk_Upload_Template.csv'): Promise<any> {
        // const headers = { responseType:  'blob'};
        const filePath = `${url}/${fileName}`;
        console.log(`FilePath -> ${filePath}`);
        return this._httpClient.get(filePath, { responseType: 'blob' })
            .toPromise();
    }

    parseExcelBlobFileAsArray(blob: Blob, includeAllsheets: boolean = false): Promise<Array<Array<any>>> {
        return new Promise<Array<Array<any>>>((resolve, reject) => {
            const fileReader: FileReader = new FileReader();
            fileReader.onload = (e) => {
                const array = new Uint8Array(fileReader.result as ArrayBuffer);
                const wb: XLSX.WorkBook = XLSX.read(array, { type: 'array' });
                if (!includeAllsheets) {
                    const wsname: string = wb.SheetNames[0];
                    const ws: XLSX.WorkSheet = wb.Sheets[wsname];
                    resolve(XLSX.utils.sheet_to_json(ws, { header: 1 }));
                } else {
                    const worksheetNames = wb.SheetNames;
                    const data: any[][] = [];
                    for (let i = 0; i < worksheetNames.length; i++) {
                        const workSheetData: XLSX.WorkSheet = wb.Sheets[worksheetNames[i]];
                        data.push(XLSX.utils.sheet_to_json(workSheetData, { header: 1 }));
                    }
                    resolve(data);
                }
            };
            fileReader.readAsArrayBuffer(blob);
        });
    }
}
