


import { Injectable, Output, EventEmitter } from '@angular/core';


@Injectable()
export class EventHandlerService {
    @Output() static SessionTimeoutEventPublisher = new EventEmitter<{}>();
    @Output() static OnAppViewChangedEventPublisher = new EventEmitter<{ hideAppViewChangeLoader: boolean }>();
    @Output() static InstantCashAdvanceLinkControlEventPublisher = new EventEmitter<{ showLink: boolean }>();
    @Output() static SignalRSessionLogoutEventPublisher = new EventEmitter();



    static emitSessionTimeoutEvent() {
        this.SessionTimeoutEventPublisher.emit({});
    }


    static emitOnAppViewChangedEvent(hideAppViewChangeLoader: boolean = true) {
        this.OnAppViewChangedEventPublisher.emit({ hideAppViewChangeLoader });
    }

    static emitInstantCashAdvanceLinkControlEvent(showLink: boolean) {
        this.InstantCashAdvanceLinkControlEventPublisher.emit({ showLink });
    }

    static emitSignalRSeesionLogoutEvent() {
        this.SignalRSessionLogoutEventPublisher.emit();
    }

}


