import { Injectable } from "@angular/core";
import {
  CustomerAccount,
  RetailUserEzCashLimitDetails,
} from "../models/account";
import { TransactionCharges } from "../models/transactions/transaction";
import {
  Bank,
  DailyTransferLimits,
} from "src/app/core/models/service-request/misc-models";
import {
  Beneficiary,
  BillerBeneficiary,
  Biller,
  BillerCategory,
  BillerProduct,
} from "src/app/core/models/beneficiary";
import { SecurityQuestion } from "src/app/core/models/auth";
import { CorporateUserLimit } from "../models/transfer";
import { StatementCountry } from "../models/country";
import { SurveyQuestion } from "../models/payloads/survey";
import { ShippingBillers } from "../models/shipping-billers";
import { WalletDetailsResponse } from "../models/WalletDetails";
import { CustomerTokenChannels } from '../models/CustomerTokenChannels';
import { CreditCardRecords } from '../models/credit-cards';
import { CreditCardEligibilityData, CardLimitEligibilityData } from "../models/credit-card-resp";
import { FlightCombination, FlightSearchResponse } from '../models/flight-search';
import { HotelSearchResponse, HotelList } from '../models/hotel-search';
import { SearchFlight } from '../models/payloads/search-flights';
import { SearchHotels } from '../models/payloads/search-hotels';

@Injectable()
export class VariablesService {
  constructor() { }
  public static CreditCardRecords: Array<CreditCardRecords>;
  public static SelectedCreditCard: CustomerAccount;
  public static CustomerAccounts: Array<CustomerAccount>;
  public static DefaultAccount: string;
  public static DebitCardRecords: any;
  public static SelectedDebitCard: any;
  public static STANBIC_CONTACT_EMAIL: string;
  public static TransferChannelOrder: Array<string>;
  public static TransferCharges: Array<TransactionCharges>;
  public static Banks: Array<Bank>;
  public static DailyTransferLimit: Array<DailyTransferLimits>;
  public static ReloadTransferLimit: boolean;
  public static UserAccountBeneficiaries: Array<Beneficiary>;
  public static BeneficiariesToBePaid: Array<Beneficiary>;
  public static BillerBeneficiaryToBePaid: BillerBeneficiary;
  public static UserAccountBillerBeneficiaries: Array<BillerBeneficiary>;
  public static BillerMerchants: Array<Biller>;
  public static BillerMerchantCategories: Array<BillerCategory>;
  public static BENEFICIARY_ADDED_FROM_ONE_OFF_PAYMENT: boolean;
  public static SECURITY_QUESTIONS: Array<SecurityQuestion>;
  public static SecurityQuestionCreationRequestAuthor: number = 0;
  public static UseValidateOTPPageForRIBFirstTimeLoginAfterRegistration: boolean;
  public static LoginPasswordChangedSuccessfully: boolean;
  public static SessionTimedOut: boolean;
  public static SessionInvalid: boolean;
  public static StatementAccountNumber: string;
  public static CustomerBVN: string;
  public static CustomerTokenChannels: CustomerTokenChannels;
  public static tokenApiHasBeenCalled: boolean=false;
  public static tokenApiCurrentCif: string = ''

  static RetailEzCashLimitDetails: RetailUserEzCashLimitDetails;

  static SMEUserLimit: CorporateUserLimit;

  public static SessionTimedOutSub: any;
  public static SessionTimeoutInterval: any;
  public static SessionTimeoutEventId: any;
  public static PendingNameEnquiryBatchPollingInterval: any;
  public static StatementCountries: Array<StatementCountry>;
  public static SecretQuestions: Array<string>;
  public static CustomerSecretStatus: boolean;
  public static SurveyQuestions: Array<SurveyQuestion>;
  public static ThreesBankingStatus: boolean;
  public static SelectedShippingPaymentBiller: ShippingBillers;
  public static EnairaWalletDetails: WalletDetailsResponse;

  public static CustomerHWTEnabled: boolean;
  public static CustomerTokenCollectionBranch:string;
  public static CreditCardEligibityData:CreditCardEligibilityData;
  public static CreditLimitIncreaseData:CardLimitEligibilityData;
  // public static FxTypes: any[];
  // public static FxBranches: any[];
  // public static FxTranTypes: any[];
  public static FlightSearchResponse: Array<FlightCombination>;
  public static SearchWakanowFlightPayload: SearchFlight;
  public static SelectedFlightDetail:any;
  public static ChooseFlightDetail:any;
  public static ChooseHotelDetail:any;

  public static SearchWakanowHotelPayload:SearchHotels;
  public static HotelSearchResponse:HotelSearchResponse;
  public static SelectedHotelDetail: any;
}

@Injectable({ providedIn: "root" })
export class VariableSingletonService {
  private _instance: VariableSingletonService;

  constructor() {
    //this._instance = this._instance != null ? this._instance : new VariableSingletonService();
  }

  private _FxType: any[];

  set FxType(data: any[]) {
    this._FxType = data
  }
  get FxType() {
    return this._FxType
  }

  private _fxBranch: any[];
  set FxBranch(data: any[]) {
    this._fxBranch = data;
  }

  private _tokenApiCurrentCif: string;

  get tokenApiCurrentCif() {
    return this._tokenApiCurrentCif;
  }

  set tokenApiCurrentCif(value) {
    this._tokenApiCurrentCif = value;
  }

  get FxBranch() {
    return this._fxBranch
  }
  private _FxTranType: any[];
  set FxTranType(data: any[]) {
    this._FxTranType = data
  }

  get FxTranType() {
    return this._FxTranType
  }

  public getInstance(): VariableSingletonService {
    return new VariableSingletonService();
  }

  private _customerAccounts: Array<CustomerAccount> = [];
  set CustomerAccounts(accounts: Array<CustomerAccount>) {
    this._customerAccounts = accounts;
  }


  get CustomerAccounts(): Array<CustomerAccount> {
    return this._customerAccounts;
  }

  private secretQuestions: Array<string> = [];
  set SecretQuestions(questions: Array<string>) {
    this.secretQuestions = questions;
  }
  get SecretQuestions(): Array<string> {
    return this.secretQuestions;
  }
  private status: boolean;
  set CustomerSecretStatus(status:boolean) {
    this.status = status;
  }
  get CustomerSecretStatus(): boolean {
    return this.status;
  }
  private _defaultAccount: string;
  set DefaultAccount(account: string) {
    this._defaultAccount = account;
  }
  get DefaultAccount(): string {
    return this._defaultAccount;
  }

  private _stanbicContactEmail: string;
  set StanbicContactEmail(stanbicEmail: string) {
    this._stanbicContactEmail = stanbicEmail;
  }
  get StanbicContactEmail(): string {
    return this._stanbicContactEmail;
  }

  private _transferChannelOrder: Array<string> = [];
  set TransferChannelOrder(transferOrder: Array<string>) {
    this._transferChannelOrder = transferOrder;
  }
  get TransferChannelOrder(): Array<string> {
    return this._transferChannelOrder;
  }

  private _transferCharges: Array<TransactionCharges> = [];
  set TransferCharges(transferCharges: Array<TransactionCharges>) {
    this._transferCharges = transferCharges;
  }
  get TransferCharges(): Array<TransactionCharges> {
    return this._transferCharges;
  }

  private _banks: Array<Bank> = [];
  set Banks(transferCharges: Array<Bank>) {
    this._banks = transferCharges;
  }
  get Banks(): Array<Bank> {
    return this._banks;
  }

  private _dailyTransferLimit: Array<DailyTransferLimits> = [];
  set DailyTransferLimit(transferCharges: Array<DailyTransferLimits>) {
    this._dailyTransferLimit = transferCharges;
  }
  get DailyTransferLimit(): Array<DailyTransferLimits> {
    return this._dailyTransferLimit;
  }

  private _reloadTransferLimit: boolean;
  set ReloadTransferLimit(reloadTransferLimit: boolean) {
    this._reloadTransferLimit = reloadTransferLimit;
  }
  get ReloadTransferLimit(): boolean {
    return this._reloadTransferLimit;
  }

  private _billerBeneficiaryToBePaid: BillerBeneficiary;
  set BillerBeneficiaryToBePaid(billerbeneficiarytobepaid: BillerBeneficiary) {
    this._billerBeneficiaryToBePaid = billerbeneficiarytobepaid;
  }
  get BillerBeneficiaryToBePaid(): BillerBeneficiary {
    return this._billerBeneficiaryToBePaid;
  }

  private _userAccountBeneficiaries: Array<Beneficiary> = [];
  set UserAccountBeneficiaries(userAccountBeneficiaries: Array<Beneficiary>) {
    this._userAccountBeneficiaries = userAccountBeneficiaries;
  }
  get UserAccountBeneficiaries(): Array<Beneficiary> {
    return this._userAccountBeneficiaries;
  }

  private _beneficiariesToBePaid: Array<Beneficiary> = [];
  set BeneficiariesToBePaid(beneficiariesToBePaid: Array<Beneficiary>) {
    this._beneficiariesToBePaid = beneficiariesToBePaid;
  }
  get BeneficiariesToBePaid(): Array<Beneficiary> {
    return this._beneficiariesToBePaid;
  }

  private _userAccountBillerBeneficiaries: Array<BillerBeneficiary> = [];
  set UserAccountBillerBeneficiaries(
    userAccountBillerBeneficiaries: Array<BillerBeneficiary>
  ) {
    this._userAccountBillerBeneficiaries = userAccountBillerBeneficiaries;
  }
  get UserAccountBillerBeneficiaries(): Array<BillerBeneficiary> {
    return this._userAccountBillerBeneficiaries;
  }

  private _billerMerchants: Array<Biller> = [];
  set BillerMerchants(billerMerchants: Array<Biller>) {
    this._billerMerchants = billerMerchants;
  }
  get BillerMerchants(): Array<Biller> {
    return this._billerMerchants;
  }

  private _billerMerchantCategories: Array<BillerCategory> = [];
  set BillerMerchantCategories(
    billerMerchantCategories: Array<BillerCategory>
  ) {
    this._billerMerchantCategories = billerMerchantCategories;
  }
  get BillerMerchantCategories(): Array<BillerCategory> {
    return this._billerMerchantCategories;
  }

  private _SECURITY_QUESTIONS: Array<SecurityQuestion> = [];
  set SECURITY_QUESTIONS(SECURITY_QUESTIONS: Array<SecurityQuestion>) {
    this._SECURITY_QUESTIONS = SECURITY_QUESTIONS;
  }
  get SECURITY_QUESTIONS(): Array<SecurityQuestion> {
    return this._SECURITY_QUESTIONS;
  }

  private _statementCountries: Array<StatementCountry> = [];
  set StatementCountries(statementCountries: Array<StatementCountry>) {
    this._statementCountries = statementCountries;
  }
  get StatementCountries(): Array<StatementCountry> {
    return this._statementCountries;
  }

  private _BENEFICIARY_ADDED_FROM_ONE_OFF_PAYMENT: boolean;
  set BENEFICIARY_ADDED_FROM_ONE_OFF_PAYMENT(
    BENEFICIARY_ADDED_FROM_ONE_OFF_PAYMENT: boolean
  ) {
    this._BENEFICIARY_ADDED_FROM_ONE_OFF_PAYMENT = BENEFICIARY_ADDED_FROM_ONE_OFF_PAYMENT;
  }
  get BENEFICIARY_ADDED_FROM_ONE_OFF_PAYMENT(): boolean {
    return this._BENEFICIARY_ADDED_FROM_ONE_OFF_PAYMENT;
  }

  private _securityQuestionCreationRequestAuthor: number = 0;
  set SecurityQuestionCreationRequestAuthor(
    securityQuestionCreationRequestAuthor: number
  ) {
    this._securityQuestionCreationRequestAuthor = securityQuestionCreationRequestAuthor;
  }
  get SecurityQuestionCreationRequestAuthor(): number {
    return this._securityQuestionCreationRequestAuthor;
  }

  private _useValidateOTPPageForRIBFirstTimeLoginAfterRegistration: boolean;
  set UseValidateOTPPageForRIBFirstTimeLoginAfterRegistration(
    useValidateOTPPageForRIBFirstTimeLoginAfterRegistration: boolean
  ) {
    this._useValidateOTPPageForRIBFirstTimeLoginAfterRegistration = useValidateOTPPageForRIBFirstTimeLoginAfterRegistration;
  }
  get UseValidateOTPPageForRIBFirstTimeLoginAfterRegistration(): boolean {
    return this._useValidateOTPPageForRIBFirstTimeLoginAfterRegistration;
  }

  private _loginPasswordChangedSuccessfully: boolean;
  set LoginPasswordChangedSuccessfully(
    loginPasswordChangedSuccessfully: boolean
  ) {
    this._loginPasswordChangedSuccessfully = loginPasswordChangedSuccessfully;
  }
  get LoginPasswordChangedSuccessfully(): boolean {
    return this._loginPasswordChangedSuccessfully;
  }

  private _sessionTimedOut: boolean;
  set SessionTimedOut(sessionTimedOut: boolean) {
    this._sessionTimedOut = sessionTimedOut;
  }
  get SessionTimedOut(): boolean {
    return this._sessionTimedOut;
  }

  private _statementAccountNumber: string;
  set StatementAccountNumber(statementAccountNumber: string) {
    this._statementAccountNumber = statementAccountNumber;
  }
  get StatementAccountNumber(): string {
    return this._statementAccountNumber;
  }

  private _customerBVN: string;
  set CustomerBVN(customerBVN: string) {
    this._customerBVN = customerBVN;
  }
  get CustomerBVN(): string {
    return this._customerBVN;
  }

  private _tokenApiHasBeenCalled: boolean;
  get tokenApiHasBeenCalled() {
    return this._tokenApiHasBeenCalled;
  }

  set tokenApiHasBeenCalled(value) {
    this._tokenApiHasBeenCalled = value;
  }

  private _retailEzCashLimitDetails: RetailUserEzCashLimitDetails;
  set RetailEzCashLimitDetails(
    retailEzCashLimitDetails: RetailUserEzCashLimitDetails
  ) {
    this._retailEzCashLimitDetails = retailEzCashLimitDetails;
  }
  get RetailEzCashLimitDetails(): RetailUserEzCashLimitDetails {
    return this._retailEzCashLimitDetails;
  }

  private _SMEUserLimit: CorporateUserLimit;
  set SMEUserLimit(SMEUserLimit: CorporateUserLimit) {
    this._SMEUserLimit = SMEUserLimit;
  }
  get SMEUserLimit(): CorporateUserLimit {
    return this._SMEUserLimit;
  }

  private _sessionTimedOutSub: any;
  set SessionTimedOutSub(sessionTimedOutSub: any) {
    this._sessionTimedOutSub = sessionTimedOutSub;
  }
  get SessionTimedOutSub(): any {
    return this._sessionTimedOutSub;
  }

  private _sessionTimeoutInterval: any;
  set SessionTimeoutInterval(sessionTimeoutInterval: any) {
    this._sessionTimeoutInterval = sessionTimeoutInterval;
  }
  get SessionTimeoutInterval(): any {
    return this._sessionTimeoutInterval;
  }

  private _sessionTimeoutEventId: any;
  set SessionTimeoutEventId(sessionTimeoutEventId: any) {
    this._sessionTimeoutEventId = sessionTimeoutEventId;
  }
  get SessionTimeoutEventId(): any {
    return this._sessionTimeoutEventId;
  }

  private _pendingNameEnquiryBatchPollingInterval: any;
  set PendingNameEnquiryBatchPollingInterval(
    pendingNameEnquiryBatchPollingInterval: any
  ) {
    this._pendingNameEnquiryBatchPollingInterval = pendingNameEnquiryBatchPollingInterval;
  }
  get PendingNameEnquiryBatchPollingInterval(): any {
    return this._pendingNameEnquiryBatchPollingInterval;
  }

  private _threesBankingStatus: boolean;
  set ThreesBankingStatus(threesBankingStatus: boolean) {
    this._threesBankingStatus = threesBankingStatus;
  }
  get ThreesBankingStatus(): boolean {
    return this._threesBankingStatus;
  }
  private _creditCardRecords: Array<CreditCardRecords> = [];
  set CreditCards(creditCardRecords: Array<CreditCardRecords>) {
    this._creditCardRecords = creditCardRecords;
  }
  get CreditCards(): Array<CreditCardRecords> {
    return this._creditCardRecords;
  }

  private _creditCardRecord: CustomerAccount;
  set SelectedCreditCard(creditCardRecord: CustomerAccount) {
    this._creditCardRecord = creditCardRecord;
  }
  get SelectedCreditCard(): CustomerAccount {
    return this._creditCardRecord;
  }
  private _creditCardEligibityData: CreditCardEligibilityData;
  set CreditCardEligibilityData(_creditCardEligibityData: CreditCardEligibilityData) {
    this._creditCardEligibityData = _creditCardEligibityData;
  }
  get CreditCardEligibilityData(): CreditCardEligibilityData {
    return this._creditCardEligibityData;
  }

  private  _creditLimitIncreaseData: CardLimitEligibilityData;
  set CreditLimitIncreaseData(_creditLimitIncreaseData: CardLimitEligibilityData) {
    this._creditLimitIncreaseData = _creditLimitIncreaseData;
  }
  get CreditLimitIncreaseData(): CardLimitEligibilityData {
    return this._creditLimitIncreaseData;
  }
  private _selectedBiller: ShippingBillers ;
  set SelectedShippingPaymentBiller(biller: ShippingBillers) {
    this._selectedBiller = biller;
  }
  get SelectedShippingPaymentBiller(): ShippingBillers{
    return this._selectedBiller;
  }

  private enairaWalletDetails: WalletDetailsResponse;
  set EnairaWalletDetails(biller: WalletDetailsResponse) {
    this.enairaWalletDetails = biller;
  }
  get EnairaWalletDetails(): WalletDetailsResponse{
    return this.enairaWalletDetails;
  }

  private _customerHWTEnabled: boolean;
  set CustomerHWTEnabled(customerHWTEnabled: boolean) {
      this._customerHWTEnabled = customerHWTEnabled;
  }
  get CustomerHWTEnabled(): boolean {
      return this._customerHWTEnabled;
  }

  private tokenChannels: CustomerTokenChannels;
  set CustomerTokenChannels(channels: CustomerTokenChannels) {
      this.tokenChannels = channels;
  }
  get CustomerTokenChannels(): CustomerTokenChannels {
      return this.tokenChannels;
  }

  private customerCollectionBranch : string;
  set CustomerTokenCollectionBranch(branch:string){
      this.customerCollectionBranch = branch;
  }
  get CustomerTokenCollectionBranch(): string{
      return this.customerCollectionBranch;
  }
  private _flightSearchResp: Array<FlightCombination>;
  set FlightSearchResponse(flightSearchResp: Array<FlightCombination>) {
      this._flightSearchResp = flightSearchResp;
  }
  get FlightSearchResponse(): Array<FlightCombination> {
      return this._flightSearchResp;
  }

  private _searchFlightPayload: SearchFlight;
  set SearchWakanowFlightPayload(searchFlightPayload: SearchFlight) {
      this._searchFlightPayload = searchFlightPayload;
  }
  get SearchWakanowFlightPayload(): SearchFlight {
      return this._searchFlightPayload;
  }

  private _selectedFlightDetail: FlightSearchResponse;
  set SelectedFlightDetail(selectedFlightDetail: FlightSearchResponse) {
      this._selectedFlightDetail = selectedFlightDetail;
  }
  get SelectedFlightDetail(): FlightSearchResponse {
      return this._selectedFlightDetail;
  }
  private _detail: any;
  set ChooseFlightDetail(selectedFlightDetail: any) {
      this._detail = selectedFlightDetail;
  }
  get ChooseFlightDetail(): any {
      return this._detail;
  }
  private _hotelDetail: any;
  set ChooseHotelDetail(selectedHotelDetail: any) {
      this._hotelDetail = selectedHotelDetail;
  }
  get ChooseHotelDetail(): any {
      return this._hotelDetail;
  }
  private _selectedHotelDetail: HotelList;
  set SelectedHotelDetail(selectedHotelDetail: HotelList) {
      this._selectedHotelDetail = selectedHotelDetail;
  }
  get SelectedHotelDetail(): HotelList {
      return this._selectedHotelDetail;
  }

  private _hotelSearchResponse: HotelSearchResponse;
  set HotelSearchResponse(hotelSearchResp: HotelSearchResponse) {
      this._hotelSearchResponse = hotelSearchResp;
  }
  get HotelSearchResponse(): HotelSearchResponse {
      return this._hotelSearchResponse;
  }

  private _selectedWakanowHotel: SearchHotels;
  set SearchWakanowHotelPayload(selectedHotelDetail: SearchHotels) {
      this._selectedWakanowHotel = selectedHotelDetail;
  }
  get SearchWakanowHotelPayload(): SearchHotels {
      return this._selectedWakanowHotel;
  }
}
