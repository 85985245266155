import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BookHotelsComponent } from "src/app/pages/wakanow-integration/book-hotels/book-hotels.component";
import { ChooseFlightResp } from "../models/choose-flight-resp";
import { ChooseHotelResp } from "../models/choose-hotel-resp";
import { HotelListResp } from "../models/hotel-list-resp";
import { FlightListResp } from "../models/flight-list-resp";
import { FlightSearchResponse } from "../models/flight-search";
import { HotelSearchResponse } from "../models/hotel-search";
import { BookFlights } from "../models/payloads/book-flights";
import { BookHotels } from "../models/payloads/book-hotel";
import { ChooseFlightPayload } from "../models/payloads/choose-flight";
import { ChooseHotelPayload } from "../models/payloads/choose-hotel";
import { GenericApiResponse } from "../models/payloads/generic-response";
import { SearchFlight } from "../models/payloads/search-flights";
import { SearchHotels } from "../models/payloads/search-hotels";
import { WakanowMakePaymentPayload } from "../models/payloads/wakanow-payment-payload";
import { AppConfigService } from "./appconfig.service";
import { BookingHistoryRequest, BookingHistoryResponse } from "../models/payloads/booking-history";
import { LocationSearchResponse } from "../models/location_search_resp";

@Injectable()
export class WakanowIntegrationService {
    private _apiBaseUrl: string;
    constructor(private _httpClient: HttpClient, private _settings: AppConfigService) {
        this._apiBaseUrl = _settings.WakanowBaseUrl;
    }
    searchFlights(payload: SearchFlight):Promise<FlightSearchResponse> {
        return this._httpClient.post<FlightSearchResponse>(`${this._apiBaseUrl}/Wakanow/SearchFlight`,
            payload,
        ).toPromise();
    }
    searchHotels(payload: SearchHotels):Promise<HotelSearchResponse>  {
        return this._httpClient.post<HotelSearchResponse>(`${this._apiBaseUrl}/Wakanow/SearchHotel`,
            payload,
        ).toPromise();
    }
    bookFlights(payload):any {
        console.log("test")
        return this._httpClient.post<GenericApiResponse>(`${this._apiBaseUrl}/Wakanow/bookflight/makeflightbooking`,
            payload,
        ).toPromise();
    }
    bookHotels(payload):any {
        return this._httpClient.post<GenericApiResponse>(`${this._apiBaseUrl}/Wakanow/bookhotel/makehotelbooking`,
            payload,
        ).toPromise();
    }

    getAllBookings(payload: BookingHistoryRequest) {
        return this._httpClient.post<BookingHistoryResponse>(`${this._apiBaseUrl}/Wakanow/Bookings/getAll`,
        payload,
        ).toPromise();
    }
    chooseFlights(payload: ChooseFlightPayload):Promise<ChooseFlightResp> {
        return this._httpClient.post<ChooseFlightResp>(`${this._apiBaseUrl}/Wakanow/chooseflight`,
            payload,
        ).toPromise();
    }
    chooseHotels(payload: ChooseHotelPayload):Promise<ChooseHotelResp> {
        return this._httpClient.post<ChooseHotelResp>(`${this._apiBaseUrl}/ChooseHotel/choosehotel`,
        payload,
        ).toPromise();
    }

    makePayment(payload):Promise<ChooseHotelResp> {
        //ask victor to give me endpoints that match the rest
        return this._httpClient.post<ChooseHotelResp>(`${this._apiBaseUrl}/Payment/makepayment/flight`,
            payload,
        ).toPromise();
    }
    makePaymentHotel(payload):Promise<ChooseHotelResp> {
        //ask victor to give me endpoints that match the rest
        return this._httpClient.post<ChooseHotelResp>(`${this._apiBaseUrl}/Payment/makepayment/hotel`,
            payload,
        ).toPromise();
    }
    getAirportList(payload):Promise<FlightListResp> {
        return this._httpClient.post<FlightListResp>(`${this._apiBaseUrl}/Wakanow/GetAirportList`,
            payload,
        ).toPromise();
    }
    getHotelList(payload):Promise<HotelListResp> {
        return this._httpClient.post<HotelListResp>(`${this._apiBaseUrl}/Wakanow/GetHotelList`,
            payload,
        ).toPromise();
    }z
    getLocations(payload):Promise<LocationSearchResponse>  {
        return this._httpClient.post<LocationSearchResponse>(`${this._apiBaseUrl}/Wakanow/GetLocationList`,
            payload,
        ).toPromise();
    }
}
