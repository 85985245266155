export const _mockBeneficiaryData = [
    {
        beneficiaryName: 'Mohammed Laila Lauretta',
        beneficiaryId: '1603037',
        requestedBy: 'AYODEJIOLAOYE',
        requestDate: '2018-12-07T14:06:21+01:00',
        requestType: 'Addition',
        status: 2
    },
    {
        beneficiaryName: 'ABIOLA LOLADE LATEEFAH',
        beneficiaryId: '1603030',
        requestedBy: 'AYODEJIOLAOYE',
        requestDate: '2018-12-07T18:06:21+01:00',
        requestType: 'Remove',
        status: 2
    },
    {
        beneficiaryName: 'LAILA MOHAMMED',
        beneficiaryId: '1603031',
        requestedBy: 'AYODEJIOLAOYE',
        requestDate: '2018-12-09T18:06:21+01:00',
        requestType: 'Addition',
        status: 8
    },
    {
        beneficiaryName: 'KATE POPOOLA',
        beneficiaryId: '1603032',
        requestedBy: 'AYODEJIOLAOYE',
        requestDate: '2018-12-09T20:06:21+01:00',
        requestType: 'Addition',
        status: 8
    }
];
