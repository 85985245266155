import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../appconfig.service';
import { AuthService } from '../auth.service';
import { AppUser } from '../../models/auth';
import { ApiURLConstants } from '../../helpers/apiURLConstants';
import { SMETransferResponsePayload } from '../../models/payloads/transactions';
import { GenericApiResponse } from '../../models/payloads/generic-response';
import { StagedServiceRequestResponse } from '../../models/service-request/misc-models';
import { Role } from '../../models/Enumerations';

@Injectable()
export class RequestStagingService {

    private _apiBaseUrl: string;
    private _loggedInUser: AppUser;
    constructor(private _httpClient: HttpClient, private _settings: AppConfigService, private _authService: AuthService) {
        this._apiBaseUrl = _settings.APIBaseUrl;
        this._loggedInUser = this._authService.getLoggedInUser();
    }

    stageServiceRequest(payload): Promise<SMETransferResponsePayload> {
        payload = this.uploadRequestPayloadWithUserId(payload);
        return this._httpClient.post<SMETransferResponsePayload>(`${this._apiBaseUrl}/${ApiURLConstants.STAGE_SERVICE_REQUEST}`, payload)
            .toPromise();
    }
    setServiceRequestApprovalWorkflow(payload): Promise<GenericApiResponse> {
        payload = this.uploadRequestPayloadWithUserId(payload);
        return this._httpClient.post<GenericApiResponse>(
            `${this._apiBaseUrl}/${ApiURLConstants.SET_SERVICE_REQUEST_APPROVAL_WORKFLOW}`, payload)
            .toPromise();
    }

    getStagedServiceRequestApprovalRequest(payload): Promise<StagedServiceRequestResponse> {
        const _loggedInUser = this._authService.getLoggedInUser();
        payload['UserId'] = _loggedInUser && _loggedInUser.UserId ? this._loggedInUser.UserId : '';
        if (_loggedInUser && _loggedInUser.Role && _loggedInUser.Role.roles.includes(Role.Initiator)) {
            payload['ForInitiator'] = true;
        }
        return this._httpClient.post<StagedServiceRequestResponse>(
            `${this._apiBaseUrl}/${ApiURLConstants.GET_STAGED_SERVICEREQUEST_APPROVAL_REQUEST}`, payload)
            .toPromise();
    }

    private uploadRequestPayloadWithUserId(payload) {
        const loggedInUser = this._authService.getLoggedInUser();
        payload['UserId'] = loggedInUser ? loggedInUser.UserId : '';
        return payload;
    }
}
