import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  TopUpRequestPayload,
  TopUpRequestResponse,
  TopUpRepaymentScheduleRequestPayload,
  TopUpRepaymentScheduleResponse,
  TopUpLoanRequestPayload,
} from "src/app/core/models/loan-topup.model";
import { AuthService } from "src/app/core/services/auth.service";
import { AppConfigService } from "src/app/core/services/appconfig.service";
import { HttpClient } from "@angular/common/http";
import { ApiURLConstants } from "src/app/core/helpers/apiURLConstants";

@Injectable()
export class LoanTopUpService {
  _apiBaseUrl: string;
  constructor(
    private _authService: AuthService,
    _settings: AppConfigService,
    private _httpClient: HttpClient
  ) {
    this._apiBaseUrl = _settings.APIBaseUrl;
  }

  verifyBVN(payload: any): Observable<TopUpRequestResponse> {
    payload = this.updateRequestPayload(payload);
    return this._httpClient.post<TopUpRequestResponse>(
      `${this._apiBaseUrl}/${ApiURLConstants.INSTANT_CASH_ADVANCE_LIMIT}`,
      payload
    );
  }

  BookLoan(payload: TopUpRequestPayload): Observable<TopUpRequestResponse> {
    payload = this.updateRequestPayload(payload);
    return this._httpClient.post<TopUpRequestResponse>(
      `${this._apiBaseUrl}/${ApiURLConstants.CASH_ADVANCE_REQUEST}`,
      payload
    );
  }

  private updateRequestPayload(payload: any) {
    const loggedInUser = this._authService.getLoggedInUser();
    payload["cifId"] =
      loggedInUser && loggedInUser.CifId ? loggedInUser.CifId : "";
    payload["userId"] =
      loggedInUser && loggedInUser.UserId ? loggedInUser.UserId : "";
    return payload;
  }

  termsAndCondition(payload) {
    console.log("object service: " + JSON.stringify(payload));
    payload = this.updateRequestPayload(payload);
    return this._httpClient.post(
      "https://appsgw.stanbicibtc.com:8443/omni-channel/consent-management/api/v1/omni/Customer/create",
      payload
    );
  }

  GetLoanRepaymentSchedules(
    payload: TopUpRepaymentScheduleRequestPayload
  ): Observable<TopUpRepaymentScheduleResponse> {
    // payload = this.updateRequestPayload(payload);
    console.log(payload);
    return this._httpClient.post<TopUpRepaymentScheduleResponse>(
      `${this._apiBaseUrl}/${ApiURLConstants.INSTANT_TOPUP_LOAN_REPAYMENT_SCHEDULES}`,
      payload
    );
  }

  BookLoanTopup(
    payload: TopUpLoanRequestPayload
  ): Observable<TopUpRequestResponse> {
    return this._httpClient.post<TopUpRequestResponse>(
      `${this._apiBaseUrl}/${ApiURLConstants.BOOK_TOPUP_LOAN}`,
      payload
    );
  }
}
