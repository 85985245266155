import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { PendingWesternUnionRedemptionRequestObject, PendingServiceRequestObject } from 'src/app/core/models/pending-request.models';
import { AuthService } from 'src/app/core/services/auth.service';
import { MiscService } from 'src/app/core/services/misc.service';
import { MatSnackBar, MatDialog } from '@angular/material';
import { BasePendingRequestComponent } from '../base.pending-request.component';
import { BasePagedRequestResponse, RequestApprovalHistoryPayload } from 'src/app/core/models/misc-requests-model';
import { ResponseCodes } from 'src/app/core/models/Enumerations';

@Component({
  selector: 'app-service-requests',
  templateUrl: './service-requests.component.html',
  styleUrls: ['./service-requests.component.css']
})
export class PendingServiceRequestsComponent extends BasePendingRequestComponent implements OnInit, OnChanges {

  @Input() _activeTab: string;
  requestList: Array<PendingServiceRequestObject> = [];
  requestListBackup: Array<PendingServiceRequestObject> = [];
  _now: Date = new Date();
  _pageSize = 5;
  _requestErrorMessage: string;

  constructor(authService: AuthService, private _miscService: MiscService, private _snackbar: MatSnackBar, _dialog: MatDialog) { 
    super(authService, _dialog);
  }
  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['_activeTab']) {
      this._activeTab = changes['_activeTab'].currentValue;
      if (this._activeTab === 'service-requests') {
        this.loadPendingServiceRequests();
      }
    }
  }

  loadPendingServiceRequests(lastFetchId = 0, pageSize = 100) {
    this.ownLoaderIsActive = true;
    this.ownloaderMsg = 'retrieving pending service requests .. please wait';
    this._requestErrorMessage = '';
    const payload = {PageSize: pageSize, LastFetchId: lastFetchId, OperationType: 'SERVICE_REQUEST' };
    this._miscService.getPendingRequest<BasePagedRequestResponse<PendingServiceRequestObject>>(payload).then(
      (response) => {
        console.log(response);
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        if (!response || !response.ResponseCode || response.ResponseCode !== ResponseCodes.SUCCESS) {
          const statusMsg = response && response.ResponseFriendlyMessage ? response.ResponseFriendlyMessage :
                            'Failed to load pending service requests';
          this._snackbar.open(`${statusMsg}`, 'Ok', { duration: 25000 }); return;
        }
        this.requestListBackup = response.PaginatedItems;
        this.requestList = this.requestListBackup.slice(0, this._pageSize);

      },
      (error) => {
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        this._requestErrorMessage = 'We encountered an unknown error while retrieving pending service-requests for your account';
        console.log(error);
      }
    );

  }


  onRequestAction(action: string, data: PendingServiceRequestObject) {
    switch (action) {
      case 'view-history': {
        const requestPayload: RequestApprovalHistoryPayload = this.buildRequestApprovalHistoryPayload(data, 'SERVICE_REQUEST');
        const approvalHistoryModal = this.launchRequestHistoryModal(requestPayload,
                                                    'service-request', 'Request Approval Hisytory');
        approvalHistoryModal.afterClosed().subscribe((result) => {});
        break;
      }
    }
  }


}
