import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { MatDialogRef, MatSnackBar } from "@angular/material";
import { Router } from "@angular/router";

import { AuthService } from "src/app/core/services/auth.service";
import { RouterConstants } from "src/app/core/helpers/routerConstants";
import { VariablesService } from "./core/services/variables.service";
import { Constants } from "./core/helpers/appConstants";

import { MultiWindowService, Message } from "ngx-multi-window";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  idleState = "Not started.";
  timedOut = false;
  lastPing?: Date = null;
  inactiveSessionAlertSent: boolean;
  title = "smeBankingApp";
  @ViewChild("timeoutMsgTemplate") sessionTimeOutModalTemplate: TemplateRef<
    any
  >;
  sessionTimeOutModalDialog: MatDialogRef<any>;
  constructor(
    private authService: AuthService,
    private router: Router,
    private multiWindowService: MultiWindowService,
    private _snackbar: MatSnackBar
  ) {
    AuthService.clearSessionTokenOnLogout = true;
    AuthService.useLoginTokenForSessionTokenOnNextAPICall = true;
    AuthService.ValidateUserSession = true;

    // this.multiWindowService.onMessage().subscribe((value: Message) => {
    //   if (value) {
    //     //Call to remove user session on this AppWindow on login from another browser Tab or App Window.
    //     if (value.data && value.data == "obkg_stanbic_rm_session") {
    //       AuthService.clearSessionTokenOnLogout = false; // setting this true will clear session token details from the local storage.
    //       this.authService.logout();
    //       this.router.navigate([RouterConstants.LoginURL]);

    //       this._snackbar.open(Constants.MULTI_SESSION_LOGOUT_MESSAGE,
    //         'OK', { duration: 600000 });
    //     }
    //   }
    // });
  }

  ngOnInit() {
    if (!AuthService.FullyAuthenticatedUser) {
      this.router.navigate([RouterConstants.LoginURL]);
      // this.router.navigate([RouterConstants.RootCorporateBillers]);
    }
  }
}
