// import { Injectable } from "@angular/core";
// import { HttpClient, HttpHeaders } from "@angular/common/http";
// import { AppConfigService } from "./appconfig.service";
// import { AuthService } from "./auth.service";
// import { Observable } from "rxjs";

// @Injectable()
// export class FXPaymentService {
//   private _apiBaseUrl: string;

//   constructor(
//     private _httpClient: HttpClient,
//     _settings: AppConfigService,
//     private _authService: AuthService
//   ) {
//     this._apiBaseUrl = _settings.FxPaymentApiBaseUrl;
//   }
//   getFxBalance(bvn: string): Observable<any> {
//     const headers = new HttpHeaders()
//       .set("content-type", "application/json")
//       .set("Q-wxTYDV-vxq", "hhdkiuvxxqeTRfHyGVccDDALoieuhsWQArtdlirXX");
//     return this._httpClient.post<any>(
//       `${this._apiBaseUrl}/GetFxBalances`,
//       { Bvn: bvn },
//       { headers: headers }
//     );
//   }

//   getFxBranches(): Observable<any> {
//     const headers = new HttpHeaders()
//       .set("content-type", "application/json")
//       .set("Q-wxTYDV-vxq", "hhdkiuvxxqeTRfHyGVccDDALoieuhsWQArtdlirXX");
//     return this._httpClient.post<any>(
//       `${this._apiBaseUrl}/GetFxBranches `,
//       {},
//       { headers: headers }
//     );
//   }
//   getFxTypes(): Observable<any> {
//     const headers = new HttpHeaders()
//       .set("content-type", "application/json")
//       .set("Q-wxTYDV-vxq", "hhdkiuvxxqeTRfHyGVccDDALoieuhsWQArtdlirXX");
//     return this._httpClient.post<any>(
//       `${this._apiBaseUrl}/GetFxTypes `,
//       {},
//       { headers: headers }
//     );
//   }
//   getPurchaseType(): Observable<any> {
//     const headers = new HttpHeaders()
//       .set("content-type", "application/json")
//       .set("Q-wxTYDV-vxq", "hhdkiuvxxqeTRfHyGVccDDALoieuhsWQArtdlirXX");
//     return this._httpClient.post<any>(
//       `${this._apiBaseUrl}/GetPurchaseTypes `,
//       {},
//       { headers: headers }
//     );
//   }
//   SubmitFxRequest(payload): Observable<any> {
//     const headers = new HttpHeaders()
//       .set("content-type", "application/json")
//       .set("Q-wxTYDV-vxq", "hhdkiuvxxqeTRfHyGVccDDALoieuhsWQArtdlirXX");
//     return this._httpClient.post<any>(
//       `${this._apiBaseUrl}/SubmitRequest `,
//       payload,
//       { headers: headers }
//     );
//   }
// }



import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppConfigService } from "./appconfig.service";
import { AuthService } from "./auth.service";
import { Observable } from "rxjs";

@Injectable()
export class FXPaymentService {
  private _apiBaseUrl: string;

  constructor(
    private _httpClient: HttpClient,
    _settings: AppConfigService,
    private _authService: AuthService
  ) {
    this._apiBaseUrl = _settings.FxPaymentApiBaseUrl;
  }
  getFxBalance(bvn: string): Observable<any> {
    const headers = new HttpHeaders()
      .set("content-type", "application/json")
      .set("Q-wxTYDV-vxq", "hhdkiuvxxqeTRfHyGVccDDALoieuhsWQArtdlirXX");
    return this._httpClient.post<any>(
      `${this._apiBaseUrl}/GetFxBalances`,
      { Bvn: bvn },
      { headers: headers }
    );
  }

  getFxBranches(): Observable<any> {
    const headers = new HttpHeaders()
      .set("content-type", "application/json")
      .set("Q-wxTYDV-vxq", "hhdkiuvxxqeTRfHyGVccDDALoieuhsWQArtdlirXX");
    return this._httpClient.post<any>(
      `${this._apiBaseUrl}/GetFxBranches `,
      {},
      { headers: headers }
    );
  }
  getFxTypes(): Observable<any> {
    const headers = new HttpHeaders()
      .set("content-type", "application/json")
      .set("Q-wxTYDV-vxq", "hhdkiuvxxqeTRfHyGVccDDALoieuhsWQArtdlirXX");
    return this._httpClient.post<any>(
      `${this._apiBaseUrl}/GetFxTypes `,
      {},
      { headers: headers }
    );
  }
  getPurchaseType(): Observable<any> {
    const headers = new HttpHeaders()
      .set("content-type", "application/json")
      .set("Q-wxTYDV-vxq", "hhdkiuvxxqeTRfHyGVccDDALoieuhsWQArtdlirXX");
    return this._httpClient.post<any>(
      `${this._apiBaseUrl}/GetPurchaseTypes `,
      {},
      { headers: headers }
    );
  }
  SubmitFxRequest(payload): Observable<any> {
    const headers = new HttpHeaders()
      .set("content-type", "application/json")
      .set("Q-wxTYDV-vxq", "hhdkiuvxxqeTRfHyGVccDDALoieuhsWQArtdlirXX");
    return this._httpClient.post<any>(
      `${this._apiBaseUrl}/SubmitRequest `,
      payload,
      { headers: headers }
    );
  }
}
