export const _mockBillersManagementData = [
    {
        billerName: '3rdmain',
        subscriptionId: '1478109',
        billerNickName : 'ARIK AIR',
        requestedBy : 'AYODEJIOLAOYE',
        requestDate : '2019-01-04T14:06:21+01:00',
        requestType : 'Remove',
        status : 4
    },
    {
        billerName: 'DSTV',
        subscriptionId : '1603037',
        billerNickName: 'DSTV SME-Biller',
        requestedBy: 'AYODEJIOLAOYE',
        requestDate: '2019-01-04T14:06:21+01:00',
        requestType : 'Addition',
        status : 2
    },
    {
        billerName: 'Arik Air Book-On-Hold',
        subscriptionId: '1478119',
        billerNickName : 'ARIK AIR',
        requestedBy: 'AYODEJIOLAOYE',
        requestDate : '2019-01-04T14:06:21+01:00',
        requestType : 'Addition',
        status : 4
    },
    {
        billerName: '3rdmain',
        subscriptionId: '1478119',
        billerNickName : 'ARIK AIR',
        requestedBy : 'AYODEJIOLAOYE',
        requestDate : '2019-01-04T14:06:21+01:00',
        requestType : 'Remove',
        status : 4
    }
];
