export class RouterConstants {
  public static get LoginURL(): string {
    return "auth/login";
  }
  public static get SurveyURL(): string {
    return "auth/survey";
  }
  public static get ChildValidateOTPURL(): string {
    return "validate-otp";
  }
  public static get FullValidateOTPURL(): string {
    return "auth/validate-otp";
  }
  public static get LOGIN_CREATE_SECURITY_QUESTIONS_URL(): string {
    return "auth/create-security-questions";
  }
  public static get LOGIN_CHANGE_PASSWORD_URL(): string {
    return "auth/change-password";
  }
  public static get LOGIN_CREATE_RIB_USER_URL(): string {
    return "auth/register-user";
  }
  public static get RESET_RIB_USER_PASSWORD_URL(): string {
    return "auth/reset-password";
  }

  public static get RootHomeUrl(): string {
    return "accounts";
  }

  public static get ChildDashboardURL(): string {
    return "";
  }
  public static get FullDashboardURL(): string {
    return `${this.RootHomeUrl}`;
  }
  public static get AccountTransactionURL(): string {
    return `${this.RootHomeUrl}/transactions`;
  }
  public static get RootWakanowUrl(): string { return 'flights'; }

  public static get Root3sUrl(): string { return '3sbanking'; }


  public static get RootTransferUrl(): string {
    return "transfers";
  }
  public static get ChildOneOffTransferURL(): string {
    return "one-off";
  }
  public static get FullOneOffTransferURL(): string {
    return `${this.RootTransferUrl}/${this.ChildOneOffTransferURL}`;
  }

  public static get RootBeneficiaryUrl(): string {
    return "beneficiaries";
  }
  public static get PayBeneficiaryURL(): string {
    return `${this.RootBeneficiaryUrl}/pay`;
  }
   public static get RootCreditCardManagementUrl(): string { return 'credit-cards'; }


  public static get RootBillerBeneficiaryUrl(): string {
    return "billers";
  }

  public static get PayBillerBeneficiaryURL(): string {
    return `${this.RootBillerBeneficiaryUrl}/pay`;
  }
  public static get RootBillPaymentUrl(): string {
    return "pay-bills";
  }
  public static get RootInstantCashAdvanceUrl(): string {
    return "instant-cash";
  }
  public static get RootUPLLoanAdvanceUrl(): string {
    return "upl-loan";
  }
  public static get RootCreditCardUrl(): string {
    return "credit-cards/";
  }
  public static get RootTopUpLoanAdvanceUrl(): string {
    return "topup-loan";
  }

  public static get RootCorporateBillers(): string {
    return "corporate-billers";
  }
}
