import { GetAuthorizedBvn } from './../models/getAuthorizedBvn';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './appconfig.service';
import { Country } from '../models/country';
import { countryList } from '../models/_countryLists';
import { StorageService } from './storage.service';
import { Constants } from '../helpers/appConstants';
import {
    TransferChannelOrderResponsePayload, TransactionChargesResponsePayload,
    TransferLimitResponsePayload,
    GetStatementCountryResponse,
    GetStatementCountryDestinationResponse,
    GenerateStatementRequest,
    GenerateStatementResponse,
    GenerateStatementRequestResponse,
    DownloadStatementResponse
} from '../models/payloads/misc';
import { NameEnquiryRequestPayload, NameEnquiryRequestResponse, BasePagedRequestResponse, CardExpiryConsent, CardExpiryNotificationDialogData } from '../models/misc-requests-model';
import { PasswordChangeOtpRequestPayload, AppUser } from '../models/auth';
import { Bank, OtpRequestResponse } from '../models/service-request/misc-models';
import { RemitaPaymentDetailsRequestResponse } from '../models/transfer';
import { ApiURLConstants } from '../helpers/apiURLConstants';
import { AuthService } from './auth.service';
import { NameEnquiryBatchRequestPayload, NameEnquiryBatchRequestResponse } from '../models/payloads/transactions';
import { Observable, of } from 'rxjs';
import { GenericApiResponse } from '../models/payloads/generic-response';
import { PensionRemittanceUploadRequestPayload, PensionRemittanceUploadResponse } from '../models/payloads/pensionremittance';

@Injectable()
export class MiscService {

    private _apiBaseUrl: string;
    private _apiBaseUrl2: string;
    private _loggedInUser: AppUser;


    constructor(private _settings: AppConfigService, private _httpClient: HttpClient, private _storageService: StorageService,
        private _authService: AuthService) {
        this._apiBaseUrl = _settings.APIBaseUrl;
        this._apiBaseUrl2 = _settings.APIBaseUrl2;
        this._loggedInUser = _authService.getLoggedInUser();
    }


    getStatementCountries(): Observable<GetStatementCountryResponse> {

        return this._httpClient.post<GetStatementCountryResponse>(`${this._apiBaseUrl}/${ApiURLConstants.StatementCountryURL}`,
            {});
        // return this._httpClient.get<GetStatementCountryResponse>(`${ApiURLConstants.StatementCountryURL}`);
    }

    getStatementCountryDestinations(countryCode, destinationTypeId): Observable<GetStatementCountryDestinationResponse> {

        return this._httpClient.post<GetStatementCountryDestinationResponse>(`${this._apiBaseUrl}/${ApiURLConstants.StatementCountryDestinationURL}`,
            {
                CountryCode: countryCode,
                DestinationTypeId: destinationTypeId
            });

        // // return of({
        // //     GetDestinationsResult: "[{\"Name\":\"Sterling Bank\",\"destinationID\":\"1\",\"categoryID\":\"1\",\"Category\":\"Commercial Bank\"},{\"Name\":\"Polaris Bank Limited\",\"destinationID\":\"2\",\"categoryID\":\"1\",\"Category\":\"Commercial Bank\"},{\"Name\":\"Key Stone Bank\",\"destinationID\":\"4\",\"categoryID\":\"1\",\"Category\":\"Commercial Bank\"},{\"Name\":\"FCM Bank\",\"destinationID\":\"5\",\"categoryID\":\"1\",\"Category\":\"Commercial Bank\"},{\"Name\":\"Access Bank\",\"destinationID\":\"6\",\"categoryID\":\"1\",\"Category\":\"Commercial Bank\"},{\"Name\":\"Heritage Bank\",\"destinationID\":\"7\",\"categoryID\":\"1\",\"Category\":\"Commercial Bank\"},{\"Name\":\"Access(Diamond) Bank\",\"destinationID\":\"8\",\"categoryID\":\"1\",\"Category\":\"Commercial Bank\"},{\"Name\":\"Union Bank\",\"destinationID\":\"11\",\"categoryID\":\"1\",\"Category\":\"Commercial Bank\"},{\"Name\":\"Wema Bank\",\"destinationID\":\"12\",\"categoryID\":\"1\",\"Category\":\"Commercial Bank\"},{\"Name\":\"GT Bank\",\"destinationID\":\"13\",\"categoryID\":\"1\",\"Category\":\"Commercial Bank\"},{\"Name\":\"UBA \",\"destinationID\":\"14\",\"categoryID\":\"1\",\"Category\":\"Commercial Bank\"},{\"Name\":\"Fidelity Bank\",\"destinationID\":\"15\",\"categoryID\":\"1\",\"Category\":\"Commercial Bank\"},{\"Name\":\"Eco Bank\",\"destinationID\":\"32\",\"categoryID\":\"1\",\"Category\":\"Commercial Bank\"},{\"Name\":\"First Bank\",\"destinationID\":\"3\",\"categoryID\":\"1\",\"Category\":\"Commercial Bank\"}]",
        // // });

        // AuthService.useOnlyContentTypeHeader = true;
        // return this._httpClient.get<GetStatementCountryDestinationResponse>
        //     (`${ApiURLConstants.StatementCountryDestinationURL}/${countryCode}/${destinationId}`);
    }


    generateStatementRequest(payload: GenerateStatementRequest): Observable<GenerateStatementRequestResponse> {

        return this._httpClient.post<GenerateStatementRequestResponse>(`${this._apiBaseUrl}/${ApiURLConstants.GenerateStatementRequestURL}`,
            {
                AccountNo: payload.accountNo,
                StartDate: payload.startDate,
                EndDate: payload.endDate,
                DestinationId: payload.destinationId,
                CountryCode: payload.countryCode
            });

        // return of({
        //     RequestStatementResult: "{\"status\":\"00\",\"Msg\":\"Successful\",\"noOfPages\":\"6\",\"Amount\":\"240.00\",\"AdditionalFee\":\"1000.0000\",\"Vat\":\"62\",\"Total\":\"1302.0000\"}"
        // });
        // AuthService.useOnlyContentTypeHeader = true;

        // return this._httpClient.get<GenerateStatementResponse>
        //     (`${ApiURLConstants.GenerateStatementRequestURL}/${payload.accountNo}/${payload.startDate}/${payload.endDate}/${payload.destinationId}/${payload.countryCode}`);

    }



    forwardStatement(payload: GenerateStatementRequest): Observable<GenerateStatementResponse> {

        return this._httpClient.post<GenerateStatementResponse>(`${this._apiBaseUrl}/${ApiURLConstants.ForwardStatementURL}`,
            {
                AccountNo: payload.accountNo,
                StartDate: payload.startDate,
                EndDate: payload.endDate,
                DestinationId: payload.destinationId,
                CountryCode: payload.countryCode,
                Applicants: payload.applicants,
                ApplicantRole: payload.role,
                DebitAccountNo: payload.debitAccountNo
            });

        // return of({
        //     GenerateStatementResult: "{\"Status\":\"00\",\"Msg\":\"Successful\",\"Ticke t\":null,\"NoOfPages\":\"5\",\"TicketNo\":\"1043889-14\",\"Password\":\"4042\"}"
        // });

        // AuthService.useOnlyContentTypeHeader = true;

        // return this._httpClient.get<GenerateStatementResponse>
        //     (`${ApiURLConstants.ForwardStatementURL}/${payload.accountNo}/${payload.startDate}/${payload.endDate}/${payload.role}/${payload.destinationId}/${payload.applicants}/IB/${payload.countryCode}`);

    }


    generateStatementRequestTicket(ticketRequestNo: string): Observable<DownloadStatementResponse> {

        return this._httpClient.post<DownloadStatementResponse>(`${this._apiBaseUrl}/${ApiURLConstants.DownloadStatementTicketURL}`,
            {
                TicketNo: ticketRequestNo
            });

        // return of({
        //     GenerateStatementResult: "{\"Status\":\"00\",\"Msg\":\"Successful\",\"Ticke t\":null,\"NoOfPages\":\"5\",\"TicketNo\":\"1043889-14\",\"Password\":\"4042\"}"
        // });
        // AuthService.useOnlyContentTypeHeader = true;
        // return this._httpClient.get<GenerateStatementResponse>
        //     (`${ApiURLConstants.DownloadStatementTicketURL}/${ticketRequestNo}`);
    }




    getTransferChannelOrder(): Observable<TransferChannelOrderResponsePayload> {
        return this._httpClient.post<any>(`${this._apiBaseUrl}/${ApiURLConstants.TransferChannelOrderSettings}`,
            { UserId: AuthService.FullyAuthenticatedUser.UserId });
    }

    getTransferCharges(): Observable<TransactionChargesResponsePayload> {
        // return of({
        //     Charges:[{
        //         StartAmount: 20,
        //         EndAmount: 10,
        //         Charges: 2
        //     }],
        //     ResponseCode:'00',
        //     ResponseFriendlyMessage:'success'
        // })
        // uncomment this
        return this._httpClient.post<any>(`${this._apiBaseUrl}/${ApiURLConstants.TransferChargesSettings}`,
            { UserId: AuthService.FullyAuthenticatedUser.UserId })
            ;
    }

    getDailyTransferLimit(): Observable<TransferLimitResponsePayload> {
        if (this._settings.UseMockData) {
            return of({
                ResponseCode: '00',
                ResponseDescription: 'String',
                LimitDataRecords: null
            })
        }
        //uncomment
        return this._httpClient.post<any>(`${this._apiBaseUrl}/${ApiURLConstants.DailyTransferLimit}`,
            {
                'SessionId': 'sample string 1',
                'AccountNumber': AuthService.FullyAuthenticatedUser.UserId,
                'StartDate': '2018-07-15T12:47:30.4494839+01:00',
                'EndDate': '2018-07-15T12:47:30.4494839+01:00',
                'TransactionCount': 5,
                'TransactionOwner': 6,
                'UserId': AuthService.FullyAuthenticatedUser.UserId,
                'transactionType': 'AHP'
            });

    }


    getBanks(): Observable<any> {
        // return of({
        //     ResponseCode:'00',
        //     Banks:[
        //         {
        //             name: 'Stanbic',
        //             code: '002'
        //         },
        //         {
        //             name: 'Zenith',
        //             code: '002'
        //         },
        //         {
        //             name: 'UBA',
        //             code: '002'
        //         }
        //     ]
        // });

        //uncomment
        if (this._settings.UseMockData) {

            const _banks = {
                ResponseCode: '00',
                Banks: [{
                    name: 'Stanbic',
                    code: '20'
                }, {
                    name: 'Zenith',
                    code: '10'
                }, {
                    name: 'Standard Chartered',
                    code: '1'
                }]
            }
            return of(
                _banks
            )
        }
        return this._httpClient.post<any>(`${this._apiBaseUrl}/${ApiURLConstants.BankList}`,
            { UserId: AuthService.FullyAuthenticatedUser ? AuthService.FullyAuthenticatedUser.UserId : '' })
            ;
    }


    getCountryCodes(): Promise<Country[]> {
        return new Promise<Country[]>((resolve, reject) => {
            const _cList = countryList.sort((a, b) => a.countryName.localeCompare(b.countryName));
            resolve(_cList);
        });
    }



    doNameEnquiry(payload: NameEnquiryRequestPayload): Promise<NameEnquiryRequestResponse> {
        payload.UserId = !this._loggedInUser ? '' : this._loggedInUser.UserId;
        payload.transferMedium = payload.destinationBankCode === '221' ? 0 : 1;
        return this._httpClient.post<NameEnquiryRequestResponse>(`${this._apiBaseUrl}/TransactionManagement/DoNameEnquiry`, payload)
            .toPromise();

    }

    initiateNonEncryptOtpRequest(reasonCode, reasonDescription: string = ''): Promise<OtpRequestResponse> {
        const payload = this.buildOtpRequestPayload(reasonCode, reasonDescription);
        return this._httpClient.post<OtpRequestResponse>(`${this._apiBaseUrl}/UserProfileManagement/InitiateOTPRequest`, payload)
            .toPromise();
    }

    initiateOtpRequest(reasonCode, reasonDescription: string = ''): Promise<OtpRequestResponse> {
        const payload = this.buildOtpRequestPayload(reasonCode, reasonDescription);
        return this._httpClient.post<OtpRequestResponse>(`${this._apiBaseUrl}/UserProfileManagement/InitiateOTPRequest`, payload)
            .toPromise();
    }

    getAuthorizedNIN(payload: any): Promise<any> {
        return;
    }

    getAuthorizedBVN(payload:GetAuthorizedBvn): Promise<any> {
        const url = `${this._apiBaseUrl}/UserProfileManagement/GetAuthorizedBVN?userId=${payload.userId}&otp=${payload.otp}&otpReference=${encodeURIComponent(payload.otpReference)}&sessionId=${payload.sessionId}&cif=${AuthService.FullyAuthenticatedUser.CifId}`

        return this._httpClient.get<any>(url).toPromise();
    }

    fetchRemitaPaymentDetails(rrrNumber: string): Promise<RemitaPaymentDetailsRequestResponse> {
        const requestPayload = this.buildRemitaDetailsFetchRequestPayload(rrrNumber);
        return this._httpClient.post<RemitaPaymentDetailsRequestResponse>(
            `${this._apiBaseUrl}/TransactionManagement/DoNameEnquiry`, requestPayload)
            .toPromise();
    }

    initiateBulkBulkNameEnquiry(payload: NameEnquiryBatchRequestPayload): Promise<NameEnquiryBatchRequestResponse> {
        // const requestPayload = this.updateBulkNameEnquiryRequestPayload(payload);
        return this._httpClient.post<NameEnquiryBatchRequestResponse>(
            `${this._apiBaseUrl}/TransactionManagement/InitiateBulkTransfer `, payload)
            .toPromise();
    }


    getPendingRequest<T>(payload: any): Promise<T> {
        if (!payload['RecordType']) {
            payload['RecordType'] = 'PENDING';
        }
        const loggedInUser = this._authService.getLoggedInUser();
        payload['UserId'] = loggedInUser && loggedInUser.UserId ? loggedInUser.UserId : '';
        return this._httpClient.post<T>(`${this._apiBaseUrl}/${ApiURLConstants.GET_PENDING_REQUEST}`, payload).toPromise();
    }

    private buildOtpRequestPayload(reasonCode, reasonDescription): PasswordChangeOtpRequestPayload {
        const loggedInUser = this._authService.getLoggedInUser();
        const payload = {
            UserId: loggedInUser && loggedInUser['UserId'] ? loggedInUser.UserId : '',
            ReasonCode: reasonCode,
            CifId: loggedInUser && loggedInUser['CifId'] ? loggedInUser.CifId : ''
        };
        if (reasonDescription && reasonCode === '20') {
            payload['ReasonDescription'] = reasonDescription;
            payload['reasonDescription'] = reasonDescription;
        }
        return payload;
    }

    getPhoneNumberFor3SBanking(): Observable<GenericApiResponse> {
        const loggedInUser = this._authService.getLoggedInUser();
        const payload = { UserId: loggedInUser && loggedInUser.UserId ? loggedInUser.UserId : '' };
        return this._httpClient.post<any>(`${this._apiBaseUrl}/${ApiURLConstants.SSS_BANKING_PHONENUMBER}`, payload);
    }

    getCardExpiryNotifications(userCif: string): Observable<GenericApiResponse> {
        const loggedInUser = this._authService.getLoggedInUser();
        userCif = userCif ? userCif : loggedInUser ? loggedInUser.CifId : '';
        const payload = { UserId: loggedInUser ? loggedInUser.UserId : '', CifId: userCif };
        return this._httpClient.post<any>(`${this._apiBaseUrl}/${ApiURLConstants.CARD_EXPIRY_NOTIFICATIONS}`, payload);
    }

    consentToCardExpiryAutoRenewal(accountNumber: string, consent: string): Observable<GenericApiResponse> {
        const loggedInUser = this._authService.getLoggedInUser();
        const payload = {
            UserId: loggedInUser && loggedInUser.UserId ? loggedInUser.UserId : '',
            AccountNo: accountNumber,
            Consent: consent
        };
        return this._httpClient.post<any>(`${this._apiBaseUrl}/${ApiURLConstants.CARD_EXPIRY_NOTIFICATIONS_CONSENT}`, payload);
    }

    private buildRemitaDetailsFetchRequestPayload(rrrNumber) {
        return {
            UserId: this._loggedInUser && this._loggedInUser['UserId'] ? this._loggedInUser.UserId : '',
            destinationAccountNo: rrrNumber,
            transferMedium: '1',
            destinationBankCode: 'REMITA',
            sourceAccountNo: this._loggedInUser && this._loggedInUser['UserId'] ? this._loggedInUser.UserId : ''
        };
    }

    private updateBulkNameEnquiryRequestPayload(payload: NameEnquiryBatchRequestPayload): NameEnquiryBatchRequestPayload {
        payload.userId = !this._loggedInUser ? '' : this._loggedInUser.UserId;
        return payload;
    }
}


