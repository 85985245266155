import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../appconfig.service';
import { BranchListResponse } from '../../models/service-request/branch';
import { ChequeBookRequestresponse, ServiceRequestPayload } from '../../models/service-request/chequebook';
import { RequestResponse } from '../../models/account';
import { AuthService } from '../auth.service';
import { AppUser } from '../../models/auth';
import { ApiURLConstants } from '../../helpers/apiURLConstants';
import { GenericApiResponse } from '../../models/payloads/generic-response';
import { Observable } from '../../../../../node_modules/rxjs';
import {environment} from '../../../../environments/environment';

@Injectable()
export class RequestService {

  environment = environment.deleteBeneficiaryBaseURL;
    private _apiBaseUrl: string;
    private _loggedInUser: AppUser;
    constructor(private _httpClient: HttpClient, private _authService: AuthService, settings: AppConfigService) {
        this._apiBaseUrl = settings.APIBaseUrl;
        this._loggedInUser = _authService.getLoggedInUser();
    }

    loadBranchList(): Promise<BranchListResponse> {
        const loggedUser = this._authService.getLoggedInUser();
        return this._httpClient
          .post<BranchListResponse>(
            `${this._apiBaseUrl}/AccountManagement/GetBranchList`,
            { UserId: loggedUser ? loggedUser.UserId : "" }
          )
          .toPromise();
    }



    logRetailServiceRequest(payload): Observable<GenericApiResponse> {
        return this._httpClient.post<GenericApiResponse>(`${this._apiBaseUrl}/${ApiURLConstants.LOG_SERVICE_REQUEST}`,
            payload);
    }

  getActiveCards(accountNumber: any): Observable<any> {
      const payload = {
        'AccountNumber': accountNumber
      };
      return this._httpClient.post<any>(`${this.environment}/AccountManagement/GetActiveCards`, payload);
    }


    // logRetailServiceRequest(payload, requestType: string): Observable<GenericApiResponse> {
    //     const requestPayload = this.buildServiceRequestPayload(requestType, payload);
    //     return this._httpClient.post<GenericApiResponse>(`${this._apiBaseUrl}/${ApiURLConstants.LOG_SERVICE_REQUEST}`,
    //         requestPayload);
    // }

    // sendChequeBookRequest(payload): Promise<GenericApiResponse> {
    //     const requestPayload = this.buildServiceRequestPayload('REQUEST_CHEQUE_BOOK', payload);
    //     return this._httpClient.post<GenericApiResponse>(`${this._apiBaseUrl}/${ApiURLConstants.LOG_SERVICE_REQUEST}`,
    //         requestPayload)
    //         .toPromise();
    // }

    // sendStopChequeRequest(payload: any): Promise<RequestResponse> {
    //     const requestPayload = this.buildServiceRequestPayload('STOP_CHEQUE', payload);
    //     return this._httpClient.post<RequestResponse>(`${this._apiBaseUrl}/AccountManagement/InitiateServiceRequest`,
    //         requestPayload).toPromise();
    // }

    // sendComplaintRequest(payload): Promise<RequestResponse> {
    //     const requestPayload = this.buildServiceRequestPayload('CUSTOMER_COMPLAINT', payload);
    //     return this._httpClient.post<RequestResponse>(`${this._apiBaseUrl}/AccountManagement/InitiateServiceRequest`, requestPayload)
    //         .toPromise();
    // }

    // sendAccountFreezeRequest(payload): Promise<RequestResponse> {
    //     const requestPayload = this.buildServiceRequestPayload('FREEZE_ACCOUNT', payload);
    //     return this._httpClient.post<RequestResponse>(`${this._apiBaseUrl}/AccountManagement/InitiateServiceRequest`, requestPayload)
    //         .toPromise();
    // }

    // sendNewCardRequest(payload): Promise<RequestResponse> {
    //     const requestPayload = this.buildServiceRequestPayload('REQUEST_CARD', payload);
    //     return this._httpClient.post<RequestResponse>(`${this._apiBaseUrl}/AccountManagement/InitiateServiceRequest`, requestPayload)
    //         .toPromise();
    // }

    // sendChangeAccountNameRequest(payload): Promise<RequestResponse> {
    //     const requestPayload = this.buildServiceRequestPayload('MODIFY_ACCOUNT', payload);
    //     return this._httpClient.post<RequestResponse>(`${this._apiBaseUrl}/AccountManagement/InitiateServiceRequest`, requestPayload)
    //         .toPromise();
    // }

    private buildServiceRequestPayload(serviceName, payload): ServiceRequestPayload {
        return {
            RequestType: serviceName,
            UserId: this._loggedInUser && this._loggedInUser.UserId ? this._loggedInUser.UserId : '',
            SessionId: this._loggedInUser && this._loggedInUser.SessionId ? this._loggedInUser.SessionId : '',
            RequestPayLoad: JSON.stringify(payload)
        };
    }
}
