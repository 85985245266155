
export class Constants {
    public static get StorageTokenKey(): string { return 'X-STC-FST-BOL267XH'; }
    public static get StorageLoginTokenKey(): string { return 'X-STC-LST-BOL267XH'; }
    public static get StorageCachePKey(): string { return 'X-STC-PST-BOL267XH'; }
    public static get DevModeStorageUserDetailsKey(): string { return 'userProfile'; }
    public static get DevModeStorageUserAccountKey(): string { return 'userAccount'; }
    public static get APITechnicalErrorMessage(): string { return 'Your request cannot be completed at the moment due to a technical error, please try again later.'; }
    public static get UnauthorizedStatusCode(): number { return 401; }
    public static get NoRecentTransactionErrorMessage(): string { return 'Transaction not available.'; }
    public static get StanbicBankCode(): string { return '221'; }
    public static get StanbicVirtualBankCode(): string { return '304'; }
    public static get NGNCurrency(): string { return 'NGN'; }
    public static get USDCurrency(): string { return 'USD'; }
    public static get STANBIC_CONTACT_EMAIL(): string { return 'customercarecentre@stanbicibtc.com'; }
    public static get ValidAccountNumberLength(): number { return 10; }
    public static get SESSION_LOGOUT_COUNTER(): number { return 60; }
    static get TIMEOUT_LOGOUT_MESSAGE(): string { return "To protect your account, we have automatically logged you out of internet banking. You can login again when you are ready."; }
    static get MULTI_SESSION_LOGOUT_MESSAGE(): string { return "You can only have a single sign on."; }


    static get NIP_CHANNEL_CODE(): string { return "NIP"; }
    static get ISW_CHANNEL_CODE(): string { return "ISW"; }
    static get ACH_CHANNEL_CODE(): string { return "ACH"; }

    static get NIP_CHANNEL_CODE_IN_DIGIT(): number { return 1; }
    static get ISW_CHANNEL_CODE_IN_DIGIT(): number { return 2; }
    static get ACH_CHANNEL_CODE_IN_DIGIT(): number { return 4; }
    // static get AUTO_LOGOUT_TIME_IN_MILLISEC(): number { return 600000; }
    static get AUTO_LOGOUT_TIME_IN_MILLISEC(): number { return 300000; }
    // static get AUTO_LOGOUT_TIME_IN_MILLISEC(): number { return 20000; }

    static get REMITA_BANK_CODE(): string { return "REMITA"; }




    public static get BENEFICIARY_ALREADY_EXIST(): string { return 'The account details provided already belongs to a beneficiary. Kindly review your entry.' };

    public static get AuthToken(): string { return 'ssm_token'; }
    public static get Store_Bank(): string { return '_banks'; }
    public static get Store_Accounts(): string { return '_accounts'; }
    public static get Store_trans(): string { return '_transactions'; }
    public static get Store_AirtimeBeneficiaries(): string { return '_airtime_beneficiaries'; }
    public static get Store_AccountBeneficiaries(): string { return '_account_beneficiaries'; }
    public static get Auth_Data(): string { return '_authData'; }
    public static get User_Id(): string { return '_userId'; }
    public static get Secret_Question(): string { return '_secret'; }
    public static get beneficiaries_pending_payment(): string { return 'beneficiaries_to_pay'; }
    public static get Store_ActiveUpload_Data(): string { return '_active_file_upload'; }
    static get STOP_SCHEDULE_PAYMENT_OTP_REASON_DESCRIPTION(): string { return "Stop Schedule"; }
    static get STOP_NEXT_SCHEDULE_PAYMENT_OTP_REASON_DESCRIPTION(): string { return "Stop Next Schedule Payment"; }
    static get SCHEDULE_TRANX_STOPPED_MESSAGE(): string { return "The selected schedule transfers has been stopped"; }
    static get DOM_REFRESHED_KEY(): string { return '_dom_refreshed'; }

    public static get SCHEDUE_PAYMENT_FREQUENCY_TYPES(): any {
        return [
            { Name: 'Just once', Id: 0 },
            { Name: 'Daily', Id: 1 },
            { Name: 'Weekly', Id: 2 },
            { Name: 'Monthly', Id: 3 },
            { Name: 'Quarterly', Id: 4 },
            { Name: 'Yearly', Id: 5 }]
    }
}

