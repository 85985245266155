import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../appconfig.service';
import { AuthService } from '../auth.service';
import { BulkPaymentBatchApprovalResponse } from '../../models/approvals/file-upload.approval';
import { ApiURLConstants } from '../../helpers/apiURLConstants';
import { GenericApiResponse } from '../../models/payloads/generic-response';
import { GenericApprovalPayload } from '../../models/payloads/transactions';

@Injectable()
export class PaymentApprovalService {

    private _apiBaseUrl: string;
    constructor(private _httpClient: HttpClient, _settings: AppConfigService, private _authService: AuthService) {
        this._apiBaseUrl = _settings.APIBaseUrl;
    }

    approvePendingBillPaymentRequest(payload: GenericApprovalPayload): Promise<BulkPaymentBatchApprovalResponse> {
        payload = this.updateRequestPayloadWithUserId(payload);
        return this._httpClient.post<BulkPaymentBatchApprovalResponse>
            (`${this._apiBaseUrl}/${ApiURLConstants.APPROVE_PENDING_BILLPAYMENT}`, payload).toPromise();
    }

    approvePendingBillPaymentRequestWithSelectedStage(payload: any):
        Promise<GenericApiResponse> {
        payload = this.updateRequestPayloadWithUserId(payload);
        return this._httpClient.post<GenericApiResponse>(
            `${this._apiBaseUrl}/${ApiURLConstants.APPROVE_PENDING_BILLPAYMENT_WITH_NEXTSTAGE_SELECTION}`, payload)
            .toPromise();
    }

    private updateRequestPayloadWithUserId(payload: any): any {
        const loggedInUser = this._authService.getLoggedInUser();
        payload['userId'] = loggedInUser && loggedInUser.UserId ? loggedInUser.UserId : '';
        return payload;
    }
}
