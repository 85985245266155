import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA, MatStepper } from '@angular/material';
import { RequestRejectionDialogData, RequestApprovalRejectionPayload } from 'src/app/core/models/misc-requests-model';
import { MiscService } from 'src/app/core/services/misc.service';
import { OtpReasonCodes, OTPType, ResponseCodes } from 'src/app/core/models/Enumerations';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ApprovalHistoryFactory } from 'src/app/core/services/workflow/approvals.history.service';
import { VariablesService } from 'src/app/core/services/variables.service';
import { PensionService } from 'src/app/core/services/pension.service';

@Component({
  selector: 'app-reject-request',
  templateUrl: './reject-request.component.html',
  styleUrls: ['./reject-request.component.css']
})
export class RejectRequestComponent implements OnInit, OnDestroy {

  ownloaderIsActive: boolean;
  ownloaderMsg: string;
  firstStepperTabIsActive: boolean = true;
  _otpReferenceId: string;
  oneTimePassCode: string;

  @ViewChild('stepper') _activeStepper: MatStepper;
  isCustomerHWTEnabled: boolean;
  isOTPStepperVisible: boolean;
  hwtFormGroup:FormGroup;
  constructor(private dialogInstance: MatDialogRef<RejectRequestComponent>,
    private _snackbar: MatSnackBar, @Inject(MAT_DIALOG_DATA) public data: RequestRejectionDialogData,
    private formBuilder:FormBuilder,
    private _miscService: MiscService, private _approvalService: ApprovalHistoryFactory,private pensionApprovalService:PensionService
  ) {

    this.hwtFormGroup = formBuilder.group({
      hwt: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.isCustomerHWTEnabled = VariablesService.CustomerHWTEnabled;
    // this.isCustomerHWTEnabled = true;
    if (this.isCustomerHWTEnabled) {
      this.isOTPStepperVisible = false;
    } else {
      this.isOTPStepperVisible = true;
    }

  }

  returnToOTPStepper(){
    if (!this.data.payload.RejectionMessage || this.data.payload.RejectionMessage.length < 2) {
      this._snackbar.open('Please provide a valid rejection message ..', 'Ok', { duration: 45000 });
      return;
    }
    this.ownloaderIsActive = true;
    this.ownloaderMsg = 'initiating .. please wait';
    this._miscService.initiateOtpRequest(OtpReasonCodes.GENERIC).then(
      (response) => {
        console.log(response);
        this.ownloaderMsg = '';
        this.ownloaderIsActive = false;
        if (!response || response.ResponseCode !== ResponseCodes.SUCCESS) {
          this._snackbar.open(`Failed to initiate request reject process. Server responded with -> ${response.ResponseFriendlyMessage}`,
            'Ok', { duration: 25000 }).onAction();
          return;
        }
        var hardwareTokenStepper = document.getElementById('hardwareTokenStepper');
        hardwareTokenStepper.style.display = 'none';
        this.isOTPStepperVisible = true;
        this._otpReferenceId = response.ResponseDescription;
      },
      (error) => {
        console.log(error);
        this.ownloaderIsActive = false;
        this.ownloaderMsg = '';
        this._snackbar.open(`We encountered an error while processing your request. please retry`, 'Ok', { duration: 25000 });
      }
    );
  }
  onInitiRejectionRequestOTP() {
    if (!this.data.payload.RejectionMessage || this.data.payload.RejectionMessage.length < 2) {
      this._snackbar.open('Please provide a valid rejection message ..', 'Ok', { duration: 45000 });
      return;
    }
    this.ownloaderIsActive = true;
    this.ownloaderMsg = 'initiating .. please wait';
    this._miscService.initiateOtpRequest(OtpReasonCodes.GENERIC).then(
      (response) => {
        console.log(response);
        this.ownloaderMsg = '';
        this.ownloaderIsActive = false;
        if (!response || response.ResponseCode !== ResponseCodes.SUCCESS) {
          this._snackbar.open(`Failed to initiate request reject process. Server responded with -> ${response.ResponseFriendlyMessage}`,
            'Ok', { duration: 25000 }).onAction();
          return;
        }
        this._otpReferenceId = response.ResponseDescription;
        this._activeStepper.next();
        this.firstStepperTabIsActive = this._activeStepper.selectedIndex === 0;
      },
      (error) => {
        console.log(error);
        this.ownloaderIsActive = false;
        this.ownloaderMsg = '';
        this._snackbar.open(`We encountered an error while processing your request. please retry`, 'Ok', { duration: 25000 });
      }
    );

  }

  onVerifyRejectionRequesOTP(form: NgForm, stepper: MatStepper, useHardwareToken:boolean) {
    
    if (useHardwareToken) {
      if (!this.hwtFormGroup.valid || !this.hwtFormGroup.controls.hwt) {
        this._snackbar.open(`Please provide a valid token number`, 'Ok', { duration: 25000 });
        return;
      }
    }
    if (!form.valid) {
      this._snackbar.open(`Invalid One Time Pin provided. Please provide a valid OTP to continue`, 'Ok',
        { duration: 10000 });
      return;
    }
    if(this.data.payload.OperationType && this.data.payload.OperationType === 'PENSION_REMITTANCE'){
      this.sendPensionRemittanceRejectRequest(stepper);
      return;
    }
    this.sendRejectionRequest(stepper);
  }

  private sendPensionRemittanceRejectRequest(stepper: MatStepper){
    this.ownloaderIsActive = true;
    this.ownloaderMsg = 'verifying OTP .. please wait';
    const payload = {
      // userId: this.oneTimePassCode,
      fileId: this.data.payload.OperationId,
      rejectionMessage: this.data.payload.RejectionMessage,
      otp: this.oneTimePassCode,
      otpReference: this._otpReferenceId
    };
    this.pensionApprovalService.rejectPendingPaymentRequest(payload).subscribe(
      (response) => {
        this.ownloaderIsActive = false;
        this.ownloaderMsg = '';
        console.log(response);
        if (response && response.ResponseCode === ResponseCodes.SUCCESS) {
          this._snackbar.open(`${response.ResponseDescription}`, 'Ok', { duration: 20000 });
          stepper.next();
          this.dialogInstance.close('success');
        }
      },
      (error) => {
        this.ownloaderIsActive = false;
        this.ownloaderMsg = '';
        this._snackbar.open(`An error occured while rejecting request .. Please try after some time`, 'Ok', { duration: 30000 });
      }
    );
  }
  private sendRejectionRequest(stepper: MatStepper) {
    this.ownloaderIsActive = true;
    this.ownloaderMsg = 'verifying OTP .. please wait';
    const payload: RequestApprovalRejectionPayload = {
      RejectionMessage: this.oneTimePassCode,
      OperationId: this.data.payload.OperationId,
      OperationType: this.data.payload.OperationType,
      OTP: this.oneTimePassCode,
      SourceReferenceId: this._otpReferenceId,
      OTPType:0
    };
    if(!this.isOTPStepperVisible){
      payload["OTP"]=  this.hwtFormGroup.controls.hwt.value;
      payload["OTPType"] = OTPType.HWT;
    }
    else{
      payload["OTPType"] = !this.isOTPStepperVisible ? OTPType.HWT : this.isOTPStepperVisible ? OTPType.OTP : OTPType.NONE;
      payload["OTP"]=  !this.isOTPStepperVisible ? this.hwtFormGroup.controls.hwt.value : this.oneTimePassCode;
    }
    this._approvalService.rejectApprovalRequest(payload).then(
      (response) => {
        this.ownloaderIsActive = false;
        this.ownloaderMsg = '';
        // console.log(response);
        if (response && response.ResponseCode === ResponseCodes.SUCCESS) {
          this._snackbar.open(`${response.ResponseFriendlyMessage}`, 'Ok', { duration: 20000 });
          stepper.next();
          // this.dialogInstance.close('success');
        }
      },
      (error) => {
        this.ownloaderIsActive = false;
        this.ownloaderMsg = '';
        this._snackbar.open(`An error occured while rejecting request .. Please try after some time`, 'Ok', { duration: 30000 });
      }
    );
  }

  onCloseDialog() {
    this.dialogInstance.close('success');
  }


  ngOnDestroy(): void {
    if (this.dialogInstance) {
      this.dialogInstance.close();
    }
  }
}
