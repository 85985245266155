

export interface AppUser {
    LastLoginIn: string;
    CifId: string;
    FirstName: string;
    MiddleName: string;
    LastName: string;
    PinStatus: string;
    accountOfficerName: string;
    accountEmail: string;
    BVN?: string;
    BearerToken: string;
    SessionId: string;
    Reference: string;
    NotificationType: string;
    NotificationAddress: string;
    transactionId: string;
    QuestionDetailList?: any[];
    Token: string;
    UserId?: string;
    Role: UserRole;
    MenuProfile: string;
}

export interface UserRole {
    UserId: string;
    roles: Role[];
    menus?: Array<string>;
    menuProfile?: string;
}

export enum Role {
    Initiator = 2,
    Viewer = 4,
    Approver = 8
}
