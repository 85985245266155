import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { AppConfigService } from "./appconfig.service";
import { GenericApiResponse } from "../models/payloads/generic-response";
import {
  GetCustodianPolicyDetailsResponse,
  GetCustodianPolicyDetailsRequest,
  RenewCustodianPolicyRequest,
} from "../models/payloads/custodian";

@Injectable()
export class CustodianInsuranceService {
  private _apiBaseUrl: string;

  constructor(private _httpClient: HttpClient, _settings: AppConfigService) {
    this._apiBaseUrl = _settings.CustodianAPIBaseUrl;
  }

  loadSubsidiaries(): Observable<GenericApiResponse> {
    // return of({
    //   ResponseCode: "00",
    //   ResponseDescription: "Request processed successfully.",
    //   Data: ["General", "Life"],
    // });
    return this._httpClient.get<GenericApiResponse>(
      `${this._apiBaseUrl}/custodian-manager/subsidiaries`
    );
  }

  loadPolicyDetails(
    request: GetCustodianPolicyDetailsRequest
  ): Observable<GetCustodianPolicyDetailsResponse> {
    // return of({
    //   ResponseCode: "00",
    //   ResponseDescription: "Request processed successfully.",
    //   Data: {
    //     ExpiryDate: "05-Mar-2019",
    //     Email: "ajadi@mediareachomd.com",
    //     EngineCapacity: "TBA",
    //     PolicyNumber: "YB/V/29/T0000024C",
    //     InsuredName: "Omolara Ogunyemi",
    //     SubsidiaryType: "General",
    //     Make: "Honda",
    //     Premium: 3333333.0,
    //     ChasisNumber: "1HGCP2F46BA003949",
    //     BusinessUnit: "PRIVATE CAR INSURANCE",
    //     Value: 1000000.0,
    //     PhoneNumber: "08902736542",
    //     RegistrationNumber: "LSR792BU",
    //   },
    // });
    return this._httpClient.post<GetCustodianPolicyDetailsResponse>(
      `${this._apiBaseUrl}/custodian-manager/policy-details`,
      request
    );
  }

  renewPolicy(
    request: RenewCustodianPolicyRequest
  ): Observable<GenericApiResponse> {
    // return of({
    //   ResponseCode: "00",
    //   ResponseDescription: "Request processed successfully.",
    // });

    return this._httpClient.post<GenericApiResponse>(
      `${this._apiBaseUrl}/custodian-manager/renew-policy`,
      request
    );
  }
}
