import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../appconfig.service';
import { ApiURLConstants } from '../../helpers/apiURLConstants';
import { BulkTransferStagingRequestPayload } from '../../models/transfer';
import { AuthService } from '../auth.service';
import {
    SMETransferResponsePayload, WorkflowSelectionRequestPayload,
    StagedBulkPaymentBatchResponse,
    BatchTransactionsResponse,
    SinglePaymentWorkflowSelectionRequestPayload,
    StagedPaymentRequestResponse,
    StagedInternationalPaymentRequestResponse,
    StagedWesternUnionRedemptionRequestResponse
} from '../../models/payloads/transactions';
import { GenericApiResponse } from '../../models/payloads/generic-response';
import { Role } from '../../models/Enumerations';

@Injectable()
export class TransferStagingService {

    private _apiBaseUrl: string;
    constructor(private _httpClient: HttpClient, private _settings: AppConfigService, private _authService: AuthService) {
        this._apiBaseUrl = _settings.APIBaseUrl;
    }

    stageBulkTransferRequest(payload: BulkTransferStagingRequestPayload): Promise<SMETransferResponsePayload> {
        payload = this.updateBulkTransferStagingPayload(payload);
        return this._httpClient.post<SMETransferResponsePayload>(`${this._apiBaseUrl}/${ApiURLConstants.STAGE_BATCH_TRANSFER}`,
            payload).toPromise();
    }

    setBulkTransferApprovalWorkflow(payload: WorkflowSelectionRequestPayload): Promise<GenericApiResponse> {
        payload = this.updateApprovalWorkflowRequestPayload(payload);
        if (this._settings.UseMockData) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve({
                        ResponseCode: '00',
                        ResponseDescription: 'success',
                        ResponseFriendlyMessage: 'selected workflow has been successfully saved. Approval request sent'
                    });
                }, 1000);
            });
        }
        return this._httpClient.post<GenericApiResponse>(`${this._apiBaseUrl}/${ApiURLConstants.SET_BATCH_TRANSFER_APPROVAL_WORKFLOW}`,
            payload).toPromise();
    }

    setSingleTransferApprovalWorkflow(payload: SinglePaymentWorkflowSelectionRequestPayload): Promise<GenericApiResponse> {
        payload = this.updateApprovalWorkflowRequestPayload(payload);
        if (this._settings.UseMockData) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve({
                        ResponseCode: '00',
                        ResponseDescription: 'success',
                        ResponseFriendlyMessage: 'selected workflow has been successfully saved. Approval request sent'
                    });
                }, 1000);
            });
        }
        return this._httpClient.post<GenericApiResponse>(`${this._apiBaseUrl}/${ApiURLConstants.SET_SINGLE_TRANSFER_APPROVAL_WORKFLOW}`,
            payload).toPromise();
    }
    setWesternUnionRedemptionApprovalWorkflow(payload: SinglePaymentWorkflowSelectionRequestPayload): Promise<GenericApiResponse> {
        payload = this.updateApprovalWorkflowRequestPayload(payload);
        if (this._settings.UseMockData) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve({
                        ResponseCode: '00',
                        ResponseDescription: 'success',
                        ResponseFriendlyMessage: 'selected workflow has been successfully saved. Approval request sent'
                    });
                }, 1000);
            });
        }
        return this._httpClient.post<GenericApiResponse>(`
        ${this._apiBaseUrl}/${ApiURLConstants.SET_WESTERN_UNION_REDEMPTION_APPROVAL_WORKFLOW}`, payload).toPromise();
    }
    setInternationalTransferApprovalWorkflow(payload: any): Promise<GenericApiResponse> {
        payload = this.updateApprovalWorkflowRequestPayload(payload);
        if (this._settings.UseMockData) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve({
                        ResponseCode: '00',
                        ResponseDescription: 'success',
                        ResponseFriendlyMessage: 'selected workflow has been successfully saved. Approval request sent'
                    });
                }, 1000);
            });
        }
        return this._httpClient.post<GenericApiResponse>(
            `${this._apiBaseUrl}/${ApiURLConstants.SET_INTERNATIONAL_TRANSFER_APPROVAL_WORKFLOW}`, payload)
            .toPromise();
    }

    setMultipleTransferApprovalWorkflow(payload: any): Promise<GenericApiResponse> {
        payload['UserId'] = this._authService.getLoggedInUser() ? this._authService.getLoggedInUser().UserId : '';
        return this._httpClient.post<GenericApiResponse>(
            `${this._apiBaseUrl}/${ApiURLConstants.SET_MULTIPLE_BENEFICIARY_TRANSFER_APPROVAL_WORKFLOW}`, payload)
            .toPromise();
    }

    getStagedBulkPayments(payload): Promise<StagedBulkPaymentBatchResponse> {
        const loggedInUser = this._authService.getLoggedInUser();
        payload['UserId'] = loggedInUser ? loggedInUser.UserId : '';
        if (loggedInUser && loggedInUser.Role && loggedInUser.Role.roles.includes(Role.Initiator)) {
            payload['ForInitiator'] = true;
        }
        return this._httpClient.post<StagedBulkPaymentBatchResponse>
            (`${this._apiBaseUrl}/${ApiURLConstants.GET_PENDING_BULK_APPROVAL_REQUEST}`, payload).toPromise();
    }
    getStagedBulkPaymentTransactions(payload: any): Promise<BatchTransactionsResponse> {
        payload['UserId'] = this._authService.getLoggedInUser() ? this._authService.getLoggedInUser().UserId : '';
        return this._httpClient.post<BatchTransactionsResponse>(`${this._apiBaseUrl}/${ApiURLConstants.GET_BATCH_TRANSACTIONS}`, payload)
            .toPromise();
    }

    getStagedPaymentRequest(payload): Promise<StagedPaymentRequestResponse> {
        const loggedInUser = this._authService.getLoggedInUser();
        payload['UserId'] = loggedInUser ? loggedInUser.UserId : '';
        payload['OperationType'] = 'SELF_TRANSFER';
        if (loggedInUser && loggedInUser.Role && loggedInUser.Role.roles.includes(Role.Initiator)) {
            payload['ForInitiator'] = true;
        }
        return this._httpClient.post<StagedPaymentRequestResponse>
            (`${this._apiBaseUrl}/${ApiURLConstants.GET_PENDING_SINGLE_APPROVAL_REQUEST}`, payload).toPromise();
    }

    getStagedInternationalPaymentRequest(payload): Promise<StagedInternationalPaymentRequestResponse> {
        const loggedInUser = this._authService.getLoggedInUser();
        payload['UserId'] = loggedInUser ? loggedInUser.UserId : '';
        payload['OperationType'] = 'INTERNATIONAL_TRANSFER';
        if (loggedInUser && loggedInUser.Role && loggedInUser.Role.roles.includes(Role.Initiator)) {
            payload['ForInitiator'] = true;
        }
        return this._httpClient.post<StagedInternationalPaymentRequestResponse>
            (`${this._apiBaseUrl}/${ApiURLConstants.GET_PENDING_INTERNATIONAL_PAYMENT_APPROVAL_REQUEST}`, payload).toPromise();
    }

    getPendingWesternUnionRedemptionRequest(payload): Promise<StagedWesternUnionRedemptionRequestResponse> {
        const loggedInUser = this._authService.getLoggedInUser();
        payload['UserId'] = loggedInUser ? loggedInUser.UserId : '';
        if (loggedInUser && loggedInUser.Role && loggedInUser.Role.roles.includes(Role.Initiator)) {
            payload['ForInitiator'] = true;
        }
        return this._httpClient.post<StagedWesternUnionRedemptionRequestResponse>
            (`${this._apiBaseUrl}/${ApiURLConstants.GET_PENDING_WESTERN_UNION_REDEMPTION_REQUESTS}`, payload).toPromise();
    }
    private updateBulkTransferStagingPayload(payload: BulkTransferStagingRequestPayload): BulkTransferStagingRequestPayload {
        payload.userId = this._authService.getLoggedInUser() ? this._authService.getLoggedInUser().UserId : '';
        return payload;
    }

    private updateApprovalWorkflowRequestPayload(payload: any): any {
        payload.UserId = this._authService.getLoggedInUser() ? this._authService.getLoggedInUser().UserId : '';
        return payload;
    }
}
