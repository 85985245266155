import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { VirtualCardComponent } from "./virtual-card.component";
import { VirtualCardManagementComponent } from "./virtual-card-management/virtual-card-management.component";
import { VirtualCardRequestComponent } from "./virtual-card-request/virtual-card-request.component";
import { VirtualCardRoutingModule } from "./virtual-card-routing.module";
import {
  MatButtonModule,
  MatCardModule,
  MatFormFieldModule,
  MatInputModule,
  MatProgressBarModule,
  MatRadioModule,
  MatSelectModule,
  MatIconModule,
  MatStepperModule,
  MatDialogModule
} from "@angular/material";
import { ReactiveFormsModule } from "@angular/forms";
import { FormsModule } from "@angular/forms";
import { SwiperModule } from "ngx-swiper-wrapper";
import { SWIPER_CONFIG } from "ngx-swiper-wrapper";
import { SwiperConfigInterface } from "ngx-swiper-wrapper";
import { RetrievePinComponent } from "./virtual-card-management/retrieve-pin/retrieve-pin.component";
import { VirtualCardService } from "src/app/core/services/virtual-card.service";
import { CardFormartPipe } from "./card-formart.pipe";
import { HotlistCardComponent } from "./virtual-card-management/hotlist-card/hotlist-card.component";
import { AppMaterialModule } from "src/app/app.material.module";


const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: "horizontal",
  slidesPerView: "auto",
};
@NgModule({
  declarations: [
    VirtualCardComponent,
    VirtualCardManagementComponent,
    VirtualCardRequestComponent,
    RetrievePinComponent,
    CardFormartPipe,
    HotlistCardComponent,
  ],
  imports: [
    CommonModule,
    VirtualCardRoutingModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatProgressBarModule,
    MatStepperModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    MatRadioModule,
    MatCardModule,
    SwiperModule,
    FormsModule,
    MatDialogModule,
    AppMaterialModule
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG,
    },
    VirtualCardService,
  ],
  entryComponents: [],
})
export class VirtualCardModule {}
