import { Injectable } from "@angular/core";
import { StorageService } from "./storage.service";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";

import { AppUser, UserRole } from "../models/auth";
import { Role } from "../models/Enumerations";
import { ApiURLConstants } from "../helpers/apiURLConstants";
import {
  LoginRequestPayload,
  LoginResponse,
  ValidateLoginOTPRequestPayload,
  SecurityQuestionsResponse,
  ConfirmRIBUserRegistrationOnRIBResponse,
  ValidateLoginOTPResponse,
  InitiateOtpRequestPayload,
  InitiateOtpResponse,
  UserCreationRequestPayload,
  ChangePasswordRequestPayload,
  RetailUserRegisteredSecurityQuestionsRequestPayload,
  ResetPasswordRequestPayload,
} from "../models/payloads/auth";

import { Constants } from "../helpers/appConstants";
import { AppConfigService } from "./appconfig.service";

import {
  PasswordChangeOtpRequestPayload,
  PasswordChangeOtpResponse,
  PasswordChangeRequestPayload,
  PasswordChangeOtpRequestResponse,
  CustomerSecret,
} from "../models/auth";
import { OtpReasonCodes } from "../models/Enumerations";
import { VariablesService } from "./variables.service";
import { GenericApiResponse } from "../models/payloads/generic-response";
import { UtilService } from "./helpers.service";
import {
  SurveyAPIResponse,
  PostSurveyQuestionAnswer,
} from "../models/payloads/survey";
// import { AppUser, Role, UserRole } from '../models/user/AppUser';

declare var $: any;

@Injectable()
export class AuthService {
  static FullyAuthenticatedUser: AppUser; // authenticated with otp validation.
  static PartiallyAuthenticatedUser: AppUser; // authenticated without otp validation.
  static userIsFullyLoggedIn: boolean;
  static userIsPartiallyLoggedIn: boolean;
  static UnauthenticatedUserId: string;
  static userCreationRequestId: string;
  static userCreationCIFId: string;
  private authRequestBaseUrl: string;
  static useOnlyContentTypeHeader: boolean;
  static FirstValidateOTPToken: string;
  static ValidateUserSession: boolean; // specify if session should be validated on all API calls
  static clearSessionTokenOnLogout: boolean;
  static useLoginTokenForSessionTokenOnNextAPICall: boolean; // The first token generated after login call is used for otp validation.
  static signalRConnectionOpened: boolean;
  static IntellixDeviceSession: string;
  private surveyRequestBaseUrl: string;

  constructor(
    private _storageService: StorageService,
    private _httpClient: HttpClient,
    private appConfig: AppConfigService
  ) {
    this.authRequestBaseUrl = `${this.appConfig.APIBaseUrl}`;
    this.surveyRequestBaseUrl = `${this.appConfig.SurveyAPIBaseUrl}`;

    if (this.GetAccessToken()) {
      AuthService.userIsFullyLoggedIn = true;
    } else {
      AuthService.userIsFullyLoggedIn = false;
    }

    AuthService.userIsPartiallyLoggedIn = false;
  }

  SetAccessPassCache(passCache: string) {
    this._storageService.saveRaw(Constants.StorageCachePKey, passCache);
  }

  setAccessToken(accessToken: string) {
    this._storageService.saveRaw(Constants.StorageTokenKey, accessToken);
  }

  setPartialLoginToken() {
    AuthService.userIsPartiallyLoggedIn = true; // logged in without OTP validation
  }

  GetLoginToken() {
    return this._storageService.find(Constants.StorageLoginTokenKey);
  }

  GetAccessToken() {
    return this._storageService.find(Constants.StorageTokenKey);
  }

  GetAccessPassCache() {
    return this._storageService.find(Constants.StorageCachePKey);
  }

  getSurvey(cif: string): Observable<SurveyAPIResponse> {
    return this._httpClient.post<any>(
      `${this.surveyRequestBaseUrl}/GetSurvey`,
      { cifid: cif }
    );
    // return of({
    //   ResponseCode: "00",
    //   ResponseDescription: "Survey returned successfully",
    //   Data: [
    //     {
    //       name: "satisfied",
    //       type: "radiogroup",
    //       choices: [
    //         {
    //           text: "Happy",
    //           value: "Happy",
    //         },
    //         {
    //           text: "Not happy",
    //           value: "Not happy",
    //         },
    //         {
    //           text: "Unsure",
    //           value: "Unsure",
    //         },
    //       ],
    //       title: "How happy are you with the service you just received?",
    //       requiredErrorText:
    //         "Please tell us how happy you are with your experience",
    //     },
    //     {
    //       name: "reasonForExperience",
    //       type: "ddl",
    //       choices: [
    //         {
    //           text: "Look and feel of platform",
    //           value: "Look and feel of platform",
    //         },
    //         {
    //           text: "Slow performance",
    //           value: "Slow performance",
    //         },
    //         {
    //           text: "Failed transaction",
    //           value: "Failed transaction",
    //         },
    //         {
    //           text: "Transaction timeout",
    //           value: "Transaction timeout",
    //         },
    //         {
    //           text: "Log-in issues",
    //           value: "Log-in issues",
    //         },
    //         {
    //           text: "Limited features",
    //           value: "Limited features",
    //         },
    //         {
    //           text: "Others",
    //           value: "Others",
    //         },
    //       ],
    //       title:
    //         "Do you mind telling us a little bit more about your experience?",
    //       requiredErrorText: "Please tell us a bit more about your experience",
    //     },
    //   ],
    // });
  }

  //   postSurvey(reason: string): Promise<any> {
  //     const user = this.getLoggedInUser();
  //     const payload = {
  //       UserId: !user ? "" : user.UserId,
  //       Feedback: "Y",
  //       Comment: !reason ? "" : reason,
  //     };
  //     return this._httpClient
  //       .post<any>(
  //         `${this.authRequestBaseUrl}/UserProfileManagement/PostSurvey`,
  //         payload
  //       )
  //       .toPromise();
  //   }

  static createFullyLoggedInUser(): boolean {
    if (this.PartiallyAuthenticatedUser) {
      this.FullyAuthenticatedUser = new AppUser();
      this.FullyAuthenticatedUser = this.PartiallyAuthenticatedUser;
      this.PartiallyAuthenticatedUser = undefined;

      this.FullyAuthenticatedUser.Role = AuthService.getUserRole(
        this.FullyAuthenticatedUser.UserId,
        this.FullyAuthenticatedUser.MenuProfile
      );

      localStorage.setItem(
        Constants.DevModeStorageUserDetailsKey,
        JSON.stringify(this.FullyAuthenticatedUser)
      );

      return true;
    }

    return false;
  }

  private static getUserRole(id: string, menuProfile: string): UserRole {
    switch (menuProfile) {
      case "S_DSPGM":
        return {
          UserId: id,
          roles: [Role.Initiator],
          menuProfile: menuProfile,
        };
      case "S_DAM":
        return {
          UserId: id,
          roles: [Role.Approver],
          menuProfile: menuProfile,
        };
      case "S_DSM":
        return {
          UserId: id,
          roles: [Role.Viewer],
          menuProfile: menuProfile,
        };
      default:
        return {
          UserId: id,
          roles: [Role.Retail],
          menuProfile: menuProfile,
        };
    }
  }

  static isRetailUser() {
    if (
      !AuthService.FullyAuthenticatedUser ||
      !AuthService.FullyAuthenticatedUser.Role
    ) {
      return false;
    }

    return (
      !AuthService.FullyAuthenticatedUser.Role.menuProfile ||
      (AuthService.FullyAuthenticatedUser.Role.menuProfile &&
        ["S_DSPGM", "S_DAM", "S_DSM"].indexOf(
          AuthService.FullyAuthenticatedUser.Role.menuProfile
        ) == -1)
    );
  }

  useTokenForSubsequentAPICalls(token: string) {
    AuthService.useLoginTokenForSessionTokenOnNextAPICall = false;
    this._storageService.saveRaw(Constants.StorageTokenKey, token);
    this._storageService.delete(Constants.StorageLoginTokenKey);
  }

  logout() {
    //clearLoginSessionToken: used to prevent clearing login details in case an event is thrown
    // from a new browser TAB.

    AuthService.userIsFullyLoggedIn = AuthService.userIsPartiallyLoggedIn =
      false;
    AuthService.FullyAuthenticatedUser = undefined;
    AuthService.PartiallyAuthenticatedUser = undefined;
    AuthService.PartiallyAuthenticatedUser = undefined;
    AuthService.UnauthenticatedUserId = undefined;
    this.removeAllMemoryVariables();

    if (UtilService.currentModalRef && UtilService.currentModalRef.length > 0) {
      for (let modalRef of UtilService.currentModalRef) {
        modalRef.close();
      }

      UtilService.currentModalRef.length = 0;
    }

    if (AuthService.clearSessionTokenOnLogout == true) {
      this._storageService.delete(Constants.StorageTokenKey);
      localStorage.removeItem("tempUserId");

      // $('.custom-add-confirmationModal').remove(); // remove all modal div
      // $('.cdk-overlay-container').remove();

      return this._storageService.delete(Constants.StorageCachePKey);
    }

    return true;
  }

  removeAllMemoryVariables() {
    VariablesService.CustomerAccounts =
      VariablesService.UserAccountBeneficiaries = undefined;
    VariablesService.DailyTransferLimit =
      VariablesService.TransferCharges =
      VariablesService.TransferChannelOrder =
      VariablesService.STANBIC_CONTACT_EMAIL =
      VariablesService.CustomerBVN =
      VariablesService.UserAccountBillerBeneficiaries =
        undefined;

    if (VariablesService.PendingNameEnquiryBatchPollingInterval) {
      UtilService.Window.clearInterval(
        VariablesService.PendingNameEnquiryBatchPollingInterval
      );
    }
  }

  authenticateUser(payload: LoginRequestPayload): Observable<LoginResponse> {
    payload.password = window.btoa(payload.password);
    return this._httpClient.post<LoginResponse>(
      `${this.authRequestBaseUrl}/${ApiURLConstants.AuthenticateUser}`,
      payload
    );
  }

  resetUserSession(payload: LoginRequestPayload): Observable<LoginResponse> {
    payload.password = window.btoa(payload.password);
    return this._httpClient.post<LoginResponse>(
      `${this.authRequestBaseUrl}/${ApiURLConstants.ResetUserSession}`,
      payload
    );
  }

  ConfirmUserExistOnRIB(
    accountNo: string
  ): Promise<ConfirmRIBUserRegistrationOnRIBResponse> {
    // return Promise.resolve({
    //     ResponseCode: '00',
    //     ResponseDescription: '567948677656',
    //     ResponseFriendlyMessage: "",
    //     QuestionDetails: [
    //         {
    //             "questionID": "1",
    //             "questionDESC": "What's your dream job.",
    //             "answer": ""
    //         },
    //         {
    //             "questionID": "2",
    //             "questionDESC": "What's your spouse name",
    //             "answer": ""
    //         },
    //         {
    //             "questionID": "31",
    //             "questionDESC": "What's your first boss name.",
    //             "answer": ""
    //         },
    //         {
    //             "questionID": "40",
    //             "questionDESC": "What your favorite soccer club.",
    //             "answer": ""
    //         }
    //     ],
    //     customerID: "0021269236",
    //     requestID: "0021269236",

    // });

    return this._httpClient
      .post<ConfirmRIBUserRegistrationOnRIBResponse>(
        `${this.authRequestBaseUrl}/${ApiURLConstants.ConfirmRIBUserExist}`,
        {
          UserId: accountNo,
          AccountNo: accountNo,
        }
      )
      .toPromise();
  }

  CheckIfDataPrivacyConsentExist(
    userId: string,
    cifId: string
  ): Observable<GenericApiResponse> {
    return this._httpClient.post<GenericApiResponse>(
      `${this.authRequestBaseUrl}/${ApiURLConstants.CONFIRM_DATA_PRIVACY_CONSENT_EXIST}`,
      {
        UserId: userId,
        CifId: cifId,
      }
    );
  }

  CreateDataPrivacyConsent(
    userId: string,
    cifId: string,
    consentGiven: string,
    otherCompanies: string,
    researchPurpose: string,
    products: string,
    withinGroup: string
  ) {
    return this._httpClient.post<GenericApiResponse>(
      `${this.authRequestBaseUrl}/${ApiURLConstants.CREATE_DATA_PRIVACY_CONSENT_EXIST}`,
      {
        UserId: userId,
        CifId: cifId,
        CG: consentGiven,
        OC: otherCompanies,
        RP: researchPurpose,
        PT: products,
        WG: withinGroup,
      }
    );
  }

  validateLoginOTP(
    payload: ValidateLoginOTPRequestPayload
  ): Promise<ValidateLoginOTPResponse> {
    return this._httpClient
      .post<ValidateLoginOTPResponse>(
        `${this.authRequestBaseUrl}/${ApiURLConstants.ValidateAuthenticatedUserOTP}`,
        payload
      )
      .toPromise();
  }

  ResetSessionOnValidateOTP(
    payload: ValidateLoginOTPRequestPayload
  ): Observable<ValidateLoginOTPResponse> {
    return this._httpClient.post<ValidateLoginOTPResponse>(
      `${this.authRequestBaseUrl}/${ApiURLConstants.ResetValidateOTPSession}`,
      payload
    );
  }

  initiateOtpRequest(
    payload: InitiateOtpRequestPayload
  ): Observable<InitiateOtpResponse> {
    return this._httpClient.post<InitiateOtpResponse>(
      `${this.authRequestBaseUrl}/${ApiURLConstants.InitiateOTP}`,
      payload
    );
  }

  LogoutRequestPromise(userId: string): Promise<GenericApiResponse> {
    return this._httpClient
      .post<GenericApiResponse>(
        `${this.authRequestBaseUrl}/${ApiURLConstants.LogoutSession}`,
        { UserId: userId }
      )
      .toPromise();
  }

  LogoutRequest(userId: string): Observable<GenericApiResponse> {
    return this._httpClient.post<GenericApiResponse>(
      `${this.authRequestBaseUrl}/${ApiURLConstants.LogoutSession}`,
      { UserId: userId }
    );
  }

  initiateLoginOtpRequest(
    payload: InitiateOtpRequestPayload
  ): Promise<InitiateOtpResponse> {
    return this._httpClient
      .post<InitiateOtpResponse>(
        `${this.authRequestBaseUrl}/${ApiURLConstants.InitiateLoginOTP}`,
        payload
      )
      .toPromise();
  }

  doRIBUserCreation(
    payload: UserCreationRequestPayload
  ): Promise<GenericApiResponse> {
    return this._httpClient
      .post<GenericApiResponse>(
        `${this.authRequestBaseUrl}/${ApiURLConstants.CreateRIBUser}`,
        payload
      )
      .toPromise();
  }

  changePassword(
    payload: ChangePasswordRequestPayload
  ): Promise<GenericApiResponse> {
    return this._httpClient
      .post<GenericApiResponse>(
        `${this.authRequestBaseUrl}/${ApiURLConstants.ChangePassword}`,
        payload
      )
      .toPromise();
  }

  resetPassword(
    payload: ResetPasswordRequestPayload
  ): Promise<GenericApiResponse> {
    return this._httpClient
      .post<GenericApiResponse>(
        `${this.authRequestBaseUrl}/${ApiURLConstants.ResetPassword}`,
        payload
      )
      .toPromise();
  }

  GetRetailUserRegisteredQuestions(
    payload: RetailUserRegisteredSecurityQuestionsRequestPayload
  ): Promise<ConfirmRIBUserRegistrationOnRIBResponse> {
    // return new Promise((resolve, reject) => {
    //     resolve({
    //         ResponseCode: '00',
    //         ResponseDescription: '567948677656',
    //         ResponseFriendlyMessage: "",
    //         customerID: "738383839",
    //         requestID: "38383838",
    //         QuestionDetails: [
    //             {
    //                 "questionID": "1",
    //                 "questionDESC": "What's your dream job.",
    //                 "answer": ""
    //             },
    //             {
    //                 "questionID": "2",
    //                 "questionDESC": "What's your spouse name",
    //                 "answer": ""
    //             },
    //             {
    //                 "questionID": "31",
    //                 "questionDESC": "What's your first boss name.",
    //                 "answer": ""
    //             }
    //         ]
    //     });
    // });

    return this._httpClient
      .post<ConfirmRIBUserRegistrationOnRIBResponse>(
        `${this.authRequestBaseUrl}/${ApiURLConstants.RetailUserSecurityQuestions}`,
        payload
      )
      .toPromise();
  }

  GetSecurityQuestions(): Promise<SecurityQuestionsResponse> {
    if (this.appConfig.UseMockData) {
      return new Promise((resolve, reject) => {
        resolve({
          ResponseCode: "00",
          ResponseDescription: "567948677656",
          ResponseFriendlyMessage: "",
          Questions: [
            {
              questionID: "1",
              questionDESC: "What's your dream job.",
              answer: "",
            },
            {
              questionID: "2",
              questionDESC: "What's your spouse name",
              answer: "",
            },
            {
              questionID: "31",
              questionDESC: "What's your first boss name.",
              answer: "",
            },
            {
              questionID: "40",
              questionDESC: "What your favorite soccer club.",
              answer: "",
            },
          ],
        });
      });
    }
    return this._httpClient
      .post<SecurityQuestionsResponse>(
        `${this.authRequestBaseUrl}/${ApiURLConstants.InitiateOTP}`,
        {}
      )
      .toPromise();
  }

  initiatePasswordChange(): Promise<PasswordChangeOtpResponse> {
    const requestPayload = this.buildPasswordChangeOtpRequestPayload();
    return this._httpClient
      .post<PasswordChangeOtpResponse>(
        `${this.authRequestBaseUrl}/UserProfileManagement/InitiateOTPRequest`,
        requestPayload
      )
      .toPromise();
  }

  sendPasswordChangeRequest(
    payload: PasswordChangeRequestPayload
  ): Promise<PasswordChangeOtpRequestResponse> {
    payload.NewPassword = window.btoa(payload.NewPassword);
    payload.OldPassword = window.btoa(payload.OldPassword);
    // const loggedInUser = this.getLoggedInUser();
    // payload.UserId = loggedInUser ? loggedInUser.UserId : '';
    return this._httpClient
      .post<PasswordChangeOtpRequestResponse>(
        `${this.authRequestBaseUrl}/UserProfileManagement/ChangePassword`,
        payload
      )
      .toPromise();
  }

  getLoggedInUser(): AppUser {
    return AuthService.FullyAuthenticatedUser;
  }

  fetchCustomerSecrets(): Promise<any> {
    const user = this.getLoggedInUser();
    const payload = {
      UserId: !user ? "" : user.UserId,
      AccountNo: !user ? "" : user.UserId,
    };
    return this._httpClient
      .post<any>(
        `${this.authRequestBaseUrl}/UserProfileManagement/GetSMERegistrationQuestions`,
        payload
      )
      .toPromise();
  }

  postSurvey(question: PostSurveyQuestionAnswer): Observable<any> {
    return this._httpClient.post<any>(
      `${this.surveyRequestBaseUrl}/SubmitSurvey`,
      question
    );
    // return of({
    //   ResponseCode: "00",
    //   ResponseDescription: "Survey returned successfully",
    // });
  }

  setSecretQuestion(payload: Array<CustomerSecret>): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this._storageService.save(Constants.Secret_Question, payload);
      setTimeout(() => {
        resolve("secret answer saved");
      }, 1500);
    });
  }

  private getUserRoles(id: string, menuProfile: string): UserRole {
    switch (menuProfile) {
      case "S_DSPGM":
        return {
          UserId: id,
          roles: [Role.Initiator],
          menuProfile: menuProfile,
        };
      case "S_DAM":
        return {
          UserId: id,
          roles: [Role.Approver],
          menuProfile: menuProfile,
        };
      case "S_DSM":
        return {
          UserId: id,
          roles: [Role.Viewer],
          menuProfile: menuProfile,
        };
      default:
        return {
          UserId: id,
          roles: [Role.Retail],
          menuProfile: menuProfile,
        };
    }
  }

  private buildPasswordChangeOtpRequestPayload(): PasswordChangeOtpRequestPayload {
    let userAuthData = sessionStorage.getItem(Constants.Auth_Data);
    userAuthData = userAuthData ? JSON.parse(userAuthData) : userAuthData;
    if (!userAuthData) {
      throw new Error("login data not found");
    }
    return {
      UserId: userAuthData["_userId"],
      ReasonCode: OtpReasonCodes.CHANGE_PASSWORD.toString(),
      CifId: userAuthData["CifId"],
    };
  }

  loggedInUserHasSMEProfile(): boolean {
    const loggedInUser = this.getLoggedInUser();
    if (!loggedInUser) {
      return false;
    }
    if (!loggedInUser.MenuProfile || loggedInUser.MenuProfile === "-999-") {
      return false;
    }
    return true;
  }
}
