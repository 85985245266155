import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {

    constructor() {}

    add<T>(obj: T, storeKey: string): boolean {
        const existingOject = localStorage.getItem(storeKey);
        if (!existingOject) {
            const store = Array<T>();
            const x = store.push(obj);
            localStorage.setItem(storeKey, JSON.stringify(store));
            return x > 0;
        }
        const existingObjArray = JSON.parse(existingOject) as T[];
        const lengthBeforeAdd = existingObjArray.length;
        existingObjArray.push(obj);
        localStorage.setItem(storeKey, JSON.stringify(existingObjArray));
        return existingObjArray.length > lengthBeforeAdd;
    }

    getAll<T>(key: string): T[] {
        try {
            const tStore = localStorage.getItem(key);
            if (!tStore) {
                return [];
            }
            const ts = JSON.parse(tStore) as T[];
            return ts;
        } catch (error) {
            console.log(`Error occured retriving store with key ${key} -> `, error);
            throw error;
        }
    }

    updateItem<T>(storeKey: string, entityKey: any, keyVal: any, updatedT: T): boolean {

        const tStore = this.getAll<T>(storeKey);
        if (!tStore || tStore.length < 1) {
            return false;
        }
        const tTobeUpdatedPos = tStore.findIndex(t => t[entityKey] === keyVal);
        if (tTobeUpdatedPos === -1) {
            return false;
        }
        tStore[tTobeUpdatedPos] = updatedT;
        localStorage.setItem(storeKey, JSON.stringify(tStore));
        return true;
    }

    removeItem<T>(storeKey: string, keyProp: string, value: any): boolean {
        const ts = this.getAll<T>(storeKey);
        const indexOfT = ts.findIndex(t => t[keyProp] === value);
        if (indexOfT === -1) {
            console.log(`Remove Item from store ${storeKey} -: could not find any record with ${keyProp} ${value} on store ${storeKey}`);
            return false;
        }
        ts.splice(indexOfT, 1);
        localStorage.removeItem(storeKey);
        localStorage.setItem(storeKey, JSON.stringify(ts));
        return true;
    }

    save(key: string , obj: any): boolean {
        if (localStorage.getItem(key)) {
            localStorage.removeItem(key);
        }
        localStorage.setItem(key, JSON.stringify(obj));
        return localStorage.getItem(key) ? true : false;
    }

    saveRaw(key: string , nonObjData: string): boolean {
        if (localStorage.getItem(key)) {
            localStorage.removeItem(key);
        }
        localStorage.setItem(key, nonObjData);
        return localStorage.getItem(key) ? true : false;
    }

    find(key): any {
        return localStorage.getItem(key);
    }

    delete(key): boolean {
        localStorage.removeItem(key);
        return localStorage.getItem(key) ? false : true;
    }

    clearStore(storeKey: string) {
        const store = localStorage.getItem(storeKey);
        if (!store) {
            return true;
        }
        localStorage.removeItem(storeKey);
        return localStorage.getItem(storeKey) ? false : true;
    }

}
