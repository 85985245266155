import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CreateThresholdStatus, IntellinxCustomerStatus, QuestionsCustomerStatus, ThresholdStatus } from "../models/customer-status";
import { AppConfigService } from "./appconfig.service";
import { CreateSecret } from '../models/create-secret'
import { DeleteSecret } from "../models/delete-secret";
@Injectable()
export class IntellinxService {

    private _apiBaseUrl: string;
    private _thresholdBaseUrl: string;
    constructor(private _settings: AppConfigService, private http: HttpClient) {
        this._apiBaseUrl = _settings.IntellinxAPIBaseUrl;
        this._thresholdBaseUrl = _settings.IntellinxAPIThresholdUrl;
    }

    getQuestions(channel: string): Observable<QuestionsCustomerStatus> {
        return this.http.get<QuestionsCustomerStatus>(`${this._apiBaseUrl}/secretword/questions?channel=${channel}`)
    }

    getCustomerStatus(customerId: string, channel: string): Observable<IntellinxCustomerStatus> {
        return this.http.get<IntellinxCustomerStatus>(`${this._apiBaseUrl}/secretword/${customerId}/status?channel=${channel}`)
    }

    resetCustomerStatus(customerId: string, channel: string, secret) {
        return this.http.put(`${this._apiBaseUrl}/secretword/${customerId}/reset?Secret=${secret}&Channel=${channel}`,{})
    }

    deleteSecretWord(payload: DeleteSecret) {
        return this.http.request('delete', `${this._apiBaseUrl}/secretword/${payload.customerId}?channel=${payload.channel}`)
    }

    createSecretWord(payload: CreateSecret) {
        return this.http.post(`${this._apiBaseUrl}/secretword`, payload)
    }

    chooseIndemnity(customerId: string, channel: string) {
        return this.http.post(`${this._apiBaseUrl}/secretword/${customerId}/indemnity?channel=${channel}`, {});
    }

    getIndemnityStatus(customerId: string, channel: string) {
        return this.http.get<IntellinxCustomerStatus>(`${this._apiBaseUrl}/secretword/${customerId}/indemnity/status?channel=${channel}`)
    }

    validateSecretAnswer(customerId: string,tranId:string, channel: string, secret: string) {
        return this.http.post(`${this._apiBaseUrl}/secretword/${customerId}/validate?SessionId=${tranId}&Secret=${secret}&Channel=${channel}&CustomerId=${customerId}`, null)
    }

    getThreshold(channel: string, customerId:string): Observable<ThresholdStatus> {
        return this.http.get<ThresholdStatus>(`${this._thresholdBaseUrl}/${customerId}?channel=${channel}`)
    }

    createThreshold(customerId: string, payload:CreateThresholdStatus) {
        return this.http.post(`${this._thresholdBaseUrl}/${customerId}`,payload)
    }

    modifyThreshold(customerId: string, payload:CreateThresholdStatus) {
        return this.http.patch(`${this._thresholdBaseUrl}/${customerId}`,payload)
    }

    notifyIntellinx(payload:CreateThresholdStatus) {
        return this.http.post(`${this._apiBaseUrl}/notification/payment`, payload)
    }

    lockoutAccount(userId: string, channel:string) {
        return this.http.post(`${this._apiBaseUrl}/secretword/${userId}/lockout?channel=${channel}`, null)
    }
}
