import { Component, OnInit, ViewChild, TemplateRef, SimpleChanges, OnChanges, Input, OnDestroy } from '@angular/core';
import { MatDialog, MatSnackBar, MatDialogRef, PageEvent, MatStepper } from '@angular/material';
import { NotificationsService } from 'angular2-notifications';
import { TransferStagingService } from 'src/app/core/services/staging/transfer.staging.service';
import { StagedBulkPaymentBatch, BulkNameEnquiryTransaction, ApprovalStage } from 'src/app/core/models/payloads/transactions';
import { ResponseCodes, OtpReasonCodes, OTPType } from 'src/app/core/models/Enumerations';
import { MiscService } from 'src/app/core/services/misc.service';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import {
  BulkPaymentBatchApprovalRequestPayload, BulkPaymentBatchApprovalResponse
} from 'src/app/core/models/approvals/file-upload.approval';
import { TransferApprovalService } from 'src/app/core/services/workflow/transfer.approval.service';
import { RejectRequestComponent } from 'src/app/shared/dialogs/reject-request/reject-request.component';
import { BaseApprovalComponent } from '../base.approval.component';
import { AuthService } from 'src/app/core/services/auth.service';
import { RequestApprovalHistoryPayload } from 'src/app/core/models/misc-requests-model';
import { ApprovalHistoryComponent } from 'src/app/shared/approval-history/approval-history.component';
import { UtilService } from 'src/app/core/services/helpers.service';
import { VariablesService } from 'src/app/core/services/variables.service';

@Component({
  selector: 'app-file-upload-approval',
  templateUrl: './file-upload-approval.component.html',
  styleUrls: ['./file-upload-approval.component.css']
})
export class FileUploadApprovalComponent extends BaseApprovalComponent implements OnInit, OnChanges, OnDestroy {

  ownLoaderIsActive: boolean;
  ownloaderMsg: string;
  @Input() _activeTab: string;
  filterIsActive: boolean;
  searchFilter = this.initSearchFilter();
  requestList: StagedBulkPaymentBatch[] = [];
  requestListBackup: StagedBulkPaymentBatch[] = [];
  requestListPageSize: 5;
  _activePaymentBatch: StagedBulkPaymentBatch;
  _selectedRequestCount = 0;
  _activeRequestCount = 0;
  allCheckboxesSelected: boolean;

  _paymentTransactionList: Array<BulkNameEnquiryTransaction> = [];
  _paymentTransactionListBackup: Array<BulkNameEnquiryTransaction> = [];
  uploadPreviewTablePageLimit = 5;
  _totalPreviewDataSelected = 0;
  _totalPreviewDataSelectedAmount = 0;
  oneTimePassCode: string;
  otpSourceReferenceId: string;
  otpValidationFailed: false;
  _availableApprovalStages: Array<ApprovalStage> = [];

  uploadPreviewModalData = {
    title: '',
    currentRequestAction: ''
  };

  @ViewChild('uploadPreviewModalTemplate') _uploadPreviewModalTemplate: TemplateRef<any>;
  public uploadPreviewModal: MatDialogRef<any>;
  @ViewChild('stepper2') _activePreviewStepper: MatStepper;
  public approvalHistoryModal: MatDialogRef<any>;
  public requestRejectionModal: MatDialogRef<any>;
  isCustomerHWTEnabled: boolean;
  isOTPStepperVisible: boolean;
  hwtFormGroup : FormGroup
  accountNoSet: Set<string>;

  constructor(private _dialog: MatDialog,
    private _snackbar: MatSnackBar, private _toast: NotificationsService, private _stagedTransferService: TransferStagingService,
    private _miscService: MiscService, private _approvalService: TransferApprovalService, _authService: AuthService, private formBuilder:FormBuilder) {
    super(_authService);
    // try {
    //   window['casa']('other'); 
    //  } catch (error) {}
    this.pageTitle = this._loggedInUser && _authService.loggedInUserHasSMEProfile() && this._loggedInUserIsApprover ?
      'Authorize Bulk-Payment Request' : '';

    UtilService.onPageLoadCallback();
    
    this.hwtFormGroup = formBuilder.group({
      hwt: ['', Validators.required]
    });
  }

  ngOnInit() { 
     this.isCustomerHWTEnabled = VariablesService.CustomerHWTEnabled;

     this.accountNoSet = new Set<string>();
     VariablesService.CustomerAccounts.forEach(element => {
     this.accountNoSet.add(element.accountNumber);
    })
    // this.isCustomerHWTEnabled = true;
    if (this.isCustomerHWTEnabled) {
      this.isOTPStepperVisible = false;
    } else {
      this.isOTPStepperVisible = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['_activeTab']) {
      this._activeTab = changes['_activeTab'].currentValue;
      if (this._activeTab !== 'fileuploads') {
        this.dismissOpenModalWindows();
      }
      if (this._activeTab === 'fileuploads') {
        this.loadStagedBulkPaymentRequests('');
      }
    }
  }

  initSearchFilter() {
    return {
      startDate: '',
      endDate: '',
      fileSequenceNo: '',
      uploadedBy: '',
      productType: ''
    };
  }

  loadStagedBulkPaymentRequests(loaderMsg, lastFetchId = 0, pageSize = 100) {
    this.ownLoaderIsActive = true;
    this.ownloaderMsg = loaderMsg || 'fetching pending bulk-payment requests. please wait';
    this._stagedTransferService.getStagedBulkPayments({ PageSize: pageSize, LastFetchId: lastFetchId }).then(
      (response) => {
        this.ownLoaderIsActive = this._activeRequestCount > 0;
        this.ownloaderMsg = '';
        if (!response || response.ResponseCode !== ResponseCodes.SUCCESS) {
          this._snackbar.open(`${response.ResponseFriendlyMessage}`, 'Ok', { duration: 25000 });
          return;
        }
        this.requestListBackup = response.PaginatedItems;
        this.requestListBackup = this.requestListBackup.filter(item => this.accountNoSet.has(item.SourceAccount));
        this.requestList = this.requestListBackup.slice(0, 5);
      },
      (error) => { }
    );
  }

  onApprovalRequestAction(action: string, data: StagedBulkPaymentBatch) {
    this._activePaymentBatch = data;
    switch (action) {
      case 'preview': {
        this.previewBulkPaymentTransactions(data);
        break;
      }
      case 'reject': {
        this.requestRejectionModal = this._dialog.open(RejectRequestComponent,
          {
            hasBackdrop: false,
            closeOnNavigation: true,
            disableClose: true,
            data: {
              title: 'Reject Bulk Payment Request',
              payload: {
                OperationType: 'BULK_UPLOAD',
                OperationId: data.BatchId
              }
            }
          });
        this.requestRejectionModal.afterClosed()
          .subscribe((result) => {
            if (result) {
              this.loadStagedBulkPaymentRequests('refreshing .. please wait');
            }
          });
        break;
      }
      case 'view-history': {
        const requestPayload: RequestApprovalHistoryPayload = {
          OperationType: 'BULK_UPLOAD',
          OperationId: data.BatchId,
          UserId: '',
          PageSize: 100,
          LastIdFetched: 0
        };
        this.approvalHistoryModal = this._dialog.open<ApprovalHistoryComponent>(
          ApprovalHistoryComponent,
          {
            hasBackdrop: false,
            data: {
              operationName: 'bulk-transfer',
              requestPayload: requestPayload,
              title: 'Request Approval History',
              extra: {}
            }
          });
        this.approvalHistoryModal.afterClosed().subscribe(
          (result) => {
            //
          }
        );
        break;
      }
    }
  }

  loadBulkPaymentTransactions(batchId, loaderMsg = '', lastFetchId = 0, pageSize = 3000) {
    this.ownLoaderIsActive = true;
    this.ownloaderMsg = loaderMsg || 'fetching payment details .. please wait';
    this._stagedTransferService.getStagedBulkPaymentTransactions(
      { LastIdFetched: lastFetchId, PageSize: pageSize, BatchId: batchId }).then(
        (response) => {
          this.ownLoaderIsActive = false;
          this.ownloaderMsg = '';
          if (!response || response.ResponseCode !== ResponseCodes.SUCCESS) {
            this._snackbar.open(`${response.ResponseFriendlyMessage}`, 'Ok', { duration: 25000 });
            return;
          }
          this._paymentTransactionListBackup = response.PaginatedItems;
          this._paymentTransactionListBackup.forEach(t => t['checked'] = false);
          this._paymentTransactionList = this._paymentTransactionListBackup.slice(0, this.uploadPreviewTablePageLimit);

        },
        (error) => {
          console.log(error);
          this.ownLoaderIsActive = false;
          this.ownloaderMsg = '';
          this._snackbar.open(`We encoutered a technical error while loading payment transactions`, 'Ok', { duration: 25000 });
        }
      );
  }
  previewBulkPaymentTransactions(batchInformation: StagedBulkPaymentBatch) {
    this.uploadPreviewModal = this._dialog.open(this._uploadPreviewModalTemplate, {
      hasBackdrop: false, closeOnNavigation: true
    });
    this.loadBulkPaymentTransactions(batchInformation.BatchId);
  }
  onRequestDataItemCheckboxChanged(evtData, id: string, postion: number) {
    this._paymentTransactionList[postion]['checked'] = evtData.checked;
    const indexOnBackup = this._paymentTransactionListBackup.indexOf(this._paymentTransactionList[postion]);
    this._paymentTransactionListBackup[indexOnBackup]['checked'] = evtData.checked;
    this._totalPreviewDataSelected = this._paymentTransactionListBackup.filter(request => request['checked']).length;
    if (evtData.checked) {
      this._totalPreviewDataSelectedAmount += this._paymentTransactionList[postion].Amount;
    } else {
      this._totalPreviewDataSelectedAmount -= this._paymentTransactionList[postion].Amount;
    }
  }

  onAllRequestDataCheckboxChanged(evtData) {
    if (evtData.checked) {
      this._paymentTransactionListBackup.forEach(row => row['checked'] = true);
      this._totalPreviewDataSelected = this._paymentTransactionListBackup.length;
      this._totalPreviewDataSelectedAmount = this._paymentTransactionListBackup.map(rowData => rowData.Amount)
        .reduce((prev, current) => Number(prev) + Number(current));
    } else {
      this._paymentTransactionListBackup.forEach(b => b['checked'] = false);
      this._totalPreviewDataSelected = 0;
      this._totalPreviewDataSelectedAmount = 0;
    }
    this._paymentTransactionList = this._paymentTransactionListBackup.slice(0, 5);
  }

  onPreviewDataPageChanged(pageChangedEvent: PageEvent) {
    // this.transactionsPageLimit = evtData.pageSize;
    const start = pageChangedEvent.pageIndex * pageChangedEvent.pageSize;
    const end = start + pageChangedEvent.pageSize;
    this._paymentTransactionList = this._paymentTransactionListBackup.slice(start, end);
  }
  onBackFromConfirmationModalClicked(stepper: MatStepper) {
    this.uploadPreviewModalData.currentRequestAction = '';
    stepper.previous();
  }
  onConfirmApprovalButtonClicked(stepper: MatStepper, isResendOTP = false) {
    this.ownLoaderIsActive = true;
    this.ownloaderMsg = 'Initiating approval .. Please wait .. ';
    this._miscService.initiateOtpRequest(OtpReasonCodes.GENERIC, 'APPROVE BULK PAYMENT').then(
      (response) => {
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        if (!response || response.ResponseCode !== ResponseCodes.SUCCESS) {
          const errorMsg = !response || !response.ResponseFriendlyMessage ?
            `Oops .. One time passcode request was not sucessful. Please retry` : `We encountered a technical error while
            sending one time passcode. Server responded with -> ${response.ResponseFriendlyMessage}. Please try again`;
          this._snackbar.open(errorMsg, 'Retry ', { duration: 25000 }).onAction().subscribe(
            () => this.onConfirmApprovalButtonClicked(stepper)
          );
          return;
        }
        this.uploadPreviewModalData['otpInstruction'] = `Please enter the one time passcode sent to
                                                          ${response.NotificationAddress} to continue approval`;
        this._snackbar.open(this.uploadPreviewModalData['otpInstruction'], 'Ok', { duration: 20000 });
        this.uploadPreviewModalData.currentRequestAction = 'verify-passcode';
        this.otpSourceReferenceId = response.ResponseDescription;
        if (!isResendOTP) {
          setTimeout(() => stepper.next(), 500);
        }
      },
      (error) => {
        console.log(error);
        this._snackbar.open(`We encountered a technical error while processing request. Please wait for some time and retry`,
          'Ok', { duration: 20000 });
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
      }
    );
  }

  returnToOTPStepper(){
    this.ownLoaderIsActive = true;
    this.ownloaderMsg = 'Initiating approval .. Please wait .. ';
    this._miscService.initiateOtpRequest(OtpReasonCodes.GENERIC, 'APPROVE BULK PAYMENT').then(
      (response) => {
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        if (!response || response.ResponseCode !== ResponseCodes.SUCCESS) {
          const errorMsg = !response || !response.ResponseFriendlyMessage ?
            `Oops .. One time passcode request was not sucessful. Please retry` : `We encountered a technical error while 
            sending one time passcode. Server responded with -> ${response.ResponseFriendlyMessage}. Please try again`;
          this._snackbar.open(errorMsg, 'Retry ', { duration: 25000 }).onAction().subscribe(
            () => this.returnToOTPStepper()
          );
          return;
        }
        var hardwareTokenStepper = document.getElementById('hardwareTokenStepper');
        hardwareTokenStepper.style.display = 'none';
        this.isOTPStepperVisible = true;
   
        this.uploadPreviewModalData['otpInstruction'] = `Please enter the one time passcode sent to
                                                          ${response.NotificationAddress} to continue approval`;
        this._snackbar.open(this.uploadPreviewModalData['otpInstruction'], 'Ok', { duration: 20000 });
        this.uploadPreviewModalData.currentRequestAction = 'verify-passcode';
        this.otpSourceReferenceId = response.ResponseDescription;
        
      },
      (error) => {
        console.log(error);
        this._snackbar.open(`We encountered a technical error while processing request. Please wait for some time and retry`,
          'Ok', { duration: 20000 });
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
      }
    );
  }

  onVerifyApprovalConfirmationOtp(form: NgForm, stepper: MatStepper, useHardwareToken:boolean) {
    
    if (useHardwareToken) {
      if (!this.hwtFormGroup.valid || !this.hwtFormGroup.controls.hwt) {
        this._snackbar.open(`Please provide a valid token number`, 'Ok', { duration: 25000 });
        return;
      }
    }
    if (!form.valid) {
      return;
    }
    const requestPayload = this.buildApprovalRequestPayload();
    // if(useHardwareToken){
    //   requestPayload["otp"]=  this.hwtFormGroup.controls.hwt.value;
    //   requestPayload["OTPType"] = OTPType.HWT;
    // }
    // else{
    //   requestPayload["OTPType"] = !this.isOTPStepperVisible ? OTPType.HWT : this.isOTPStepperVisible ? OTPType.OTP : OTPType.NONE;
    //   requestPayload["otp"]=  !this.isOTPStepperVisible ? this.hwtFormGroup.controls.hwt.value : this.oneTimePassCode;
    // }

    this.ownLoaderIsActive = true;
    this.ownloaderMsg = 'verifying passcode .. please wait';
    this._approvalService.approvePendingBulkPaymentBatch(requestPayload).then(
      (response) => {
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        if (!response || response.ResponseCode !== ResponseCodes.SUCCESS) {
          this.handleNonSuccessApprovalResponse(response, stepper);
          return;
        }
        this.uploadPreviewModalData.currentRequestAction = 'requestComplete';
        setTimeout(() => {
          stepper.next();
        }, 50);
        this.loadStagedBulkPaymentRequests('refreshing pending batch list');
        this._snackbar.open(`${response.ResponseFriendlyMessage}`, 'Ok', { duration: 50000 });
      },
      (error) => {
        console.log(error);
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        this._snackbar.open(`We encountered a technical error while processing your request`, 'Ok', { duration: 10000 });
      }
    );
  }

  onCloseUploadPreviewModal() {
    this._paymentTransactionListBackup = [];
    this._paymentTransactionList = [];
    this._totalPreviewDataSelected = 0;
    this.uploadPreviewModalData.currentRequestAction = '';
    this.uploadPreviewModalData['otpInstruction'] = '';
    this.uploadPreviewModal.close();
  }

  onExcludeSelectedRows() {
    const rowsToRemove = this._totalPreviewDataSelected;
    this._paymentTransactionListBackup = this._paymentTransactionListBackup.filter(rowData => !rowData['checked']);
    this._paymentTransactionList = this._paymentTransactionListBackup.slice(0, 5);
    this._totalPreviewDataSelected = 0;
    this._totalPreviewDataSelectedAmount = 0;
    this._snackbar.open(`${rowsToRemove} Payment requests excluded. To Undo Action,
    close preview screen and click the preview menu button again`, 'Ok',
      { verticalPosition: 'top', horizontalPosition: 'right', duration: 45000 });
  }

  onApproveBulkPayments(approveAll: boolean = false) {
    if (approveAll) {
      this._paymentTransactionListBackup.forEach(t => t['checked'] = true);
    }
    const selectedTransactionsToApprove = this._paymentTransactionListBackup.filter(t => t['checked']);
    this._totalPreviewDataSelected = selectedTransactionsToApprove.length;
    this._totalPreviewDataSelectedAmount = selectedTransactionsToApprove.map(rowData => rowData.Amount).reduce(
      (prev, current) => Number(prev) + Number(current));
    this.uploadPreviewModalData.currentRequestAction = 'confirmAction';
    setTimeout(() => {
      this._activePreviewStepper.next();
    }, 100);
  }

  onSelectedNextApprovalStageSubmitted(approvalStage: ApprovalStage, stepper: MatStepper) {
    this.ownLoaderIsActive = true;
    this.ownloaderMsg = 'setting next approval stage .. please wait';
    const requestPayload = this.buildApprovalRequestPayload();
    requestPayload.NextStages = [approvalStage];
  }

  onRequestListPageChanged(pageChangedEvent: PageEvent) {
    const start = pageChangedEvent.pageIndex * pageChangedEvent.pageSize;
    const end = start + pageChangedEvent.pageSize;
    this.requestList = this.requestListBackup.slice(start, end);
  }

  ngOnDestroy(): void {

  }

  private buildApprovalRequestPayload(): BulkPaymentBatchApprovalRequestPayload {
    return {
      batchId: this._activePaymentBatch.BatchId,
      userId: AuthService.FullyAuthenticatedUser.UserId,
      ApproverId: this._activePaymentBatch.ApproverId,
      OTP: this.oneTimePassCode,
      SourceReferenceId: this.otpSourceReferenceId,
      ApproverStageId: this._activePaymentBatch.ApprovalStageId,
      Transactions: this._paymentTransactionListBackup.filter(t => t['checked']).map(tr => tr.Id),
      DeletedTransactions: this._paymentTransactionListBackup.filter(t => t['checked'] != true).map(tr => tr.Id),
    };
  }

  private handleNonSuccessApprovalResponse(response: BulkPaymentBatchApprovalResponse, stepper: MatStepper) {
    switch (response.ResponseCode) {
      case '70': {
        this.uploadPreviewModalData.currentRequestAction = 'next-approval-stage';
        setTimeout(() => {
          stepper.next();
        }, 50);
        break;
      }
    }
  }

  private dismissOpenModalWindows() {
    if (this.uploadPreviewModal) {
      this.uploadPreviewModal.close();
    }
    if (this.approvalHistoryModal) {
      this.approvalHistoryModal.close();
    }
    if (this.requestRejectionModal) {
      this.requestRejectionModal.close();
    }
  }
}
