
import { Pipe, PipeTransform } from "@angular/core";
import { UtilService } from "src/app/core/services/helpers.service";



declare var moment: any;

@Pipe({ name: 'momentFormat' })
export class MomentDateFormatPipe implements PipeTransform {
    transform(value: string, format: string) {
        if (!UtilService.StringIsNullOrEmpty(value)) {
            if (value && value.length > 0) {
                return moment(value).format("DD MMM 'YY");
            }
        }

        return '';
    }
}
 




@Pipe({ name: 'htmlDecode' })
export class HtmlDecodePipe implements PipeTransform {
    transform(value: string) {
        if (!UtilService.StringIsNullOrEmpty(value)) {
            var e = document.createElement('div');
            e.innerHTML = value;
            return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
        }

        return '';
    }
}




@Pipe({ name: 'AbbreviateBeneficiaryName' })
export class BeneficiaryNameAbbreviationPipe implements PipeTransform {
    transform(value: string) {
        if (!UtilService.StringIsNullOrEmpty(value)) {

            if (value && value.length > 0) {
                let nonEmptyWordsInArray = value.split(' ').filter(c => c != '');
                if (nonEmptyWordsInArray && nonEmptyWordsInArray.length > 1) {
                    return nonEmptyWordsInArray[0][0] + nonEmptyWordsInArray[nonEmptyWordsInArray.length - 1][0];
                }

                return nonEmptyWordsInArray[0][0];
            }
        }

        return '';
    }
}