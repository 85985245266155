import { Component, OnInit } from '@angular/core';
import { TransactionLimitRecords } from 'src/app/core/models/transactions/transactionLimits';

@Component({
  selector: 'app-transaction-limit',
  templateUrl: './transaction-limit.component.html',
  styleUrls: ['./transaction-limit.component.css']
})
export class TransactionLimitComponent implements OnInit {

  public transactionLimit: TransactionLimitRecords;
  constructor() { }

  ngOnInit() {
  }

}
