import { Component, OnInit, Input, SimpleChanges, OnChanges, ViewChild, TemplateRef } from '@angular/core';
import { PaymentStagingService } from 'src/app/core/services/staging/payment.staging.service';
import { MatSnackBar, PageEvent, MatDialogRef, MatDialog, MatStepper } from '@angular/material';
import { ResponseCodes, Role, OtpReasonCodes } from 'src/app/core/models/Enumerations';
import { CustomerAccount } from 'src/app/core/models/account';
import { ApprovalStage, StagedBillPaymentRequestobject, GenericApprovalPayload } from 'src/app/core/models/payloads/transactions';
import { Approval } from 'src/app/core/models/approvals/payment.approval';
import { MiscService } from 'src/app/core/services/misc.service';
import { NgForm } from '@angular/forms';
import { AuthService } from 'src/app/core/services/auth.service';
import { PaymentApprovalService } from 'src/app/core/services/workflow/payments.approval.service';
import { Constants } from 'src/app/core/helpers/appConstants';
import { AppUser } from 'src/app/core/models/auth';
import { BaseApprovalComponent } from '../base.approval.component';
import { RejectRequestComponent } from 'src/app/shared/dialogs/reject-request/reject-request.component';
import { RequestApprovalHistoryPayload } from 'src/app/core/models/misc-requests-model';
import { ApprovalHistoryComponent } from 'src/app/shared/approval-history/approval-history.component';
import { UtilService } from 'src/app/core/services/helpers.service';
import { VariablesService } from 'src/app/core/services/variables.service';

@Component({
  selector: 'app-bill-payment-approvals',
  templateUrl: './bill-payment-approvals.component.html',
  styleUrls: ['./bill-payment-approvals.component.css']
})
export class BillPaymentApprovalsComponent extends BaseApprovalComponent implements OnInit, OnChanges {

  ownLoaderIsActive: boolean;
  ownloaderMsg: string;
  @Input() _activeTab: string;
  _activeRequestCount = 0;

  accountList: CustomerAccount[];
  requestList: StagedBillPaymentRequestobject[] = [];
  requestListBackup: StagedBillPaymentRequestobject[] = [];
  _pageSize = 5;
  _selectedRequestCount = 0;
  requestDetailModalData = {
    currentRequestAction: '',
    currentApprovalAction: '',
    data: null,
    requestMeta: {} as any
  };
  oneTimePassCode: string;
  _otpReferenceId: string;
  filterIsActive: boolean;
  userRoleEnum = Role;
  allCheckboxesSelected: boolean;

  _requestApprovalHistory: Array<Approval> = [];
  _availableApprovalStages: Array<ApprovalStage> = [];
  _selectedApprovalStage: ApprovalStage;

  pageTitle: string;

  _paymentApprovalCompleted: boolean;
  @ViewChild('paymentApprovalModal') _requestApprovalModalTemplate: TemplateRef<any>;
  public requestDetailModal: MatDialogRef<any>;
  public approvalHistoryModal: MatDialogRef<any>;
  public requestRejectionModal: MatDialogRef<any>;

  public otpInstruction: string;
  accountNoSet: Set<string>;

  constructor(private _billStagingService: PaymentStagingService, private _snackbar: MatSnackBar,
    private _dialog: MatDialog, private _miscService: MiscService, _authService: AuthService,
    private _approvalService: PaymentApprovalService) {
    super(_authService);
    // try {
    //   window['casa']('other'); 
    //  } catch (error) {}
    this.pageTitle = _authService.loggedInUserHasSMEProfile() && this._loggedInUserIsApprover ?
      'Authorize Pending Bill Payment Approval Requests' : '';
    UtilService.onPageLoadCallback();
  }

  ngOnInit() {
    this.accountNoSet = new Set<string>();
      VariablesService.CustomerAccounts.forEach(element => {
      this.accountNoSet.add(element.accountNumber);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['_activeTab']) {
      this._activeTab = changes['_activeTab'].currentValue;
      if (this._activeTab !== 'bill_payments') {
        this.dismissOpenModalWindows();
      }
      if (this._activeTab === 'bill_payments') {
        this.loadBillPaymentRequests();
      }
    }
  }

  loadBillPaymentRequests(loaderMsg = 'fetching pending bill payment requests. please wait ..', lastFetchId = 0, pageSize = 100) {
    this.ownLoaderIsActive = true;
    this.ownloaderMsg = loaderMsg;
    this._billStagingService.getStagedBillPaymentsRequest({ PageSize: pageSize, LastFetchId: lastFetchId }).then(
      (response) => {
        this.ownLoaderIsActive = this._activeRequestCount > 0;
        this.ownloaderMsg = '';
        if (!response || response.ResponseCode !== ResponseCodes.SUCCESS || !response.PaginatedItems) {
          this._snackbar.open(`${response.ResponseFriendlyMessage}`, 'Ok', { duration: 25000 });
          return;
        }
        response.PaginatedItems.forEach(request => {
          request['checked'] = false;
        });
        this.requestListBackup = response.PaginatedItems;
        this.requestListBackup = this.requestListBackup.filter(item => this.accountNoSet.has(item.BillDetails.sourceAccountNo));
        this.requestList = this.requestListBackup.slice(0, 5);
      },
      (error) => {
        console.log(error);
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        this._snackbar.open(`We encountered an error while loading pending payment request`, 'Ok', { duration: 25000 });
      }
    );
  }

  onApprovalRequestAction(action: string, data: StagedBillPaymentRequestobject) {
    this.requestDetailModalData.requestMeta['BatchId'] = data.BatchId;
    this.requestDetailModalData.requestMeta['Initiator'] = data.Initiator;
    this.requestDetailModalData.requestMeta['ApprovalStageId'] = data.ApprovalStageId;
    this.requestDetailModalData.requestMeta['ApproverId'] = data.ApproverId;
    this.requestDetailModalData.data = data.BillDetails;
    switch (action) {
      case 'approve': {
        this.initRequestApprovalModal(data);
        break;
      }
      case 'reject': {
        this.requestRejectionModal = this._dialog.open(RejectRequestComponent,
          {
            data: {
              title: 'Reject Payment Approval Request',
              payload: {
                OperationType: data.BillDetails['operationType'],
                OperationId: data.BillDetails.Id
              }
            },
            hasBackdrop: false,
            closeOnNavigation: true,
            disableClose: true
          });
        this.requestRejectionModal.afterClosed()
          .subscribe((result) => {
            if (result) {
              this.loadBillPaymentRequests('refreshing .. please wait');
            }
          });

        break;
      }
      case 'view-history': {
        const requestPayload: RequestApprovalHistoryPayload = {
          OperationType: data.BillDetails['operationType'],
          OperationId: Number(data.BillDetails.Id),
          UserId: '',
          PageSize: 100,
          LastIdFetched: 0
        };
        this.approvalHistoryModal = this._dialog.open<ApprovalHistoryComponent>(
          ApprovalHistoryComponent,
          {
            hasBackdrop: false,
            data: {
              operationName: 'biller-payments',
              requestPayload: requestPayload,
              title: 'Request Approval History',
              extra: {}
            }
          });
        this.approvalHistoryModal.afterClosed().subscribe(
          (result) => {
            //
          }
        );
        break;
      }
    }
  }

  onRequestModalAction(action: string, stepper: MatStepper, form?: NgForm) {
    console.log(action);
    switch (action) {
      case 'approve': {
        this.initiateRequestApprovalOTP(stepper);
        break;
      }
      case 'reject': {
        if (form && !form.valid) {
          this._snackbar.open('Please provode a reason for rejecting request .. ', 'Ok', { duration: 10000 });
          return;
        }
        break;
      }
    }
  }

  onVerifyApprovalConfirmationOtp(form: NgForm, stepper: MatStepper) {
    if (!form.valid) {
      this._snackbar.open(`Invalid One Time PIN provided. Please provide a valid OTP to continue`, 'Ok',
        { duration: 10000 });
      return;
    }
    this.sendBillPaymentApprovalRequest(this.requestDetailModalData.data as StagedBillPaymentRequestobject, stepper);
  }
  onTransactionsPageChanged(pageChangedEvent: PageEvent) {
    const start = pageChangedEvent.pageIndex * pageChangedEvent.pageSize;
    const end = start + pageChangedEvent.pageSize;
    this.requestList = this.requestListBackup.slice(start, end);
  }

  onApprovaStageSelected(evtData, stage: ApprovalStage, stageIndex: number) {
    this._availableApprovalStages.forEach(stage_ => stage_['checked'] = false);
    this._availableApprovalStages[stageIndex]['checked'] = evtData.checked;
    this._selectedApprovalStage = evtData.checked ? stage : null;
  }

  onAprovalFlowSelected(stepper: MatStepper) {
    if (!this._selectedApprovalStage) {
      this._snackbar.open(`Please select an approval stage.`, 'Ok');
      return;
    }
    const requestPayload = this.buildBillPaymentApprovalStageRequestPayload(this.requestDetailModalData.data.operationType);
    this.ownLoaderIsActive = true;
    this.ownloaderMsg = 'submitting selected next approval level';
    this._approvalService.approvePendingBillPaymentRequestWithSelectedStage(requestPayload).then(
      (response) => {
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        if (!response || response.ResponseCode !== ResponseCodes.SUCCESS && response.ResponseCode !== '70') {
          this._snackbar.open(`Request failed with error -> ${response.ResponseFriendlyMessage}`, 'Ok', { duration: 25000 });
          return;
        }
        if (response.ResponseCode === ResponseCodes.SUCCESS) {
          this.requestDetailModalData.currentApprovalAction = 'approvalComplete';
          this.requestDetailModalData.currentRequestAction = 'requestComplete';
          stepper.next();
          this._snackbar.open(`${response.ResponseFriendlyMessage}`, 'Ok', { duration: 25000 });
          this.loadBillPaymentRequests('refreshing pending bill payment requests .. please wait');
          return;
        }
      },
      (error) => {
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        if (error && error.status != Constants.UnauthorizedStatusCode) {
          this._snackbar.open(Constants.APITechnicalErrorMessage,
            'Ok', { verticalPosition: 'bottom', horizontalPosition: 'right', duration: 1500 });
        }
      }
    );

  }

  oncloseRequestApprovalModal() {
    this.requestDetailModal.close();
    this.ownLoaderIsActive = false;
    this.ownloaderMsg = '';
    this.oneTimePassCode = '';
    this._otpReferenceId = '';
    this.requestDetailModalData.currentApprovalAction = '';
    this.requestDetailModalData.currentRequestAction = '';
  }

  private sendBillPaymentApprovalRequest(request: StagedBillPaymentRequestobject, stepper: MatStepper) {
    this.ownLoaderIsActive = true;
    this.ownloaderMsg = `Approving request... please wait`;
    const approvalRequestPayload = this.buildBillPaymentApprovalRequestPayload(request.BillDetails.operationType);
    this._approvalService.approvePendingBillPaymentRequest(approvalRequestPayload).then(
      (response) => {
        console.log(response);
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        if (!response || response.ResponseCode !== ResponseCodes.SUCCESS) {
          this.handleNonSuccessApprovalResponse(request, response, stepper);
          return;
        }
        this._snackbar.open(`${response.ResponseFriendlyMessage}`, 'Ok', { duration: 50000 });
        this.loadBillPaymentRequests('refreshing bill pending bill payments');
        this.requestDetailModalData.currentRequestAction = 'requestComplete';
        setTimeout(() => stepper.next(), 15);
      },
      (error: any) => {
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        this._snackbar.open(`We encountered an error while approving request .. Please retry`, 'Ok', { duration: 25000 });
      }
    );
  }

  private initRequestApprovalModal(data: StagedBillPaymentRequestobject) {
    this.requestDetailModalData.currentApprovalAction = '';
    this.requestDetailModalData.currentRequestAction = '';
    this.requestDetailModalData.data = data;
    this.requestDetailModal = this._dialog.open(this._requestApprovalModalTemplate,
      { hasBackdrop: false, panelClass: 'beneficiary-details-card' });
  }

  private initiateRequestApprovalOTP(stepper: MatStepper, isResendOtpRequest = false) {
    this.ownLoaderIsActive = true;
    this.ownloaderMsg = 'initiating... please wait';
    this._miscService.initiateOtpRequest(OtpReasonCodes.GENERIC, 'APPROVE BILL PAYMENT').then(
      (response) => {
        console.log(response);
        this.ownloaderMsg = '';
        this.ownLoaderIsActive = false;
        if (!response || response.ResponseCode !== ResponseCodes.SUCCESS) {
          this._snackbar.open(`Failed to initiate approval process. Server responded with -> ${response.ResponseFriendlyMessage}`,
            'Ok', { duration: 25000 });
          return;
        }
        this._snackbar.open(`${response.ResponseFriendlyMessage}`, 'Ok', { duration: 25000 });
        this.requestDetailModalData.currentApprovalAction = 'verify-otp';
        this._otpReferenceId = response.ResponseDescription;
        this.otpInstruction = `Please enter the One Time Pin sent to ${response.NotificationAddress} to approve request`;
        if (!isResendOtpRequest) {
          setTimeout(() => stepper.next(), 25);
        }
      },
      (error) => {
        console.log(error);
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        this._snackbar.open(`We encountered an error while processing your request. please retry`, 'Ok', { duration: 25000 });
      }
    );
  }

  private handleNonSuccessApprovalResponse(request: StagedBillPaymentRequestobject, response: any, stepper: MatStepper) {
    switch (response.ResponseCode) {
      case '70': {
        this._snackbar.open(`${response.ResponseFriendlyMessage}`, 'Ok', { duration: 50000 });
        this.requestDetailModalData.currentApprovalAction = 'select-next-approval-stage';
        this._availableApprovalStages = response.Stages;
        setTimeout(() => {
          stepper.next();
        }, 50);
        break;
      }
      default: {
        this._snackbar.open(`${response.ResponseFriendlyMessage}`, 'Retry', { duration: 25000 }).onAction().subscribe(
          () => this.sendBillPaymentApprovalRequest(request, stepper)
        );
        break;
      }
    }
  }

  private buildBillPaymentApprovalRequestPayload(operationType: string):
    GenericApprovalPayload {
    return {
      BatchId: Number(this.requestDetailModalData.requestMeta.BatchId),
      UserId: AuthService.FullyAuthenticatedUser && AuthService.FullyAuthenticatedUser.UserId ?
        AuthService.FullyAuthenticatedUser.UserId : '',
      ApproverId: Number(this.requestDetailModalData.requestMeta.ApproverId),
      ApproverStageId: Number(this.requestDetailModalData.requestMeta.ApprovalStageId),
      OTP: this.oneTimePassCode,
      SourceReferenceId: this._otpReferenceId,
      OperationType: operationType
    };
  }

  private buildBillPaymentApprovalStageRequestPayload(operationType) {
    return {
      BatchId: this.requestDetailModalData.requestMeta.BatchId,
      CurrentApprovalStageId: this.requestDetailModalData.requestMeta.ApprovalStageId,
      OperationType: operationType,
      UserId: AuthService.FullyAuthenticatedUser && AuthService.FullyAuthenticatedUser.UserId ?
        AuthService.FullyAuthenticatedUser.UserId : '',
      NextStages: [this._selectedApprovalStage]
    };
  }

  private initComponent() {
    if (this._authService.loggedInUserHasSMEProfile() && this._loggedInUserIsApprover) {
      this.pageTitle = 'Authorize Pending Payment Approval Requests';
    }
  }

  private dismissOpenModalWindows() {
    if (this.requestDetailModal) {
      this.requestDetailModal.close();
    }
    if (this.approvalHistoryModal) {
      this.approvalHistoryModal.close();
    }
    if (this.requestRejectionModal) {
      this.requestRejectionModal.close();
    }
  }

}
