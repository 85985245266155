import { ShowBvnNumberComponent } from "./dialogs/show-bvn-number/show-bvn-number.component";
import { VerifyAccountUserComponent } from "./dialogs/verify-account-user/verify-account-user.component";
import { NgModule } from "@angular/core";
import { CommonModule, DecimalPipe } from "@angular/common";
import { AirtimeComponent } from "./airtime/airtime.component";
import { TransactionLimitComponent } from "./transaction-limit/transaction-limit.component";
import { WorkflowComponent } from "./workflow/workflow.component";
import { AppMaterialModule } from "../app.material.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  HtmlDecodePipe,
  MomentDateFormatPipe,
  BeneficiaryNameAbbreviationPipe,
} from "./custom-pipes";
import { SessionTimeoutComponent } from "./session-timeout/session-timeout.component";

import { ApprovalHistoryComponent } from "./approval-history/approval-history.component";
import { RejectRequestComponent } from "./dialogs/reject-request/reject-request.component";
import { VerticalTimelineModule } from "angular-vertical-timeline";
import { CardExpiryNotificationComponent } from "./dialogs/card-expiry-notification/card-expiry-notification.component";
import { MatProgressSpinnerModule } from "@angular/material";
import { CorporateBillerDynamicFormComponent } from "./corporate-biller-forms/corporate-biller-dynamic-form.component";
import { RowComponent } from './row-component/row/row.component';
import { FileUploadComponent } from './row-component/file-upload-component/file-upload/file-upload.component';
// import { CustomDateAdapter } from './CustomDateAdapter';

@NgModule({
  declarations: [
    AirtimeComponent,
    TransactionLimitComponent,
    WorkflowComponent,
    SessionTimeoutComponent,
    HtmlDecodePipe,
    MomentDateFormatPipe,
    BeneficiaryNameAbbreviationPipe,
    ApprovalHistoryComponent,
    RejectRequestComponent,
    CardExpiryNotificationComponent,
    VerifyAccountUserComponent,
    ShowBvnNumberComponent,
    CorporateBillerDynamicFormComponent,
    RowComponent,
    FileUploadComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    //  Clipboard
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    AppMaterialModule,
    VerticalTimelineModule,
  ],
  exports: [
    AirtimeComponent,
    WorkflowComponent,
    SessionTimeoutComponent,
    HtmlDecodePipe,
    MomentDateFormatPipe,
    BeneficiaryNameAbbreviationPipe,
    ApprovalHistoryComponent,
    RejectRequestComponent,
    CardExpiryNotificationComponent,
    VerifyAccountUserComponent,
    ShowBvnNumberComponent,
    CorporateBillerDynamicFormComponent,
    RowComponent,
    FileUploadComponent,
 
  ],
  providers: [DecimalPipe],
})
export class SharedModule {}
