export class ApiURLConstants {
  public static get ResetUserSession(): string {
    return "UserProfileManagement/ResetIBLoginSession";
  }

  public static get ValidateAuthenticatedUserOTP(): string {
    return "UserProfileManagement/ValidateOTPRequest";
  }
  public static get ResetValidateOTPSession(): string {
    return "UserProfileManagement/ResetValidateOTPSession";
  }
  public static get CustomerAccounts(): string {
    return "AccountManagement/GetAccountsByCIFId";
  }
  public static get SMECustomerAccounts(): string {
    return "TransactionManagement/GetAccountByUserId";
  }
  public static get CreateHardwareTokenStatus(): string {
    return "hardwaretokenmanager/create-token";
  }

  public static get CustomerAccountTransactionsByCount(): string {
    return "TransactionManagement/GetTransactionDetailsByAccountNumberAndCount";
  }
  public static get CustomerAccountTransactionsByDate(): string {
    return "TransactionManagement/GetAccountTransactionDetails";
  } //GetTransactionDetailsByAccountNumberAndDate
  public static get CorporateStaement(): string {
    return "TransactionManagement/GetCorporateStatement";
  } //GetTransactionDetailsByAccountNumberAndDate
  public static get RequestCorporateStaement(): string {
    return "TransactionManagement/LogCorporateStatementRequest";
  } //GetTransactionDetailsByAccountNumberAndDate
  public static get AuthenticateUser(): string {
    return "UserProfileManagement/AuthenticateUser";
  }

  public static get BeneficiaryList(): string {
    return "BeneficiaryManagement/GetBeneficiaryList";
  }
  public static get BillerBeneficiaryList(): string {
    return "BeneficiaryManagement/GetBillerBeneficiaryList";
  }
  public static get BillerMerchants(): string {
    return "BeneficiaryManagement/GetBillerList";
  }

  public static get BankList(): string {
    return "TransactionManagement/GetBankList";
  }
  public static get CHECK_CROSS_CURRENCY_TRANSFER_TIMING(): string {
    return "TransactionManagement/ConfirmInterCurrency";
  }
  public static get TRANSFER(): string {
    return "TransactionManagement/Transfer";
  }
  public static get WALLET_TRANSFER(): string {
    return "TransactionManagement/WalllerTransfer";
  }
  public static get STAGE_BATCH_TRANSFER(): string {
    return "WorkflowManagement/StageBatchTransfer";
  }
  public static get SET_BATCH_TRANSFER_APPROVAL_WORKFLOW(): string {
    return "WorkflowManagement/StageBatchTransferFromUserSelection";
  }
  public static get GET_BATCH_TRANSACTIONS(): string {
    return "TransactionManagement/GetBatchTransaction";
  }
  public static get REMOVE_TRANSACTIONS_BATCH(): string {
    return "TransactionManagement/RemoveTransferBatches";
  }
  public static get GET_PENDING_BULK_APPROVAL_REQUEST(): string {
    return "WorkflowManagement/GetPendingBulkItemsForApproval";
  }
  public static get APPROVE_PENDING_BULK_REQUEST(): string {
    return "WorkflowManagement/ApprovePendingBatch";
  }
  public static get INITIATE_SINGLE_TRANSFER(): string {
    return "WorkflowManagement/InitiateSingleTransfer";
  }
  public static get SET_SINGLE_TRANSFER_APPROVAL_WORKFLOW(): string {
    return "WorkflowManagement/StageSingleTransferFromUserSelection";
  }
  public static get GET_PENDING_SINGLE_APPROVAL_REQUEST(): string {
    return "WorkflowManagement/GetPendingSingleTransferForApproval";
  }
  public static get APPROVE_PENDING_SINGLE_PAYMENT_REQUEST(): string {
    return "WorkflowManagement/ApprovePendingSingleTransfer";
  }
  public static get APPROVE_PENDING_SINGLE_PAYMENT_REQUEST_WITH_NEXTSTAGE_SELECTION(): string {
    return "WorkflowManagement/ApproveSingleTransferAndMoveToNextStage";
  }

  public static get INITIATE_INTERNATIONAL_TRANSFER(): string {
    return "WorkflowManagement/InitiateInternationalPayment";
  }
  public static get SET_INTERNATIONAL_TRANSFER_APPROVAL_WORKFLOW(): string {
    return "WorkflowManagement/StageInternationalTransferFromUserSelection";
  }
  public static get INTERNATIONAL_TRANSFER(): string {
    return "TransactionManagement/MakeInternationalPayment";
  }
  public static get GET_PENDING_INTERNATIONAL_PAYMENT_APPROVAL_REQUEST(): string {
    return "WorkflowManagement/GetPendingInternationalTransferForApproval";
  }
  public static get APPROVE_PENDING_INTERNATIONAL_PAYMENT_REQUEST(): string {
    return "WorkflowManagement/ApprovePendingInternationalTransfer";
  }
  public static get APPROVE_PENDING_INTNL_PAYMENT_REQUEST_WITH_NEXTSTAGE_SELECTION(): string {
    return "WorkflowManagement/ApproveInternationalTransferAndMoveToNextStage";
  }
  public static get MULTIPLE_BENEFICIARY_TRANSFER(): string {
    return "TransactionManagement/MultipleTransfer";
  }
  public static get INITIATE_MULTIPLE_BENEFICIARY_TRANSFER(): string {
    return "WorkflowManagement/InitiateMultipleTransfer";
  }
  public static get SET_MULTIPLE_BENEFICIARY_TRANSFER_APPROVAL_WORKFLOW(): string {
    return "WorkflowManagement/StageMulitpleTransferFromUserSelection";
  }
  public static get WESTERN_UNION_REDEMPTION(): string {
    return "TransactionManagement/RedeemWesternUnion";
  }
  public static get AIRTIME_TOPUP(): string {
    return "TransactionManagement/BuyAirtime";
  }
  public static get INITIATE_WESTERN_UNION_REDEMPTION(): string {
    return "WorkflowManagement/StageWesternUnionRedemption";
  }
  public static get SET_WESTERN_UNION_REDEMPTION_APPROVAL_WORKFLOW(): string {
    return "WorkflowManagement/StageWesternUnionRedemptionFromUserSelection";
  }
  public static get GET_PENDING_WESTERN_UNION_REDEMPTION_REQUESTS(): string {
    return "WorkflowManagement/GetPendingWesternUnionRedemptionForApproval";
  }
  public static get APPROVE_WESTERN_UNION_REDEMPTION_REQUEST(): string {
    return "WorkflowManagement/ApprovePendingWesternUnionRedemption";
  }
  public static get APPROVE_PENDING_WESTERN_UNION_REDEMPTION_REQUEST_WITH_NEXTSTAGE_SELECTION(): string {
    return "WorkflowManagement/ApprovePendingWesternUnionRedemptionAndMoveToNextStage";
  }
  public static get AddBeneficiary(): string {
    return "BeneficiaryManagement/AddBeneficiary";
  }
  public static get INITIATE_BENEFICIARY_CREATE(): string {
    return "WorkflowManagement/InitiateBeneficiaryAddition";
  }
  public static get INITIATE_BENEFICIARY_UPDATE(): string {
    return "WorkflowManagement/InitiateBeneficiaryUpdate";
  }
  public static get SET_BENEFICIARY_UPDATE_APPROVAL_WORKFLOW(): string {
    return "WorkflowManagement/StageBeneficiaryUpdateFromSelection";
  }
  public static get SET_BENEFICIARY_CREATE_APPROVAL_WORKFLOW(): string {
    return "WorkflowManagement/StageBeneficiaryAdditionFromSelection";
  }
  public static get APPROVE_PENDING_BENEFICIARY_ADD_REQUEST_WITH_NEXTSTAGE_SELECTION(): string {
    return "WorkflowManagement/ApprovePendingNewBeneficiaryAndMoveToNextStage";
  }
  public static get GET_STAGED_BENEFICIARY_REQUEST(): string {
    return "WorkflowManagement/GetPendingNewBeneficiariesForApproval";
  }
  public static get GET_STAGED_BENEFICIARY_UPDATE_REQUEST(): string {
    return "WorkflowManagement/GetAllPendingBeneficiariesUpdatesForApproval";
  }

  public static get LOG_SERVICE_REQUEST(): string {
    return "AccountManagement/LogServiceRequest";
  }

  public static get APPROVE_PENDING_BENEFICIARY_ADD_REQUEST(): string {
    return "WorkflowManagement/ApprovePendingNewBeneficiary";
  }
  public static get APPROVE_PENDING_BENEFICIARY_UPDATE_REQUEST(): string {
    return "WorkflowManagement/ApprovePendingBeneficiaryUpdate";
  }
  public static get STAGE_SERVICE_REQUEST(): string {
    return "WorkflowManagement/StageServiceRequest";
  }
  public static get SET_SERVICE_REQUEST_APPROVAL_WORKFLOW(): string {
    return "WorkflowManagement/StageServiceRequestFromSelection";
  }
  public static get GET_STAGED_SERVICEREQUEST_APPROVAL_REQUEST(): string {
    return "WorkflowManagement/GetPendingServiceRequestForApproval";
  }
  public static get APPROVE_PENDING_SERVICEREQUEST_OPERATION_REQUEST(): string {
    return "WorkflowManagement/ApprovePendingServiceRequest";
  }
  public static get APPROVE_PENDING_SERVICEREQUEST_OPERATION_REQUEST_WITH_STAGE_SELECTION(): string {
    return "WorkflowManagement/ApprovePendingServiceRequestAndMoveToNextStage";
  }
  public static get GET_PENDING_REQUEST(): string {
    return "WorkflowManagement/GetInitatorOperations";
  }
  public static get AddBillerBeneficiary(): string {
    return "BeneficiaryManagement/AddBillerBeneficiary";
  }
  public static get EditBenficiary(): string {
    return "BeneficiaryManagement/EditBeneficiary";
  }

  public static get DeleteBenficiary(): string {
    return "BeneficiaryManagement/DeleteBeneficiary";
  }
  public static get EditBillerBenficiary(): string {
    return "BeneficiaryManagement/EditBillerBeneficiary";
  }
  public static get DeleteBillerBenficiary(): string {
    return "BeneficiaryManagement/DeleteBillerBeneficiary";
  }
  public static get GetBillerBeneficiaryProduct(): string {
    return "TransactionManagement/GetBillerProductListByBiller";
  }
  public static get INITIATE_BILLER_OPERATION(): string {
    return "WorkflowManagement/InitiateBillerOperation";
  }
  public static get SET_BILLER_OPERATION_APPROVAL_WORKFLOW(): string {
    return "WorkflowManagement/StageBillerOperationFromSelection";
  }
  public static get GET_STAGED_BILLER_OPERATIONS_REQUEST(): string {
    return "WorkflowManagement/GetPendingBillerOperationsForApproval";
  }
  public static get APPROVE_PENDING_BILLER_OPERATION_REQUEST(): string {
    return "WorkflowManagement/ApprovePendingBillerOperation";
  }
  public static get APPROVE_PENDING_BILLER_OPERATION_REQUEST_WITH_STAGE_SELECTION(): string {
    return "WorkflowManagement/ApprovePendingBillerOperationAndMoveToNextStage";
  }

  public static get BILLPAYMENT(): string {
    return "TransactionManagement/PayBills";
  }
  public static get BILLCHARGES(): string {
    return "TransactionManagement/GetBillPaymentCharges";
  }
  public static get INITIATE_EBILLS_PAYMENT(): string {
    return "Ebills/GenerateEbillsUrlCaller";
  }
  public static get TRANSFER_HISTORY_RECEIPT(): string {
    return "TransactionManagement/GetTransferHistoryForReceipt";
  }
  public static get SCHEDULED_PAYMENTS(): string {
    return "TransactionManagement/GetScheduledPaymentList";
  }
  public static get STOP_SCHEDULE_TRANSACTION(): string {
    return "TransactionManagement/StopScheduledPayments";
  }
  public static get INITIATE_BILLPAYMENT(): string {
    return "WorkflowManagement/InitiateBillPayment";
  }
  public static get SET_BILLPAYMENT_APPROVAL_WORKFLOW(): string {
    return "WorkflowManagement/StageBillPaymentFromSelection";
  }
  public static get GET_PENDING_BILLPAYMENT_APPROVAL_REQUESTS(): string {
    return "WorkflowManagement/GetPendingBillPaymentsForApproval";
  }
  public static get APPROVE_PENDING_BILLPAYMENT(): string {
    return "WorkflowManagement/ApprovePendingBillPayments";
  }
  public static get APPROVE_PENDING_BILLPAYMENT_WITH_NEXTSTAGE_SELECTION(): string {
    return "WorkflowManagement/ApprovePendingBillPaymentAndMoveToNextStage";
  }
  public static get GET_BILLER_PAYMENT_REQUEST_APPROVAL_HISTORY(): string {
    return "WorkflowManagement/GetBillPaymentApprovalHistory";
  }
  public static get GET_BILLER_OPERATION_REQUEST_APPROVAL_HISTORY(): string {
    return "WorkflowManagement/GetBillerOperationApprovalHistory";
  }
  public static get GET_BENEFICIARY_ADD_APPROVAL_HISTORY(): string {
    return "WorkflowManagement/GetBeneficiaryOperationApprovalHistory";
  }
  public static get GET_BENEFICIARY_UPDATE_APPROVAL_HISTORY(): string {
    return "WorkflowManagement/GetBeneficiaryUpdateApprovalHistory";
  }

  public static get GET_WESTERN_UNION_REDEMPTION_APPROVAL_HISTORY(): string {
    return "WorkflowManagement/GetWesternUnionApprovalHistory";
  }
  public static get GET_SERVICEREQUEST_APPROVAL_HISTORY(): string {
    return "WorkflowManagement/GetServiceRequestApprovalHistory";
  }
  public static get GET_INTL_TRANSFER_APPROVAL_HISTORY(): string {
    return "WorkflowManagement/GetInternationalTransferApprovalHistory";
  }
  public static get GET_SINGLE_TRANSFER_APPROVAL_HISTORY(): string {
    return "WorkflowManagement/GetSingleApprovalHistory";
  }
  public static get GET_BULK_TRANSFER_APPROVAL_HISTORY(): string {
    return "WorkflowManagement/GetBatchApprovalHistory";
  }

  public static get REJECT_APPROVAL_REQUEST(): string {
    return "WorkflowManagement/RejectOperation";
  }
  public static get TransferChannelOrderSettings(): string {
    return "TransactionManagement/GetTransferChannelOrder";
  }
  public static get TransferChargesSettings(): string {
    return "TransactionManagement/GetCharges";
  }
  public static get CustomerBVN(): string {
    return "UserProfileManagement/GetCustomerBVN";
  }
  public static get SMEUserCorporateLimit(): string {
    return "TransactionManagement/GetCorporateUserLimit";
  }
  public static get RateConversion(): string {
    return "TransactionManagement/CurrencyConversionRate";
  }

  public static get CustomerAccountBeneficiary(): string {
    return "BeneficiaryManagement/GetBeneficiaryList";
  }
  public static get Banks(): string {
    return "TransactionManagement/GetBankList";
  }
  public static get DailyTransferLimit(): string {
    return "TransactionManagement/GetTransactionLimit";
  }
  public static get NameEnquiry(): string {
    return "TransactionManagement/DoNameEnquiry";
  }
  public static get VBNameEnquiry(): string {
    return "TransactionManagement/DoVBNameEnquiry";
  }
  public static get InitiateOTP(): string {
    return "UserProfileManagement/InitiateOTPRequest";
  }
  public static get LogoutSession(): string {
    return "UserProfileManagement/LogoutIBSession";
  }

  public static get InitiateLoginOTP(): string {
    return "UserProfileManagement/InitiateLoginOTPRequest";
  }
  public static get RetailUserSecurityQuestions(): string {
    return "UserProfileManagement/GetRegisteredQNA";
  }
  public static get CreateRIBUser(): string {
    return "UserProfileManagement/RegisterRIBUser";
  }
  public static get ConfirmRIBUserExist(): string {
    return "UserProfileManagement/GetRIBRegistrationQuestions";
  }
  public static get ChangePassword(): string {
    return "UserProfileManagement/ChangePassword";
  }
  public static get ResetPassword(): string {
    return "UserProfileManagement/ResetPassword";
  }
  public static get MANAGE_SSS_BANKING_COMMAND(): string {
    return "BeneficiaryManagement/3SBeneficiaryOps";
  }
  public static get GET_SSS_BANKING_COMMANDS(): string {
    return "BeneficiaryManagement/3SBeneficiaryList";
  }
  public static get SSS_BANKING_NAME_ENQUIRY(): string {
    return "BeneficiaryManagement/3SNameEnquiry";
  }
  public static get SSS_BANKING_PHONENUMBER(): string {
    return "BeneficiaryManagement/3SUserPhone";
  }
  public static get GET_PREPAID_CARD_DETAILS(): string {
    return "TransactionManagement/GetPrepaidDetails";
  }
  public static get FUND_PREPAID_CARD(): string {
    return "TransactionManagement/PrepaidTransfer";
  }

  public static get INSTANT_CASH_ADVANCE_LIMIT(): string {
    return "CashAdvance/GetLimit";
  }
  public static get INSTANT_UPL_LOAN_LIMIT(): string {
    return "CashAdvance/GetUplLimit";
  }
  public static get INSTANT_UPL_LOAN_REPAYMENT_SCHEDULES(): string {
    return "CashAdvance/GetUplRepaymentSchedules";
  }
  public static get INSTANT_TOPUP_LOAN_REPAYMENT_SCHEDULES(): string {
    return "CashAdvance/GetTopupReschedules";
  }
  public static get BOOK_UPL_LOAN(): string {
    return "CashAdvance/BookUplLoan";
  }
  public static get BOOK_TOPUP_LOAN(): string {
    return "CashAdvance/BookTopup";
  }

  public static get CASH_ADVANCE_REQUEST(): string {
    return "CashAdvance/BookLoan";
  }
  public static get CARD_EXPIRY_NOTIFICATIONS(): string {
    return "AccountManagement/CardExpiryNotifications";
  }
  public static get CARD_EXPIRY_NOTIFICATIONS_CONSENT(): string {
    return "AccountManagement/ScheduleCardRenewal";
  }

  public static get CONFIRM_DATA_PRIVACY_CONSENT_EXIST(): string {
    return "UserProfileManagement/ConfirmIfUserDataPrivacyExist";
  }
  public static get CREATE_DATA_PRIVACY_CONSENT_EXIST(): string {
    return "UserProfileManagement/CreateUserDataPrivacyExist";
  }

  public static get StatementCountryURL(): string {
    return "TransactionManagement/GetStatementCountries";
  }
  public static get StatementCountryDestinationURL(): string {
    return "TransactionManagement/GetStatementCountryDestinations";
  }
  public static get GenerateStatementRequestURL(): string {
    return "TransactionManagement/GenerateStatementForwardingRequest";
  }
  public static get ForwardStatementURL(): string {
    return "TransactionManagement/GenerateStatementForwarding";
  }
  public static get DownloadStatementTicketURL(): string {
    return "TransactionManagement/DownloadStatementTicket";
  }
  public static get RemitaLookup(): string {
    return "TransactionManagement/RemitaLookup";
  }
  public static get GetHardwareTokenStatus(): string {
    return "hardwaretokenmanager/token-status";
  }
  public static get GetPensionFileInfo(): string {
    return "pensionremittance/GetPensionFilesForApproval";
  }
  public static get GetPensionRecords(): string {
    return "pensionremittance/GetPensionRecords";
  }
  public static get GetApprovedPensionFiles(): string {
    return "pensionremittance/GetApprovedPensionFiles";
  }
  public static get GetPendingItemsForApprovals(): string {
    return "pensionremittance/GetMyPendingPensionItemsForApproval";
  }
  public static get UpdateHardwareTokenStatus(): string {
    return "hardwaretokenmanager/updatetokenrequest";
  }

  public static get StagePensionFile(): string {
    return "pensionremittance/StagePensionFile";
  }
  public static get ApprovePensionFile(): string {
    return "pensionremittance/ApprovePensionFile";
  }
  public static get RejectPensionFile(): string {
    return "pensionremittance/RejectPensionFile";
  }
  public static get StagePensionFileFromConflict(): string {
    return "pensionremittance/StagePensionFileFromConflict";
  }

  public static get ValidateIntellinxTransfer(): string {
    return "TransactionManagement/Transfer/ValidateSource";
  }
  public static get ActiveCreditCards(): string { return 'credit-card-transactions/GetCreditCardBalance' }
  public static get LoadCreditCard(): string { return 'credit-card-transactions/LoadCreditCards' }
  public static get DebitCreditCard(): string { return 'credit-card-transactions/DebitCreditCard' }
  public static get LogCardTransaction(): string { return 'credit-card-transactions/log-card-transaction' }



}
