import { Component, OnInit, SimpleChanges, Input, OnChanges } from '@angular/core';
import { PendingBillPaymentRequestObject, PendingInternationalPaymentRequestObject } from 'src/app/core/models/pending-request.models';
import { BasePendingRequestComponent } from '../base.pending-request.component';
import { AuthService } from 'src/app/core/services/auth.service';
import { MiscService } from 'src/app/core/services/misc.service';
import { MatSnackBar, MatDialog, MatDialogRef } from '@angular/material';
import { BasePagedRequestResponse, RequestApprovalHistoryPayload } from 'src/app/core/models/misc-requests-model';
import { ResponseCodes } from 'src/app/core/models/Enumerations';
import { ApprovalHistoryComponent } from 'src/app/shared/approval-history/approval-history.component';

@Component({
  selector: 'app-pending-fx-payment-requests',
  templateUrl: './pending-fx-payment-requests.component.html',
  styleUrls: ['./pending-fx-payment-requests.component.css']
})
export class PendingFxPaymentRequestsComponent extends BasePendingRequestComponent implements  OnInit, OnChanges {

  @Input() _activeTab: string;
  requestList: Array<PendingInternationalPaymentRequestObject> = [];
  requestListBackup: Array<PendingInternationalPaymentRequestObject> = [];
  _pageSize = 5;
  _requestErrorMessage: string;

  constructor(authService: AuthService, private _miscService: MiscService, private _snackbar: MatSnackBar, _dialog: MatDialog) {
    super(authService, _dialog);
   }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['_activeTab']) {
      this._activeTab = changes['_activeTab'].currentValue;
      if (this._activeTab === 'international-payments') {
        this.loadPendingInternationalPaymentRequests();
      }
    }
  }

  loadPendingInternationalPaymentRequests(loaderMsg = 'fetching pending international transfer requests. please wait ..' ,
                                          lastFetchId = 0, pageSize = 100) {
    this.ownLoaderIsActive = true;
    this.ownloaderMsg = loaderMsg;
    this._requestErrorMessage = '';
    const payload = {PageSize: pageSize, LastFetchId: lastFetchId, OperationType: 'INTERNATIONAL_TRANSFER' };
    this._miscService.getPendingRequest<BasePagedRequestResponse<PendingInternationalPaymentRequestObject>>(payload).then(
      (response) => {
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        if (!response || response.ResponseCode !== ResponseCodes.SUCCESS || !response.PaginatedItems) {
          this._snackbar.open(`${response.ResponseFriendlyMessage}`, 'Ok', {duration: 25000});
          return;
        }
        this.requestListBackup = response.PaginatedItems;
        this.requestList = this.requestListBackup.slice(0, pageSize);
        this._pageSize = this.requestList.length > this._pageSize ? this._pageSize : this.requestList.length;
      },
      (error) => {
        console.log(error);
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        this._requestErrorMessage = 'We encountered an unknown error while retrieving pending international payments requests for your account';
        this._snackbar.open(`We encountered an error while loading pending payment request`, 'Ok', {duration: 25000});
       }
    );
  }

  onRequestAction(action: string, data: PendingInternationalPaymentRequestObject) {
    switch (action) {
      case 'view-history': {
        const requestPayload: RequestApprovalHistoryPayload = this.buildRequestApprovalHistoryPayload(data, data.operationType);
        const approvalHistoryModal = this.launchRequestHistoryModal(requestPayload);
        approvalHistoryModal.afterClosed().subscribe(
          (result) => {}
        );
        break;
      }
    }
  }
/*
  private buildRequestApprovalHistoryPayload(data: PendingInternationalPaymentRequestObject, pageSize: number = 100, start: number = 0 ) {
    return  {
      OperationType: data.operationType,
      OperationId: data.Id,
      UserId: '',
      PageSize: pageSize,
      LastIdFetched: start
    };
  }
*/
  launchRequestHistoryModal(payload: RequestApprovalHistoryPayload): MatDialogRef<ApprovalHistoryComponent, any> {
    return this._dialog.open<ApprovalHistoryComponent>(
      ApprovalHistoryComponent,
      {
        hasBackdrop: false,
        closeOnNavigation: true,
        data: {
          operationName: 'international-transfer',
          requestPayload: payload,
          title: 'Request Approval History',
          extra: {}
        }
      });
  }

}
