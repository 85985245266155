import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppMaterialModule } from 'src/app/app.material.module';
import { VerticalTimelineModule } from 'angular-vertical-timeline';
import { TransactionStatusComponent } from './transaction-status.component';
import { ApprovalsModule } from '../approvals/approvals.module';
import { PaymentRequestsComponent } from './payment-requests/payment-requests.component';
import { BillPaymentRequestsComponent } from './bill-payment-requests/bill-payment-requests.component';
import { PendingBulkPaymentRequestsComponent } from './bulk-payment-requests/bulk-payment-requests.component';
import { WesternUnionPaymentRequestsComponent } from './western-union-payment-requests/western-union-payment-requests.component';
import { PendingServiceRequestsComponent } from './service-requests/service-requests.component';
import { PendingFxPaymentRequestsComponent } from './pending-fx-payment-requests/pending-fx-payment-requests.component';
import { PendingBillerMgtRequestComponent } from './pending-biller-mgt-request/pending-biller-mgt-request.component';
import { PendingBeneficiaryMgtRequestComponent } from './pending-beneficiary-mgt-request/pending-beneficiary-mgt-request.component';

export const transactionStatusRoutes = RouterModule.forChild([
    {
        path: '',
        component: TransactionStatusComponent,
    }
]);

@NgModule({
    declarations: [
        TransactionStatusComponent,
        PaymentRequestsComponent,
        BillPaymentRequestsComponent,
        PendingBulkPaymentRequestsComponent,
        WesternUnionPaymentRequestsComponent,
        PendingServiceRequestsComponent,
        PendingFxPaymentRequestsComponent,
        PendingBillerMgtRequestComponent,
        PendingBeneficiaryMgtRequestComponent
    ],
    imports: [CommonModule, FormsModule, RouterModule, transactionStatusRoutes, ReactiveFormsModule,
        AppMaterialModule, ApprovalsModule, VerticalTimelineModule],
    exports: [
        TransactionStatusComponent
    ],
    providers: [
    ],
})
export class TransactionsStatusModule { }
