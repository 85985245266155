import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { Approval, RequestApprovalStage } from 'src/app/core/models/approvals/payment.approval';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { ApprovalHistoryDialogData } from 'src/app/core/models/misc-requests-model';
import { MiscService } from 'src/app/core/services/misc.service';
import { ApprovalHistoryFactory } from 'src/app/core/services/workflow/approvals.history.service';
import { ResponseCodes, Role } from 'src/app/core/models/Enumerations';
import { AuthService } from 'src/app/core/services/auth.service';
import { AppUser } from 'src/app/core/models/auth';
import { UtilService } from 'src/app/core/services/helpers.service';

@Component({
  selector: 'app-approval-history',
  templateUrl: './approval-history.component.html',
  styleUrls: ['./approval-history.component.css']
})
export class ApprovalHistoryComponent implements OnInit, OnDestroy {

  _requestApprovalHistory: Array<Approval> = [];
  _requestApprovalStages: Array<RequestApprovalStage> = [];
  _approvalHistorySummary = {
    totalStages: 0,
    completedStages: 0,
    pendingStages: 0,
    totalApprovals: 0,
    completedApprovals: 0,
    pendingApprovals: 0
  };
  ownloaderIsActive: boolean;
  ownloaderMsg: string;
  _requestError: string;

  loggedInUser: AppUser;
  loggedInUserIsInitiator: boolean;
  loggedInUserIsApprover: boolean;

  constructor(private dialogInstance: MatDialogRef<ApprovalHistoryComponent>,
    private _snackbar: MatSnackBar, @Inject(MAT_DIALOG_DATA) public data: ApprovalHistoryDialogData,
    private _miscService: MiscService, private _approvalHistoryService: ApprovalHistoryFactory, private _authService: AuthService
  ) {
    this.loggedInUser = _authService.getLoggedInUser();
      this.loggedInUserIsInitiator = this.loggedInUser && this.loggedInUser.Role && this.loggedInUser.Role.roles &&
                                        this.loggedInUser.Role.roles.includes(Role.Initiator);
      this.loggedInUserIsApprover = this.loggedInUser && this.loggedInUser.Role && this.loggedInUser.Role.roles &&
                                    this.loggedInUser.Role.roles.includes(Role.Approver);
      if (dialogInstance) {
        UtilService.currentModalRef.push(dialogInstance);
      }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.dialogInstance) {
      this.dialogInstance.close();
    }
  }
  ngOnInit() {
    this.initiatlizeComponent(this.data);
    this.loadRequestApprovalHistory();
  }

  private loadRequestApprovalHistory() {
    this.ownloaderIsActive = true;
    this.ownloaderMsg = 'fetching .. please wait while we retrieve request approval history ..';
    this._approvalHistoryService.getApprovalHistory(this.data.operationName, this.data.requestPayload)
      .then(
        (response) => {
          console.log(response);
          this.ownloaderIsActive = false;
          this.ownloaderMsg = '';
          if (!response || !response.ResponseCode) {
            this._snackbar.open(`We encountered an unknown error while loading approval history. please retry`, 'Ok', { duration: 25000 });
            return;
          }
          if (response.ResponseCode !== ResponseCodes.SUCCESS) {
            this._snackbar.open(response.ResponseFriendlyMessage, 'Ok', { duration: 25000 });
            return;
          }
          if (!response.History || !response.History.ApprovalStages) {
            this._snackbar.open(`Could not find approval history for this ${this.data.operationName} );`, 'OK', { duration: 15000 });
            return;
          }
          this._requestApprovalStages = response.History.ApprovalStages;
          this.processApprovalHistory(response.History.ApprovalStages);
        },
        (error) => {
          this.ownloaderIsActive = false;
          this.ownloaderMsg = '';
          this._snackbar.open(`Could not load approval history. we encountered an error while loading approval history`,
            'Ok', { duration: 25000 });
        }
      );
  }
  private initiatlizeComponent(data: ApprovalHistoryDialogData) {

  }

  private processApprovalHistory(approvalStages: RequestApprovalStage[]) {
    this._requestApprovalHistory = [];
    this._approvalHistorySummary.totalStages = approvalStages.length;
    this._approvalHistorySummary.completedStages = approvalStages.filter(stage => stage.Status.toLowerCase() === 'done').length;
  this._approvalHistorySummary.pendingStages = this._requestApprovalStages.filter(stage => stage.Status.toLowerCase() === 'new' ||
                                                                                           stage.Status.toLowerCase() === 'active').length;
    this._approvalHistorySummary.totalApprovals = this._requestApprovalStages.length > 0 ?
                          this._requestApprovalStages.map(stage => stage.MinimumApprover).reduce((a, b) => a + b) : 0;
    for (let i = 0; i < approvalStages.length; i++) {
      const completedApprovals = approvalStages[i].Approvers.filter(approval => approval.Status.toLowerCase() === 'done');
      this._requestApprovalHistory = this._requestApprovalHistory.concat(completedApprovals);
    }
    this._approvalHistorySummary.completedApprovals = this._requestApprovalHistory.length;
    this._approvalHistorySummary.pendingApprovals = this._approvalHistorySummary.totalApprovals
                                                    - this._approvalHistorySummary.completedApprovals;
  }

  onCloseDialog() {
    this.dialogInstance.close(false);
  }
}
