import { Injectable, NgZone } from '@angular/core';
import * as signalR from "@aspnet/signalr";
import { AuthService } from './auth.service';
import { AppConfigService } from './appconfig.service';

@Injectable()
export class SignalRService {

    public hubConnection: signalR.HubConnection;

    public startConnection = () => {
        this.hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(AppConfigService.SignalRURL)
            .configureLogging(signalR.LogLevel.Debug)
            .build();

        this.hubConnection.start()
            .then(() => {
                console.log("Connection established");
                AuthService.signalRConnectionOpened = true;
            })
            .catch(err => console.log("Error while starting connection: " + err));
    }

    public stopConnection = (): Promise<string> => {

        return this.hubConnection.stop()
            .then(() => {
                console.log("Connection destroyed");
                AuthService.signalRConnectionOpened = false;
                return Promise.resolve("");
            })
            .catch(err => {
                console.log("Error destroying connection: " + err);
                AuthService.signalRConnectionOpened = false;
                return Promise.reject(err);
            });
    }

    public AddSessionOnConnectionMadeListener = (userId: string) => {
        let self = this;
        this.hubConnection.on("AddSessionOnConnectionMade", () => {
            self.hubConnection.invoke("LoginAndTerminateOtherUserSessions", userId)
                .catch(err => console.log("Error while calling LoginAndTerminateOtherUserSessions on the server on reconnection: " + err));
        });
    }

    public AllUserSessionsTerminatedExceptSelfListener = () => {
        let self = this;
        this.hubConnection.on("AllUserSessionsTerminatedExceptSelf", (message) => {
            console.log(message);
        });
    }

    public LogoutSessionMethodListener = () => {
        let self = this;
        // this.hubConnection.on("LogoutSession", () => {
        //     console.log("logout user session via eventhandler.");
        //     EventHandlerService.emitSignalRSeesionLogoutEvent();

        //     self.stopConnection()
        //         .then(() => {
        //             // self.router.navigate([RouterConstants.LoginURL]);
        //             // self.ngZone.run(() => self.router.navigate([RouterConstants.LoginURL]));
        //         })
        //         .catch(err => {
        //             console.log("Error stopping Connection on logout call from server: " + err);
        //         });
        // });
    }

    public ReAddSessionConnectionIdListener = (userId: string) => {
        let self = this;
        this.hubConnection.on("ReAddSessionConnectionId", () => {
            self.hubConnection.invoke("ReAddSessionConnection", userId)
                .catch(err => console.log("Error while calling ReAddSessionConnection on the server: " + err));
        });
    }
}
