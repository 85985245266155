import { Injectable } from '@angular/core';
import { AccountService } from './account.service';
import { TransactionService } from './transaction.service';
import { AppConfigService } from './appconfig.service';


@Injectable()
export class DashboardService {

    private _apiBaseUrl: string;
    constructor(private _accountService: AccountService, private _transactionService: TransactionService,
        private _settings: AppConfigService) {
        this._apiBaseUrl = _settings.APIBaseUrl;

    }






}
