import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { UtilService } from './helpers.service';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {


    if (AuthService.useOnlyContentTypeHeader) {

      const changedReq = req.clone({
        headers: req.headers
          .set('Content-Type', 'application/json')
      });
      return next.handle(changedReq);
    }

    const changedReq = req.clone({
      headers: req.headers
        .set('Content-Type', 'application/json')
        .set('BankChannel', 'INTERNET_BANKING')
        .set('X-STC-AGENT-CACHE', AuthService.FullyAuthenticatedUser ? AuthService.FullyAuthenticatedUser.UserId : AuthService.UnauthenticatedUserId || "")
        .set('X-RTC-AGENT-CACHE', AuthService.FullyAuthenticatedUser ? AuthService.FirstValidateOTPToken : "")
        .set('X-TTC-AGENT-CACHE', AuthService.ValidateUserSession ? "100" : "200")
        .set('X-STM1UC-AGENT-REDUX', AuthService.FullyAuthenticatedUser && AuthService.FullyAuthenticatedUser.YPSFlixDescription ?
          AuthService.FullyAuthenticatedUser.YPSFlixDescription :
          AuthService.PartiallyAuthenticatedUser && AuthService.PartiallyAuthenticatedUser.YPSFlixDescription ?
            AuthService.PartiallyAuthenticatedUser.YPSFlixDescription : '')
        .set('Authorization', this.getAuthToken() || '')
    });
    return next.handle(changedReq);
  }

  getAuthToken(): string {

    let bearerToken = this.authService.GetAccessToken();

    if (AuthService.useLoginTokenForSessionTokenOnNextAPICall) {
      bearerToken = this.authService.GetLoginToken();
    }

    if (!UtilService.StringIsNullOrEmpty(bearerToken)) {
      return `Bearer ${bearerToken}`;
    }
    return null;
  }



  getLoginToken(): string {

    let bearerToken = this.authService.GetAccessToken();
    if (!UtilService.StringIsNullOrEmpty(bearerToken)) {
      return `Bearer ${bearerToken}`;
    }
    return 'Bearer';
  }


  // getLoggedInUser() {

  //   const loggedInUser = this.authService.getLoggedInUser();

  //   return !loggedInUser ? localStorage.getItem('tempUserId') : loggedInUser.UserId;
  // }
}
