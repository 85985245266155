
import { Injectable } from '@angular/core';

// import { TransactionCharges, UserTransferLimits } from '@app/core/models/accountTransaction.model';
// import { Beneficiary } from '@app/core/models/beneficiary.model';
import { CustomerAccount } from '../models/account';
// import { Bank } from '@app/core/models/bank-model';


export class VARIABLES {

    public static CustomerAccounts: Array<CustomerAccount>;
    public static STANBIC_CONTACT_EMAIL: string;

    // public static TransferChannelOrder: Array<string>;
    // public static TransferCharges: Array<TransactionCharges>;
    // public static UserAccountBeneficiaries: Array<Beneficiary>;
    // public static RELOAD_ACCOUNT_BENEFICIARIES_ON_CUD: boolean;
    // public static RELOAD_BILLER_BENEFICIARIES_ON_CUD: boolean;
    // public static DefaultAccountNo: string;
    // public static Banks: Array<Bank>;
    // // public static DailyTransferLimit: Array<UserTransferLimits>; public static TransferChannelOrder: Array<string>;
    // public static TransferCharges: Array<TransactionCharges>;
    // public static UserAccountBeneficiaries: Array<Beneficiary>;
    // public static RELOAD_ACCOUNT_BENEFICIARIES_ON_CUD: boolean;
    // public static RELOAD_BILLER_BENEFICIARIES_ON_CUD: boolean;
    // public static CustomerAccounts: Array<CustomerAccount>;
    // public static DefaultAccountNo: string;
    // public static ReloadTransferLimit: boolean;
}

