import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  NgZone,
} from "@angular/core";
import { Constants } from "src/app/core/helpers/appConstants";
import { Router } from "@angular/router";
import { BankAccount } from "src/app/core/models/bankAccount";
import { TransactionModel } from "src/app/core/models/transactions/transaction";
import {
  AccountBeneficiary,
  AirtimeBeneficiary,
} from "src/app/core/models/beneficiary";
import { MatSnackBar, MatDialog } from "@angular/material";
import { Keepalive } from "@ng-idle/keepalive";
import { Idle } from "@ng-idle/core";
import { AppUser } from "src/app/core/models/auth";
import { Role } from "src/app/core/models/Enumerations";
import { RouterConstants } from "src/app/core/helpers/routerConstants";
import { AuthService } from "src/app/core/services/auth.service";
import { VariablesService } from "src/app/core/services/variables.service";
import { EventHandlerService } from "src/app/core/services/EventHandlerService";
import { Subscription } from "rxjs";
import { SignalRService } from "../../../core/services/signalr.service";
import { CreditCardRequestService } from 'src/app/core/services/credit-card-request.service';


function _window(): any {
  return window;
}

@Component({
  selector: "app-default-layout",
  templateUrl: "./default-layout.component.html",
  styleUrls: ["./default-layout.component.css"],
})
export class DefaultLayoutComponent implements OnInit, OnDestroy {
  idleState = "Not started.";
  timedOut = false;
  lastPing?: Date = null;
  inactiveSessionAlertSent: boolean;
  title = "smeBankingApp";
  // @ViewChild('timeoutMsgTemplate') sessionTimeOutModalTemplate: TemplateRef<any>;
  // sessionTimeOutModalDialog: MatDialogRef<any>;

  public loggedInUser: AppUser;
  public loggedInUserIsRetailUser: boolean;
  public AppRoleEnum = Role;

  userHasFullAccess: boolean;

  userHasCreditCardLimit: boolean;
  HasRequestedCreditCard: boolean

  appViewChangedSub: any;
  showAppViewChangeLoader: boolean = false;
  activeMenuLink: string;
  logoutSignalrSessionSub: Subscription;

  // opened = true;
  // over = 'side';
  // expandHeight = '42px';
  // collapseHeight = '42px';
  // displayMode = 'flat';

  // watcher: Subscription;

  // 85% 35 25 25 15
  showSidebar: boolean;

  instantCashAdvanceMenuLinkVisibilitySub: Subscription;
  logoutSub: Subscription;

  constructor(
    private _router: Router,
    private _snackbar: MatSnackBar,
    private _sessionManager: Idle,
    private _keepAlive: Keepalive,
    private _dialog: MatDialog,
    private _authService: AuthService,
    private cdRef: ChangeDetectorRef,
    private _signalRService: SignalRService,
    private ngZone: NgZone,
    private creditCardRequestService: CreditCardRequestService,

  ) {
     // try {
    //   window['casa']('other'); 
    //  } catch (error) {}
    // , media: ObservableMedia) {
    this.loggedInUser =
      AuthService.FullyAuthenticatedUser || _authService.getLoggedInUser();
    this.loggedInUserIsRetailUser =
      this.loggedInUser && AuthService.isRetailUser();
    this.userHasFullAccess = true;

    if (
      AuthService.FullyAuthenticatedUser &&
      AuthService.FullyAuthenticatedUser.LimitedAccess
    ) {
      this.userHasFullAccess = false;
    }

    // this.showAppViewChangeLoader = true;

    // this.watcher = media.subscribe((change: MediaChange) => {
    //   if (change.mqAlias === 'sm' || change.mqAlias === 'xs') {
    //     this.opened = false;
    //     this.over = 'over';
    //   } else {
    //     this.opened = true;
    //     this.over = 'side';
    //   }
    // });
  }

  ngOnInit() {
    // const user = localStorage.getItem(Constants.Auth_Data);
    // if (!user) {

    // }
    this.activeMenuLink = "accounts";
    let self = this;
    this._signalRService.startConnection();
    this._signalRService.AddSessionOnConnectionMadeListener(
      AuthService.FullyAuthenticatedUser.UserId
    );
    this._signalRService.ReAddSessionConnectionIdListener(
      AuthService.FullyAuthenticatedUser.UserId
    );
    this._signalRService.LogoutSessionMethodListener();

    this._signalRService.AllUserSessionsTerminatedExceptSelfListener();

    this.fetchVirtualCardByCIFID(AuthService.FullyAuthenticatedUser.CifId)

  }


  fetchVirtualCardByCIFID(cif: any) {
  this.creditCardRequestService.GetCardEligibilityByCif(cif).subscribe(
      (response) => {
        if (response.ResponseCode === "000" ) {
          this.userHasCreditCardLimit = true
          this.HasRequestedCreditCard = response.Data['0'].HasRequestedCreditCard

        }
      },
      (error) => {
      }
    );

  }

  showInstantCashAdvanceLink: boolean = false;
  showLogoutLoader: boolean;

  ngAfterViewInit() {
    let self = this;
    this.appViewChangedSub = EventHandlerService.OnAppViewChangedEventPublisher.subscribe(
      (object) => {
        self.showAppViewChangeLoader = object.hideAppViewChangeLoader
          ? !(object.hideAppViewChangeLoader as boolean)
          : false;
        self.cdRef.detectChanges();
      }
    );

    this.instantCashAdvanceMenuLinkVisibilitySub = EventHandlerService.InstantCashAdvanceLinkControlEventPublisher.subscribe(
      (object) => {
        self.showInstantCashAdvanceLink = object.showLink
          ? (object.showLink as boolean)
          : false;
        self.cdRef.detectChanges();
      }
    );

    this._signalRService.hubConnection.on("LogoutSession", () => {
      this._signalRService
        .stopConnection()
        .then(() => {
          self.logoutSignalRSession();
        })
        .catch((err) =>
          console.log("Error stopping Connection on logout click: " + err)
        );
      // this.logoutSignalRSession();
    });
  }

  logoutSignalRSession() {
    localStorage.clear();
    sessionStorage.clear();
    AuthService.clearSessionTokenOnLogout = true;
    this._authService.logout();
    VariablesService.SessionInvalid = true;
    this._router.navigate([RouterConstants.LoginURL]);
  }

  toggleSidebar(): void {
    this.showSidebar = !this.showSidebar;
    // console.log(this.showSidebar);
    if (this.showSidebar) {
      this.showSideNav();
    } else {
      this.hideSideNav();
    }
  }
  onPageBodyClicked(e) {
    if (this.showSidebar) {
      this.showSidebar = false;
      this.hideSideNav();
    }
  }
  onInvokeFullScreen() {
    const elem = document.documentElement;
    const methodToBeInvoked =
      elem["requestFullscreen"] ||
      elem["webkitRequestFullScreen"] ||
      elem["mozRequestFullscreen"] ||
      elem["msRequestFullscreen"];
    if (methodToBeInvoked) {
      methodToBeInvoked.call(elem);
    }
  }

  onLogoutUser(): void {
    let self = this;
    this._router.navigate([RouterConstants.SurveyURL]);
    this.showLogoutLoader = true;
    this.showAppViewChangeLoader = false;
    VariablesService.tokenApiHasBeenCalled = false;
    VariablesService.tokenApiCurrentCif = ''
    if (this.logoutSub) {
      this.logoutSub.unsubscribe();
    }

  //   this.showLogoutLoader = true;
  //   this.showAppViewChangeLoader = false;
  //   if (this.logoutSub) {
  //     this.logoutSub.unsubscribe();
  //   }

    (this.logoutSub = this._authService
      .LogoutRequest(AuthService.FullyAuthenticatedUser.UserId)
      .subscribe((response) => {
        // localStorage.clear();
        // sessionStorage.clear();
        // AuthService.clearSessionTokenOnLogout = true;
        // this._authService.logout();
        this.showLogoutLoader = false;

        // this._router.navigate([RouterConstants.LoginURL]);

        localStorage.clear()
        return;
      })),
      (error: any) => {
        // localStorage.clear();
        // sessionStorage.clear();
        // AuthService.clearSessionTokenOnLogout = true;
        // this._authService.logout();
        this.showLogoutLoader = false;
        // this._router.navigate([RouterConstants.LoginURL]);

        this._router.navigate([RouterConstants.SurveyURL]);
      };

    this._signalRService
      .stopConnection()
      .then(() => {})
      .catch((err) =>
        console.log("Error stopping connection on logout click: " + err)
      );
      try {
        localStorage.removeItem("tokenApiHasBeenCalled")
      }catch(er){}


  }

  onLogoutUser2(): void {
    this._router.navigate([RouterConstants.SurveyURL]);
  }
  navigateToView(event, url: string) {
    if (event) {
      event.stopPropagation();
    }

    if (this.activeMenuLink != url) {
      this.showAppViewChangeLoader = true;
      if (url) {
        this.activeMenuLink = url;
        this.showAppViewChangeLoader = false;
        this._router.navigate([`/${url}`]);
        this.showAppViewChangeLoader = false;//uncomment
      //  this.showAppViewChangeLoader = false; //
      }
      this.hideSideNav();
    }

    return false;
  }

  navigateToUrl(url: string) {
    if (url) {
      this._router.navigate([`/${url}`]);
    }
  }

  setupMockStartData() {
    const mockAccounts = localStorage.getItem(Constants.Store_Accounts);
    if (!mockAccounts) {
      localStorage.setItem(
        Constants.Store_Accounts,
        JSON.stringify(this.mockAccountsData())
      );
    }
    const mockTransactions = localStorage.getItem(Constants.Store_trans);
    if (!mockTransactions) {
      localStorage.setItem(
        Constants.Store_trans,
        JSON.stringify(this.mockTransferData())
      );
    }
    const mockAccountsBeneficiaries = localStorage.getItem(
      Constants.Store_AccountBeneficiaries
    );
    if (!mockAccountsBeneficiaries) {
      localStorage.setItem(
        Constants.Store_AccountBeneficiaries,
        JSON.stringify(this.mockAccountBeneficiary())
      );
    }
    const mockAirtimeBeneficiaries = localStorage.getItem(
      Constants.Store_AirtimeBeneficiaries
    );
    if (!mockAirtimeBeneficiaries) {
      localStorage.setItem(
        Constants.Store_AirtimeBeneficiaries,
        JSON.stringify(this.mockAirtimeBeneficiary())
      );
    }
  }
  mockAccountsData(): BankAccount[] {
    return [
      {
        id: "11111",
        accountName: "Adedamola Folarin",
        accountType: "Savings",
        accountNumber: "0002455029",
        availableBalance: 2326000,
        ledgerBalance: 2076000,
        cardType: "Visa",
        transient: 250000,
        isDefault: true,
      },
      {
        id: "22222",
        accountName: "Adedamola Folarin",
        accountType: "Current",
        accountNumber: "0001365031",
        availableBalance: 3500000,
        ledgerBalance: 3500000,
        cardType: "MasterCard",
        transient: 0,
        isDefault: true,
      },
      {
        id: "33333",
        accountName: "Adedamola Folarin",
        accountType: "Deposit",
        accountNumber: "000525029",
        availableBalance: 95000,
        ledgerBalance: 185000,
        cardType: "Visa",
        transient: 0,
        isDefault: false,
      },
      {
        id: "55555",
        accountName: "Adedamola Folarin",
        accountType: "Salary",
        accountNumber: "0002455369",
        availableBalance: 25000000,
        ledgerBalance: 25000000,
        cardType: "Visa",
        transient: 0,
        isDefault: false,
      },
    ];
  }
  mockTransferData(): TransactionModel[] {
    return [
      {
        transactionId: "1539856261810",
        narration:
          "Recharged +2348135170804_ - 3000 on Thu Oct 18 2018 10:51:01 GMT+0100 (West Africa Standard Time)",
        amount: "3000",
        refAccountId: "11111",
        transactionType: "debit",
        date: new Date("2018-10-18T09:51:01.810Z"),
      },
      {
        transactionId: "1539856941940",
        narration:
          "Sent 530000 to 2014178029 from 11111 on Thu Oct 18 2018 11:02:21 GMT+0100 (West Africa Standard Time)",
        amount: "530000",
        refAccountId: "11111",
        transactionType: "debit",
        date: new Date("2018-10-18T10:02:21.940Z"),
      },
      {
        transactionId: "1539857766596",
        narration:
          "Sent 500000 to 1000000000 from 11111 on Thu Oct 18 2018 11:16:06 GMT+0100 (West Africa Standard Time)",
        amount: "500000",
        refAccountId: "11111",
        transactionType: "debit",
        date: new Date("2018-10-18T10:16:06.596Z"),
      },
      {
        transactionId: "1539857777985",
        narration:
          "Sent 500000 to 1000000000 from 11111 on Thu Oct 18 2018 11:16:17 GMT+0100 (West Africa Standard Time)",
        amount: "500000",
        refAccountId: "11111",
        transactionType: "debit",
        date: new Date("2018-10-18T10:16:17.985Z"),
      },
      {
        transactionId: "1539858481388",
        narration:
          "Recharged +2348135170804_ - 5000 on Thu Oct 18 2018 11:28:01 GMT+0100 (West Africa Standard Time)",
        amount: "5000",
        refAccountId: "11111",
        transactionType: "debit",
        date: new Date("2018-10-18T10:28:01.388Z"),
      },
    ];
  }

  mockAccountBeneficiary(): AccountBeneficiary[] {
    return [
      {
        beneficiaryName: "Bassey Simon",
        accountNumber: "2014178029",
        accountTitle: "Bassey Simon",
        bankName: "First Bank",
      },
      {
        beneficiaryName: "Jude Frank",
        accountNumber: "1000000000",
        accountTitle: "Jude Frank",
        bankName: "Access Bank",
      },
    ];
  }
  mockAirtimeBeneficiary(): AirtimeBeneficiary[] {
    return [
      {
        beneficiaryName: "David Bassey",
        mobileNetwork: "9Mobile",
        mobileNumber: "+2348135170804",
      },
      {
        beneficiaryName: "Vivian Bassey",
        mobileNetwork: "MTN NG",
        mobileNumber: "+2348103570477",
      },
      {
        beneficiaryName: "Airtel Data SIM",
        mobileNetwork: "Airtel NG",
        mobileNumber: "+2349077432735",
      },
    ];
  }

  reset() {
    this._sessionManager.watch();
    this.idleState = "Started.";
    this.timedOut = false;
  }

  ngOnDestroy() {
    if (VariablesService.SessionTimeoutInterval) {
      _window().clearInterval(VariablesService.SessionTimeoutInterval);
    }

    if (VariablesService.SessionTimedOutSub) {
      VariablesService.SessionTimedOutSub.unsubscribe();
    }

    if (VariablesService.SessionTimeoutEventId) {
      _window().clearTimeout(VariablesService.SessionTimeoutEventId);
    }

    if (this.appViewChangedSub) {
      this.appViewChangedSub.unsubscribe();
    }

    if (this.instantCashAdvanceMenuLinkVisibilitySub) {
      this.instantCashAdvanceMenuLinkVisibilitySub.unsubscribe();
    }
  }

  private showSideNav() {
    const sidebar = document.getElementById("mdl-layout__drawer");
    const header = document.getElementById("mdl-layout__header-row");
    if (sidebar) {
      sidebar.classList.remove("mdl-layout__drawer_view");
    }
    if (header) {
      header.classList.add("mdl-layout__header-row_with_right_padding");
    }
  }
  private hideSideNav() {
    const sidebar = document.getElementById("mdl-layout__drawer");
    const header = document.getElementById("mdl-layout__header-row");
    if (sidebar) {
      sidebar.classList.add("mdl-layout__drawer_view");
    }
    if (header) {
      header.classList.remove("mdl-layout__header-row_with_right_padding");
    }
    this.showSidebar = false;
  }
}
