
import { Injectable } from '@angular/core';
import { AccountService } from './account.service';
import { TransactionService } from './transaction.service';
import { AppConfigService } from './appconfig.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CardLimitEligibility, CreditCardEligibilityResp } from '../models/credit-card-resp';
import { CreditCardRequest } from '../models/credtit-card-request';
import { GenericApiResponse } from '../models/payloads/generic-response';
import { CreditCardIncreaseRequest } from '../models/credit-card-increase-request';
import { CardIncreaseEligibilityRequest } from '../models/card-increase-eligibility-request';
import { ResponseCodes } from '../models/Enumerations';
import { LEAVE_SELECTOR } from '@angular/animations/browser/src/util';
@Injectable()
export class VirtualCardService {

    cardDetails = new BehaviorSubject<any>({
        card: {}
    });

    private _apiBaseUrl: string;
    constructor(private _httpClient: HttpClient, private _settingsService: AppConfigService) {
        this._apiBaseUrl = this._settingsService.VirtaulCardBaseUrl;

    }


    fetchCardByCif(CifId: string): Observable<any> {
        const _url = `${this._apiBaseUrl}/virtualcard/fetchCardByCif?CIFID=${CifId}`;
        return this._httpClient.post<any>(`${_url}`,
            { cif: CifId }
        );
    }

    fetchCards(payload: any): Observable<any> {
        return this._httpClient.post<any>(`${this._apiBaseUrl}/virtualcard/fetchCards`,
            payload
        );
    }

    accountBalanceCheck(payload): Observable<any> {
        return this._httpClient.post<any>(`${this._apiBaseUrl}/customer-account/account-balance`,
            payload
        );
    }

    cardtypeCheckCheck(payload): Observable<any> {
        console.log(' checkin current acct',payload)
        return this._httpClient.post<any>(`${this._apiBaseUrl}/DebitCard/CardType`,
            payload
        );
    }


    requestVirtualCard(payload: any): Observable<any> {
        console.log(this._apiBaseUrl, 'api url');
        console.log(payload, 'payload');
        return this._httpClient.post<any>(`${this._apiBaseUrl}/virtualcard/create`,
            payload
        );
    }
    changePin(payload: any): Observable<any> {
        return this._httpClient.post<any>(`${this._apiBaseUrl}/virtualcard/ChangePin`,
            payload
        );
    }

    sendOpt(customerId: string): Observable<any> {
        const _url = `${this._apiBaseUrl}/virtualcard/send-otp?customerId=${customerId}`;
        return this._httpClient.post<any>(`${_url}`,
            { customerId: customerId }
        );
    }

    validateOpt(payload: any): Observable<any> {
        const _url = `${this._apiBaseUrl}/virtualcard/validate-otp`;
        return this._httpClient.post<any>(`${_url}`,
            payload
        );
    }

    hotlistCard(payload: any, type: string): Observable<any> {
      return this._httpClient.post<any>(`${this._apiBaseUrl}/virtualcard/blockCard?type=${type}`,
      payload
    );

  }




}
