import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AcceptConditionModalComponent } from './AcceptCondition-Modal.component';
import { RouterModule } from '@angular/router';
import { MatButtonModule, MatDialogModule } from '@angular/material';
import { AppMaterialModule } from 'src/app/app.material.module';


const AcceptConditionModalModuleRoute = RouterModule.forChild([
  {
      path: '',
      component: AcceptConditionModalComponent,
  },
]);

@NgModule({
  imports: [
    CommonModule,MatDialogModule,RouterModule,AppMaterialModule,MatButtonModule,
    AcceptConditionModalModuleRoute
  ],
  declarations: [AcceptConditionModalComponent]
})
export class AcceptConditionModalModule { }
