import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../appconfig.service';
import { ApiURLConstants } from '../../helpers/apiURLConstants';
import { AuthService } from '../auth.service';
import { StagedRequestApprovalResponse } from '../../models/misc-requests-model';
import { StagedBeneficiaryRequestApprovalPayload } from '../../models/beneficiary'; 

@Injectable()
export class ServiceRequestOperationApprovalService {

    private _apiBaseUrl: string;
    constructor(private _httpClient: HttpClient, _settings: AppConfigService, private _authService: AuthService) {
        this._apiBaseUrl = _settings.APIBaseUrl;
    }
    approvePendingServiceOperationRequest(payload: StagedBeneficiaryRequestApprovalPayload):
        Promise<StagedRequestApprovalResponse> {
        payload = this.updateRequestPayloadWithUserId(payload);
        return this._httpClient.post<StagedRequestApprovalResponse>(
            `${this._apiBaseUrl}/${ApiURLConstants.APPROVE_PENDING_SERVICEREQUEST_OPERATION_REQUEST}`, payload)
            .toPromise();
    }

    approvePendingServiceOperationRequestWithSelectedStage(payload: StagedBeneficiaryRequestApprovalPayload):
        Promise<StagedRequestApprovalResponse> {
        payload = this.updateRequestPayloadWithUserId(payload);
        return this._httpClient.post<StagedRequestApprovalResponse>(
            `${this._apiBaseUrl}/${ApiURLConstants.APPROVE_PENDING_SERVICEREQUEST_OPERATION_REQUEST_WITH_STAGE_SELECTION}`, payload)
            .toPromise();
    }

    private updateRequestPayloadWithUserId(payload: any): any {
        const loggedInUser = this._authService.getLoggedInUser();
        payload['UserId'] = loggedInUser && loggedInUser.UserId ? loggedInUser.UserId : '';
        return payload;
    }
}
