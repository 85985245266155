

import { RouterModule } from "@angular/router";
import { ManageFxTransactionComponent } from "./manage-fx-transaction.component";
 
export const routes = RouterModule.forChild([
    {
        path: '',
        component: ManageFxTransactionComponent,
    },
]);