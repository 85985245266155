import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LoanRequestPayload, LoanRequestResponse, LoanRepaymentScheduleRequestPayload, LoanRepaymentScheduleResponse, UPLLoanRequestPayload } from 'src/app/core/models/loan.request.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { AppConfigService } from 'src/app/core/services/appconfig.service';
import { HttpClient } from '@angular/common/http';
import { ApiURLConstants } from 'src/app/core/helpers/apiURLConstants';

@Injectable()
export class LoanRequestService {
  _apiBaseUrl: string;
  baseURL: string;
  constructor(
    private _authService: AuthService,
    _settings: AppConfigService,
    private _httpClient: HttpClient
  ) {
    this._apiBaseUrl = _settings.APIBaseUrl;
    this.baseURL = _settings.EZCash;
  }

  verifyBVN(payload: any): Observable<LoanRequestResponse> {
    payload = this.updateRequestPayload(payload);
    return this._httpClient.post<LoanRequestResponse>(
      `${this._apiBaseUrl}/${ApiURLConstants.INSTANT_CASH_ADVANCE_LIMIT}`,
      payload
    );
  }

  BookLoan(payload: LoanRequestPayload): Observable<LoanRequestResponse> {
    payload = this.updateRequestPayload(payload);
    return this._httpClient.post<LoanRequestResponse>(
      `${this._apiBaseUrl}/${ApiURLConstants.CASH_ADVANCE_REQUEST}`,
      payload
    );
  }

  private updateRequestPayload(payload: any) {
    const loggedInUser = this._authService.getLoggedInUser();
    payload["cifId"] =
      loggedInUser && loggedInUser.CifId ? loggedInUser.CifId : "";
    payload["userId"] =
      loggedInUser && loggedInUser.UserId ? loggedInUser.UserId : "";
    return payload;
  }

  termsAndCondition(payload) {
    console.log('object service: ' + JSON.stringify(payload));
    payload = this.updateRequestPayload(payload);
    return this._httpClient.post(
      "https://appsgw.stanbicibtc.com:8443/omni-channel/consent-management/api/v1/omni/Customer/create",
      payload
    );
  };

  GetLoanRepaymentSchedules(
    payload: LoanRepaymentScheduleRequestPayload
  ): Observable<LoanRepaymentScheduleResponse> {
    return this._httpClient.post<LoanRepaymentScheduleResponse>(
      `${this._apiBaseUrl}/${ApiURLConstants.INSTANT_UPL_LOAN_REPAYMENT_SCHEDULES}`,
      payload
    );
  }

  BookUPLLoan(payload: UPLLoanRequestPayload): Observable<LoanRequestResponse> {
    return this._httpClient.post<LoanRequestResponse>(
      `${this._apiBaseUrl}/${ApiURLConstants.BOOK_UPL_LOAN}`,
      payload
    );
  }

  GetEzCashInsuranceTypes(): Observable<any> {
    return this._httpClient.get<any>(
      `${this.baseURL}/mobile-banking/api/CashAdvance/GetEzCashInsuranceTypes`
    );
  }
}
