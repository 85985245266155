import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './appconfig.service';
import { StorageService } from './storage.service';
import { TransferRequestPayload, TransferResponsePayload } from '../models/payloads/transactions';
import { OneOffPaymentTransferRequestPayload } from '../models/transfer';
import { AuthService } from './auth.service';
import { ApiURLConstants } from '../helpers/apiURLConstants';

@Injectable()
export class WalletTransferService {

    private _apiBaseUrl: string;
    constructor(private _httpClient: HttpClient, private _settings: AppConfigService, private _storageService: StorageService,
        private _authService: AuthService) {
        this._apiBaseUrl = _settings.APIBaseUrl;
    }

    doTransfer(payload: OneOffPaymentTransferRequestPayload): Promise<TransferResponsePayload> {
        payload = this.updateRequestPayload(payload);
        return this._httpClient.post<TransferResponsePayload>(`${this._apiBaseUrl}/${ApiURLConstants.WALLET_TRANSFER}`, payload
        ).toPromise();
    }

    private updateRequestPayload(payload: OneOffPaymentTransferRequestPayload): OneOffPaymentTransferRequestPayload {
        const loggedInUser = this._authService.getLoggedInUser();
        payload['sessionId'] = loggedInUser && loggedInUser['SessionId'] ? loggedInUser['SessionId'] : '';
        payload['userId'] = loggedInUser && loggedInUser.UserId ? loggedInUser.UserId : '';
        return payload;
    }

}
