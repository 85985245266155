import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppConfigService } from "./appconfig.service";
import { AuthService } from "./auth.service";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { WalletDetails, WalletDetailsResponse, WalletTransactionHistory, WalletTransactionHistoryRequest } from "../models/WalletDetails";
import { DebitEnairaWallet } from "../models/debitWallet";
import { FundWallet } from "../models/fund-wallet";

@Injectable()
export class EnairaService {
  private _apiBaseUrl: string;
  walletDetails: BehaviorSubject<WalletDetailsResponse> = new BehaviorSubject<WalletDetailsResponse>(null);
  data = this.walletDetails.asObservable();

  constructor(
    private _httpClient: HttpClient,
    _settings: AppConfigService,
    private _authService: AuthService
  ) {
    this._apiBaseUrl = _settings.ENairaAPIBaseUrl;
  }
  
  updatedDataSelection(data: WalletDetailsResponse){
    this.walletDetails.next(data);
  }
  fetchWalletDetails(payload:WalletDetails): Observable<WalletDetailsResponse> {
    return this._httpClient.post<any>(
      `${this._apiBaseUrl}/fetchWalletDetails`,
      payload,
    );
  }

  fetchWalletHistory(payload: WalletTransactionHistoryRequest): Observable<WalletTransactionHistory> {
      return this._httpClient.post<WalletTransactionHistory>(`${this._apiBaseUrl}/walletHistory`,payload)
  }

  fundEnairaWallet(payload:FundWallet): Observable<any> {
    return this._httpClient.post<any>(
      `${this._apiBaseUrl}/fundWallet `,
      payload,
    );
  }

  debitEnairaWallet(payload:DebitEnairaWallet): Observable<any> {
    return this._httpClient.post<any>(
      `${this._apiBaseUrl}/debitWallet`,
      payload,
    );
  }
 
}
