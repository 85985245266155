import { Component, Inject } from "@angular/core";
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from "@angular/material";
import { FxRequestHistoryService } from "src/app/core/services/FxRequestHistory.service";

@Component({
    selector:"cancel-fx-request",
    templateUrl: "./cancel-fx-request.component.html"
})
export class CancelFxRequestComponent {
    constructor(
        private _service: FxRequestHistoryService,
        private _snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _dialogRef: MatDialogRef<CancelFxRequestComponent>
    ){
      
    }


    cancel() {
        this._dialogRef.close();
    }
    loading = false;

    CanCelFxRequest() {
        this.loading = true;
        this._service.CancelFxRequesr(this.data)
            .then(res=>{
                this.loading = false;
                this._dialogRef.close('1');

            })
            .catch(er=> {
                this.loading = false;
                this._snackBar.open(er, "Ok", {
                    verticalPosition: "bottom",
                    horizontalPosition: "right",
                    duration: 1500,
                  });
            })
    }
}


