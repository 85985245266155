import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
} from "@angular/core";
import { MatSnackBar } from "@angular/material";
import { UtilService } from "src/app/core/services/helpers.service";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { CustomerAccount } from "src/app/core/models/account";
import { AuthService } from "src/app/core/services/auth.service";
import { CorporateBillerCollectionInput } from "src/app/core/models/payloads/corporate-billers";
import { DecimalPipe } from "@angular/common";

@Component({
  selector: "ng-corporate-biller-dynamic-form",
  templateUrl: "./corporate-biller-dynamic-form.component.html",
  styleUrls: ["./corporate-biller-dynamic-form.component.css"],
})
export class CorporateBillerDynamicFormComponent implements OnInit, OnChanges {
  dynamicFormGroup: FormGroup;

  @Input() formInputs: CorporateBillerCollectionInput[] = [];
  @Input() accountList: CustomerAccount[] = [];
  @Input() otpNotificationAddress: string = "";
  @Input() formTitle: string = "";
  @Input() isLastForm: boolean = false;  
  @Input() loaderIsActive: boolean = false;
  @Output() formSubmittedEvent = new EventEmitter<
    CorporateBillerCollectionInput[]
  >();
  @Output() showBackBtnEvent = new EventEmitter();

  visibleFormInputs: CorporateBillerCollectionInput[] = [];

  constructor(
    private _formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private _authService: AuthService,
    private decimalPipe: DecimalPipe
  ) {}

  ngOnInit() {
    // this.accountList = VariablesService.CustomerAccounts.filter(
    //   (x) => x.debitAllowed == true
    // );

    this.InitFormFromCollectionInputs();
    this.visibleFormInputs = this.formInputs.filter((x) => x.Visible);
    if (this.accountList && this.accountList.length > 0) {
      this.accountList = this.accountList.filter((x) => x.debitAllowed);
    }

    console.log(this.visibleFormInputs);
  }

  InitFormFromCollectionInputs() {
    if (this.formInputs && this.formInputs.length > 0) {
      let group = {};

      if(this.dynamicFormGroup){
        this.dynamicFormGroup.reset();
        this.dynamicFormGroup.controls ={};
      }
 
      this.formInputs.forEach((element) => {
        if (
          element.InputType.toLocaleLowerCase() == "list" &&
          element.ListItems &&
          element.ListItems.ListItem &&
          element.ListItems.ListItem.length > 0
        ) {
          element.SelectionList = element.ListItems.ListItem;
        }

        group[element.Key] = element.Required
          ? new FormControl(
              {
                value: element.Value ? element.Value.trim() : "",
                disabled: element.InputType == "label",
                
              },
              Validators.required
            )
          : new FormControl({
              value: element.Value ? element.Value.trim() : "",
              disabled: element.InputType == "label",
            });
      });

      this.dynamicFormGroup = new FormGroup(group);

      // Nullify error cos of Material
      // for (let cursor in this.dynamicFormGroup.controls) {
      //   this.dynamicFormGroup.controls[cursor].setErrors(null);
      // }
    }

    console.log(this.dynamicFormGroup)
  }

  decimalInputKeyup(formControlName: string) {
    console.log("In decimal key up");
    console.log(formControlName);
    console.log(this.dynamicFormGroup.controls);
    console.log(this.dynamicFormGroup.controls["" + formControlName]);

    // Only allow digits on amount alone.
    if (this.dynamicFormGroup.controls["" + formControlName]) {
      let valueEntered = this.dynamicFormGroup.controls["" + formControlName]
        .value;

      valueEntered = UtilService.getSanitizedAmount(valueEntered);

      this.dynamicFormGroup.controls["" + formControlName].setValue(
        valueEntered
      );
      // console.log("ValueEntered" + valueEntered);
    }
  }

  decimalInputFocus(formControlName: string) {
    // console.log("In decimal focus");

    console.log("In decimal focus");
    console.log(formControlName);
    console.log(this.dynamicFormGroup.controls);
    console.log(this.dynamicFormGroup.controls["" + formControlName]);


    if (this.dynamicFormGroup.controls["" + formControlName]) {
      let amountEntered = this.dynamicFormGroup.controls["" + formControlName]
        .value;

      if (amountEntered) {
        //remove .00 if exist
        if ((amountEntered as string).indexOf(".00") != -1) {
          amountEntered = (amountEntered as string).replace(".00", "");
        }

        amountEntered = amountEntered.replace(/,/g, "");
      }

      this.dynamicFormGroup.controls["" + formControlName].setValue(
        amountEntered
      );

      // console.log("Amount entered: " + amountEntered);
    }
  }

  decimalInputBlur(formControlName: string) {
    // console.log("In decimal blur");

    console.log("In decimal blur");
    console.log(formControlName);
    console.log(this.dynamicFormGroup.controls);
    console.log(this.dynamicFormGroup.controls["" + formControlName]);

    if (this.dynamicFormGroup.controls["" + formControlName]) {
      let amount = 0;

      if (this.dynamicFormGroup.controls["" + formControlName]) {
        amount = this.dynamicFormGroup.controls["" + formControlName]
          .value as number;
      }

      if (amount > 0) {
        this.dynamicFormGroup.controls["" + formControlName + ""].setValue(
          this.decimalPipe.transform(amount, "1.2-2")
        );
      }
      // console.log("Amount: " + amount);
    }
  }

  submitForm() {
    //console.log(this.dynamicFormGroup);
    // if (!this.dynamicFormGroup.valid) {
    //   this._snackBar.open("Please fill in all required details", "Ok", {
    //     verticalPosition: "top",
    //     horizontalPosition: "center",
    //     duration: 1500,
    //   });
    //   return;
    // }
    
    let fieldsValid = true;

    if (this.formInputs && this.formInputs.length > 0) {
      let self = this;
      this.formInputs.forEach((element) => {

        if(element.Required == true && self.dynamicFormGroup.controls["" + element.Key] && UtilService.StringIsNullOrEmpty(self.dynamicFormGroup.controls["" + element.Key].value)){
          
          if(element.LabelName.toLowerCase() == "pin"){
            this._snackBar.open("Please enter your OTP.", "Ok", {
              verticalPosition: "top",
              horizontalPosition: "center",
              duration: 1500,
            });
          }
          else{
            this._snackBar.open("Please enter your " + element.LabelName.toLowerCase() + ".", "Ok", {
              verticalPosition: "top",
              horizontalPosition: "center",
              duration: 1500,
            });
          }    
          fieldsValid = false; 
        }
        
        if(element.InputType.toLowerCase() == "decimal"){
          let amountEntered = self.dynamicFormGroup.controls["" + element.Key].value;
          if (amountEntered) {
            //remove .00 if exist
            if ((amountEntered as string).indexOf('.00') != -1) {
              amountEntered = (amountEntered as string).replace('.00', '');
            }
            
            element.Value = amountEntered.replace(/,/g, '');
          }
        }
        else{
          element.Value = self.dynamicFormGroup.controls["" + element.Key].value;
        }
       
        element.IsPinItem = element.InputType.toLowerCase() == "password" && element.LabelName.toLowerCase() == "pin";
      });
 
      if(fieldsValid == true){
        this.formSubmittedEvent.emit(this.formInputs);
      }
    }
  }

  goBack() {
    this.showBackBtnEvent.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["formInputs"]) {
      this.formInputs = changes["formInputs"].currentValue;
      this.InitFormFromCollectionInputs();

      this.visibleFormInputs = this.formInputs.filter((x) => x.Visible);
    }

    if (changes["isLastForm"]) {
      this.isLastForm = changes["isLastForm"].currentValue;
    }

    if (changes["formTitle"]) {
      this.formTitle = changes["formTitle"].currentValue;
    }

    if (changes["otpNotificationAddress"]) {
      this.otpNotificationAddress =
        changes["otpNotificationAddress"].currentValue;
    }
  }
}
