import { Injectable } from "@angular/core";
import { VARIABLES } from "../helpers/appVariables";

@Injectable()
export class AppConfigService {
  static config: any;

  constructor() {
    if (typeof AppConfigService.config == "undefined") {
      AppConfigService.config = this.GetSettings();
      VARIABLES.STANBIC_CONTACT_EMAIL =
        AppConfigService.config.stanbicContactEmail;
    }
  }

  GetSettings() {
    const appSettings = this.loadMockSettings();

    if (!appSettings) {
      throw new Error("AppConfig unable to load");
    }

    return appSettings;
  }

  private LoadAppSettings() {
    let response = "";
    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function (evt) {
      if (this.readyState === 4 && this.status === 200) {
        response = this.responseText;
      }
    };
    xhr.open("GET", "../assets/x-v-rrcache.json", false);
    xhr.send(null);

    return JSON.parse(response);
  }

  public get APIBaseUrl(): string {
    if (!AppConfigService.config) {
      throw new Error("AppConfig not loaded.");
    }

    if (AppConfigService.config.environment.IsProd == true) {
      return AppConfigService.config.APIBaseUrl.prod;
    }

    return AppConfigService.config.APIBaseUrl.dev;
  }

  public get EZCash(): string {
    if (!AppConfigService.config) {
      throw new Error("AppConfig not loaded.");
    }

    if (AppConfigService.config.environment.IsProd == true) {
      return AppConfigService.config.ezCash.prod;
    }

    return AppConfigService.config.ezCash.dev;
  }

  public get APIBaseUrl2(): string {
    if (!AppConfigService.config) {
      throw new Error("AppConfig not loaded.");
    }
    if (AppConfigService.config.environment.IsProd == true) {
      return AppConfigService.config.APIBaseUrl2.prod;
    }

    return AppConfigService.config.APIBaseUrl2.dev;
  }

  debitCardAutoRenewal: {
    // dev: 'https://devtest.stanbicibtc.com:8091/autorenewal',
    dev: "https://stanbic.nibse.com/mybank/DebitCardAutoRenewalAPI";
    /*"https://devtest.stanbicibtc.com:7443/auto-renewal-service", "https://localhost:44328"*/ /*"https://sibtc-azure-external-api.stanbicibtc-devase.p.azurewebsites.net/ib-debit-card-consent"*/
   // prod: "https://ibanking.stanbicibtcbank.com/DebitCardConsentAutorenewal";
    prod: "https://ibanking.stanbicibtcbank.com/DebitCardConsentAutorenewal";
  };

  public get DebitCardAutoRenewalURL(): string {
    if (!AppConfigService.config) {
      throw new Error("AppConfig not loaded.");
    }
    if (AppConfigService.config.environment.IsProd == true) {
      return AppConfigService.config.debitCardAutoRenewalBaseUrl.prod;
    }

    return AppConfigService.config.debitCardAutoRenewalBaseUrl.dev;
  }

  public get UseMockData(): boolean {
    if (AppConfigService.config.useMock) {
      return true;
    }

    return false;
  }

  public get pensionAPIBaseUrl(): string {
    if (!AppConfigService.config) {
      throw new Error("AppConfig not loaded.");
    }

    if (AppConfigService.config.environment.IsProd == true) {
      return AppConfigService.config.pensionAPIBaseUrl.prod;
    }
    return AppConfigService.config.pensionAPIBaseUrl.dev;
  }

  public static get IsProdEnv(): boolean {
    if (!AppConfigService.config) {
      throw new Error("AppConfig not loaded.");
    }
    return AppConfigService.config.environment.IsProd;
  }

  public static get isUATModeForRetailUserCreation(): boolean {
    if (!AppConfigService.config) {
      throw new Error("AppConfig not loaded.");
    }
    return AppConfigService.config.isUATModeForRetailUserCreation;
  }

  public static get DefaultStanbicAccountOficerEmail(): string {
    if (!AppConfigService.config) {
      throw new Error("AppConfig not loaded.");
    }
    return AppConfigService.config.stanbicContactEmail;
  }

  public static get DevModeLoaderInterval(): number {
    if (!AppConfigService.config) {
      throw new Error("AppConfig not loaded.");
    }
    return AppConfigService.config.devModeLoaderInterval;
  }

  public static get CCCNumber(): string {
    if (!AppConfigService.config) {
      throw new Error("AppConfig not loaded.");
    }

    return AppConfigService.config.cccNumber;
  }

  public static get EbillsApiURL(): string {
    if (!AppConfigService.config) {
      throw new Error("AppConfig not loaded.");
    }

    return AppConfigService.config.ebillsAPIBaseURL;
  }

  public get CreditCardBaseUrl(): string {
    if (!AppConfigService.config) {
      throw new Error("AppConfig not loaded.");
    }
    if (AppConfigService.config.environment.IsProd == true) {
      return AppConfigService.config.creditCardBaseUrl.prod;
    }

    return AppConfigService.config.creditCardBaseUrl.dev;
  }

  public get CreditCheckBaseUrl(): string {
    if (!AppConfigService.config) {
      throw new Error("AppConfig not loaded.");
    }
    if (AppConfigService.config.environment.IsProd == true) {
      return AppConfigService.config.creditCheckBaseUrl.prod;
    }

    return AppConfigService.config.creditCheckBaseUrl.dev;
  }

  public get VirtaulCardBaseUrl(): string {
    if (!AppConfigService.config) {
      throw new Error("AppConfig not loaded.");
    }
    if (AppConfigService.config.environment.IsProd == true) {
      return AppConfigService.config.virtualCardBaseUrl.prod;
    }

    return AppConfigService.config.virtualCardBaseUrl.dev;
  }

  public static get SignalRURL(): string {
    if (!AppConfigService.config) {
      throw new Error("AppConfig not loaded.");
    }
    if (AppConfigService.config.environment.IsProd == true) {
      return AppConfigService.config.creditCardBaseUrl.prod;
    }

    return AppConfigService.config.creditCardBaseUrl.dev;
  }

  // public static get SignalRURL(): string {
  //   if (!AppConfigService.config) {
  //     throw new Error("AppConfig not loaded.");
  //   }
  //   if (AppConfigService.config.environment.IsProd == true) {
  //     return AppConfigService.config.SignalRUrl.prod;
  //   }

  //   return AppConfigService.config.SignalRUrl.dev;
  // }

  public get CustodianAPIBaseUrl(): string {
    if (!AppConfigService.config) {
      throw new Error("AppConfig not loaded.");
    }

    if (AppConfigService.config.environment.IsProd == true) {
      return AppConfigService.config.CustodianServiceAPIBaseUrl.prod;
    }

    return AppConfigService.config.CustodianServiceAPIBaseUrl.dev;
  }
  public get CreditCardRequestBaseUrl(): string {
    if (!AppConfigService.config) {
      throw new Error("AppConfig not loaded.");
    }
    if (AppConfigService.config.environment.IsProd == true) {
      return AppConfigService.config.creditCardBaseUrl.prod;
    }
  }
  public get SurveyAPIBaseUrl(): string {
    if (!AppConfigService.config) {
      throw new Error("AppConfig not loaded.");
    }

    if (AppConfigService.config.environment.IsProd == true) {
      return AppConfigService.config.surveyAPIBaseUrl.prod;
    }

    return AppConfigService.config.surveyAPIBaseUrl.dev;
  }

  public get UnscheduledRepaymentBaseUrl(): string {
    if (!AppConfigService.config) {
      throw new Error("AppConfig not loaded.");
    }

    if (AppConfigService.config.environment.IsProd == true) {
      return AppConfigService.config.UnscheduledRepaymentBaseUrl.prod;
    }

    return AppConfigService.config.UnscheduledRepaymentBaseUrl.dev;
  }
  public get WakanowBaseUrl(): string {
    if (!AppConfigService.config) {
      throw new Error("AppConfig not loaded.");
    }
    if (AppConfigService.config.environment.IsProd == true) {
      return AppConfigService.config.wakanowBaseUrl.prod;
    }

    return AppConfigService.config.wakanowBaseUrl.dev;
  }

  public get DebitCardAutoRenewalUrl(): string {
    if (!AppConfigService.config) {
      throw new Error("AppConfig not loaded.");
    }
    if (AppConfigService.config.environment.IsProd == true) {
      return AppConfigService.config.debitCardBaseUrl.prod;
    }

    return AppConfigService.config.debitCardBaseUrl.dev;
  }

  public get IntellinxAPIBaseUrl(): string {
    if (!AppConfigService.config) {
      throw new Error("AppConfig not loaded.");
    }

    if (AppConfigService.config.environment.IsProd == true) {
      return AppConfigService.config.intellinxAPIBaseUrl.prod;
    }

    return AppConfigService.config.intellinxAPIBaseUrl.dev;
  }

  public get DebitCardPasswordResetBaseUrl(): string {
    if (!AppConfigService.config) {
      throw new Error("AppConfig not loaded.");
    }

    if (AppConfigService.config.environment.IsProd == true) {
      return AppConfigService.config.debitCardPasswordResetBaseUrl.prod;
    }

    return AppConfigService.config.debitCardPasswordResetBaseUrl.dev;
  }

  public get IntellinxAPIThresholdUrl(): string {
    if (!AppConfigService.config) {
      throw new Error("AppConfig not loaded.");
    }

    if (AppConfigService.config.environment.IsProd == true) {
      return AppConfigService.config.thresholdAPIBaseUrl.prod;
    }

    return AppConfigService.config.thresholdAPIBaseUrl.dev;
  }

  public get CorporateBillerAPIBaseUrl(): string {
    if (!AppConfigService.config) {
      throw new Error("AppConfig not loaded.");
    }

    if (AppConfigService.config.environment.IsProd == true) {
      return AppConfigService.config.CorporateBillerServiceAPIBaseUrl.prod;
    }

    return AppConfigService.config.CorporateBillerServiceAPIBaseUrl.dev;
  }
  public get ShippingBillersBaseUrl(): string {
    if (!AppConfigService.config) {
      throw new Error("AppConfig not loaded.");
    }

    if (AppConfigService.config.environment.IsProd == true) {
      return AppConfigService.config.shippingPaymentBillersAPIBaseUrl.prod;
    }

    return AppConfigService.config.shippingPaymentBillersAPIBaseUrl.dev;
  }

  public get FxPaymentApiBaseUrl(): string {
    if (!AppConfigService.config) {
      throw new Error("AppConfig not loaded.");
    }

    if (AppConfigService.config.environment.IsProd == true) {
      return AppConfigService.config.fxPaymentApiBaseUrl.prod;
    }

    return AppConfigService.config.fxPaymentApiBaseUrl.dev;
  }

  public get ENairaAPIBaseUrl(): string {
    if (!AppConfigService.config) {
      throw new Error("AppConfig not loaded.");
    }

    if (AppConfigService.config.environment.IsProd == true) {
      return AppConfigService.config.ENairaAPIBaseUrl.prod;
    }

    return AppConfigService.config.ENairaAPIBaseUrl.dev;
  }

  public get HardwareTokenAPIBaseUrl(): string {
    if (!AppConfigService.config) {
      throw new Error("AppConfig not loaded.");
    }

    if (AppConfigService.config.environment.IsProd == true) {
      return AppConfigService.config.HardwareTokenServiceAPIBaseUrl.prod;
    }

    return AppConfigService.config.HardwareTokenServiceAPIBaseUrl.dev;
  }

  loadMockSettings() {
    return {
      APIBaseUrl: {
        // "dev": "https://10.234.135.46:4421/api",
        // "dev": "http://10.234.135.46:7442/api",
        // "dev": "https://10.234.135.46:4443/api",
        // "dev": "https://10.234.135.46:7443/token-validation/api",
        // "dev": "http://10.234.135.46:8099/api",
        // "dev": "http://localhost:53390/api",
        // dev: "https://80.248.0.83:7443/mocked-api/api",
        //   dev: "https://80.248.0.83:7443/token-validation/api",
        dev: "https://devtest.stanbicibtc.com:7443/internet-banking/api",
        prod: "https://ibanking.stanbicibtcbank.com/api",
        // "prod": "https://10.234.174.61/api",
        //prod: "https://10.234.175.80:9985/HWTService/api",
        // "prod": "https://10.234.175.81:4433/api"
        // "prod": "https://10.234.174.89:7443/api"
        // "prod": "https://10.234.175.80:9985/api"
        // "dev": "http://10.234.135.46:7442/api",
        // "dev": "http://10.234.135.46:8047/api",

        // "dev":"http://10.234.175.80:5443/api",
        // "dev": "http://localhost:53391/api",
        // "prod": "https://devtest.stanbicibtc.com:7443/internet-banking/api",
        // prod: "https://ibanking.stanbicibtcbank.com:8443/api",
        // "prod": "https://10.234.174.61/api",
        // prod: "https://ibanking.stanbicibtcbank.com/api",
        // prod: "https://10.234.175.80:8443/api",
        // prod: "https://ibanking.stanbicibtcbank.com/api",
        // prod: "https://10.234.175.80:8443/api",
        // prod: "https://10.234.174.89:5443/api",
        //  prod:  "https://ibanking.stanbicibtcbank.com:8443/api"
      },
      APIBaseUrl2: {
        dev: "http://10.234.135.46:7442/api",
        prod: "https://10.234.175.80:8443/api",
        // prod: "https://ibanking.stanbicibtcbank.com/api",
      },
      pensionAPIBaseUrl: {
        dev: "https://devtest.stanbicibtc.com:7443/internet-banking",
        // prod: "https://ibanking.stanbicibtcbank.com/Pension-Service",
        prod: "https://ibanking.stanbicibtcbank.com/Pension-Service",
      },
      creditCardBaseUrl: {
        //"dev": "http://localhost:49901/api",
        // dev: "https://devtest.stanbicibtc.com:7443/internet-banking/CreditCardManagement",

        dev: "https://stanbic.nibse.com/mybank/CreditCardManagement",

        //"dev":"https://devtest.stanbicibtc.com:7443/internet-banking/api",
        //prod: "https://ibanking.stanbicibtcbank.com/creditcardmanagement",
        prod: "https://ibanking.stanbicibtcbank.com/CreditCardManagement",
        //"dev":"https://devtest.stanbicibtc.com:7443/api",
        // prod: "tps://ibanking.stanbicibtcbank.com/creditcardmanagement"
      },

      ezCash: {
        // dev: "https://stanbic.nibse.com/",
        dev: "https://devtest.stanbicibtc.com:7443/",
        prod: "https://mobile.stanbicibtc.com/",
      },

      // Update api once api gets available
      debitCardPasswordResetBaseUrl: {
        dev: "https://devtest.stanbicibtc.com:7443/internet-banking/mybank/mobile-banking/api",

        // Change when production api becomes available
        prod: "http://devtest.stanbicibtc.com:7443/mybank/mobile-banking/api",
      },

      creditCheckBaseUrl: {
        dev: "https://devtest.stanbicibtc.com:7443/internet-banking/CreditCheckService/api/v1",

        //prod: "https://ibanking.stanbicibtcbank.com/CreditCheckService",
        prod: "https://ibanking.stanbicibtcbank.com/CreditCheckService",
      },

      virtualCardBaseUrl: {
        //"dev": "http://localhost:49901/api",
        dev: "https://devtest.stanbicibtc.com:7443/virtualcard-service/v1",
        // dev: "https://stanbic.nibse.com/mybank/VirtualCardAPI/api/v1",

        //"dev":"https://devtest.stanbicibtc.com:7443/api",
        // prod: "https://10.234.175.186:9985/VirtualCardAPI/v1",
        prod: "https://ibanking.stanbicibtcbank.com/VirtualCardAPI/v1",
      },

      debitCardAutoRenewalBaseUrl: {
        //"dev": "http://localhost:49901/api",
        dev: "https://stanbic.nibse.com/mybank/DebitCardAutoRenewalAPI",

        //"dev":"https://devtest.stanbicibtc.com:7443/internet-banking/api",
        // prod: "https://ibanking.stanbicibtcbank.com/DebitCardConsentAutorenewal",
        prod: "https://ibanking.stanbicibtcbank.com/DebitCardConsentAutorenewal",
      },

      creditCardRequestBaseUrl: {
        //"dev": "https://localhost:44314/api",
        dev: "https://devtest.stanbicibtc.com:8091/creditcard-request/api",

        //"dev":"https://devtest.stanbicibtc.com:7443/internet-banking/api",
        prod: "https://ibanking.stanbicibtcbank.com/api",
      },

      intellinxAPIBaseUrl: {
        dev: "https://devtest.stanbicibtc.com:7443/internet-banking/intellinx-service/v1",
        prod: "https://mobile.stanbicibtc.com/MBSecretWordService/v1",
      },
      thresholdAPIBaseUrl: {
        dev: "https://devtest.stanbicibtc.com:7443/internet-banking/intellinx-threshold-service/v1/threshold",
        prod: "https://mobile.stanbicibtc.com/ThresholdService/v1/threshold",
      },

      environment: {
        IsProd: true,
      },

      wakanowBaseUrl: {
        //"dev": "https://devtest.stanbicibtc.com:7443/wakanow-service/api/Wakanow",
        dev: "https://devtest.stanbicibtc.com:7443/WakaNowAPI",

        // dev: "https://sibtc-azure-external-api.stanbicibtc-devase.p.azurewebsites.net/wakanow/api",
        //prod: "https://ibanking.stanbicibtcbank.com/WakanowAPI",
        prod: "https://ibanking.stanbicibtcbank.com/WakanowAPI",
      },
      SignalRUrl: {
        dev: "https://localhost:5001/sessionhub",
        // prod: "https://ibanking.stanbicibtcbank.com/SessionManager/sessionhub",
        prod: "https://ibanking.stanbicibtcbank.com/SessionManager/sessionhub",
      },
      surveyAPIBaseUrl: {
        dev: "https://devtest.stanbicibtc.com:7443/InstantSurveyAPI/api",
        prod: "https://ibanking.stanbicibtcbank.com/InstantSurvey",
        //prod: "https://ibanking.stanbicibtcbank.com/InstantSurvey",
      },

      UnscheduledRepaymentBaseUrl: {
        dev: "https://sibtc-azure-external-api.stanbicibtc-devase.p.azurewebsites.net/InstantSurvey/api",
        // prod: "https://ibanking.stanbicibtcbank.com/InstantSurvey/api",
        prod: "https://ibanking.stanbicibtcbank.com/InstantSurvey/api",
      },
      // SignalRURL: "https://ibanking.stanbicibtcbank.com/SessionManager/sessionhub",
      SignalRURL: "https://10.234.135.46:6099/SessionManager/sessionhub",
      // SignalRURL: "https://localhost:5001/sessionhub",

      fxPaymentApiBaseUrl: {
        dev: "https://devtest.stanbicibtc.com:7443/internet-banking/FxPurchase/api/FxPurchase",
        // prod: "https://ibanking.stanbicibtcbank.com/FXPurchase/FxPurchase",
        prod: "https://ibanking.stanbicibtcbank.com/FXPurchase/FxPurchase",
      },
      shippingPaymentBillersAPIBaseUrl: {
        dev: "https://mobile.stanbicibtc.com/BillPayments/api/MB/GetAlternateCorporateBillers",
        // dev:
        //   "https://stanbic.nibse.com/mybank/MBBillPayment/api/MB/GetAlternateCorporateBillers",
        prod: "https://mobile.stanbicibtc.com/BillPayments/api/MB/GetAlternateCorporateBillers",
      },
      useMock: false,
      isUATModeForRetailUserCreation: false,
      devModeLoaderInterval: 500,
      stanbicContactEmail: "customercarenigeria@stanbicibtcbank.com",
      ebillsAPIBaseURL: "http://10.234.200.155:53390/api",
      // cccNumber: "+234 1 422 2222",
      cccNumber: "02014222222",
      HardwareTokenServiceAPIBaseUrl: {
        // dev:" https://10.234.135.46:7443/token-status/api",

        dev: "https://sibtc-azure-external-api.stanbicibtc-devase.p.azurewebsites.net/HardwareToken",
        // "https://devtest.stanbicibtc.com:7443/internet-banking/token-status/api",
        // prod: "https://ibanking.stanbicibtcbank.com/HardwaretokenAPI",
        //prod: "https://ibanking.stanbicibtcbank.com/HardwareTokenAPI",
        prod: "https://ibanking.stanbicibtcbank.com/HardwareTokenAPI",
        //prod: "https://10.234.175.80:6443/HardwaretokenAPI"
        // prod: "https://10.234.175.80:9985/HardwaretokenAPI"
        // prod: "https://10.234.175.80:6443/HardwaretokenAPI"
      },
      CustodianServiceAPIBaseUrl: {
        dev: "https://devtest.stanbicibtc.com:7443/internet-banking/custodian-service/api",
        //prod: "https://ibanking.stanbicibtcbank.com/Custodian-Service",
        prod: "https://ibanking.stanbicibtcbank.com/Custodian-Service",
      },
      CorporateBillerServiceAPIBaseUrl: {
        dev: "https://devtest.stanbicibtc.com:7443/corporatebillers-service/api",
        // dev: "https://mobile.stanbicibtc.com/CorporateBillers/api",
        prod: "https://mobile.stanbicibtc.com/CorporateBillers/api",
      },
      ENairaAPIBaseUrl: {
        dev: "https://stanbic.nibse.com/mybank/enaira/api",
        prod: "https://mobile.stanbicibtc.com/eNaira/api",
      },
    };
  }
}
