import { Component, OnInit } from "@angular/core";
import { EventHandlerService } from "src/app/core/services/EventHandlerService";

@Component({
    selector:"fx-transaction-request",
    templateUrl: "./fx-transaction-request.html",
    styleUrls:['./fx-transactions.component.css']
})
export class FxTransactionRequest implements OnInit {
    activeTransferForm
    loaderIsActive
    ngOnInit(): void {
        this.loaderIsActive = false;
        EventHandlerService.emitOnAppViewChangedEvent();
        this.activeTransferForm ='new-request'

        
    try {
        window['casa']('transaction'); 
} catch (error) {}
    }
   


    onTabSelected(activeTransferForm: string) {
        this.activeTransferForm = activeTransferForm
    }

    onNavigationOptionChange(event) {
        console.log(event)
    }

}