import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { AppConfigService } from "./appconfig.service";
import { GenericApiResponse } from "../models/payloads/generic-response";
import {
  GetCorporateBillerResponse,
  GetBillerFlowItemsResponse,
  GetNextBillerFlowItemsRequest,
} from "../models/payloads/corporate-billers";

@Injectable()
export class CorporateBillersService {
  private _apiBaseUrl: string;
  private _alternateApiBaseUrl: string;

  constructor(private _httpClient: HttpClient, _settings: AppConfigService) {
    this._apiBaseUrl = _settings.CorporateBillerAPIBaseUrl;
   }

  loadBillers(): Observable<GetCorporateBillerResponse> {
    return this._httpClient.get<GetCorporateBillerResponse>(
      `${this._apiBaseUrl}/biller-collection/billers/IB`
    );

    // return of({
    //   ResponseCode: "00",
    //   ResponseDescription: "Request processed successfully.",
    //   Data: [
    //     {
    //       Id: 21,
    //       Name: "Government",
    //       Description: "Remita Payment",
    //       Image: "Image",
    //       CollectionList: {
    //         Collections: [
    //           {
    //             CollectionId: 1,
    //             CollectionName: "Road Worthiness",
    //             CollectionDescription: "",
    //             CollectionOwner: "",
    //             CollectionFlow: [],
    //             Image: "",
    //           },
    //           {
    //             CollectionId: 2,
    //             CollectionName: "Vehicle License",
    //             CollectionDescription: "",
    //             CollectionOwner: "",
    //             CollectionFlow: [],
    //             Image: "",
    //           },
    //           {
    //             CollectionId: 3,
    //             CollectionName: "Insurance",
    //             CollectionDescription: "",
    //             CollectionOwner: "",
    //             CollectionFlow: [],
    //             Image: "",
    //           },
    //         ],
    //       },
    //     },
    //     {
    //       Id: 22,
    //       Name: "DSTV",
    //       Description: "DSTV Payment",
    //       Image: "Image",
    //       CollectionList: {
    //         Collections: [
    //           {
    //             CollectionId: 3,
    //             CollectionName: "Compact Bouquet",
    //             CollectionDescription: "",
    //             CollectionOwner: "",
    //             CollectionFlow: [],
    //             Image: "",
    //           },
    //           {
    //             CollectionId: 4,
    //             CollectionName: "Premium Bouquet",
    //             CollectionDescription: "",
    //             CollectionOwner: "",
    //             CollectionFlow: [],
    //             Image: "",
    //           },
    //           {
    //             CollectionId: 5,
    //             CollectionName: "Access Bouquet",
    //             CollectionDescription: "",
    //             CollectionOwner: "",
    //             CollectionFlow: [],
    //             Image: "",
    //           },
    //         ],
    //       },
    //     },
    //   ],
    // });
  }

  loadNextFlowItems(
    flowRequest: GetNextBillerFlowItemsRequest
  ): Observable<GetBillerFlowItemsResponse> {
    return this._httpClient.post<GetBillerFlowItemsResponse>(
      `${this._apiBaseUrl}/biller-collection/billers/flow`,
      flowRequest
    );

    return of({
      ResponseCode: "00",
      ResponseDescription: "Request processed successfully.",
      Data: {
        FlowId: 1,
        IsFlowIdDerived: true,
        Description: "Enter payment details",
        OTPReference: "",
        OTPNotificationAddress: "",
        CollectionInputSpecs: {
          CollectionInputSpec: [
            {
              Key: "80",
              LabelName: "Which state were you born?",
              InputType: "list",
              Required: true,
              Visible: true,
              Placeholder: "Which state were you born?",
              Value: "",
              IsPinItem: false,
              ListItems: {
                ListItem: [
                  {
                    Description: "Lagos",
                    Code: "1",
                  },
                  {
                    Description: "Ibadan",
                    Code: "2",
                  },
                  {
                    Description: "Port harcourt",
                    Code: "3",
                  },
                  {
                    Description: "Oyo",
                    Code: "4",
                  },
                ],
              },
            },
            {
              Key: "70",
              LabelName: "Do you have a web GUID?",
              InputType: "list",
              Required: true,
              Visible: true,
              Placeholder: "Do you have a web GUID?",
              Value: "",
              IsPinItem: false,
              ListItems: {
                ListItem: [
                  {
                    Description: "Yes",
                    Code: "1",
                  },
                  {
                    Description: "No",
                    Code: "2",
                  },
                ],
              },
            },
            {
              Key: "71",
              LabelName: "Amount",
              InputType: "decimal",
              Required: true,
              Visible: true,
              Placeholder: "Enter Amount",
              Value: "",
              IsPinItem: false,
            },
            {
              Key: "75",
              LabelName: "Payment Code",
              InputType: "password",
              Required: true,
              Visible: true,
              Placeholder: "Enter Payment Code",
              Value: "",
              IsPinItem: false,
            },
            {
              Key: "76",
              LabelName: "Smart card number",
              InputType: "label",
              Required: true,
              Visible: true,
              Placeholder: "Enter Payment Code",
              Value: "10564738838",
              IsPinItem: false,
            },
            {
              Key: "77",
              LabelName: "Smart card phone no",
              InputType: "label",
              Required: true,
              Visible: true,
              Placeholder: "Enter Payment pho",
              Value: "08098465262",
              IsPinItem: false,
            },
            {
              Key: "78",
              LabelName: "Debit Account",
              InputType: "account",
              Required: true,
              Visible: true,
              Placeholder: "Select Debit account",
              Value: "",
              IsPinItem: false,
            },
          ],
        },
      },
    });

    // switch (flowId) {
    //   case 0:
    //     return of({
    //       ResponseCode: "00",
    //       ResponseDescription: "Request processed successfully.",
    //       Data: {
    //         FlowId: 1,
    //         IsFlowIdDerived: true,
    //         Description: "Enter payment details",
    //         OTPReference: "",
    //         OTPNotificationAddress: "",
    //         CollectionInputSpecs: {
    //           CollectionInputSpec: [
    //             {
    //               Key: "70",
    //               LabelName: "Branch ID",
    //               InputType: "text",
    //               Required: true,
    //               Visible: true,
    //               Placeholder: "Enter your branch Id",
    //               Value: "",
    //               IsPinItem: false,
    //             },
    //             {
    //               Key: "71",
    //               LabelName: "Amount",
    //               InputType: "decimal",
    //               Required: true,
    //               Visible: true,
    //               Placeholder: "Enter Amount",
    //               Value: "",
    //               IsPinItem: false,
    //             },
    //             {
    //               Key: "75",
    //               LabelName: "Payment Code",
    //               InputType: "password",
    //               Required: true,
    //               Visible: true,
    //               Placeholder: "Enter Payment Code",
    //               Value: "",
    //               IsPinItem: false,
    //             },
    //             {
    //               Key: "76",
    //               LabelName: "Smart card number",
    //               InputType: "label",
    //               Required: true,
    //               Visible: true,
    //               Placeholder: "Enter Payment Code",
    //               Value: "10564738838",
    //               IsPinItem: false,
    //             },
    //             {
    //               Key: "77",
    //               LabelName: "Smart card phone no",
    //               InputType: "label",
    //               Required: true,
    //               Visible: true,
    //               Placeholder: "Enter Payment pho",
    //               Value: "08098465262",
    //               IsPinItem: false,
    //             },
    //             {
    //               Key: "78",
    //               LabelName: "Debit Account",
    //               InputType: "account",
    //               Required: true,
    //               Visible: true,
    //               Placeholder: "Select Debit account",
    //               Value: "",
    //               IsPinItem: false,
    //             },
    //           ],
    //         },
    //       },
    //     });
    //   case 1:
    //     return of({
    //       ResponseCode: "00",
    //       ResponseDescription: "Request processed successfully.",
    //       Data: {
    //         FlowId: 2,
    //         IsFlowIdDerived: true,
    //         Description: "Enter beneficiary details",
    //         OTPReference: "",
    //         OTPNotificationAddress: "",
    //         CollectionInputSpecs: {
    //           CollectionInputSpec: [
    //             {
    //               Key: "72",
    //               LabelName: "Beneficiary Code",
    //               InputType: "text",
    //               Required: true,
    //               Visible: true,
    //               Placeholder: "Beneficiary",
    //               Value: "",
    //               IsPinItem: false,
    //             },
    //             {
    //               Key: "73",
    //               LabelName: "Beneficiary Narration",
    //               InputType: "text",
    //               Required: false,
    //               Visible: true,
    //               Placeholder: "Beneficiary",
    //               Value: "",
    //               IsPinItem: false,
    //             },
    //             {
    //               Key: "80",
    //               LabelName: "Beneficiary Address",
    //               InputType: "text",
    //               Required: true,
    //               Visible: true,
    //               Placeholder: "Enter beneficiary address",
    //               Value: "",
    //               IsPinItem: false,
    //             },
    //           ],
    //         },
    //       },
    //     });
    //   case 2:
    //     return of({
    //       ResponseCode: "00",
    //       ResponseDescription: "Request processed successfully.",
    //       Data: {
    //         FlowId: 3,
    //         IsFlowIdDerived: true,
    //         Description: "Confirm Details",
    //         OTPReference: "2737373yw728==",
    //         OTPNotificationAddress: "********1927",
    //         CollectionInputSpecs: {
    //           CollectionInputSpec: [
    //             {
    //               Key: "70",
    //               LabelName: "Branch ID",
    //               InputType: "label",
    //               Required: true,
    //               Visible: true,
    //               Placeholder: "Enter your branch Id",
    //               Value: "Marina Branch",
    //               IsPinItem: false,
    //             },
    //             {
    //               Key: "71",
    //               LabelName: "Amount",
    //               InputType: "label",
    //               Required: true,
    //               Visible: true,
    //               Placeholder: "Enter Amount",
    //               Value: "234500",
    //               IsPinItem: false,
    //             },
    //             {
    //               Key: "72",
    //               LabelName: "Beneficiary Code",
    //               InputType: "label",
    //               Required: true,
    //               Visible: true,
    //               Placeholder: "Beneficiary",
    //               Value: "BN282829",
    //               IsPinItem: false,
    //             },
    //             {
    //               Key: "73",
    //               LabelName: "Beneficiary Narration",
    //               InputType: "label",
    //               Required: false,
    //               Visible: true,
    //               Placeholder: "Beneficiary",
    //               Value: "Use this for lunch",
    //               IsPinItem: false,
    //             },
    //             {
    //               Key: "74",
    //               LabelName: "PIN",
    //               InputType: "password",
    //               Required: true,
    //               Visible: true,
    //               Placeholder: "OTP",
    //               Value: "",
    //               IsPinItem: false,
    //             },
    //             {
    //               Key: "75",
    //               LabelName: "Payment Code",
    //               InputType: "label",
    //               Required: true,
    //               Visible: false,
    //               Placeholder: "Enter Payment Code",
    //               Value: "272727",
    //               IsPinItem: false,
    //             },
    //             {
    //               Key: "76",
    //               LabelName: "Smart card number",
    //               InputType: "label",
    //               Required: true,
    //               Visible: true,
    //               Placeholder: "Enter Payment Code",
    //               Value: "10564738838",
    //               IsPinItem: false,
    //             },
    //             {
    //               Key: "77",
    //               LabelName: "Smart card phone no",
    //               InputType: "label",
    //               Required: true,
    //               Visible: true,
    //               Placeholder: "Enter Payment pho",
    //               Value: "08098465262",
    //               IsPinItem: false,
    //             },
    //             ,
    //             {
    //               Key: "78",
    //               LabelName: "Debit Account",
    //               InputType: "label",
    //               Required: true,
    //               Visible: true,
    //               Placeholder: "Select Debit account",
    //               Value: "0021269876",
    //               IsPinItem: false,
    //             },
    //             {
    //               Key: "80",
    //               LabelName: "Beneficiary Address",
    //               InputType: "label",
    //               Required: true,
    //               Visible: true,
    //               Placeholder: "Enter beneficiary address",
    //               Value: "Ogudu lagos",
    //               IsPinItem: false,
    //             },
    //           ],
    //         },
    //       },
    //     });

    //   case 3:
    //     return of({
    //       ResponseCode: "00",
    //       ResponseDescription: "Request processed successfully.",
    //       Data: {
    //         FlowId: 4,
    //         IsFlowIdDerived: true,
    //         Description: "Confirm Details",
    //         OTPReference: "2737373yw728==",
    //         OTPNotificationAddress: "********2727",
    //         CollectionInputSpecs: {
    //           CollectionInputSpec: [],
    //         },
    //       },
    //     });
    // }
  }

  // loadPolicyDetails(
  //   request: GetCustodianPolicyDetailsRequest
  // ): Observable<GetCustodianPolicyDetailsResponse> {
  //   // return of({
  //   //   ResponseCode: "00",
  //   //   ResponseDescription: "Request processed successfully.",
  //   //   Data: {
  //   //     ExpiryDate: "05-Mar-2019",
  //   //     Email: "ajadi@mediareachomd.com",
  //   //     EngineCapacity: "TBA",
  //   //     PolicyNumber: "YB/V/29/T0000024C",
  //   //     InsuredName: "Omolara Ogunyemi",
  //   //     SubsidiaryType: "General",
  //   //     Make: "Honda",
  //   //     Premium: 3333333.0,
  //   //     ChasisNumber: "1HGCP2F46BA003949",
  //   //     BusinessUnit: "PRIVATE CAR INSURANCE",
  //   //     Value: 1000000.0,
  //   //     PhoneNumber: "08902736542",
  //   //     RegistrationNumber: "LSR792BU",
  //   //   },
  //   // });
  //   return this._httpClient.post<GetCustodianPolicyDetailsResponse>(
  //     `${this._apiBaseUrl}/custodian-manager/policy-details`,
  //     request
  //   );
  // }

  // renewPolicy(
  //   request: RenewCustodianPolicyRequest
  // ): Observable<GenericApiResponse> {
  //   // return of({
  //   //   ResponseCode: "00",
  //   //   ResponseDescription: "Request processed successfully.",
  //   // });

  //   return this._httpClient.post<GenericApiResponse>(
  //     `${this._apiBaseUrl}/custodian-manager/renew-policy`,
  //     request
  //   );
  // }
  loadAlternateCorporateBillers(): Observable<any> {
    return this._httpClient.get<any>(
      `${this._alternateApiBaseUrl}`
    );
  }
}
