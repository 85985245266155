import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-virtual-card',
  templateUrl: './virtual-card.component.html',
  styleUrls: ['./virtual-card.component.css']
})
export class VirtualCardComponent implements OnInit {
  loaderIsActive: boolean;

  constructor(
    private router: Router
  ) {
    this.loaderIsActive = false;
  }

  ngOnInit() {
    this.router.navigate(['/virtual-card/card-management']).then();
  }

}
