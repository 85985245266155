import { Component, OnInit, TemplateRef, ViewChild, Input, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { StagedRequestService } from 'src/app/core/services/request.staged.service';
import { StagedTransfer } from 'src/app/core/models/transfer';
import { MatDialogRef, MatDialog, MatStepper, MatSnackBar, PageEvent } from '@angular/material';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { CustomerAccount } from 'src/app/core/models/account';
import { AccountService } from 'src/app/core/services/account.service';
import { ResponseCodes, OtpReasonCodes, OTPType } from 'src/app/core/models/Enumerations';
import { Role } from 'src/app/core/models/user/AppUser';
import { NotificationsService } from 'angular2-notifications';
import { Approval } from 'src/app/core/models/approvals/payment.approval';
import { TransferStagingService } from 'src/app/core/services/staging/transfer.staging.service';
import { StagedPaymentRequest, ApprovalStage, StagedPaymentRequestResponse } from 'src/app/core/models/payloads/transactions';
import { MiscService } from 'src/app/core/services/misc.service';
import { TransferApprovalService } from 'src/app/core/services/workflow/transfer.approval.service';
import { SinglePaymentApprovalRequestPayload, BulkPaymentBatchApprovalResponse } from 'src/app/core/models/approvals/file-upload.approval';
import { ApprovalHistoryComponent } from 'src/app/shared/approval-history/approval-history.component';
import { RejectRequestComponent } from 'src/app/shared/dialogs/reject-request/reject-request.component';
import { RequestApprovalHistoryPayload } from 'src/app/core/models/misc-requests-model';
import { AuthService } from 'src/app/core/services/auth.service';
import { AppUser } from 'src/app/core/models/auth';
import { UtilService } from 'src/app/core/services/helpers.service';
import { VariablesService } from '../../../core/services/variables.service';
import { _banksList } from 'src/app/core/models/_banks_list_backup';

@Component({
  selector: 'app-payment-approvals',
  templateUrl: './payment-approvals.component.html',
  styleUrls: ['./payment-approvals.component.css']
})
export class PaymentApprovalsComponent implements OnInit, OnChanges, OnDestroy {

  ownLoaderIsActive: boolean;
  ownloaderMsg: string;
  @Input() _activeTab: string;

  _activeRequestCount = 0;
  accountList: CustomerAccount[];
  requestList: StagedPaymentRequest[] = [];
  requestListBackup: StagedPaymentRequest[] = [];
  pageSize = 5;
  _selectedRequestCount = 0;
  _approvalModalData = {
    title: '',
    message: '',
    currentRequestAction: '',
    currentApprovalAction: '',
    data: null,
    requestMeta: { batchId: '', approverStageId: '', approverId: '', initiator: '' }
  };
  oneTimePassCode: string;
  _otpReferenceId: string;
  otpInstruction: string;
  searchFilter = this.initSearchFilter();
  filterIsActive: boolean;
  userRoleEnum = Role;
  allCheckboxesSelected: boolean;

  _requestApprovalHistory: Array<Approval> = [];
  _availableApprovalStages: Array<ApprovalStage> = [];

  _paymentApprovalCompleted: boolean;
  public _loggedInUser: AppUser;
  public _loggedInUserIsApprover: boolean;
  public _loggedInUserIsInitiator: boolean;
  pageTitle: string;
  @ViewChild('deleteConfirmationTemplate') _approvalModalTemplate: TemplateRef<any>;
  public approvalRequestModal: MatDialogRef<any>;

  @ViewChild('approvalRequestDetailModalTemplate') _requestDetailModalTemplate: TemplateRef<any>;
  public approvalRequestDetailModal: MatDialogRef<any>;

  @ViewChild('approvalRequestHistoryModalTemplate') _approvalHistoryModalTemplate: TemplateRef<any>;
  public approvalHistoryModal: MatDialogRef<any>;
  public requestRejectionModal: MatDialogRef<any>;
  isCustomerHWTEnabled: any;
  isOTPStepperVisible: boolean;
  hwtFormGroup:FormGroup;
  accountNoSet: Set<string>;

  constructor(private _stageRequestService: StagedRequestService, private _stagedTransferService: TransferStagingService,
    private _dialog: MatDialog, private _accountService: AccountService, private _snackBar: MatSnackBar, private _authService: AuthService,
    private _toast: NotificationsService, private _miscService: MiscService, private _approvalService: TransferApprovalService, private formBuilder:FormBuilder) {
      // try {
      //   window['casa']('other'); 
      //  } catch (error) {}
    this._loggedInUser = _authService.getLoggedInUser();
    this._loggedInUserIsApprover = this._authService.loggedInUserHasSMEProfile() && this._loggedInUser.Role.roles.includes(Role.Approver);
    this._loggedInUserIsInitiator = this._authService.loggedInUserHasSMEProfile() && this._loggedInUser.Role.roles.includes(Role.Initiator);

    UtilService.onPageLoadCallback();
    
    this.hwtFormGroup = formBuilder.group({
      hwt: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.accountNoSet = new Set<string>();
    VariablesService.CustomerAccounts.forEach(element => {
    this.accountNoSet.add(element.accountNumber)});
    this.initComponent();
      this.isCustomerHWTEnabled = VariablesService.CustomerHWTEnabled;
    //this.isCustomerHWTEnabled = true;
    if (this.isCustomerHWTEnabled) {
      this.isOTPStepperVisible = false;
    } else {
      this.isOTPStepperVisible = true;
    }
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['_activeTab']) {
      this._activeTab = changes['_activeTab'].currentValue;
      if (this._activeTab !== 'payments') {
        this.dismissOpenModalWindows();
      }
      if (this._activeTab === 'payments') {
        this.loadPendingPaymentRequests();
      }
    }
  }

  ngOnDestroy(): void {
    this.dismissOpenModalWindows();
  }


  loadPendingPaymentRequests(loaderMsg = 'fetching pending payment requests. please wait', lastFetchId = 0, pageSize = 100) {
   

    this.ownLoaderIsActive = true;
    this.ownloaderMsg = loaderMsg;
    const payload = { PageSize: pageSize, LastFetchId: lastFetchId };
    if (this._loggedInUserIsInitiator) {
      payload['OperationType'] = 'SINGLE_TRANSFER';
    }
    const _request = this._loggedInUserIsApprover ? this._stagedTransferService.getStagedPaymentRequest(payload) :
      this._miscService.getPendingRequest<StagedPaymentRequestResponse>(payload);
    _request.then(
      (response) => {
        this.ownLoaderIsActive = this._activeRequestCount > 0;
        this.ownloaderMsg = '';
        if (!response || response.ResponseCode !== ResponseCodes.SUCCESS || !response.PaginatedItems) {
          this._snackBar.open(`${response.ResponseFriendlyMessage}`, 'Ok', { duration: 25000 });
          return;
        }
        response.PaginatedItems.forEach(request => {
          request['color'] = this.getRequestColor();
          request['checked'] = false;
        });
        this.requestListBackup = response.PaginatedItems;
        this.requestListBackup = this.requestListBackup.filter(item => this.accountNoSet.has(item.SingleTransfer.sourceAccountNo));
        this.requestList = this.requestListBackup.slice(0, 5);
      },
      (error) => {
        console.log(error);
        this._snackBar.open(`We encountered an error while loading pending payment request`, 'Ok', { duration: 25000 });
      }
    );
  }

  onSearchFilterStateChanged() {
    this.filterIsActive = !this.filterIsActive;
    if (this.filterIsActive) {
      this.loadAccounts();
    }
  }
  onApprovalRequestAction(action: string, data: StagedPaymentRequest) {
    this._approvalModalData.data = data.SingleTransfer;
    this._approvalModalData.requestMeta.batchId = data.BatchId.toString();
    this._approvalModalData.requestMeta.approverStageId = data.ApprovalStageId.toString();
    this._approvalModalData.requestMeta.approverId = data.ApproverId.toString();
    this._approvalModalData.requestMeta.initiator = data.Initiator;
    if (!this._loggedInUserIsApprover && action !== ('view-history')) {
      return;
    }
    switch (action) {
      case 'approve': {
        this._approvalModalData.currentRequestAction = 'approve';
        this._approvalModalData.message = '';
        this._approvalModalData.currentApprovalAction = '';
        this.approvalRequestModal = this._dialog.open(this._approvalModalTemplate);
        break;
      }
      case 'reject': {
        console.log("reject")
        this.requestRejectionModal = this._dialog.open(RejectRequestComponent,
          {
            data: {
              title: 'Reject Payment Approval Request',
              payload: {
                OperationType: 'SINGLE_TRANSFER',
                OperationId: data.SingleTransfer.Id
              }
            },
            hasBackdrop: false,
            closeOnNavigation: true,
            disableClose: true
          });
        this.requestRejectionModal.afterClosed()
          .subscribe((result) => this.loadPendingPaymentRequests('refreshing .. please wait'));

        break;
      }
      case 'view-detail': {
        this._approvalModalData.currentRequestAction = 'view-detail';
        this._approvalModalData.title = '';
        this.approvalRequestDetailModal = this._dialog.open(this._requestDetailModalTemplate, {
          hasBackdrop: true,
          disableClose: true,
          minWidth: 480,
          panelClass: ['custom-add-confirmationModal'],
          closeOnNavigation: true
        });
        break;
      }
      case 'view-history': {
        const requestPayload: RequestApprovalHistoryPayload = {
          OperationType: data.SingleTransfer['OperationType'],
          OperationId: data.SingleTransfer.Id,
          UserId: '',
          PageSize: 100,
          LastIdFetched: 0
        };
        this.approvalHistoryModal = this._dialog.open<ApprovalHistoryComponent>(
          ApprovalHistoryComponent,
          {
            hasBackdrop: false,
            data: {
              operationName: 'single-transfer',
              requestPayload: requestPayload,
              title: 'Request Approval History',
              extra: {}
            },
            closeOnNavigation: true
          });
        this.approvalHistoryModal.afterClosed().subscribe(
          (result) => {
            //
          }
        );
        break;
      }
      case 'approve-multiple': {
        this._approvalModalData.currentRequestAction = 'approve-multiple';
        const _selectedRequests = this.requestListBackup.filter(request => request['checked']);
        this._approvalModalData.data['totalAmount'] = _selectedRequests.map(request => request.SingleTransfer.amount)
          .reduce((prev, current) => current + prev);
        this._approvalModalData.title = `Approve ${this._selectedRequestCount} Payment ` +
          (this._selectedRequestCount > 1 ? 'Requests' : 'Request');
        this.approvalRequestModal = this._dialog.open((this._approvalModalTemplate));
        break;
      }
      case 'reject-multiple': {
        this._approvalModalData.currentRequestAction = 'reject-multiple';
        this._approvalModalData.title = `Reject ${this._selectedRequestCount} Payment ` +
          (this._selectedRequestCount > 1 ? 'Requests' : 'Request');
        this.approvalRequestModal = this._dialog.open((this._approvalModalTemplate));
        break;
      }
    }
  }

  onRequestModalAction(action: string, stepper: MatStepper, form?: NgForm) {

    switch (action) {
      case 'approve-multiple':
      case 'approve': {
        this.initiatePaymentRequestApprovalOTP(stepper);
        break;
      }
      case 'reject': {
        if (form && !form.valid) {
          this._snackBar.open('Please provode a reason for rejecting request .. ', 'Ok',
            { duration: 10000 });
          return;
        }
        this.sendPaymentApprovalRecallRequest(stepper, {} as StagedTransfer);
        break;
      }
    }
  }

  initiatePaymentRequestApprovalOTP(stepper: MatStepper, isResendOTP = false) {
    this.ownLoaderIsActive = true;
    this.ownloaderMsg = 'initiating .. please wait';
    this._miscService.initiateOtpRequest(OtpReasonCodes.GENERIC, 'APPROVE TRANSFER').then(
      (response) => {
        console.log(response);
        this.ownloaderMsg = '';
        this.ownLoaderIsActive = false;
        if (!response || response.ResponseCode !== ResponseCodes.SUCCESS) {
          this._snackBar.open(`Failed to initiate approval process. Server responded with -> ${response.ResponseFriendlyMessage}`,
            'Try again', { duration: 25000 }).onAction().subscribe(() => this.initiatePaymentRequestApprovalOTP(stepper));
          return;
        }
        this._otpReferenceId = response.ResponseDescription;
        this._approvalModalData.currentApprovalAction = 'verify-otp';
        this.otpInstruction = `Please enter the One Time Pin sent to ${response.NotificationAddress} to approve request`;
        if (!isResendOTP) {
          stepper.next();
        }
      },
      (error) => {
        console.log(error);
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        this._snackBar.open(`We encountered an error while processing your request. please retry`, 'Ok', { duration: 25000 });
      }
    );
  }
  onVerifyApprovalConfirmationOtp(form: NgForm, stepper: MatStepper , useHardwareToken:boolean) {
    
    if (useHardwareToken) {
      if (!this.hwtFormGroup.valid || !this.hwtFormGroup.controls.hwt) {
        this._snackBar.open(`Please provide a valid token number`, 'Ok', { duration: 25000 });
        return;
      }
    }
    if (!form.valid) {
      this._snackBar.open(`Invalid One Time Pin provided. Please provide a valid OTP to continue`, 'Ok',
        { duration: 10000 });
      return;
    }
    this.sendPaymentApprovalRequest(this._approvalModalData.data as StagedPaymentRequest, stepper);
  }
  
  returnToOTPStepper() {
    this.ownLoaderIsActive = true;
    this.ownloaderMsg = 'initiating .. please wait';
    this._miscService.initiateOtpRequest(OtpReasonCodes.GENERIC, 'APPROVE TRANSFER').then(
      (response) => {
        console.log(response);
        this.ownloaderMsg = '';
        this.ownLoaderIsActive = false;
        if (!response || response.ResponseCode !== ResponseCodes.SUCCESS) {
          this._snackBar.open(`Failed to initiate approval process. Server responded with -> ${response.ResponseFriendlyMessage}`,
            'Try again', { duration: 25000 }).onAction().subscribe(() => this.returnToOTPStepper());
          return;
        }
        var hardwareTokenStepper = document.getElementById('hardwareTokenStepper');
        hardwareTokenStepper.style.display = 'none';
        this.isOTPStepperVisible = true;
        this._otpReferenceId = response.ResponseDescription;
        this._approvalModalData.currentApprovalAction = 'verify-otp';
        this.otpInstruction = `Please enter the One Time Pin sent to ${response.NotificationAddress} to approve request`;
        
      },
      (error) => {
        console.log(error);
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        this._snackBar.open(`We encountered an error while processing your request. please retry`, 'Ok', { duration: 25000 });
      }
    );
  }
  
  sendPaymentApprovalRequest(request: StagedPaymentRequest, stepper: MatStepper) {
    this.ownLoaderIsActive = true;
    this.ownloaderMsg = `Approving payment .. please wait`;
    const approvalRequestPayload = this.buildSinglePaymentApprovalRequestPayload();
    if(!this.isOTPStepperVisible){
      approvalRequestPayload["otp"]=  this.hwtFormGroup.controls.hwt.value;
      approvalRequestPayload["OTPType"] = OTPType.HWT;
    }
    else{
      approvalRequestPayload["OTPType"] = !this.isOTPStepperVisible ? OTPType.HWT : this.isOTPStepperVisible ? OTPType.OTP : OTPType.NONE;
      approvalRequestPayload["otp"]=  !this.isOTPStepperVisible ? this.hwtFormGroup.controls.hwt.value : this.oneTimePassCode;
    }
    this._approvalService.approvePendingPaymentRequest(approvalRequestPayload).then(
      (response) => {
        console.log(response);
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        if (!response || response.ResponseCode !== ResponseCodes.SUCCESS) {
          this.handleNonSuccessApprovalResponse(request, response, stepper);
          return;
        }
        this._approvalModalData.currentRequestAction = 'requestComplete';
        setTimeout(() => {
          stepper.next();
        }, 50);
        this.loadPendingPaymentRequests('refreshing pending batch list');
        this._snackBar.open(`${response.ResponseFriendlyMessage}`, 'Ok', { duration: 50000 });
        this.oneTimePassCode = '';
      },
      (error: any) => {
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        this._snackBar.open(`We encountered an error while approving request .. Please retry`, 'Ok', { duration: 25000 });
      }
    );
  }
  sendPaymentApprovalRequestWithStageSelection(request: StagedPaymentRequest, stepper: MatStepper) {
    this.ownLoaderIsActive = true;
    this.ownloaderMsg = `Approving payment .. please wait`;
    const approvalRequestPayload = this.buildSinglePaymentApprovalRequestPayload();
    this._approvalService.approvePendingPaymentRequestWithSelectedStage(approvalRequestPayload).then(
      (response) => {
        console.log(response);
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        if (!response || response.ResponseCode !== ResponseCodes.SUCCESS) {
          this.handleNonSuccessApprovalResponse(request, response, stepper);
          return;
        }
        this._approvalModalData.currentRequestAction = 'requestComplete';
        setTimeout(() => {
          stepper.next();
        }, 50);
        this.loadPendingPaymentRequests('refreshing pending batch list');
        this._snackBar.open(`${response.ResponseFriendlyMessage}`, 'Ok', { duration: 50000 });
      },
      (error: any) => {
        console.log(error);
      }
    );
  }
  onSelectedNextApprovalStageSubmitted(approvalStage: ApprovalStage, stepper: MatStepper) {
    this.ownLoaderIsActive = true;
    this.ownloaderMsg = 'setting next approval stage .. please wait';
    const requestPayload = this.buildSinglePaymentApprovalRequestPayload();
    requestPayload.NextStages = [approvalStage];
    this.sendPaymentApprovalRequestWithStageSelection(this._approvalModalData.data, stepper);
  }

  sendPaymentApprovalRecallRequest(stepper: MatStepper, request: StagedTransfer) {
    this.ownLoaderIsActive = true;
    this.ownloaderMsg = `Approving payment .. please wait`;
    this._stageRequestService.makePaymentApprovalRecallRequest(request).then(
      (response: any) => {
        console.log(response);
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        // this.approvalRequestModal.close();
        stepper.next();

        this._snackBar.open(`Payment request recalled. Notification email has been sent to initiator`, 'Ok',
          { duration: 5000 });
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  getRequestColor(): string {
    const color = ['lightcoral', '#2196F3', '#009688', '#b71c1c'];
    const pos = Math.floor(Math.random() * color.length);
    return color[pos];
  }

  onTransactionsPageChanged(pageChangedEvent: PageEvent) {
    const start = pageChangedEvent.pageIndex * pageChangedEvent.pageSize;
    const end = start + pageChangedEvent.pageSize;
    this.requestList = this.requestListBackup.slice(start, end);
  }

  loadAccounts() {
    this.accountList = VariablesService.CustomerAccounts;
  }

  getRoleName(role: Role): string {
    switch (role) {
      case Role.Initiator:
        return 'Initiator';
      case Role.Approver:
        return 'Approver';
      case Role.Viewer:
        return 'Viewer';
      default:
        return 'N\\A';
    }
  }
  getBankName(bankCode: string) {
    const bank = _banksList.find(b => b['bankCode'] === bankCode);
    return bank && bank['name'] ? bank['name'] : 'N/A';
  }

  onRequestCheckboxChanged(evtData, position: number) {
    this.requestList[position]['checked'] = evtData.checked;
    this._selectedRequestCount = this.requestList.filter(request => request['checked']).length;
  }

  onAllCheckboxChanged(evtData) {
    if (evtData.checked) {
      this.requestList.forEach(b => b['checked'] = true);
      this._selectedRequestCount = this.requestList.filter(b => b['checked']).length;
      this.allCheckboxesSelected = true;
      console.log(this.requestList);
    } else {
      this.requestList.forEach(b => b['checked'] = false);
      this._selectedRequestCount = 0;
      this.allCheckboxesSelected = false;
    }
  }

  initSearchFilter() {
    return {
      startDate: '',
      endDate: '',
      transactionType: '',
      selectedAccount: {} as CustomerAccount,
      transactionStatus: '',
      role: 0 as Role
    };
  }
  private handleNonSuccessApprovalResponse(request: StagedPaymentRequest, response: BulkPaymentBatchApprovalResponse, stepper: MatStepper) {
    switch (response.ResponseCode) {
      case '70': {
        this._approvalModalData.currentApprovalAction = 'select-next-approval-stage';
        this._availableApprovalStages = response.Stages;
        setTimeout(() => {
          stepper.next();
        }, 50);
        break;
      }
      default: {
        this._snackBar.open(`${response.ResponseFriendlyMessage}`, 'Retry', { duration: 25000 }).onAction().subscribe(
          () => this.sendPaymentApprovalRequest(request, stepper)
        );
        break;
      }
    }
  }

  private buildSinglePaymentApprovalRequestPayload():
    SinglePaymentApprovalRequestPayload {
    return {
      BatchId: Number(this._approvalModalData.requestMeta.batchId),
      UserId: '',
      ApproverId: Number(this._approvalModalData.requestMeta.approverId),
      ApproverStageId: Number(this._approvalModalData.requestMeta.approverStageId),
      OTP: this.oneTimePassCode,
      SourceReferenceId: this._otpReferenceId,
      Transactions: [],
      OperationType: 'ONE_OFF_PAYMENT' ,// this._approvalModalData.data.OperationType
      OTPType:0
    };
  }

  private initComponent() {
    if (this._authService.loggedInUserHasSMEProfile() && this._loggedInUserIsApprover) {
      this.pageTitle = 'Authorize Pending Payment Approval Requests';
    }
  }

  private dismissOpenModalWindows() {
    if (this.approvalRequestDetailModal) {
      this.approvalRequestDetailModal.close();
    }
    if (this.approvalHistoryModal) {
      this.approvalHistoryModal.close();
    }
    if (this.approvalRequestModal) {
      this.approvalRequestModal.close();
    }

    if (this.requestRejectionModal) {
      this.requestRejectionModal.close();
    }
  }

}


