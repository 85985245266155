import { Injectable } from '@angular/core';
import { AppConfigService } from './appconfig.service';
import { HttpClient } from '@angular/common/http';
import { PendingNameEnquiryBatchResponse, BatchTransactionsResponse, PendingNameEnquiryBatchInfoResponse } from '../models/payloads/transactions';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { GenericApiResponse } from '../models/payloads/generic-response';
import { ApiURLConstants } from '../helpers/apiURLConstants';

@Injectable()
export class NameEnquiryService {


    private _apiBaseUrl: string;

    constructor(_settings: AppConfigService, private _httpClient: HttpClient, private _authService: AuthService) {
        this._apiBaseUrl = _settings.APIBaseUrl;
    }

    getPendingNameEnquiryBatches(payload: any): Promise<PendingNameEnquiryBatchResponse> {
        const requestPayload = this.updatePendingNameEnquiryRequestPayload(payload);
        return this._httpClient.post<PendingNameEnquiryBatchResponse>(
            `${this._apiBaseUrl}/TransactionManagement/GetTransferBatches `, requestPayload)
            .toPromise();
    }

    getBatchNameEnquiryTransactions(payload: any): Promise<BatchTransactionsResponse> {
        const requestPayload = this.updatePendingNameEnquiryRequestPayload(payload);
        return this._httpClient.post<BatchTransactionsResponse>(
            `${this._apiBaseUrl}/TransactionManagement/GetBatchTransaction `, requestPayload)
            .toPromise();
    }

    getPendingNameEnquiryBatchInfo(batchId: any): Promise<PendingNameEnquiryBatchInfoResponse> {
        const requestPayload = this.updatePendingNameEnquiryRequestPayload({});
        requestPayload['BatchId'] = batchId;
        return this._httpClient.post<PendingNameEnquiryBatchInfoResponse>(
            `${this._apiBaseUrl}/TransactionManagement/GetBatchDetails `, requestPayload)
            .toPromise();
    }

    getPendingNameBatchStatus(batchId: any): Promise<PendingNameEnquiryBatchInfoResponse> {
        const requestPayload = this.updatePendingNameEnquiryRequestPayload({});
        requestPayload['BatchId'] = batchId;
        return this._httpClient.post<PendingNameEnquiryBatchInfoResponse>(
            `${this._apiBaseUrl}/TransactionManagement/GetBatchStatus `, requestPayload)
            .toPromise();
    }


    deleteBatch(batchIds: Array<any>): Observable<GenericApiResponse> {
        const loggedInUser = this._authService.getLoggedInUser();
        const payload = {batchIds: batchIds, UserId: loggedInUser && loggedInUser.UserId ? loggedInUser.UserId : ''};
        return this._httpClient.post<GenericApiResponse>(`${this._apiBaseUrl}/${ApiURLConstants.REMOVE_TRANSACTIONS_BATCH}`, payload);
    }
    private updatePendingNameEnquiryRequestPayload(payload) {
        payload['UserId'] = this._authService.getLoggedInUser().UserId;
        return payload;
    }
}
