import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { VirtualCardService } from 'src/app/core/services/virtual-card.service';


@Component({
  selector: 'app-hotlist-card',
  templateUrl: './hotlist-card.component.html',
  styleUrls: ['./hotlist-card.component.css']
})
export class HotlistCardComponent implements OnInit {

  cardDetails:any;
  cardDetailsSub: Subscription;
  currentCardDetails: any;
  loaderIsActive: boolean;
  // page = 0;
  // accountOptions =[];
  // cardPans = [];
  // show = false;
  lastCardDigits:string;

  success:boolean = false;

  constructor (
    private virtualCardService: VirtualCardService,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {
    this.cardDetailsSub = this.virtualCardService.cardDetails.subscribe(
      (response) => {
        this.cardDetails = response.card;
        console.log(this.cardDetails)
      }
    );
  }

  hotlist() {
    this.loaderIsActive = true;

    let hotlistObj: any  = {
      "cardReference": this.cardDetails.carD_REF,
      "accountNumber": this.cardDetails.accounT_NUM,
    }

    this.virtualCardService.hotlistCard(hotlistObj, "b").subscribe(
      (response) => {
        if (response.responseCode === "000") {
          this.loaderIsActive = false;
          console.log(response)
          this._snackBar.open("Card Hotlisted Succesfully",
            '', { verticalPosition: 'bottom', horizontalPosition: 'right', duration: 3000 });
            this.success = true;

        } else {
          this.loaderIsActive = false;
          console.log("else statement")
          console.log(response.responseDescription)
          this._snackBar.open(response.responseDescription,
            'Error', { verticalPosition: 'bottom', horizontalPosition: 'right', duration: 2000 });
        }

      },
      (error) => {
        this.loaderIsActive = false
        console.log("an error")
        console.log(error)
        this._snackBar.open(error.error.responseDescription,
          'Error', { verticalPosition: 'bottom', horizontalPosition: 'right', duration: 2000 });
      }
    )


    this.lastCardDigits = this.data.card.pan

  }
}
