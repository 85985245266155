import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../appconfig.service';
import { AuthService } from '../auth.service';
import {
    BulkPaymentBatchApprovalRequestPayload, BulkPaymentBatchApprovalResponse,
    SinglePaymentApprovalRequestPayload
} from '../../models/approvals/file-upload.approval';
import { ApiURLConstants } from '../../helpers/apiURLConstants';
import { GenericApprovalPayload } from '../../models/payloads/transactions';
import { GenericApiResponse } from '../../models/payloads/generic-response';

@Injectable()
export class TransferApprovalService {

    private _apiBaseUrl: string;
    private _transactionsAPIBase: string;
    constructor(private _httpClient: HttpClient, _settings: AppConfigService, private _authService: AuthService) {
        this._apiBaseUrl = _settings.APIBaseUrl;
        this._transactionsAPIBase = `${this._apiBaseUrl}/TransactionManagement`;
    }

    approvePendingBulkPaymentBatch(payload: BulkPaymentBatchApprovalRequestPayload): Promise<BulkPaymentBatchApprovalResponse> { 
        return this._httpClient.post<BulkPaymentBatchApprovalResponse>
            (`${this._apiBaseUrl}/${ApiURLConstants.APPROVE_PENDING_BULK_REQUEST}`, payload).toPromise();
    }

    approvePendingBulkPaymentBatchWithSelectedStage(payload: BulkPaymentBatchApprovalRequestPayload):
        Promise<BulkPaymentBatchApprovalResponse> {
        payload['UserId'] = this._authService.getLoggedInUser() ? this._authService.getLoggedInUser().UserId : '';
        return this._httpClient.post<BulkPaymentBatchApprovalResponse>(`${this._transactionsAPIBase}/ApproveAndMoveToNextStage`, payload)
            .toPromise();
    }

    approvePendingPaymentRequest(payload: SinglePaymentApprovalRequestPayload): Promise<BulkPaymentBatchApprovalResponse> {
        payload['UserId'] = this._authService.getLoggedInUser() ? this._authService.getLoggedInUser().UserId : '';
        return this._httpClient.post<BulkPaymentBatchApprovalResponse>
            (`${this._apiBaseUrl}/${ApiURLConstants.APPROVE_PENDING_SINGLE_PAYMENT_REQUEST}`, payload).toPromise();
    }

    approvePendingPaymentRequestWithSelectedStage(payload: SinglePaymentApprovalRequestPayload): Promise<BulkPaymentBatchApprovalResponse> {
        payload['UserId'] = this._authService.getLoggedInUser() ? this._authService.getLoggedInUser().UserId : '';
        return this._httpClient.post<BulkPaymentBatchApprovalResponse>
            (`${this._apiBaseUrl}/${ApiURLConstants.APPROVE_PENDING_SINGLE_PAYMENT_REQUEST_WITH_NEXTSTAGE_SELECTION}`, payload).toPromise();
    }

    approvePendingInternationalPaymentRequest(payload: SinglePaymentApprovalRequestPayload): Promise<BulkPaymentBatchApprovalResponse> {
        payload['UserId'] = this._authService.getLoggedInUser() ? this._authService.getLoggedInUser().UserId : '';
        return this._httpClient.post<BulkPaymentBatchApprovalResponse>
            (`${this._apiBaseUrl}/${ApiURLConstants.APPROVE_PENDING_INTERNATIONAL_PAYMENT_REQUEST}`, payload).toPromise();
    }

    approvePendingInternationalPaymentRequestWithSelectedStage(payload: SinglePaymentApprovalRequestPayload):
        Promise<BulkPaymentBatchApprovalResponse> {
        payload['UserId'] = this._authService.getLoggedInUser() ? this._authService.getLoggedInUser().UserId : '';
        return this._httpClient.post<BulkPaymentBatchApprovalResponse>
            (`${this._apiBaseUrl}/${ApiURLConstants.APPROVE_PENDING_INTNL_PAYMENT_REQUEST_WITH_NEXTSTAGE_SELECTION}`, payload).toPromise();
    }

    approveWesterUnionRedemptionRequest(payload: GenericApprovalPayload): Promise<BulkPaymentBatchApprovalResponse> {
        payload['UserId'] = this._authService.getLoggedInUser() ? this._authService.getLoggedInUser().UserId : '';
        return this._httpClient.post<BulkPaymentBatchApprovalResponse>
            (`${this._apiBaseUrl}/${ApiURLConstants.APPROVE_WESTERN_UNION_REDEMPTION_REQUEST}`, payload).toPromise();
    }

    approveWesterUnionRedemptionRequestWithNextStageSelection(payload: any): Promise<GenericApiResponse> {
        payload['UserId'] = this._authService.getLoggedInUser() ? this._authService.getLoggedInUser().UserId : '';
        return this._httpClient.post<BulkPaymentBatchApprovalResponse>
            (`${this._apiBaseUrl}/${ApiURLConstants.APPROVE_PENDING_WESTERN_UNION_REDEMPTION_REQUEST_WITH_NEXTSTAGE_SELECTION}`,
            payload).toPromise();
    }

}
