import { Component, OnInit, Input, SimpleChanges, OnChanges, TemplateRef, ViewChild } from '@angular/core';
import { CustomerAccount } from 'src/app/core/models/account';
import {
  ApprovalStage, StagedWesternUnionRedemptionRequestObject,
  GenericApprovalPayload
} from 'src/app/core/models/payloads/transactions';
import { Role, ResponseCodes, OtpReasonCodes, OTPType } from 'src/app/core/models/Enumerations';
import { Approval } from 'src/app/core/models/approvals/payment.approval';
import { TransferStagingService } from 'src/app/core/services/staging/transfer.staging.service';
import { MatSnackBar, PageEvent, MatDialog, MatDialogRef, MatStepper } from '@angular/material';
import { MiscService } from 'src/app/core/services/misc.service';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/services/auth.service';
import { TransferApprovalService } from 'src/app/core/services/workflow/transfer.approval.service';
import { Constants } from 'src/app/core/helpers/appConstants';
import { RejectRequestComponent } from 'src/app/shared/dialogs/reject-request/reject-request.component';
import { BaseApprovalComponent } from '../base.approval.component';
import { RequestApprovalHistoryPayload } from 'src/app/core/models/misc-requests-model';
import { ApprovalHistoryComponent } from 'src/app/shared/approval-history/approval-history.component';
import { UtilService } from 'src/app/core/services/helpers.service';
import { VariablesService } from 'src/app/core/services/variables.service';

@Component({
  selector: 'app-western-union-approvals',
  templateUrl: './western-union-approvals.component.html',
  styleUrls: ['./western-union-approvals.component.css']
})
export class WesternUnionApprovalsComponent extends BaseApprovalComponent implements OnInit, OnChanges {


  ownLoaderIsActive: boolean;
  ownloaderMsg: string;
  @Input() _activeTab: string;
  _activeRequestCount = 0;

  accountList: CustomerAccount[];
  requestList: StagedWesternUnionRedemptionRequestObject[] = [];
  requestListBackup: StagedWesternUnionRedemptionRequestObject[] = [];
  pageSize = 5;
  _selectedRequestCount = 0;
  requestDetailModalData = {
    currentRequestAction: '',
    currentApprovalAction: '',
    data: null,
    requestMeta: {} as any
  };
  oneTimePassCode: string;
  _otpReferenceId: string;
  filterIsActive: boolean;
  userRoleEnum = Role;
  allCheckboxesSelected: boolean;

  _requestApprovalHistory: Array<Approval> = [];
  _availableApprovalStages: Array<ApprovalStage> = [];
  _selectedApprovalStage: ApprovalStage;

  _paymentApprovalCompleted: boolean;
  @ViewChild('westerUnionApprovalModal') _requestApprovalModalTemplate: TemplateRef<any>;
  public requestDetailModal: MatDialogRef<any>;
  hwtFormGroup:FormGroup;
  isOTPStepperVisible: any;
  isCustomerHWTEnabled: any;
  accountNoSet: Set<string>;

  constructor(private _transferStagingService: TransferStagingService, private _snackbar: MatSnackBar,
    private _dialog: MatDialog, private _miscService: MiscService, authService: AuthService,
    private _approvalService: TransferApprovalService, private formBuilder:FormBuilder) {
    super(authService);
     // try {
    //   window['casa']('other'); 
    //  } catch (error) {}
    this.pageTitle = this._loggedInUser && this._authService.loggedInUserHasSMEProfile() && this._loggedInUserIsApprover ?
      'Authorize Western Union Redemption Requests' : '';

    UtilService.onPageLoadCallback();
    
    this.hwtFormGroup = formBuilder.group({
      hwt: ['', Validators.required]
    });
  }

  ngOnInit() {
     this.isCustomerHWTEnabled = VariablesService.CustomerHWTEnabled;

     this.accountNoSet = new Set<string>();
     VariablesService.CustomerAccounts.forEach(element => {
     this.accountNoSet.add(element.accountNumber);
    })
    // this.isCustomerHWTEnabled = true;
    if (this.isCustomerHWTEnabled) {
      this.isOTPStepperVisible = false;
    } else {
      this.isOTPStepperVisible = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['_activeTab']) {
      this._activeTab = changes['_activeTab'].currentValue;
      if (this._activeTab === 'wester_union_redemption') {
        this.loadWesternUnionPaymentRequests();
      }
    }
  }

  returnToOTPStepper(){
    this.ownLoaderIsActive = true;
    this.ownloaderMsg = 'initiating redemption request .. please wait';
    this._miscService.initiateOtpRequest(OtpReasonCodes.GENERIC, 'APPROVE WESTERN UNION REDEMPTION').then(
      (response) => {
        console.log(response);
        this.ownloaderMsg = '';
        this.ownLoaderIsActive = false;
        if (!response || response.ResponseCode !== ResponseCodes.SUCCESS) {
          const errorMsg = !response || !response.ResponseFriendlyMessage ?
            `Oops .. One time passcode request was not sucessful. Please retry` : `We encountered a technical error while 
            sending one time passcode. Server responded with -> ${response.ResponseFriendlyMessage}. Please try again`;
       
          return;
        }
        var hardwareTokenStepper = document.getElementById('hardwareTokenStepper');
        hardwareTokenStepper.style.display = 'none';
        this.isOTPStepperVisible = true;
      this.requestDetailModalData['otpIntstruction'] = `Please enter the One time PIN sent to ${response.NotificationAddress}
                                         to approve request`;
        this._snackbar.open(`${this.requestDetailModalData['otpIntstruction']}`, 'Ok', { duration: 25000 });
        this.requestDetailModalData.currentApprovalAction = 'verify-otp';
        this._otpReferenceId = response.ResponseDescription;
      },
      (error) => {
        console.log(error);
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        this._snackbar.open(`We encountered an error while processing your request. please retry`, 'Ok', { duration: 25000 });
      }
    );
  }
  loadWesternUnionPaymentRequests(loaderMsg = 'fetching pending western union redemption requests. please wait',
    lastFetchId = 0, pageSize = 100) {
    this.ownLoaderIsActive = true;
    this.ownloaderMsg = loaderMsg;
    this._transferStagingService.getPendingWesternUnionRedemptionRequest({ PageSize: pageSize, LastFetchId: lastFetchId }).then(
      (response) => {
        this.ownLoaderIsActive = this._activeRequestCount > 0;
        this.ownloaderMsg = '';
        if (!response || response.ResponseCode !== ResponseCodes.SUCCESS || !response.PaginatedItems) {
          this._snackbar.open(`${response.ResponseFriendlyMessage}`, 'Ok', { duration: 25000 });
          return;
        }
        response.PaginatedItems.forEach(request => {
          request['checked'] = false;
        });
        this.requestListBackup = response.PaginatedItems;
        this.requestListBackup = this.requestListBackup.filter(item => this.accountNoSet.has(item.Details.creditAcct));
        this.requestList = this.requestListBackup.slice(0, 5);
      },
      (error) => {
        console.log(error);
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        this._snackbar.open(`We encountered an error while loading pending payment request`, 'Ok', { duration: 25000 });
      }
    );
  }

  onTransactionsPageChanged(pageChangedEvent: PageEvent) {
    const start = pageChangedEvent.pageIndex * pageChangedEvent.pageSize;
    const end = start + pageChangedEvent.pageSize;
    this.requestList = this.requestListBackup.slice(start, end);
  }che

  onApprovalRequestAction(action: string, data: StagedWesternUnionRedemptionRequestObject) {
    this.requestDetailModalData.requestMeta['BatchId'] = data.BatchId;
    this.requestDetailModalData.requestMeta['Initiator'] = data.Initiator;
    this.requestDetailModalData.requestMeta['ApprovalStageId'] = data.ApprovalStageId;
    this.requestDetailModalData.requestMeta['ApproverId'] = data.ApproverId;
    this.requestDetailModalData.data = data.Details;
    switch (action) {
      case 'approve': {
        this.initRequestApprovalModal(data);
        break;
      }
      case 'reject': {
        const rejectionDialog = this._dialog.open(RejectRequestComponent,
          {
            hasBackdrop: false,
            closeOnNavigation: true,
            disableClose: true,
            data: {
              title: 'Reject Western Union Redemption Request',
              payload: {
                OperationType: 'WESTERN_UNION',
                OperationId: data.BatchId
              }
            }
          });
        rejectionDialog.afterClosed()
          .subscribe((result) => {
            if (result) {
              this.loadWesternUnionPaymentRequests('refreshing .. please wait');
            }
          });
        break;
      }
      case 'view-history': {
        const requestPayload: RequestApprovalHistoryPayload = {
          OperationType: data.Details['operationType'],
          OperationId: Number(data.Details.Id),
          UserId: '',
          PageSize: 100,
          LastIdFetched: 0
        };
        const approvalHistoryModal = this._dialog.open<ApprovalHistoryComponent>(
          ApprovalHistoryComponent,
          {
            hasBackdrop: false,
            data: {
              operationName: 'western-union',
              requestPayload: requestPayload,
              title: 'Request Approval History',
              extra: {}
            }
          });
        approvalHistoryModal.afterClosed().subscribe(
          (result) => {
            //
          }
        );
        break;
      }
    }
  }

  onRequestModalAction(action: string, stepper: MatStepper, form?: NgForm) {
    console.log(action);
    switch (action) {
      case 'approve': {
        this.initiateRequestApprovalOTP(stepper);
        break;
      }
      case 'reject': {
        if (form && !form.valid) {
          this._snackbar.open('Please provode a reason for rejecting request .. ', 'Ok',
            { duration: 10000 });
          return;
        }
        break;
      }
    }
  }

  onVerifyApprovalConfirmationOtp(form: NgForm, stepper: MatStepper,useHardwareToken) {
    
    if (useHardwareToken) {
      if (!this.hwtFormGroup.valid || !this.hwtFormGroup.controls.hwt) {
        this._snackbar.open(`Please provide a valid token number`, 'Ok', { duration: 25000 });
        return;
      }
    }
    
    if (!form.valid) {
      this._snackbar.open(`Invalid One Time PIN provided. Please provide a valid OTP to continue`, 'Ok',
        { duration: 10000 });
      return;
    }
    this.sendWesternUnionRedemptionApprovalRequest(this.requestDetailModalData.data as StagedWesternUnionRedemptionRequestObject, stepper);
  }

  oncloseRequestApprovalModal() {
    this.requestDetailModal.close();
    this.ownLoaderIsActive = false;
    this.ownloaderMsg = '';
    this.oneTimePassCode = '';
    this._otpReferenceId = '';
    this.requestDetailModalData.currentApprovalAction = '';
    this.requestDetailModalData.currentRequestAction = '';
  }

  onApprovaStageSelected(evtData, stage: ApprovalStage, stageIndex: number) {
    this._availableApprovalStages.forEach(stage_ => stage_['checked'] = false);
    this._availableApprovalStages[stageIndex]['checked'] = evtData.checked;
    this._selectedApprovalStage = evtData.checked ? stage : null;
  }

  onAprovalFlowSelected(stepper: MatStepper) {
    if (!this._selectedApprovalStage) {
      this._snackbar.open(`Please select an approval stage.`, 'Ok');
      return;
    }
    const requestPayload = this.buildWesternUnionRedemptionApprovalStageRequestPayload();
    this.ownLoaderIsActive = true;
    this.ownloaderMsg = 'submitting selected next approval level';
    this._approvalService.approveWesterUnionRedemptionRequestWithNextStageSelection(requestPayload).then(
      (response) => {
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        if (!response || response.ResponseCode !== ResponseCodes.SUCCESS && response.ResponseCode !== '70') {
          this._snackbar.open(`Request failed with error -> ${response.ResponseFriendlyMessage}`, 'Ok', { duration: 25000 });
          return;
        }
        if (response.ResponseCode === ResponseCodes.SUCCESS) {
          this.requestDetailModalData.currentApprovalAction = 'approvalComplete';
          this.requestDetailModalData.currentRequestAction = 'requestComplete';
          stepper.next();
          this._snackbar.open(`${response.ResponseFriendlyMessage}`, 'Ok', { duration: 25000 });
          this.loadWesternUnionPaymentRequests('refreshing pending bill payment requests .. please wait');
          return;
        }
      },
      (error) => {
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        if (error && error.status != Constants.UnauthorizedStatusCode) {
          this._snackbar.open(Constants.APITechnicalErrorMessage,
            'Ok', { verticalPosition: 'bottom', horizontalPosition: 'right', duration: 1500 });
        }
      }
    );

  }

  private initRequestApprovalModal(data: StagedWesternUnionRedemptionRequestObject) {
    this.requestDetailModalData.data = data;
    this.requestDetailModal = this._dialog.open(this._requestApprovalModalTemplate,
      { hasBackdrop: false, panelClass: 'beneficiary-details-card' });
  }

  private initiateRequestApprovalOTP(stepper: MatStepper) {
    this.ownLoaderIsActive = true;
    this.ownloaderMsg = 'initiating redemption request .. please wait';
    this._miscService.initiateOtpRequest(OtpReasonCodes.GENERIC, 'APPROVE WESTERN UNION REDEMPTION').then(
      (response) => {
        console.log(response);
        this.ownloaderMsg = '';
        this.ownLoaderIsActive = false;
      this.requestDetailModalData['otpIntstruction'] = `Please enter the One time PIN sent to ${response.NotificationAddress}
                                         to approve request`;
        this._snackbar.open(`${this.requestDetailModalData['otpIntstruction']}`, 'Ok', { duration: 25000 });
        this.requestDetailModalData.currentApprovalAction = 'verify-otp';
        this._otpReferenceId = response.ResponseDescription;
        setTimeout(() => stepper.next(), 25);
      },
      (error) => {
        console.log(error);
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        this._snackbar.open(`We encountered an error while processing your request. please retry`, 'Ok', { duration: 25000 });
      }
    );
  }

  private sendWesternUnionRedemptionApprovalRequest(request: StagedWesternUnionRedemptionRequestObject, stepper: MatStepper) {
    this.ownLoaderIsActive = true;
    this.ownloaderMsg = `Approving request .. please wait`;
    const approvalRequestPayload = this.buildWesternUnionRedemptionApprovalRequestPayload();
    
    if(!this.isOTPStepperVisible){     
      approvalRequestPayload["otp"]=  this.hwtFormGroup.controls.hwt.value;
      approvalRequestPayload["OTPType"] = OTPType.HWT;
    }
    else{
      approvalRequestPayload["OTPType"] = !this.isOTPStepperVisible ? OTPType.HWT : this.isOTPStepperVisible ? OTPType.OTP : OTPType.NONE;
      approvalRequestPayload["otp"]=  !this.isOTPStepperVisible ? this.hwtFormGroup.controls.hwt.value : this.oneTimePassCode;
    }
    
    this._approvalService.approveWesterUnionRedemptionRequest(approvalRequestPayload).then(
      (response) => {
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        if (!response || response.ResponseCode !== ResponseCodes.SUCCESS) {
          this.handleNonSuccessApprovalResponse(request, response, stepper);
          return;
        }
        this._snackbar.open(`${response.ResponseFriendlyMessage}`, 'Ok', { duration: 50000 });
        this.loadWesternUnionPaymentRequests('refreshing bill pending bill payments');
        this.requestDetailModalData.currentRequestAction = 'requestComplete';
        setTimeout(() => stepper.next(), 15);
      },
      (error: any) => {
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
        this._snackbar.open(`We encountered an error while approving request .. Please retry`, 'Ok', { duration: 25000 });
      }
    );
  }

  private handleNonSuccessApprovalResponse(request: StagedWesternUnionRedemptionRequestObject, response: any, stepper: MatStepper) {
    switch (response.ResponseCode) {
      case '70': {
        this._snackbar.open(`${response.ResponseFriendlyMessage}`, 'Ok', { duration: 50000 });
        this.requestDetailModalData.currentApprovalAction = 'select-next-approval-stage';
        this._availableApprovalStages = response.Stages;
        setTimeout(() => stepper.next(), 50);
        break;
      }
      default: {
        this._snackbar.open(`${response.ResponseFriendlyMessage}`, 'Retry', { duration: 25000 }).onAction().subscribe(
          () => this.sendWesternUnionRedemptionApprovalRequest(request, stepper)
        );
        break;
      }
    }
  }

  private buildWesternUnionRedemptionApprovalRequestPayload():
    GenericApprovalPayload {
    return {
      BatchId: Number(this.requestDetailModalData.requestMeta.BatchId),
      UserId: AuthService.FullyAuthenticatedUser && AuthService.FullyAuthenticatedUser.UserId ?
        AuthService.FullyAuthenticatedUser.UserId : '',
      ApproverId: Number(this.requestDetailModalData.requestMeta.ApproverId),
      ApproverStageId: Number(this.requestDetailModalData.requestMeta.ApprovalStageId),
      OTP: this.oneTimePassCode,
      SourceReferenceId: this._otpReferenceId
    };
  }

  private buildWesternUnionRedemptionApprovalStageRequestPayload() {
    return {
      BatchId: this.requestDetailModalData.requestMeta.BatchId,
      CurrentApprovalStageId: this.requestDetailModalData.requestMeta.ApprovalStageId,
      UserId: AuthService.FullyAuthenticatedUser && AuthService.FullyAuthenticatedUser.UserId ?
        AuthService.FullyAuthenticatedUser.UserId : '',
      NextStages: [this._selectedApprovalStage]
    };
  }
}
