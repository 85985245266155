import { Component, OnInit } from '@angular/core';
import { EventHandlerService } from 'src/app/core/services/EventHandlerService';
import { VariablesService } from 'src/app/core/services/variables.service';
import { RouterConstants } from 'src/app/core/helpers/routerConstants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-manage-fx-transaction',
  templateUrl: './manage-fx-transaction.component.html',
  styleUrls: ['./manage-fx-transaction.component.css']
})
export class ManageFxTransactionComponent implements OnInit {
  activeTab: any;

  constructor(private router: Router) { 
    // try {
    //   window['casa']('transaction');
    //  } catch (error) {}


    try {
      window['casa']('transaction'); 
} catch (error) {}
  
  }

  ngOnInit() {
    
    if (!VariablesService.CustomerBVN) {
      this.router.navigate([RouterConstants.FullDashboardURL]);
      return;
    }
    EventHandlerService.emitOnAppViewChangedEvent();
    this.activeTab = "initiate-fx-transaction";

  }

  openTab(evt, tabName) {
    let i, tablinks;
    this.activeTab = tabName;
    
    tablinks = document.getElementsByClassName('tablinks');
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(' active', '');
    }
    evt.currentTarget.className += ' active';
  }
  
  onNavigationOptionChange(evtData) {
    this.activeTab = evtData.value as string;
    this.openTab('', this.activeTab);
  }
}
