import { UnscheduledRepaymentServiceService } from "src/app/core/services/unscheduledRepaymentServices/unscheduled-repayment.service";
import { NgModule } from "@angular/core";
import {CommonModule, CurrencyPipe, DatePipe} from '@angular/common';
import { HttpClientModule } from "@angular/common/http";
import { AppConfigService } from "./services/appconfig.service";
import { StorageService } from "./services/storage.service";
import { TransactionService } from "./services/transaction.service";
import { AuthService } from "./services/auth.service";
import { AccountService } from "./services/account.service";
import { RequestService } from "./services/service-request/service-request.service";
import { BeneficiaryService } from "./services/beneficiary.service";
import { MiscService } from "./services/misc.service";
import { DashboardService } from "./services/dashboard.service";
import { TransferService } from "./services/transfer.service";
import { UtilityService } from "./services/utils.service";
import { FileHelperService } from "./services/file.helper.service";
import { NameEnquiryService } from "./services/name.enquiry.service";
import { TransferStagingService } from "./services/staging/transfer.staging.service";
import { TransferApprovalService } from "./services/workflow/transfer.approval.service";
import { BeneficiaryStagingService } from "./services/staging/beneficiary.staging.service";
import { RequestStagingService } from "./services/staging/service-request.staging.service";
import { BillerStagingService } from "./services/staging/biller.staging.service";
import { BillerApprovalService } from "./services/workflow/biller.approval.service";
import { ServiceRequestOperationApprovalService } from "./services/workflow/service-request.approval.service";
import { PaymentStagingService } from "./services/staging/payment.staging.service";
import { PaymentApprovalService } from "./services/workflow/payments.approval.service";
import { ReceiptService } from "./services/receipt.service";
import { ApprovalHistoryFactory } from "./services/workflow/approvals.history.service";
import { SssBankingTagService } from "./services/sss-banking.tag.service";
import { WalletTransferService } from "./services/transfer.wallet.service";
import { CardService } from "./services/prepaid-card.service";
import { LoanRequestService } from "../pages/loan/loan-request/loan.request.service";
import { LoanTopUpService } from "../pages/loan/loan-topup/loan-topup.service";
import { DocumentHelperService } from "./services/document.helper.service";
import { VariableSingletonService } from "./services/variables.service";
import { SignalRService } from "./services/signalr.service";
import { MockService } from "./services/mock.service";
import { PensionService } from "./services/pension.service";
import { CustodianInsuranceService } from "./services/custodian.service";
import { IntellinxService } from "./services/intellinx-service";
import { CorporateBillersService } from "./services/corporate-billers.service";
import { ShippingPaymentService } from "./services/shipping-payment.service";
import { FxRequestHistoryService } from "./services/FxRequestHistory.service";
import { FXPaymentService } from "./services/fx-payment.service";
import { EnairaService } from "./services/enaira.service";
import {WakanowIntegrationService} from './services/wakanow-integration.service';
import {BentoService} from './services/bento/bento.service';


@NgModule({
  imports: [CommonModule, HttpClientModule],
  exports: [],
  providers: [
    AppConfigService,
    StorageService,
    TransactionService,
    AuthService,
    AccountService,
    RequestService,
    MiscService,
    BeneficiaryService,
    DashboardService,
    TransferService,
    UtilityService,
    FileHelperService,
    NameEnquiryService,
    TransferStagingService,
    TransferApprovalService,
    BeneficiaryStagingService,
    RequestStagingService,
    BillerStagingService,
    BillerApprovalService,
    ServiceRequestOperationApprovalService,
    PaymentStagingService,
    PaymentApprovalService,
    ReceiptService,
    ApprovalHistoryFactory,
    SssBankingTagService,
    WalletTransferService,
    CardService,
    LoanRequestService,
    LoanTopUpService,
    DocumentHelperService,
    DatePipe,
    SignalRService,
    MockService,
    PensionService,
    VariableSingletonService,
    UnscheduledRepaymentServiceService,
    CorporateBillersService,
    CustodianInsuranceService,
    IntellinxService,
    ShippingPaymentService,
    FxRequestHistoryService,
    FXPaymentService,
    EnairaService,
    WakanowIntegrationService,
    BentoService,
    CurrencyPipe
  ],
})
export class CoreModule {}
