

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { EventHandlerService } from 'src/app/core/services/EventHandlerService';
import { Constants } from '../../core/helpers/appConstants';
import { AuthService } from '../../core/services/auth.service';
import { VariablesService } from '../../core/services/variables.service';
import { RouterConstants } from '../../core/helpers/routerConstants';



function _window(): any {
    return window;
}

declare var $: any;


@Component({
    selector: 'app-session-timeout',
    templateUrl: './session-timeout.component.html',
    styleUrls: ['./session-timeout.component.css']
})
export class SessionTimeoutComponent implements OnInit, OnDestroy {


    // sessionTimeoutInterval: any;
    // sessionTimeoutSub: any;
    showSessionTimeoutPopup: boolean;
    timeoutSecondLabel: number;
    // timeoutID: any;


    constructor(private authService: AuthService, private router: Router) {
        this.setupAutoLogout();
    }

    ngOnInit() {
        this.SetupTimeout();
    }

    keepBanking() {
        let self = this;
        this.showSessionTimeoutPopup = false;

        if (VariablesService.SessionTimeoutInterval) {
            _window().clearInterval(VariablesService.SessionTimeoutInterval);
        }

        self.setupAutoLogout();
    }



    setupAutoLogout() {

        let self = this;


        if ($('body').off) {
            $('body').off("mousemove");
            $('body').off("mousedown");
            $('body').off("keypress");
            $('body').off("DOMMouseScroll");
            $('body').off("mousewheel");
            $('body').off("touchmove");
            $('body').off("MSPointerMove");
            $('body').off("pointermove");
            $('body').off("keyup");
            $('body').off("keydown");
        }
        else if ($('body').unbind) {
            $('body').unbind("mousemove");
            $('body').unbind("mousedown");
            $('body').unbind("keypress");
            $('body').unbind("DOMMouseScroll");
            $('body').unbind("mousewheel");
            $('body').unbind("touchmove");
            $('body').unbind("MSPointerMove");
            $('body').unbind("pointermove");
            $('body').unbind("keyup");
            $('body').unbind("keydown");

        }


        if (VariablesService.SessionTimeoutEventId) {
            _window().clearTimeout(VariablesService.SessionTimeoutEventId);
        }

        if ($.on) {

            $('body').on("mousemove", function () {
                self.resetAutoLogoutTimer(self);
            });
            $('body').on("mousedown", function () {
                self.resetAutoLogoutTimer(self);
            });
            $('body').on("keypress", function () {
                self.resetAutoLogoutTimer(self);
            });
            $('body').on("keydown", function () {
                self.resetAutoLogoutTimer(self);
            });
            $('body').on("keyup", function () {
                self.resetAutoLogoutTimer(self);
            });
            $('body').on("DOMMouseScroll", function () {
                self.resetAutoLogoutTimer(self);
            });
            $('body').on("mousewheel", function () {
                self.resetAutoLogoutTimer(self);
            });
            $('body').on("touchmove", function () {
                self.resetAutoLogoutTimer(self);
            });
            $('body').on("MSPointerMove", function () {
                self.resetAutoLogoutTimer(self);
            });
            $('body').on("pointermove", function () {
                self.resetAutoLogoutTimer(self);
            });

        }
        else if ($.bind) {


            $('body').bind("mousemove", function () {
                self.resetAutoLogoutTimer(self);
            });
            $('body').bind("mousedown", function () {
                self.resetAutoLogoutTimer(self);
            });
            $('body').bind("keypress", function () {
                self.resetAutoLogoutTimer(self);
            });
            $('body').bind("DOMMouseScroll", function () {
                self.resetAutoLogoutTimer(self);
            });
            $('body').bind("mousewheel", function () {
                self.resetAutoLogoutTimer(self);
            });
            $('body').bind("touchmove", function () {
                self.resetAutoLogoutTimer(self);
            });

            $('body').bind("MSPointerMove", function () {
                self.resetAutoLogoutTimer(self);
            });

            $('body').bind("pointermove", function () {
                self.resetAutoLogoutTimer(self);
            });

            $('body').bind("keydown", function () {
                self.resetAutoLogoutTimer(self);
            });
            $('body').bind("keyup", function () {
                self.resetAutoLogoutTimer(self);
            });
        }

        self.startAutoLogoutTimer();
    }


    startAutoLogoutTimer() {
        let self = this;
        VariablesService.SessionTimeoutEventId = _window().setTimeout(function () {

            EventHandlerService.emitSessionTimeoutEvent();

            self.stopAutoLogout();
        }, Constants.AUTO_LOGOUT_TIME_IN_MILLISEC);
    }

    stopAutoLogout() {

        let self = this;
        if ($('body').off) {
            $('body').off("mousemove");
            $('body').off("mousedown");
            $('body').off("keypress");
            $('body').off("DOMMouseScroll");
            $('body').off("mousewheel");
            $('body').off("touchmove");
            $('body').off("MSPointerMove");
            $('body').off("pointermove");
        }
        else if ($('body').unbind) {
            $('body').unbind("mousemove");
            $('body').unbind("mousedown");
            $('body').unbind("keypress");
            $('body').unbind("DOMMouseScroll");
            $('body').unbind("mousewheel");
            $('body').unbind("touchmove");
            $('body').unbind("MSPointerMove");
            $('body').unbind("pointermove");
        }

        if (VariablesService.SessionTimeoutEventId) {
            _window().clearTimeout(VariablesService.SessionTimeoutEventId);
        }
    }


    resetAutoLogoutTimer(self: SessionTimeoutComponent) {
        _window().clearTimeout(VariablesService.SessionTimeoutEventId);
        self.startAutoLogoutTimer();
    }



    SetupTimeout() {
        let self = this;
        VariablesService.SessionTimedOutSub = EventHandlerService.SessionTimeoutEventPublisher
            .subscribe((object) => {

                self.showSessionTimeoutPopup = true;

                self.timeoutSecondLabel = Constants.SESSION_LOGOUT_COUNTER;

                VariablesService.SessionTimeoutInterval = setInterval(function () {

                    self.timeoutSecondLabel -= 1;

                    if (self.timeoutSecondLabel < 1) {
                        if (VariablesService.SessionTimeoutInterval) {
                            _window().clearInterval(VariablesService.SessionTimeoutInterval);
                        }

                        VariablesService.SessionTimedOut = true;
                        // self.authService.logout();

                        if (VariablesService.SessionTimedOutSub) {
                            VariablesService.SessionTimedOutSub.unsubscribe();
                        }

                        VariablesService.SessionTimedOut = true;

                        // self.router.navigate([RouterConstants.LoginURL]);
                        self.logoutFromSessionTimeout();
                    }


                }, 1000);
            });
    }


    logoutFromSessionTimeout() {
        let self = this;

        this.clearingSession = true;
        this.authService.LogoutRequest(AuthService.FullyAuthenticatedUser.UserId
        ).subscribe(response => {

            self.clearingSession = false;
            self.authService.logout();
            self.router.navigate([RouterConstants.LoginURL]);
            return;
        }),
            (error: any) => {
                self.clearingSession = false;
                self.authService.logout();
                self.router.navigate([RouterConstants.LoginURL]);
            };
    }

    clearingSession: boolean;
    logout() {
        // this.authService.logout();
        // this.destroySessionTimeout();
        // this.router.navigate([RouterConstants.LoginURL]);


        let self = this;
        this.clearingSession = true;

        this.authService.LogoutRequest(AuthService.FullyAuthenticatedUser.UserId
        ).subscribe(response => {
            this.clearingSession = false;
            self._logout();
            return;
        }),
            (error: any) => {
                self.clearingSession = false;
                self._logout();
            };
    }

    _logout() {
        this.authService.logout();
        this.destroySessionTimeout();
        this.router.navigate([RouterConstants.LoginURL]);
    }

    ClearLoginSession() {

        let self = this;
        this.clearingSession = true;

        self.authService.LogoutRequestPromise(AuthService.FullyAuthenticatedUser.UserId)
            .then(
                response => {
                    this.clearingSession = false;
                    self._logout();

                },
                error => {
                    self.clearingSession = false;
                    self._logout();
                }
            );
    }



    destroySessionTimeout() {

        if (VariablesService.SessionTimeoutInterval) {
            _window().clearInterval(VariablesService.SessionTimeoutInterval);
        }

        if (VariablesService.SessionTimedOutSub) {
            VariablesService.SessionTimedOutSub.unsubscribe();
        }

        if (VariablesService.SessionTimeoutEventId) {
            _window().clearTimeout(VariablesService.SessionTimeoutEventId);
        }

    }

    ngOnDestroy() {
        this.destroySessionTimeout();
    }
}
