import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from '../appconfig.service';
import { UnscheduledRepaymentGetBalance } from '../../models/unschedulepayment/uscheduledRepaymentgetBalance';
import { InitiateOtpRequestUnscheduledRepayment } from '../../models/unschedulepayment/InitiateOtpRequestUnscheduledRepayment';
import { InitiateOtpResponseUnscheduledRepayment } from '../../models/unschedulepayment/InitiateOtpResponseUnscheduledRepayment';
import { GetOutStandingTenure } from '../../models/unschedulepayment/unscheduledGetOuStandingTenure';
import { MakeUscheduledRepaymentRequest } from '../../models/unschedulepayment/makeUnscheduleRepaymentRequest';
import { UnscheduleRepaymentResponse } from '../../models/unschedulepayment/makeUscheduledRepaymentResponse';
// import { UnscheduledRepaymentGetBalance } from '../../models/unschedulepayment/uscheduledRepaymentgetBalance';
// import { GetOutStandingTenure } from '../../models/unschedulepayment/unscheduledGetOuStandingTenure';
// import { MakeUscheduledRepaymentRequest } from '../../models/unschedulepayment/makeUnscheduleRepaymentRequest';
// import { UnscheduleRepaymentResponse } from '../../models/unschedulepayment/makeUscheduledRepaymentResponse';
// import { InitiateOtpResponseUnscheduledRepayment } from '../../models/unschedulepayment/InitiateOtpResponseUnscheduledRepayment';
// import { InitiateOtpRequestUnscheduledRepayment } from '../../models/unschedulepayment/InitiateOtpRequestUnscheduledRepayment';

@Injectable()
export class UnscheduledRepaymentServiceService {

  private _apiBaseUrl: string;

  constructor(private _httpClient: HttpClient, private _settingsService: AppConfigService) {
    this._apiBaseUrl = this._settingsService.UnscheduledRepaymentBaseUrl;
  }
  // /api/unscheduled-payment/get-balance
  getUscheduledPaymentGetBalance(bvn: string): Observable<UnscheduledRepaymentGetBalance>{
    return this._httpClient.get<UnscheduledRepaymentGetBalance>(`${this._apiBaseUrl}/unscheduled-payment/get-balance?bvn=${bvn}`);
  }

  initiateOtpRequest(payload: InitiateOtpRequestUnscheduledRepayment): Observable<InitiateOtpResponseUnscheduledRepayment> {
    return this._httpClient.post<InitiateOtpResponseUnscheduledRepayment>(
        `${this._apiBaseUrl}/unscheduled-payment/send-otp`, payload);
}

  // /api/unscheduled-payment/get-outstanding-tenure
  getUscheduledPaymentGetOutstandingTenure(bvn: string): Observable<GetOutStandingTenure>{
    return this._httpClient.get<GetOutStandingTenure>(`${this._apiBaseUrl}/unscheduled-payment/get-outstanding-tenure?bvn=${bvn}`);
  }

  // /api/unscheduled-payment/make-unscheduled-payment
  makeUnscheduledPayment(payload: MakeUscheduledRepaymentRequest): Observable<UnscheduleRepaymentResponse>{
    return this._httpClient.post<UnscheduleRepaymentResponse>(`${this._apiBaseUrl}/unscheduled-payment/make-unscheduled-payment`,
     payload);
  }


}



