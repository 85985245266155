import { Component, OnInit, Input } from '@angular/core';
import { VirtualCardService } from "src/app/core/services/virtual-card.service";
import { AuthService } from 'src/app/core/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatSnackBar } from '@angular/material';
import { HotlistCardComponent } from './hotlist-card/hotlist-card.component';


@Component({
  selector: 'app-virtual-card-management',
  templateUrl: './virtual-card-management.component.html',
  styleUrls: ['./virtual-card-management.component.css']
})
export class VirtualCardManagementComponent implements OnInit {


  data: any
  cards: any
  @Input() show:  Boolean = false
  @Input() showId:  Number = 0
 loaderIsActive: Boolean = true


  constructor( private virtualCardService: VirtualCardService,
    private router: Router,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog
    ) { }


  fetchVirtualCardByCIFID(cif: any) {
    this.data =  this.virtualCardService.fetchCardByCif(cif).subscribe(
      (response) => {
        this.loaderIsActive = false;
        if (response.responseCode === "000") {

          this.cards = response.data.list
          this.loaderIsActive = false;

        } else {
          this.loaderIsActive = false;
          this._snackBar.open(response.responseDescription,
            'Error', { verticalPosition: 'bottom', horizontalPosition: 'right', duration: 1500 });
        }
      },
      (error) => {
        this.loaderIsActive = false
        this._snackBar.open(error,
          'Error', { verticalPosition: 'bottom', horizontalPosition: 'right', duration: 1500 });
      }
    );

  }

  showCardDetails(id: any){
    this.show= !this.show
    this.showId = id
  }

  resetPin(card:any, index: number) {
    this.virtualCardService.cardDetails.next({card, index});
    this.router.navigate(['retrieve-pin'], {relativeTo: this.route})

  }

  hotlistCard(card: any, index: number) {
    this.virtualCardService.cardDetails.next({card, index});
    // this.router.navigate(['hotlist-card'], {relativeTo: this.route})

    this.dialog.open(HotlistCardComponent, {
      backdropClass: 'backdropBackground',
      data: {card: card},
      disableClose: true,
    })
  }

  ngOnInit() {
    this.fetchVirtualCardByCIFID(AuthService.FullyAuthenticatedUser.CifId)

    // this.fetchVirtualCardByCIFID(AuthService.FullyAuthenticatedUser.CifId)

  }

}
