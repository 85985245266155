import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatLabel, MatTable } from "@angular/material";
import { AppMaterialModule } from "src/app/app.material.module";
import { InitateFxTransactionComponent } from "../manage-fx-transaction/initate-fx-transaction/initate-fx-transaction.component";
import { CancelFxRequestComponent } from "./cancel-fx-request/cancel-fx-request.component";
import { FxTransactionRequest } from "./fx-transaction-request";
import { FxTransactionRequestHistory } from "./fx-transaction-request-history/fx-transaction-request-history.component";
import { fxRequestRoutes } from "./fx-transactions-route";

@NgModule({
    declarations:[
        FxTransactionRequest,
       InitateFxTransactionComponent,
        FxTransactionRequestHistory,
        CancelFxRequestComponent
    ],
    imports:[CommonModule,fxRequestRoutes, 
                AppMaterialModule, 
              FormsModule,
                ReactiveFormsModule],
    exports: [ FxTransactionRequest ],
    entryComponents:[
        CancelFxRequestComponent
    ]
})
export class FxRequestModule{}