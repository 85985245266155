import { ResponseCodes, Role } from './Enumerations';




export class AppUser {
  LastLoginIn: string;
  CifId: string;
  FirstName: string;
  MiddleName: string;
  LastName: string;
  PinStatus: string;
  accountOfficerName: string;
  accountEmail: string;
  BVN?: string;
  BearerToken: string;
  SessionId: string;
  Reference: string;
  NotificationType: string;
  NotificationAddress: string;
  transactionId: string;
  QuestionDetailList?: any[];
  Token: string;
  UserId?: string;
  Role: UserRole;
  MenuProfile: string;


  LimitedAccess: boolean;
  LoginStatus: string;
  AuthPassForChange: string;


  YPSFlixDescription: string;
  OTPResponseDescription: string;
  OTPNotificationAddress: string;
  OTPNotificationType: string;
  Firstname: string;
  Lastname: string;
  AccountOfficerEmail: string;
  AccountOfficerName: string;
}

export interface UserRole {
  UserId: string;
  roles: Role[];
  menus?: Array<string>;
  menuProfile?: string;
}







export interface LoginRequestPayload {
  password: string;
  returnRMDetails: boolean;
  userId: string;
}

export interface AuthResponse {
  responseCode: ResponseCodes.SUCCESS;
  last_four_digit_of_customer_phone_no: string;
  lastLoginIn: Date;
  sessionId: string;
  cifId: string;
  oTPResponseDescription: string;
  responseDescription: string;
  responseFriendlyMessage: string;
  notificationType: string;
  notificationAddress: string;
}

export interface PasswordChangeRequestPayload {
  OldPassword: string;
  NewPassword: string;
  OTP: string;
  UserId: string;
  SourceRefId: string;
}

export interface PasswordChangeOtpRequestResponse {
  ResponseCode: string;
  ResponseDescription: string;
  ResponseFriendlyMessage: string;
}

export interface PasswordChangeOtpRequestPayload {
  UserId: string;
  CifId: string;
  ReasonCode: string;
}

export interface PasswordChangeOtpResponse {
  ResponseCode: string;
  ResponseDescription: string;
  ResponseFriendlyMessage: string;
  NotificationAddress: string;
  NotificationType: string;
}

export interface LoginValidationRequestPayload {
  UserId: string;
  Otp: string;
  Reference: string; // OTPResponseDescription from login response,
  CifId: string;
  ReasonCode: string; // login_reason_Code from reasoncodes ,
  sessionId: string; // user.SessionId
}

export interface CustomerSecret {
  question: string;
  answer: string;
}

export class SecurityQuestion {
  questionId?: string;
  questionID: string;
  questionDescription?: string;
  questionDESC: string;
  answer?: string;
  index?: number;
}


export class UserSecurityQuestion {
  questions: Array<SecurityQuestion>;
  userId: string;
  otp: string;
  requestID: string;
  customerId: string;
}


