import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { CardExpiryNotificationDialogData, CardExpiryConsent } from 'src/app/core/models/misc-requests-model';
import { RejectRequestComponent } from '../reject-request/reject-request.component';
import { MiscService } from 'src/app/core/services/misc.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { ResponseCodes } from 'src/app/core/models/Enumerations';

@Component({
  selector: 'app-card-expiry-notification',
  templateUrl: './card-expiry-notification.component.html',
  styleUrls: ['./card-expiry-notification.component.css']
})
export class CardExpiryNotificationComponent implements OnInit, OnDestroy {

  ownloaderIsActive: boolean;
  ownloaderMsg: string;
  confirmationCheckboxChecked: boolean;
  _expiryNotifications: Array<CardExpiryConsent> = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: CardExpiryNotificationDialogData,
      private dialogInstance: MatDialogRef<RejectRequestComponent>, private _snackbar: MatSnackBar, private _miscService: MiscService) {
        this._formatNotificationData();
        this._expiryNotifications = data.consents;

  }

  ngOnInit() {
    const _data = this.data.consents;
  }

  onCloseDialog() {
    this.dialogInstance.close('success');
  }

  private _formatNotificationData() {
    if (!this.data.consents || this.data.consents.length < 1) {
      return;
    }
    this.data.consents.forEach(consent => {
      consent.MaskedPAN = consent.MaskedPAN.length <= 4 ? consent.MaskedPAN : consent.MaskedPAN.substr(consent.MaskedPAN.length - 4);
      const expiryDate = consent.Expiry.toString().split('');
      if (expiryDate.length < 4) {
        return;
      }
      const mm = `${expiryDate[2]}${expiryDate[3]}`;
      const yy = `${expiryDate[0]}${expiryDate[1]}`;
      consent.Expiry = `${mm}/${yy}`;
    });
  }
  onConsentToRenew(notification: CardExpiryConsent, consent: string) {
    this.ownloaderIsActive = true;
    this.ownloaderMsg = 'initiating  card auto-renewal consent request .. please wait';
    this._miscService.consentToCardExpiryAutoRenewal(notification.AccountNumber, consent).subscribe(
      (response) => {
        this.ownloaderIsActive = false;
        this.ownloaderMsg = '';
        if (!response.ResponseCode || response.ResponseCode !== ResponseCodes.SUCCESS) {
          this._snackbar.open(`${response.ResponseFriendlyMessage} .. please retry after some time`);
          return;
        }
        const successful_renewal_index = this.data.consents.indexOf(notification);
        console.log(successful_renewal_index);
        if (successful_renewal_index > -1) {
          this.data.consents = this.data.consents.filter(c => c.MaskedPAN !== notification.MaskedPAN);
          this._expiryNotifications = this.data.consents;
        }
        this._snackbar.open(`Card renewal request sent successfully`, 'Ok', {duration: 25000});
        if (this._expiryNotifications.length < 1) {
          this.dialogInstance.close();
        }
      },
      (error) => {
        this.ownloaderIsActive = false;
        this.ownloaderMsg = '';
        this._snackbar.open(`We encountered an error while sending card auto-renewal consent request ..
        Please try after some time`, 'OK', {duration: 15000});
      }
    );
  }
  ngOnDestroy(): void {
    if (this.dialogInstance) {
      this.dialogInstance.close();
    }
  }
}
