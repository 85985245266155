



import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpResponse,
    HttpErrorResponse,
} from '@angular/common/http';

import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Constants } from '../helpers/appConstants';
import { RouterConstants } from '../helpers/routerConstants';
import { AuthService } from './auth.service';
import { StorageService } from './storage.service';


@Injectable()
export class AllResponseInterceptor implements HttpInterceptor {
    constructor(private router: Router, private storageService: StorageService, private authService: AuthService) { }

    intercept(incomingRequest: HttpRequest<any>, nextInterceptor: HttpHandler): Observable<HttpEvent<any>> {
        return nextInterceptor.handle(incomingRequest).
            pipe(
                tap((event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                        if (event.body && event.body.Token) {
                            if (AuthService.useLoginTokenForSessionTokenOnNextAPICall) {
                                this.storageService.saveRaw(Constants.StorageLoginTokenKey, event.body.Token);
                            }
                            else {
                                this.storageService.saveRaw(Constants.StorageTokenKey, event.body.Token);
                                this._UpdateToken(event.body);

                            }
                        }
                    }
                    return event;
                },

                    (err: any) => {
                        if (err instanceof HttpErrorResponse && err.status == Constants.UnauthorizedStatusCode) {
                            this.authService.logout();
                            this.router.navigate([RouterConstants.LoginURL]);
                        }
                    }
                )
            );
    }

    private _UpdateToken(responseData) {
        let userAuthData: any = sessionStorage.getItem(Constants.Auth_Data);
        userAuthData = userAuthData ? JSON.parse(userAuthData) : userAuthData;
        if (responseData && responseData['Token'] && userAuthData) {
            userAuthData['Token'] = responseData['Token'];
            sessionStorage.setItem(Constants.Auth_Data, JSON.stringify(userAuthData));
        }
    }
}
