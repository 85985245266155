import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { AppConfigService } from './appconfig.service';
import { HttpClient } from '@angular/common/http';
import {
    TransferHistoryRequestPayload, TransferHistoryRequestResponse,
    TransferTransactionDetailRequestResponse, SchedulePaymentRequestPayload, SchedulePaymentResponse,
    StopSchedulePaymentRequestPayload
} from '../models/transactions/transaction';
import { Constants } from '../helpers/appConstants';
import { ApiURLConstants } from '../helpers/apiURLConstants';
import { AuthService } from './auth.service';
import { AppUser } from '../models/auth';
import { UtilService } from './helpers.service';
import { GenericApiResponse } from '../models/payloads/generic-response';

@Injectable()
export class ReceiptService {

    private _apiBaseUrl: string;
    private _transactionsAPIBase: string;
    private _receiptsUrl: string;
    private _loggedInUser: AppUser;
    constructor(private _storageService: StorageService, _settings: AppConfigService, private _httpClient: HttpClient,
        private _authService: AuthService) {
        this._apiBaseUrl = _settings.APIBaseUrl;
        this._transactionsAPIBase = `${this._apiBaseUrl}/TransactionManagement`;
        this._receiptsUrl = `${this._transactionsAPIBase}/GetTransferHistoryForReceipt`;
        this._loggedInUser = _authService.getLoggedInUser();
    }


    getTransactionsHistoryForReceipts(payload: TransferHistoryRequestPayload): Promise<TransferHistoryRequestResponse> {


        // const requestPayload = this.buildTransactionsReceiptPayload(payload);
        return this._httpClient.post<TransferHistoryRequestResponse>(`${this._apiBaseUrl}/${ApiURLConstants.TRANSFER_HISTORY_RECEIPT}`, payload).toPromise();
    }

    getTransactionDetails(trnsRefId, paymentRefId, transactionType): Promise<TransferTransactionDetailRequestResponse> {
        const requestPayload = this.buildTransactionDetailRequestPayload(trnsRefId, paymentRefId, transactionType);
        return this._httpClient.post<TransferTransactionDetailRequestResponse>
            (`${this._transactionsAPIBase}/GetTransactionDetailsForReceipt`, requestPayload).toPromise();
    }


    getScheduledPayments(payload: SchedulePaymentRequestPayload): Promise<SchedulePaymentResponse> {
        return this._httpClient.post<SchedulePaymentResponse>(`${this._apiBaseUrl}/${ApiURLConstants.SCHEDULED_PAYMENTS}`, payload).toPromise();
    }




    stopScheduledPayment(payload: StopSchedulePaymentRequestPayload): Promise<GenericApiResponse> {
        return this._httpClient.post<GenericApiResponse>(`${this._apiBaseUrl}/${ApiURLConstants.STOP_SCHEDULE_TRANSACTION}`, payload).toPromise();
    }


    private buildTransactionsReceiptPayload(payload: TransferHistoryRequestPayload): any {


        return {
            EndDate: payload.EndDate,
            SessionId: UtilService.generateSessionId(),
            StartDate: payload.StartDate,
            UserId: this._loggedInUser && this._loggedInUser['UserId'] ? this._loggedInUser.UserId : '',
            transactionType: payload.transactionType
        };
    }

    private buildTransactionDetailRequestPayload(trnsRefId, paymentRefId, transactionType) {
        return {
            UserId: this._loggedInUser && this._loggedInUser['UserId'] ? this._loggedInUser.UserId : '',
            transactionType: transactionType,
            RequestId: trnsRefId,
            TransactionId: paymentRefId
        };
    }
}
