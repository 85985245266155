import { ShowBvnNumberComponent } from './shared/dialogs/show-bvn-number/show-bvn-number.component';
import { VerifyAccountUserComponent } from './shared/dialogs/verify-account-user/verify-account-user.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { MomentModule } from 'angular2-moment';

import { AppComponent } from './app.component';
import { DefaultLayoutComponent } from './pages/containers/default-layout/default-layout.component';
import { CommonModule, LocationStrategy, HashLocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app.routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppMaterialModule } from './app.material.module';
import { BeneficiaryDialogComponent } from './pages/dialogs/beneficiary/beneficiary.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CoreModule } from './core/core.module';
import { HttpRequestInterceptor } from './core/services/httpinterceptor.service';
import { AllResponseInterceptor } from './core/services/httpResponseInterceptors';
import { EventHandlerService } from './core/services/EventHandlerService';
import { SimpleNotificationsModule } from 'angular2-notifications';

import { SharedModule } from './shared/shared.module';
import { ApprovalsModule } from './pages/approvals/approvals.module';
import { ApprovalHistoryComponent } from './shared/approval-history/approval-history.component';
import { RejectRequestComponent } from './shared/dialogs/reject-request/reject-request.component';
import { TransactionsStatusModule } from './pages/transaction-status/transactionstatus.module';
import { CardExpiryNotificationComponent } from './shared/dialogs/card-expiry-notification/card-expiry-notification.component';
import { MultiWindowModule, WindowSaveStrategy } from 'ngx-multi-window'; // https://www.npmjs.com/package/ngx-multi-window
import { VariableSingletonService } from './core/services/variables.service';
import { SignalRService } from './core/services/signalr.service';
import { TenureModalComponent } from './pages/unscheduled-repayment/Tenure-Modal/tenure-modal.component';
import { TenureModalModule } from './pages/unscheduled-repayment/Tenure-Modal/tenure-modal.module';
import { FxRequestModule } from './pages/fx-transactions/fx-transactions.module';
import { InitateFxTransactionComponent } from './pages/manage-fx-transaction/initate-fx-transaction/initate-fx-transaction.component';
import { ManageFxTransactionComponent } from './pages/manage-fx-transaction/manage-fx-transaction.component';
import { ManageFxTransactionModule } from './pages/manage-fx-transaction/manage-fx-transaction.module';
import {VirtualCardModule} from './pages/virtual-card/virtual-card.module';
import { AcceptConditionModalModule } from './pages/DebitCardAutoRenewal/AcceptCondition-Modal/AcceptCondition-Modal.module';
// import {BentoModule} from './pages/bento/bento.module';
//import { FxRequestModule } from './pages/fx-transactions/fx-transactions.module';
import { CreditCardRequestService } from 'src/app/core/services/credit-card-request.service';


@NgModule({
  declarations: [
    AppComponent,
    DefaultLayoutComponent,
    BeneficiaryDialogComponent,
   // InitateFxTransactionComponent
    //ManageFxTransactionComponent
  ],
  imports: [
    BrowserModule,
    AppMaterialModule,
    AppRoutingModule,
    TenureModalModule,
    CoreModule,
    SharedModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AcceptConditionModalModule,
    MomentModule,
    VirtualCardModule,
    // BentoModule,
    MultiWindowModule.forRoot({
      keyPrefix: 'olbank.stanbicibtc_',
      heartbeat: 1000,
      newWindowScan: 5000,
      messageTimeout: 10000,
      windowTimeout: 15000,
      windowSaveStrategy: WindowSaveStrategy.NONE,
    }),
    NgIdleKeepaliveModule.forRoot(),
    SimpleNotificationsModule.forRoot({
      timeOut: 5000,
      icons: {
        'error': '<i class="ti ti-close fa-2x"></i>', alert: '', success: '<i class="ti ti-check fa-2x"></i>',
        info: '', warn: ''
      }
    }),

    FxRequestModule,
    ManageFxTransactionModule,
    ApprovalsModule,
    // ProfileModule,
    TransactionsStatusModule,

  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,

    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AllResponseInterceptor,
      multi: true,
    },
    EventHandlerService,
    VariableSingletonService,
    SignalRService,
    CreditCardRequestService
  ],
  bootstrap: [AppComponent],
  entryComponents: [ApprovalHistoryComponent, RejectRequestComponent, CardExpiryNotificationComponent,VerifyAccountUserComponent,ShowBvnNumberComponent,TenureModalComponent]
})
export class AppModule { }
