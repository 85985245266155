import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SssBankingTagRequestPayload } from '../models/sss-banking.model';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './appconfig.service';
import { AuthService } from './auth.service';
import { ApiURLConstants } from '../helpers/apiURLConstants';
import { GenericApiResponse } from '../models/payloads/generic-response';
import { BeneficiaryListRequestResponse } from '../models/payloads/beneficiaries';

@Injectable()
export class SssBankingTagService {

    private _apiBaseUrl: string;

    constructor(private _httpClient: HttpClient, _settings: AppConfigService, private _authService: AuthService) {
        this._apiBaseUrl = _settings.APIBaseUrl;
    }

    saveTag(payload: SssBankingTagRequestPayload):Observable<GenericApiResponse> {
        const requestPayload = this.updateRequestPayload(payload);
        return this._httpClient.post<GenericApiResponse>(
            `${this._apiBaseUrl}/${ApiURLConstants.MANAGE_SSS_BANKING_COMMAND}`, requestPayload);
    }

    doEnquiry(tagName: string, phoneNumber: string): Observable<any> {
        let requestPayload = {phoneNumber: phoneNumber, nickName: tagName};
        requestPayload = this.updateRequestPayload(requestPayload);
        return this._httpClient.post<any>(`${this._apiBaseUrl}/${ApiURLConstants.SSS_BANKING_NAME_ENQUIRY}`, requestPayload);
    }

    manageSSSBankingCommand(payload): Observable<GenericApiResponse> {
        payload = this.updateRequestPayload(payload);
        return this._httpClient.post<any>(`${this._apiBaseUrl}/${ApiURLConstants.MANAGE_SSS_BANKING_COMMAND}`, payload);
    }

    getSSSBankingTags(phoneNumber: string, transactionType: string): Observable<BeneficiaryListRequestResponse> {
        const payload = this.updateRequestPayload({phoneNumber: phoneNumber, transactionType: transactionType});
        return this._httpClient.post<BeneficiaryListRequestResponse>(
            `${this._apiBaseUrl}/${ApiURLConstants.GET_SSS_BANKING_COMMANDS}`, payload);
    }

    private updateRequestPayload(payload: any) {
        const loggedInUser = this._authService.getLoggedInUser();
        payload['cifId'] = loggedInUser && loggedInUser.CifId ? loggedInUser.CifId : '';
        payload['userId'] = loggedInUser && loggedInUser.UserId ? loggedInUser.UserId : '';
        return payload;
    }
}
