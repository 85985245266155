import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../appconfig.service';
import { AuthService } from '../auth.service';
import { ApiURLConstants } from '../../helpers/apiURLConstants';
import { GenericApiResponse } from '../../models/payloads/generic-response';
import { RequestApprovalHistoryResponse } from '../../models/approvals/payment.approval';

@Injectable()
export class ApprovalHistoryFactory {

    private _apiBaseUrl: string;
    constructor(private _httpClient: HttpClient, _settings: AppConfigService, private _authService: AuthService) {
        this._apiBaseUrl = _settings.APIBaseUrl;
    }

    getApprovalHistory(operationName: string, requestPayload: any): Promise<RequestApprovalHistoryResponse> {
        const requestUrl = this.getApprovalHistoryEndpoint(operationName);
        requestPayload = this.updateRequestPayloadWithUserId(requestPayload);
        return this._httpClient.post<RequestApprovalHistoryResponse>(`${this._apiBaseUrl}/${requestUrl}`, requestPayload)
            .toPromise();
    }

    rejectApprovalRequest(payload: any): Promise<GenericApiResponse> {
        payload = this.updateRequestPayloadWithUserId(payload);
        return this._httpClient.post<GenericApiResponse>(`${this._apiBaseUrl}/${ApiURLConstants.REJECT_APPROVAL_REQUEST}`, payload)
            .toPromise();
    }

    private getApprovalHistoryEndpoint(operationName: string): string {
        switch (operationName) {
             case 'biller-payments':
                 return `${ApiURLConstants.GET_BILLER_PAYMENT_REQUEST_APPROVAL_HISTORY}`;
             case 'biller-management':
                 return `${ApiURLConstants.GET_BILLER_OPERATION_REQUEST_APPROVAL_HISTORY}`;
             case 'beneficiary-management-add':
                 return `${ApiURLConstants.GET_BENEFICIARY_ADD_APPROVAL_HISTORY}`;
            case 'beneficiary-management-update':
                 return `${ApiURLConstants.GET_BENEFICIARY_UPDATE_APPROVAL_HISTORY}`;
             case 'service-request':
                 return `${ApiURLConstants.GET_SERVICEREQUEST_APPROVAL_HISTORY}`;
             case 'western-union':
                 return `${ApiURLConstants.GET_WESTERN_UNION_REDEMPTION_APPROVAL_HISTORY}`;
             case 'single-transfer':
                 return `${ApiURLConstants.GET_SINGLE_TRANSFER_APPROVAL_HISTORY}`;
             case 'bulk-transfer':
                 return `${ApiURLConstants.GET_BULK_TRANSFER_APPROVAL_HISTORY}`;
             case 'international-transfer':
                 return `${ApiURLConstants.GET_INTL_TRANSFER_APPROVAL_HISTORY}`;
            default: return '';
        }
    }

    private updateRequestPayloadWithUserId(payload) {
        const loggedInUser = this._authService.getLoggedInUser();
        payload['UserId'] = loggedInUser && loggedInUser.UserId ? loggedInUser.UserId : '';
        return payload;
    }

}
