import { Component, OnInit } from '@angular/core';
import { AppUser } from 'src/app/core/models/auth';
import { AuthService } from 'src/app/core/services/auth.service';
import { Role } from 'src/app/core/models/user/AppUser';

export class BaseApprovalComponent  {

    public _loggedInUser: AppUser;
    public _loggedInUserIsApprover: boolean;
    public _loggedInUserIsInitiator: boolean;
    public pageTitle: string;
    constructor( public _authService: AuthService) {

      this._loggedInUser = _authService.getLoggedInUser();
      this._loggedInUserIsApprover = _authService.loggedInUserHasSMEProfile() && this._loggedInUser.Role.roles.includes(Role.Approver);
      this._loggedInUserIsInitiator = _authService.loggedInUserHasSMEProfile() &&
                                        this._loggedInUser.Role.roles.includes(Role.Initiator);
     }
}
