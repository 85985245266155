import { Component, OnInit } from "@angular/core";
import { EventHandlerService } from "src/app/core/services/EventHandlerService";
import { UtilService } from "src/app/core/services/helpers.service";

@Component({
  selector: "app-transaction-status",
  templateUrl: "./transaction-status.component.html",
  styleUrls: ["./transaction-status.component.css"],
})
export class TransactionStatusComponent implements OnInit {
  public _activeTab: string;
  // _requestApprovalHistory: Array<RequestApprovalStage> = [];
  loaderIsActive: boolean;
  loaderMsg: string;

  pendingRequestList: Array<any>;
  pendingRequestListBackup: Array<any>;

  constructor() {
    UtilService.onPageLoadCallback();
  }

  ngOnInit() {
    EventHandlerService.emitOnAppViewChangedEvent();
    if (!this._activeTab) {
      this._activeTab = "fileuploads";
    }
    this.openTab(null, this._activeTab);
  }

  openTab(evt, tabName) {
    this._activeTab = tabName;
  }

  buildRequestHistoryPayload(requestType: string) {
    switch (requestType) {
    }
  }
}
