import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApprovalRule, ApprovalStage, SinglePaymentWorkflowSelectionRequestPayload } from 'src/app/core/models/payloads/transactions';
import { TransferStagingService } from 'src/app/core/services/staging/transfer.staging.service';
import { MatSnackBar, MatStepper } from '@angular/material';
import { ResponseCodes } from 'src/app/core/models/Enumerations';
import { BeneficiaryStagingService } from 'src/app/core/services/staging/beneficiary.staging.service';
import { RequestStagingService } from 'src/app/core/services/staging/service-request.staging.service';
import { BillerStagingService } from 'src/app/core/services/staging/biller.staging.service';
import { PaymentStagingService } from 'src/app/core/services/staging/payment.staging.service';

@Component({
  selector: 'app-workflow',
  templateUrl: './workflow.component.html',
  styleUrls: ['./workflow.component.css']
})
export class WorkflowComponent implements OnInit {

  @Input() approvalWorkFlowRules: Array<ApprovalRule> = [];
  @Input() metaData: any;
  @Input() transferData: any;

  @Output() selectedWorkflowSaved: EventEmitter<any> = new EventEmitter();
  workFlowPanelOpenState: boolean;
  isSubmittingApprovalRule: boolean;

  constructor(private _transferStagingService: TransferStagingService, private _snackBar: MatSnackBar,
    private _beneficiaryStagingService: BeneficiaryStagingService, private _requestStagingService: RequestStagingService,
    private _billerMgtStagingService: BillerStagingService, private _paymentStagingService: PaymentStagingService
    ) { }

  ngOnInit() {
  }

  onApprovaStageSelected(evtData, workFlowRule: ApprovalRule, ruleIndex: number, stageIndex: number) {
    workFlowRule.ConflictedApprovalStages.forEach(stage => stage['checked'] = false);
    workFlowRule.ConflictedApprovalStages[stageIndex]['checked'] = evtData.checked;
    this.approvalWorkFlowRules[ruleIndex] = workFlowRule;
  }

  onAprovalFlowSelected(workflowRule: ApprovalRule, workflowIndex: number, stepper: MatStepper) {
    let selectedApprovalStage: ApprovalStage;
    if (workflowRule.ConflictedApprovalStages.length > 0) {
      selectedApprovalStage = workflowRule.ConflictedApprovalStages.find(selectedStage => selectedStage['checked']);
    }
    if (workflowRule.ConflictedApprovalStages.length > 0 && !selectedApprovalStage) {
      this._snackBar.open(`Please select an approval stage.`, 'Ok');
      return;
    }
    const requestPayload = this.buildWorkflowRuleSelectionRequestPayload(
                                  [workflowRule], selectedApprovalStage, this.metaData.serviceOperationType);
    this.isSubmittingApprovalRule = true;
    const workflowRequest = this.getWorkflowRequestObject(this.metaData.serviceOperationType, requestPayload);
    workflowRequest.then((response) => {
        console.log(response);
        this.isSubmittingApprovalRule = false;
        if (!response || response.ResponseCode !== ResponseCodes.SUCCESS) {
          this._snackBar.open(`Oops ${response.ResponseFriendlyMessage}`, 'Ok', {
            verticalPosition: 'top', horizontalPosition: 'right', duration: 25000
          });
          return;
        }
        this._snackBar.open(`${response.ResponseFriendlyMessage}`, 'Ok', {
      duration: 15000});
      this.selectedWorkflowSaved.emit(response);
      },
      (error) => {
        console.log(error);
        this.isSubmittingApprovalRule = false;
        this._snackBar.open(`Oops . We encountered a technical error while processing request .. Please retry after some time`,
         'Try Again', {duration: 25000});
      }
    );

  }

  getMinRequiredApprovers(workflowRule: ApprovalRule) {
    let _minApproversCount = 0;
    if (workflowRule.ConflictedApprovalStages && workflowRule.ConflictedApprovalStages.length > 0) {
      let _min = workflowRule.ConflictedApprovalStages[0].NumOfRequiredApprovers;
      for (let i = 0 ; i < workflowRule.ConflictedApprovalStages.length; i++) {
        const n = workflowRule.ConflictedApprovalStages[i].NumOfRequiredApprovers;
        if (_min > n) {
          _min = n;
        }
      }
      _minApproversCount += _min;
    }
    workflowRule.OtherApprovalStages.forEach(stage => _minApproversCount = _minApproversCount + stage.NumOfRequiredApprovers);
    return _minApproversCount;
  }

  private getWorkflowRequestObject(operationName: string, payload: any): Promise<any> {
    switch (operationName) {
      case 'INTERNATIONAL_TRANSFER':
        return this._transferStagingService.setInternationalTransferApprovalWorkflow(payload);
      case 'ONE_OFF_PAYMENT':
      case 'SELF_TRANSFER':
        return this._transferStagingService.setSingleTransferApprovalWorkflow(payload);
      case 'BENEFICIARY_CREATE':
        return this._beneficiaryStagingService.setBeneficiaryCreateApproval(payload);
      case 'EDIT_BENEFICIARY':
      case 'REMOVE_BENEFICIARY':
          return this._beneficiaryStagingService.setBeneficiaryMgtOperationApprovalWorkflow(payload);
      case 'CARD_REQUEST':
      case 'CHEQUEBOOK_REQUEST':
      case 'MAKE_ENQUIRY':
      case 'MODIFY_ACCOUNT':
      case 'FREEZE_ACCOUNT':
      case 'STOP_CHEQUE':
        return this._requestStagingService.setServiceRequestApprovalWorkflow(payload);
      case 'ADD_BILLER_BENEFICIARY':
      case 'EDIT_BILLER_BENEFICIARY':
      case 'REMOVE_BILLER_BENEFICIARY':
        return this._billerMgtStagingService.setBillerOperationApprovalWorkflow(payload);
      case 'BILLER_PAYMENT':
      case 'REMITA_PAYMENT':
        return this._paymentStagingService.setBillPaymentRequestApprovalWorkflow(payload);
      case 'WESTERN_UNION_REDEMPTION':
        return this._transferStagingService.setWesternUnionRedemptionApprovalWorkflow(payload);
      case 'BENEFICIARY_TRANSFER':
        return this._transferStagingService.setMultipleTransferApprovalWorkflow(payload);
    }
  }

  private buildWorkflowRuleSelectionRequestPayload(rule: Array<ApprovalRule>, selectedApprovalStage: ApprovalStage, operationName: string):
  SinglePaymentWorkflowSelectionRequestPayload | any {
    const selectedRule: Array<ApprovalRule> = rule.copyWithin(rule.length, 0);
    selectedRule[0].ConflictedApprovalStages = selectedApprovalStage ? [selectedApprovalStage] : [];
    switch (operationName) {
      case 'INTERNATIONAL_TRANSFER':
      case 'ONE_OFF_PAYMENT':
      case 'SELF_TRANSFER':
        {
          return  {
            Rules: selectedRule,
            transfer: this.transferData
          };
        }
      case 'BENEFICIARY_CREATE':
        return {
          Rules: selectedRule,
          Beneficiary: this.transferData
        };
      case 'EDIT_BENEFICIARY':
      case 'REMOVE_BENEFICIARY':
        return  {
          Rules: selectedRule,
          Beneficiary: this.transferData,
          OperationType: operationName,
        };
        case 'CARD_REQUEST':
        case 'CHEQUEBOOK_REQUEST':
        case 'MAKE_ENQUIRY':
        case 'MODIFY_ACCOUNT':
        case 'FREEZE_ACCOUNT':
        case 'STOP_CHEQUE':
        case 'ADD_BILLER_BENEFICIARY':
        case 'EDIT_BILLER_BENEFICIARY':
        case 'REMOVE_BILLER_BENEFICIARY':
        case 'BILLER_PAYMENT':
        case 'REMITA_PAYMENT':
        case 'WESTERN_UNION_REDEMPTION':
          return {
            Rules: selectedRule,
            Operation: this.transferData
          };
        case 'BENEFICIARY_TRANSFER':
          return {
            Rules: selectedRule,
            OperationType: operationName,
            TransferRequests: this.transferData
          };
      default: {
        throw new Error(`Invalid workflow operation type. Ensure valid operation service type is passed on component meta property`);
      }
    }
  }

}
