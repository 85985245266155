import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../appconfig.service';
import { BillerOperationWorkflowSelectionPayload } from '../../models/payloads/beneficiaries';
import { GenericApiResponse } from '../../models/payloads/generic-response';
import { ApiURLConstants } from '../../helpers/apiURLConstants';
import { AuthService } from '../auth.service';
import { StagedBillerOperationRequestReponse } from '../../models/beneficiary';
import { Role } from '../../models/user/AppUser';

@Injectable()
export class BillerStagingService {
    private _apiBaseUrl: string;
    constructor(private _httpClient: HttpClient, private _settings: AppConfigService, private _authService: AuthService) {
        this._apiBaseUrl = _settings.APIBaseUrl;
    }

    setBillerOperationApprovalWorkflow(payload: BillerOperationWorkflowSelectionPayload): Promise<GenericApiResponse> {
        payload = this.updateApprovalWorkflowRequestPayload(payload);
        return this._httpClient.post<GenericApiResponse>(
            `${this._apiBaseUrl}/${ApiURLConstants.SET_BILLER_OPERATION_APPROVAL_WORKFLOW}`, payload)
            .toPromise();
    }

    getStagedBillerOperationRequests(payload): Promise<StagedBillerOperationRequestReponse> {
        payload = this.updateApprovalWorkflowRequestPayload(payload);
        const loggedInUser = this._authService.getLoggedInUser();
        if (loggedInUser && loggedInUser.Role && loggedInUser.Role.roles.includes(Role.Initiator)) {
            payload['ForInitiator'] = true;
        }

        return this._httpClient.post<StagedBillerOperationRequestReponse>(
            `${this._apiBaseUrl}/${ApiURLConstants.GET_STAGED_BILLER_OPERATIONS_REQUEST}`, payload
        ).toPromise();
    }
    private updateApprovalWorkflowRequestPayload(payload: any): any {
        payload.UserId = AuthService.FullyAuthenticatedUser && AuthService.FullyAuthenticatedUser.UserId ?
            AuthService.FullyAuthenticatedUser.UserId : '';
        return payload;
    }
}
