import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './appconfig.service';
import { StorageService } from './storage.service';
import {
    OneOffPaymentTransferRequestPayload, OneOffPaymentTransferRequestResponse, RemitaPaymentDetails, RRRLookupResponse
} from '../models/transfer';

import {
    InternationalTransferRequestPayload, SMETransferResponsePayload, ServiceOperatorResponse,
    ServiceProviderProductResponse, AirtimeTopupRequestPayload, CurrencyConversionRequestPayload, CurrencyConversionResponse
} from '../models/payloads/transactions';


import { ApiURLConstants } from '../helpers/apiURLConstants';
import { AuthService } from './auth.service';

import { GenericApiResponse } from '../models/payloads/generic-response';
import {
    TransferRequestPayload, TransferResponsePayload, NameEnquiryRequestPayload,
    InitiateEbillsRequestPayload, NameEnquiryRequestResponse, BillPaymentRequestPayload, WesternUnionRedemptionRequestPayload
} from '../models/payloads/transactions';

import {
    BeneficiaryTransferRequestPayload, BeneficiaryTransferResponse,
    SMEBeneficiaryTranferResponse
} from 'src/app/core/models/payloads/beneficiaries';
import { _workflowRules } from '../models/mocks/_workflow.mock';
import { Observable, of, ObservableInput } from '../../../../node_modules/rxjs';
import {environment} from '../../../environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable()
export class TransferService {

    private airtimeBaseURL = environment.airtimeBaseURL;
    private _apiBaseUrl: string;
    private _apiBaseUrl2: string;
    private _transactionsAPIBase: string;
    constructor(private _httpClient: HttpClient, private _settings: AppConfigService, private _storageService: StorageService) {
        this._apiBaseUrl = _settings.APIBaseUrl;
        this._apiBaseUrl2 = _settings.APIBaseUrl2;
        this._transactionsAPIBase = `${this._apiBaseUrl}/`;
    }



    checkIfTransferIsAllowedInTiming(): Promise<GenericApiResponse> {
        return this._httpClient.post<GenericApiResponse>(`${this._apiBaseUrl}/${ApiURLConstants.CHECK_CROSS_CURRENCY_TRANSFER_TIMING}`,
            { UserId: AuthService.FullyAuthenticatedUser.UserId },
        ).toPromise();
    }



    doTransfer(payload: TransferRequestPayload): Promise<TransferResponsePayload> {
        https://mobile.stanbicibtc.com/api/TransactionManagement/Transfer
        return this._httpClient.post<TransferResponsePayload>(`${this._apiBaseUrl}/${ApiURLConstants.TRANSFER}`, payload
        ).toPromise();

        // return this._httpClient.post<TransferResponsePayload>(`https://mobile.stanbicibtc.com/api/TransactionManagement/Transfer`, payload
        // ).toPromise();
    }

    doIntellinxTransfer(payload: TransferRequestPayload): Promise<TransferResponsePayload> {
        return this._httpClient.post<TransferResponsePayload>(`${this._apiBaseUrl}/${ApiURLConstants.ValidateIntellinxTransfer}`, payload
        ).toPromise();
    }

    doWesternUnionRedemption(payload: WesternUnionRedemptionRequestPayload): Promise<TransferResponsePayload> {
        return this._httpClient.post<TransferResponsePayload>(`${this._apiBaseUrl}/${ApiURLConstants.WESTERN_UNION_REDEMPTION}`, payload
        ).toPromise();
    }


    doAirtimeTopup(payload: any): Promise<TransferResponsePayload> {
        return this._httpClient.post<TransferResponsePayload>(`${this.airtimeBaseURL}Aggregator/GiveValue`, payload
        ).toPromise();
    }




    initiateTransfer(payload: TransferRequestPayload): Promise<SMETransferResponsePayload> {
        if (this._settings.UseMockData) {
            return new Promise((r) => {
                r({
                    amount: 0,
                    transactionId: 'HDFYFGFUYF',
                    ResponseCode: '70',
                    ResponseFriendlyMessage: '',
                    BatchId: '1',
                    SelectionType: 'RULE',
                    Rules: _workflowRules,
                    ResponseDescription: ''

                });
            });
        }
        return this._httpClient.post<SMETransferResponsePayload>(`${this._apiBaseUrl}/${ApiURLConstants.INITIATE_SINGLE_TRANSFER}`, payload
        ).toPromise();
    }

    initiateInternationalTransfer(payload: InternationalTransferRequestPayload): Promise<SMETransferResponsePayload> {
        if (this._settings.UseMockData) {
            return new Promise((r) => {
                r({
                    amount: 0,
                    transactionId: 'HDFYFGFUYF',
                    ResponseCode: '70',
                    ResponseFriendlyMessage: '',
                    ResponseDescription: '',
                    BatchId: '1',
                    SelectionType: 'RULE',
                    Rules: _workflowRules
                }
                );
            });
        }
        return this._httpClient.post<SMETransferResponsePayload>(
            `${this._apiBaseUrl}/${ApiURLConstants.INITIATE_INTERNATIONAL_TRANSFER}`, payload
        ).toPromise();
    }

    initiateWesternUnionRedemption(payload: WesternUnionRedemptionRequestPayload): Promise<SMETransferResponsePayload> {
        return this._httpClient.post<SMETransferResponsePayload>(
            `${this._apiBaseUrl}/${ApiURLConstants.INITIATE_WESTERN_UNION_REDEMPTION}`, payload).toPromise();
    }

    FetchCurrencyConversionRate(payload: CurrencyConversionRequestPayload): Observable<CurrencyConversionResponse> {

        return this._httpClient.post<CurrencyConversionResponse>(
            `${this._apiBaseUrl}/${ApiURLConstants.RateConversion}`, payload
        );
    }



    doNameEnquiry(payload: NameEnquiryRequestPayload): Observable<NameEnquiryRequestResponse> {



        if (this._settings.UseMockData) {

            return of({
                NotificationAddress: null,
                NotificationType: null,
                QuestionDetailList: null,
                Reference: null,
                ResponseCode: "00",
                ResponseDescription: "Request processed successfully",
                ResponseFriendlyMessage: "Request processed successfully",
                SessionId: null,
                Token: "IWB+ipyKXg/QZO+cRHmytF6lTDuAq6fSFgS8Nva9fXdoJdisVtE8s0mfyYu25A55ZKXpG6uNRFEpkLZ4+yfwHh920WeJlJX3lGELWfNsfnqBDenYFe+BL/hDWX+Rp4Ys",
                destinationAccountName: "OLADELE OLUWASEUN POPOOLA",
                transactionId: null
            })
        }
        return this._httpClient.post<NameEnquiryRequestResponse>(
            `${this._apiBaseUrl}/${ApiURLConstants.NameEnquiry}`, payload
        );
    }



    doVBNameEnquiry(payload: NameEnquiryRequestPayload): Observable<NameEnquiryRequestResponse> {
        return this._httpClient.post<NameEnquiryRequestResponse>(
            `${this._apiBaseUrl}/${ApiURLConstants.VBNameEnquiry}`, payload
        );
    }

    //DoVBNameEnquiry

    doRemitaNameEnquiry(payload: NameEnquiryRequestPayload, mockRRRDetails: boolean = true): Promise<NameEnquiryRequestResponse> {


        if (this._settings.UseMockData || mockRRRDetails) {
            return new Promise((resolve) => {
                resolve({
                    destinationAccountName: `$123##456##789##101##121##${payload.destinationAccountNo}`,
                    ResponseCode: '00',
                    ResponseDescription: 'GFSFSESCS109',
                    ResponseFriendlyMessage: 'sucess. One time passcode sent to ********40',
                    Reference: '000000000',
                    NotificationType: 'SMS',
                    NotificationAddress: '********40',
                    transactionId: '108745766',
                    QuestionDetailList: null
                });
            });
        }
        return this._httpClient.post<NameEnquiryRequestResponse>(
            `${this._apiBaseUrl}/${ApiURLConstants.NameEnquiry}`, payload
        ).toPromise();
    }

    doRemitaLookup(userId: string, rrr: string): Observable<RRRLookupResponse> {

        return this._httpClient.post<RRRLookupResponse>(
            `${this._apiBaseUrl}/${ApiURLConstants.RemitaLookup}`, { UserId: userId, RRR: rrr }
        );
    }







    makeInternationalTransfer(payload: InternationalTransferRequestPayload): Promise<GenericApiResponse> {

        return this._httpClient.post<GenericApiResponse>(`${this._apiBaseUrl}/${ApiURLConstants.INTERNATIONAL_TRANSFER}`,
            payload).toPromise();
    }


    makeMultipleBeneficiaryTransfer(payload: BeneficiaryTransferRequestPayload): Observable<BeneficiaryTransferResponse> {
        return this._httpClient.post<BeneficiaryTransferResponse>(
            `${this._apiBaseUrl}/${ApiURLConstants.MULTIPLE_BENEFICIARY_TRANSFER}`, payload);
    }

    initiateMultipleBeneficiaryTransfer(payload: BeneficiaryTransferRequestPayload): Observable<SMEBeneficiaryTranferResponse> {
        return this._httpClient.post<SMEBeneficiaryTranferResponse>(
            `${this._apiBaseUrl}/${ApiURLConstants.INITIATE_MULTIPLE_BENEFICIARY_TRANSFER}`, payload);
    }

    doBillPayment(payload: BillPaymentRequestPayload): Promise<TransferResponsePayload> {
        return this._httpClient.post<TransferResponsePayload>(`${this._apiBaseUrl}/${ApiURLConstants.BILLPAYMENT}`, payload
        ).toPromise();
    }

    getBillCharges(): Observable<any> {
        // Assuming you have the required body for the post request
        const body = {
          // Add properties required for the request body
        };
        return this._httpClient.post<any>(`${this._apiBaseUrl}/${ApiURLConstants.BILLCHARGES}`, body)
          .pipe(
            catchError(error => {
              // Handle errors here if necessary
              console.error('Error fetching bill charges:', error);
              throw error; // Rethrow the error to propagate it downstream
            })
          );
      }

    initiateEbillsPayment(payload: InitiateEbillsRequestPayload): Promise<GenericApiResponse> {

        // return this._httpClient.post<GenericApiResponse>(`${AppConfigService.EbillsApiURL}/${ApiURLConstants.INITIATE_EBILLS_PAYMENT}`, payload
        // ).toPromise();
        return this._httpClient.post<GenericApiResponse>(`${this._apiBaseUrl}/${ApiURLConstants.INITIATE_EBILLS_PAYMENT}`, payload
        ).toPromise();
    }



    makeOneOffTransfer(payload: OneOffPaymentTransferRequestPayload): Promise<OneOffPaymentTransferRequestResponse> {
        const requestPayload = this.buildOneOffTransferPayload(payload);
        return this._httpClient.post<OneOffPaymentTransferRequestResponse>(`${this._transactionsAPIBase}/Transfer`, requestPayload
        ).toPromise();
    }

    private buildOneOffTransferPayload(payload: OneOffPaymentTransferRequestPayload): OneOffPaymentTransferRequestPayload {
        const userAuthData = AuthService.FullyAuthenticatedUser;
        payload.sessionId = userAuthData && userAuthData['SessionId'] ? userAuthData['SessionId'] : '';
        payload.userId = userAuthData && userAuthData.UserId ? userAuthData.UserId : '';
        return payload;
    }








    getServiceOperators(): Promise<ServiceOperatorResponse> {
        return new Promise((resolve) => {
            resolve({
                ResponseCode: '00',
                Operators: [
                    {
                        operatorId: '_9MOBILE',
                        operatorName: "9mobile Recharge (E-Top Up)"
                    },
                    {
                        operatorId: 'AIRTEL',
                        operatorName: "Airtel Mobile Top-up (Prepaid)"
                    },

                    {
                        operatorId: 'GLO',
                        operatorName: "Glo QuickCharge (Top-up)"
                    },

                    {
                        operatorId: 'MTN',
                        operatorName: "MTN Direct Top-up (Prepaid)"
                    }
                ]
            });
        });
    }

    getServiceOperatorsProducts(): Promise<ServiceProviderProductResponse> {
        return new Promise((resolve) => {
            resolve({
                ResponseCode: '00',
                Products: [
                    {
                        operatorId: 'MTN',
                        productId: 1,
                        productCode: "51617",
                        productName: "MTN VTU 50",
                        isAmountFixed: 'Y',
                        amount: 50
                    },
                    {
                        operatorId: 'MTN',
                        productId: 2,
                        productCode: "51617",
                        productName: "MTN VTU 100",
                        paymentCurrency: "NGN",
                        isAmountFixed: 'Y',
                        amount: 100
                    },
                    {
                        operatorId: 'MTN',
                        productId: 3,
                        productCode: "51617",
                        productName: "MTN VTU 200",
                        paymentCurrency: "NGN",
                        isAmountFixed: 'Y',
                        amount: 200
                    },
                    {
                        operatorId: 'MTN',
                        productId: 4,
                        productCode: "51617",
                        productName: "MTN VTU 400",
                        paymentCurrency: "NGN",
                        isAmountFixed: 'Y',
                        amount: 400
                    },
                    {
                        operatorId: 'MTN',
                        productId: 5,
                        productCode: "51617",
                        productName: "MTN VTU 750",
                        paymentCurrency: "NGN",
                        isAmountFixed: 'Y',
                        amount: 750
                    },

                    {
                        operatorId: 'MTN',
                        productId: 6,
                        productCode: "51617",
                        productName: "MTN VTU 1500",
                        paymentCurrency: "NGN",
                        isAmountFixed: 'Y',
                        amount: 1500
                    },

                    {
                        operatorId: 'MTN',
                        productId: 7,
                        productCode: "51617",
                        productName: "MTN VTU 3000",
                        paymentCurrency: "NGN",
                        isAmountFixed: 'Y',
                        amount: 3000
                    },

                    {
                        operatorId: 'AIRTEL',
                        productId: 1,
                        productCode: "51617",
                        productName: "Airtel MTU 50",
                        paymentCurrency: "NGN",
                        isAmountFixed: 'Y',
                        amount: 50
                    },

                    {
                        operatorId: 'AIRTEL',
                        productId: 2,
                        productCode: "51617",
                        productName: "Airtel MTU 100",
                        paymentCurrency: "NGN",
                        isAmountFixed: 'Y',
                        amount: 100
                    },

                    {
                        operatorId: 'AIRTEL',
                        productId: 3,
                        productCode: "51617",
                        productName: "Airtel MTU 200",
                        paymentCurrency: "NGN",
                        isAmountFixed: 'Y',
                        amount: 200
                    },

                    {
                        operatorId: 'AIRTEL',
                        productId: 4,
                        productCode: "51617",
                        productName: "Airtel MTU 500",
                        paymentCurrency: "NGN",
                        isAmountFixed: 'Y',
                        amount: 500
                    },

                    {
                        operatorId: 'AIRTEL',
                        productId: 5,
                        productCode: "51617",
                        productName: "Airtel MTU 1000",
                        paymentCurrency: "NGN",
                        isAmountFixed: 'Y',
                        amount: 1000
                    },

                    {
                        operatorId: '_9MOBILE',
                        productId: 1,
                        productCode: "51617",
                        productName: "9mobile 50",
                        paymentCurrency: "NGN",
                        isAmountFixed: 'Y',
                        amount: 50
                    },

                    {
                        operatorId: '_9MOBILE',
                        productId: 2,
                        productCode: "51617",
                        productName: "9mobile 100",
                        paymentCurrency: "NGN",
                        isAmountFixed: 'Y',
                        amount: 100
                    },
                    {
                        operatorId: '_9MOBILE',
                        productId: 3,
                        productCode: "51617",
                        productName: "9mobile 200",
                        paymentCurrency: "NGN",
                        isAmountFixed: 'Y',
                        amount: 200
                    },

                    {
                        operatorId: '_9MOBILE',
                        productId: 4,
                        productCode: "51617",
                        productName: "9mobile 500",
                        paymentCurrency: "NGN",
                        isAmountFixed: 'Y',
                        amount: 500
                    },

                    {
                        operatorId: '_9MOBILE',
                        productId: 5,
                        productCode: "51617",
                        productName: "9mobile 1000",
                        paymentCurrency: "NGN",
                        isAmountFixed: 'Y',
                        amount: 1000
                    },

                    {
                        operatorId: '_GLO',
                        productId: 1,
                        productCode: "51617",
                        productName: "GLO 100",
                        paymentCurrency: "NGN",
                        isAmountFixed: 'Y',
                        amount: 100
                    },
                    {
                        operatorId: '_GLO',
                        productId: 2,
                        productCode: "51617",
                        productName: "GLO 150",
                        paymentCurrency: "NGN",
                        isAmountFixed: 'Y',
                        amount: 150
                    },
                    {
                        operatorId: '_GLO',
                        productId: 3,
                        productCode: "51617",
                        productName: "GLO 200",
                        paymentCurrency: "NGN",
                        isAmountFixed: 'Y',
                        amount: 200
                    },
                    {
                        operatorId: '_GLO',
                        productId: 4,
                        productCode: "51617",
                        productName: "GLO 400",
                        paymentCurrency: "NGN",
                        isAmountFixed: 'Y',
                        amount: 400
                    },
                    {
                        operatorId: '_GLO',
                        productId: 5,
                        productCode: "51617",
                        productName: "GLO 750",
                        paymentCurrency: "NGN",
                        isAmountFixed: 'Y',
                        amount: 750
                    },
                    {
                        operatorId: '_GLO',
                        productId: 6,
                        productCode: "51617",
                        productName: "GLO 1500",
                        paymentCurrency: "NGN",
                        isAmountFixed: 'Y',
                        amount: 1500
                    },
                    {
                        operatorId: '_GLO',
                        productId: 7,
                        productCode: "51617",
                        productName: "GLO 3000",
                        paymentCurrency: "NGN",
                        isAmountFixed: 'Y',
                        amount: 3000
                    },

                    {
                        operatorId: 0,
                        productId: 100,
                        productCode: "51617",
                        productName: "Specify amount",
                        paymentCurrency: "NGN",
                        isAmountFixed: 'N',
                        amount: 0
                    }
                ]
            });
        });
    }




}
