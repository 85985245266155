import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher, MatSnackBar } from '@angular/material';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { VirtualCardService } from 'src/app/core/services/virtual-card.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-retrieve-pin',
  templateUrl: './retrieve-pin.component.html',
  styleUrls: ['./retrieve-pin.component.css']
})
export class RetrievePinComponent implements OnInit, OnDestroy {
  retrievePin: FormGroup;
  cardDetails:any;
  cardDetailsSub: Subscription;
  currentCardDetails: any;
  loaderIsActive: boolean;
  inputedValue: string;
  visibilityStatus: string[] = ['password', 'password'];

  show = false;

  matcher = new MyErrorStateMatcher();


  constructor(
    private _formBuilder: FormBuilder,
    private virtualCardService: VirtualCardService,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.retrievePin = this._formBuilder.group({
      newPin: new FormControl("", [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
      newPinCheck: new FormControl ("", [Validators.required, this.pinCheck.bind(this)])
    });
    this.cardDetailsSub = this.virtualCardService.cardDetails.subscribe(
      (response) => {
        this.cardDetails = response.card;
      }
    );

    let cardsObj: any  = {
      "cardReference": this.cardDetails.carD_REF,
      "accountNumber": this.cardDetails.accounT_NUM,
      "clientReference": this.cardDetails.clienT_REF
    }
    this.loaderIsActive = true;
    this.virtualCardService.fetchCards(cardsObj).subscribe(
      (response) => {
        this.loaderIsActive = false;
        if (response.responseCode === "000") {
          this.loaderIsActive = false;
          this.currentCardDetails = response.data.cards[0];
          console.log(this.currentCardDetails )
        } else {
          this.loaderIsActive = false;
          console.log("else statement")
          console.log(response.responseDescription)
          this._snackBar.open(response.responseDescription,
            'Error', { verticalPosition: 'bottom', horizontalPosition: 'right', duration: 2000 });
        }

      },
      (error) => {
        this.loaderIsActive = false
              console.log("an error")
              console.log(error)
              this._snackBar.open(error.error.responseDescription,
                'Error', { verticalPosition: 'bottom', horizontalPosition: 'right', duration: 2000 });
      }
    )
  }

  resetPin() {
    this.loaderIsActive = true;
    console.log(this.loaderIsActive)
    let formObj: any = {
      "cardReference": this.currentCardDetails.cardReference,
      "accountNumber": this.retrievePin.value.accountNum,
      "oldPin": this.currentCardDetails.pinInfo,
      "newPin": this.retrievePin.value.newPin
    }

    this.virtualCardService.changePin(formObj).subscribe(
            (response) => {
              if (response.responseCode === "000") {
                this.loaderIsActive = false;
                console.log(response)
                this._snackBar.open("Pin Reset Succesful",
                  '', { verticalPosition: 'bottom', horizontalPosition: 'right', duration: 3000 });
                  this.retrievePin.reset();
                  this.router.navigate(['../'], {relativeTo: this.route})

              } else {
                this.loaderIsActive = false;
                console.log("else statement")
                console.log(response.responseDescription)
                this._snackBar.open(response.responseDescription,
                  'Error', { verticalPosition: 'bottom', horizontalPosition: 'right', duration: 2000 });
              }

            },
            (error) => {
              this.loaderIsActive = false
              console.log("an error")
              console.log(error)
              this._snackBar.open(error.error.responseDescription,
                'Error', { verticalPosition: 'bottom', horizontalPosition: 'right', duration: 2000 });
            }
          );


  }

  onVisibilityChange(index:number) {
    this.visibilityStatus[index] === 'text'? this.visibilityStatus[index] = 'password': this.visibilityStatus[index] = 'text';
  }


  ngOnDestroy(): void {
    this.cardDetailsSub.unsubscribe();
  }

  pinCheck(control: FormControl): {[s: string]: boolean} {
    if (control.value == this.inputedValue) {
      return null
    } else {
      return {'pins do not match': true}
    }
  }

}
