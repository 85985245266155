import { Injectable } from '@angular/core';

@Injectable()
export class UtilityService {

    constructor() { }

    formatDateAsFriendlyShortString(date: Date): string {
        const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
        return `${date.getDate()}/${month}/${date.getFullYear()}`;
    }

    formatNumStringAsCurrency(numbString, currency?: string) {
        if (currency) {
            return numbString.toLocaleString('en-US', { style: 'currency', currency: currency });
        }
        return numbString.toLocaleString();
    }
    formatDateAsSystemShortString(date: Date): string {
        const month = date.getMonth() < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
        return `${date.getFullYear()}-${month}-${date.getDate() > 9 ? date.getDate() : '0' + date.getDate()}`;
    }



    //   private formatAsDateString(date: Date): string {
    //     const month = date.getMonth() < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    //     return `${date.getFullYear()}-${month}-${date.getDate()}`;
    //   }
}
