import { FlexibleConnectedPositionStrategy } from "@angular/cdk/overlay";
import { Component, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatPaginator, MatSnackBar, PageEvent } from "@angular/material";
import { Router } from "@angular/router";
import { AppMaterialModule } from "src/app/app.material.module";
import { RouterConstants } from "src/app/core/helpers/routerConstants";
import { EditRequest, FxBranch, FxRequestHistoryResponse, GetFxTypesResponse } from "src/app/core/models/GetFxTypesResponse";
import { NIBBSBranch } from "src/app/core/models/service-request/branch";
import { FXPaymentService } from "src/app/core/services/fx-payment.service";
import { FxRequestHistoryService } from "src/app/core/services/FxRequestHistory.service";
import { VariablesService } from "src/app/core/services/variables.service";
import { CancelFxRequestComponent } from "../cancel-fx-request/cancel-fx-request.component";

@Component({
    selector: "fx-transaction-request-history",
    templateUrl: "./fx-transaction-request-history.component.html",
    styleUrls: ["./fx-transaction-request-history.component.css"]
})
export class FxTransactionRequestHistory implements OnInit {
    
    constructor(private _service: FxRequestHistoryService,
        private _snackBar: MatSnackBar,
        private fx: FXPaymentService,
        private router:Router,
        private _dialog: MatDialog)
    {
       
       
        this.editFxForm = new FormGroup({
            amount: new FormControl('', [Validators.required]),
            bankBranch: new FormControl('', [Validators.required]),
            requestId: new FormControl()
        })
        
        
    }

    fxTypes: GetFxTypesResponse[]=[]
    fxBranches: any[] = []
    documentsUploaded = [];
    history: any[] = [];
    editFxForm: FormGroup;
    isLoading = false;
    fxRequestHistory: FxRequestHistoryResponse[] = [];
    fxRequestHistoryBackup: FxRequestHistoryResponse[] = [];
    loadingFxTypes = false;
    currentFxObject: FxRequestHistoryResponse;
    customerBvn: any;
    rowDef = ["SN", "Amount", "Currency", "Branch", "STATUS", "ACTION"]
    purchaseTypes: any[] = [];
    tranTypes: any[] = [];
    searchFilter: any = ''

    ngOnInit(): void {
        this.historyStage = true;
        this.editStage = false;
        this.viewStage = false;
        this.stage = '1';
        //console.log(VariablesService.CustomerBVN)
       this.LoadFxInformation();

    }

    @ViewChild("paginator") paginator: MatPaginator;

    ngAfterViewInit() {
        // this.dataSource.paginator = this.paginator;
        // this.dataSource.sort = this.sort;
      }

    
      LoadPurchaseTYpe() {
          this.fx.getPurchaseType().subscribe(res=>{
              console.log(res)
                this.purchaseTypes = res["data"]
          })
      }

      LoadTranType() {
        this.fx.getFxTypes().subscribe(res=>{
            console.log(res)
              this.tranTypes = res["data"]
        })
    }

      onRequestedPageChanged(pageEvent: PageEvent) {
     
        const start = pageEvent.pageIndex  * pageEvent.pageSize
        const end = start + pageEvent.pageSize
        //console.log(pageEvent.pageIndex)
        const filtered = this.fxRequestHistoryBackup.slice(start, end)
        console.log(filtered)
        this.fxRequestHistory = filtered;
        this.history = filtered
        //console.log("history", this.history)
    }

    isTruthy(haystack: FxRequestHistoryResponse, needle: any): boolean {
        return (
            haystack.status && haystack.status.toLowerCase().includes(needle.toLowerCase()) ||
            haystack.amountUSD && haystack.amountUSD == needle || 
            haystack.branchName && haystack.branchName.toLowerCase().includes(needle.toLowerCase())
        )
    }

    searchTable(event) {
       // console.log(event)
        let searchFilter = event.target.value
        console.log(this.searchFilter)
        if(this.searchFilter == '' || this.searchFilter.length == 0)  {
            this.fxRequestHistory = this.fxRequestHistoryBackup
        }
        else {
            var result = this.fxRequestHistoryBackup.filter(x=>x["status"].toLowerCase().includes(searchFilter.toLowerCase()))
            // var result = this.fxRequestHistory.filter(x=>{
            //     return (
            //         x=>this.isTruthy(x, this.searchFilter)
            //     )
            // })
            // this.history = result.slice(0, this.pageSize)
            // this.paginator.pageIndex = 0;

            //  console.log(result)
            if(result != null && result.length > 0) {
                this.history =this.SortOutBranchName(result)
            }
            else {
                result = this.fxRequestHistoryBackup.filter(x=>x["branchName"].toLowerCase().includes(searchFilter.toLowerCase()))
                 //console.log(result)
                if(result != null && result.length > 0) {
                    this.history =this.SortOutBranchName(result)
                }
                else {
                    result = this.fxRequestHistoryBackup.filter(x=>x["amountUSD"] == searchFilter)
                    console.log(result)
                    if(result != null && result.length > 0) {
                        console.log(result)
                        this.history =result
                    }
                }

              //  this.history = this.fxRequestHistory
            }
        }

       
        
    }

   SortOutBranchName(data: FxRequestHistoryResponse[]) {
        let rep = []

        for(let k of data) {
            const _branchInfo = this.fxBranches.filter(x=>x["branchCode"] == k["brancH_CODE"])
            let branchName = ""
            if(_branchInfo != null && _branchInfo.length > 0) {
               // console.log(_branchInfo[0])
                branchName = String(_branchInfo[0].branchName)
            }

            const fxHistory: FxRequestHistoryResponse = this.Copy(k, {})
            fxHistory["branchNameInfo"] = branchName
           // fxHistory["status"] = "PENDING"
            const dt = new Date(k['requesT_DATE'])
            const date = dt.toDateString();
            const time = dt.toTimeString()
            fxHistory["dateTimeString"] = `${date} | ${time}`
            const purChaseType = this.purchaseTypes.filter(x=>x["id"] == k["purchasE_TYPE"])
            if(purChaseType != null) {
                console.log(purChaseType)
                if(purChaseType.length > 0) {
                    fxHistory["purhcaseType"] = (purChaseType[0])["typeName"]
                }
            }

            const tranTypes = this.tranTypes.filter(x=>x["id"] == k["transactioN_TYPE"]);
            if(tranTypes != null) {
                console.log(tranTypes)
                if(tranTypes.length > 0) {
                    fxHistory["transactionType"] = (tranTypes[0])["typeName"]
                }
            }
            rep.push(fxHistory)
        }

        return rep;
    }

    Copy(source, target) {
        return Object.assign(target, source)
    }
    
    progressColor = 'primary'
    pageSize =10;
    stage = '1';
    prevStage = '0';
    editStage = false;
    viewStage = false;
    historyStage = false;
   
    currobject = {}
    processingEdit = false;
    statusInfo = {
        "N":"New Request",
       "I" : "initiated in branch",
        "P" : "Processed",
       "E": "Error",
        "R":"Rejected"

    }
    prevAmount: any;
    GoToNext(Id, stage) {
        //const obj = this._historyFiltered.filter(x=>x["Id"] == Id);
    //    this.currobject = obj
        this.prevStage = stage
        this.stage = stage
        if(this.stage == '3') {
        

            const find = this.FindById(Id)

            if(find != undefined) {
                this.currentFxObject = this.SortOutBranchName([find])[0];
                this.prevAmount = this.currentFxObject["amount"]
                this.get('amount').setValue(this.currentFxObject['amount'])
                //this.get('bankBranch').setValue(this.currentFxObject['branchCode'])
            }
        }

        if(this.stage == '2') {
            const find = this.FindById(Id)

            if(find != undefined) {
                this.currentFxObject = find;
              
                (this.editFxForm.get('bankBranch')).setValue(this.currentFxObject["branchCode"])
            }
        }


    }

    get(formName): AbstractControl {
        return (this.editFxForm.get(formName)) as AbstractControl
    }

    

    LoadFxInformation() {
        if (!VariablesService.CustomerBVN) {
            this.router.navigate([RouterConstants.FullDashboardURL]);
            return;
          }
         
      
          
          this.customerBvn = VariablesService.CustomerBVN;


          this.isLoading = true;
          setTimeout(()=>{
   
              this.loadingFxTypes = true;
              this._service.LoadFxTypes()
              .then(res=>{
                  this.fxTypes = res["data"];
                  //VariablesService
                  //VariablesService
                  this.loadingFxTypes = false;
              })
              .catch(er=>{
                  this.loadingFxTypes = false;
                  this._snackBar.open(er, "Ok", {
                      verticalPosition: "bottom",
                      horizontalPosition: "right",
                      duration: 1500,
                    });
              })
  
              this._service.LoadFxBranches()
                      .then(res=>{
                          console.log(res)
                          this.fxBranches = res["data"]
                          this.fxBranches = this.sortBranches(res["data"]);
                      })
                      .catch(er=>{
                          this._snackBar.open(er, "Ok", {
                              verticalPosition: "bottom",
                              horizontalPosition: "right",
                              duration: 1500,
                            });
                      })
  
                      
                      this.LoadPurchaseTYpe();
                      this.LoadTranType();
                      this._service.LoadFxRequestHistory({"bvn":this.customerBvn})
                      .then(res=>
                      {
                          this.fxRequestHistory = this.SortOutBranchName(res["data"])
                          this.isLoading = false;
                          //this.fxRequestHistoryBackup = res["data"]
                          this.fxRequestHistoryBackup = this.fxRequestHistory;
                          this.history = this.fxRequestHistory.slice(0, this.pageSize)
                          
                          //console.log(this.fxRequestHistory)
                          //this.fxBranches
                      }).catch(er=>{
                          this.isLoading = false;
                          this._snackBar.open(er, "Ok", {
                              verticalPosition: "bottom",
                              horizontalPosition: "right",
                              duration: 1500,
                            });
                      })
          }, 2000)
    }

    submitRequest() {
        const amt = this.get('amount').value;


        console.log("amt",amt)
        console.log("prev_amount",this.prevAmount)


        //if(amt == null || amt == '') {
        //     if(this.prevAmount) {
        //         this.get('amount').setErrors(null)
        //     }
        // }

        if(this.editFxForm.valid) {
            const editObject: EditRequest = {
                requestId: this.currentFxObject['id'],
                amount: this.get('amount').value,
                branchCode: this.get('bankBranch').value
            }
            this.processingEdit = true;
            this._service.EditRequest(editObject)
                .then(res=>{
                    this.processingEdit = false;
                    //console.log(res)
                    this.LoadFxInformation();
                    this.prevAmount = null;
                    this.stage = '1'
                    this._snackBar.open(res["responseDescription"], "OK", 
                    {
                        verticalPosition: "bottom",
                        horizontalPosition: "right",
                        duration: 3500,
                    })

                   
                })
                .catch(er=>{
                    this.processingEdit = false;
                    const _er = new Error(er)
                    this._snackBar.open(_er.message, "OK", 

                    {
                        verticalPosition: "bottom",
                        horizontalPosition: "right",
                        duration: 3500,
                    })
                })
        }
        else {
            return ''
        }
    }

    FindById(Id: Number): FxRequestHistoryResponse {
        const response = this.fxRequestHistory.filter(x=>x["id"] == Id)
        if (response != null && response.length > 0) {
            return response[0];
        }

        return undefined
    }

    GetStatusInfo(code) {
        if(code == "N" || code == "I") {
            return "PENDING"
        }

        if(code == "P" || code == "p") {
            return "COLLECTED"
        }

        if(code == "E") {
            return "ERROR"
        }

        if(code == "R") {
            return "REJECTED"
        }

        if(code == "C") {
            return "CANCELLED"
        }
    }

    successfullCanceled = false;
    CancelRequest(id) {
        const res = this._dialog.open(CancelFxRequestComponent, {
            hasBackdrop: true,
            disableClose: true,
            width: "500px",
            data: id
        })

        //this._dialog.afterAllClosed()

        res.afterClosed().subscribe(res=>{
            if(res != null) {
                //this.GoToNext('', res);
                //this.stage = res;
                this.successfullCanceled = true;
            }
        })
    }

    sortBranches(branchArr): NIBBSBranch[] {
        return branchArr.sort((a: NIBBSBranch, b: NIBBSBranch) => {
          // var textA = a.branchName.toUpperCase();
          // var textB = b.branchName.toUpperCase();
          // textA < textB ? -1 : textA > textB ? 1 : 0;
          var nameA = a.branchName.toUpperCase(); // ignore upper and lowercase
          var nameB = b.branchName.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
        
          // names must be equal
          return 0;
        });
      }

    goBack() {

        this.LoadFxInformation();
        if(this.stage == '3') {
            if(this.prevStage == '2') {
                this.stage = '2'
            }
            else {
                this.stage = '1'
            }
            
        }
        if(this.stage == '2') {
            this.stage = '1'
            this.successfullCanceled = false;
        }
    }

}